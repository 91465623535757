import { IEnvironment } from './ienvironment';

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment: IEnvironment = {
	production: true,
	restServices: [
		{
			name: 'metrics',
			get url() {
				return (
					window.location.protocol + '//' + window.location.hostname + '/rest/'
				);
			}
		}
	],
	authService: {
		name: 'auth',
		get url() {
			return (
				window.location.protocol + '//' + window.location.hostname + '/auth'
			);
		},
		clientId: 'HBApiClientID',
		clientPassword: 'arfuKT6MVZD59lM2tQNlVQ4dKVVx8+H1UCz8VGeQJUw='
	},
	realtimeServices: {
		get url() {
			return 'wss://' + window.location.hostname + '/mqtt/';
		},
		clientId: window.location.hostname + '-clientID'
	},
	showJSexception: false,
	checkFrontendEqualsBackend: true,
	firebaseConfig: {
		apiKey: 'AIzaSyB5p5lIeBW42n7tw2-NcMFUEmEdDxNMC8w',
		authDomain: 'trackyourplant-prod.firebaseapp.com',
		databaseURL: 'https://trackyourplant-prod.firebaseio.com',
		projectId: 'trackyourplant-prod',
		storageBucket: 'trackyourplant-prod.appspot.com',
		messagingSenderId: '816122505641',
		appId: '1:816122505641:web:61dd93ce0135701cbbfcb8'
	}
};
