import { Injectable } from '@angular/core';
import { ChartDefinitionBase } from './chart-definition-base';
import { IMachine } from '../api/domain-model';
import { MachineService } from '../api/machine.service';
import { NotificationService } from '../shared/notifications/notification.service';
import { ChartConfigService } from '../company/chart-config.service';
import { AuthService } from '../shared/auth-service';
import { TranslateService } from '../shared/translate';
import { IGaugeCardDefinition, IInterruptsTableCardDefinition, ISimpleContentCardDefinition, IWeightDataTableCardDefinition } from '../charts/definition-model';
import CardDisplayVariables from '../common/domain/card-display-variables';
import { Identifiers } from '../common/domain/bindings';

@Injectable()
export class ChartDefinitionEgfService extends ChartDefinitionBase {

	private machine: IMachine;

	constructor(
		public machineService: MachineService,
		public notificationService: NotificationService,
		public chartConfigService: ChartConfigService,
		public authService: AuthService,
		public translateService: TranslateService
	) {
		super();
	}

	setMachine(machine: IMachine): void {
		this.machine = machine;
	}

	getMachine(): IMachine {
		return this.machine;
	}

	getFillingTimeCardDefinition() {
		const requiredVariables = ['u32FillingTime', 'u32FillingTime'];
		return <ISimpleContentCardDefinition>{
			neededValues: [requiredVariables],
			historyVariables: requiredVariables,
			title: 'A150FillingTime',
			cardBatchTitle: 'A150FillingTime',
			unit: 'A122Seconds',
			displayVariable: CardDisplayVariables.egfCardDisplayVariables().fillingTime,
		}
	}

	getIncorrectWeightCardDefinition() {
		const requiredVariables = ['u32WrongWeight', 'u32WrongWeightBatch'];
		return <ISimpleContentCardDefinition>{
			neededValues: [requiredVariables],
			historyVariables: requiredVariables,
			title: 'A238IncorrectWeight',
			cardBatchTitle: 'A238IncorrectWeight',
			unit: 'A020Count',
			displayVariable: CardDisplayVariables.egfCardDisplayVariables().incorrectWeight,
		}
	}

	getTypeChangeCardDefinition() {
		const requiredVariables = ['u16SortChgDay', 'u16SortChgBatch'];
		return <ISimpleContentCardDefinition>{
			neededValues: [requiredVariables],
			historyVariables: requiredVariables,
			title: 'A035TypeChange',
			cardBatchTitle: 'A035TypeChangeBatch',
			unit: 'A020Count',
			displayVariable: CardDisplayVariables.egfCardDisplayVariables().typeChangesToday,
		}
	}

	getProductionChartDefinition(): IGaugeCardDefinition {
		return <IGaugeCardDefinition>{
			chartOptions: this.chartConfigService.anyGaugeChartSpec(
				this.machine,
				'produktionGutsaeckeSpec',
				0,
				100,
				Identifiers.egfGoodProductionCards,
				null
			).options,
			title: 'A129BagManufacture',
			unit: 'A027Percent',
			historyVariables: Identifiers.egfGoodProductionCards[2],
			colorHistoryVariables: Identifiers.generalPageProductionCardVariables,
			displayVariable: CardDisplayVariables.egfCardDisplayVariables().goodProduction
		};
	}

	getInterruptsCardDefinition(): IInterruptsTableCardDefinition {
		const requiredVariables = [
			...Identifiers.egfInterruptCard.countParams,
			...Identifiers.egfInterruptCard.timeParams
		];
		return {
			neededValues: requiredVariables,
			historyVariables: ['u16NoReleaseCnt'],
			title: 'A284Interrupts',
			cardBatchTitle: 'A284InterruptsBatch',
			unit: 'A124Minutes',
			machineType: '',
			countParams: Identifiers.egfInterruptCard.countParams,
			timeParams: Identifiers.egfInterruptCard.timeParams,
			titleParams: Identifiers.egfInterruptCard.titleParams,
			displayVariable: CardDisplayVariables.egfCardDisplayVariables().interruptonsToday,
		}
	}

	getWeightFillingStationDataCardDefinition(): IWeightDataTableCardDefinition {
		const requiredVariables = Identifiers.fillingStationWeightCard;
		return {
			neededValues: requiredVariables,
			historyVariables: ['s32MeanNetScale'],
			netScalePrefix: 's32MeanNetScale',
			netWeightsPrefix: 's32MeanWeights',
			decimals: 's16MeanDecimals',
			title: 'A422WeightData',
			cardBatchTitle: 'A422WeightData',
			unit: 's16MeanUnit',
			multilineChartSpec: this.chartConfigService.fillingStationNetWeighterMultiLineChartConfig(
				Identifiers.fillingStationWeightCard,
				['u8NetWeighersCnt', 'u8SpoutCnt']
			),
			displayVariable: CardDisplayVariables.egfCardDisplayVariables().weightData,
		}
	}

	getStandardDeviationDataCardDefinition(): IWeightDataTableCardDefinition {
		const requiredVariables = Identifiers.fillingStationStandardDeviationCard;
		return {
			neededValues: requiredVariables,
			historyVariables: ['s32StdDevNetScale'],
			netScalePrefix: 's32StdDevNetScale',
			netWeightsPrefix: 's32StdDev',
			decimals: 's16StdDevDecimals',
			title: 'A423StdDevData',
			cardBatchTitle: 'A423StdDevData',
			unit: 's16StdDevUnit',
			multilineChartSpec: this.chartConfigService.fillingStationNetWeighterMultiLineChartConfig(
				Identifiers.fillingStationStandardDeviationCard,
				['u8NetWeighersCnt', 'u8SpoutCnt']
			),
			displayVariable: CardDisplayVariables.egfCardDisplayVariables().stdDevData,
		}
	}
}
