import { Injectable } from '@angular/core';
import { ChartDefinitionBase } from './chart-definition-base';
import { IMachine } from '../api/domain-model';
import { MachineService } from '../api/machine.service';
import { NotificationService } from '../shared/notifications/notification.service';
import { ChartConfigService } from '../company/chart-config.service';
import { AuthService } from '../shared/auth-service';
import { TranslateService } from '../shared/translate';
import { IGaugeCardDefinition, IInterruptsTableCardDefinition, INotPlacedTableCardDefinition, ISimpleContentCardDefinition, ISpoutTableCardDefinition, IWeightDataTableCardDefinition } from '../charts/definition-model';
import CardDisplayVariables from '../common/domain/card-display-variables';
import { Identifiers } from '../common/domain/bindings';

@Injectable()
export class ChartDefinitionRvt8rotopackerService extends ChartDefinitionBase {

	private machine: IMachine;

	constructor(
		public machineService: MachineService,
		public notificationService: NotificationService,
		public chartConfigService: ChartConfigService,
		public authService: AuthService,
		public translateService: TranslateService
	) {
		super();
	}

	setMachine(machine: IMachine): void {
		this.machine = machine;
	}

	getMachine(): IMachine {
		return this.machine;
	}

	getSpoutCardDefinition(): ISpoutTableCardDefinition {
		const requiredVariables = Identifiers.spoutCard;
		return {
			neededValues: requiredVariables,
			historyVariables: ['u32SpoutProdCnt'],
			title: 'A023BagCounterPerFS',
			cardBatchTitle: 'A023BagCounterPerFSBatch',
			unit: 'A027Percent',
			labelPrefix: 'A024FillingStation',
			displayVariable: CardDisplayVariables.rvt8rotopackerCardDisplayVariables().bagCounterToday,
		}
	}

	getTypeChangeCardDefinition(): ISimpleContentCardDefinition {
		const requiredVariables = ['u16SortChgDay', 'u16SortChgBatch'];
		return <ISimpleContentCardDefinition>{
			neededValues: [requiredVariables],
			historyVariables: requiredVariables,
			title: 'A035TypeChange',
			cardBatchTitle: 'A035TypeChangeBatch',
			unit: 'A020Count',
			displayVariable: CardDisplayVariables.rvt8rotopackerCardDisplayVariables().typeChangesToday,
		}
	}

	getFSDifferenceCardDefinition(): ISimpleContentCardDefinition {
		const requiredVariables = ['u8SpoutProdSpreadPercent', 'u8SpoutProdSpreadPercentBatch'];
		return <ISimpleContentCardDefinition>{
			neededValues: [requiredVariables],
			historyVariables: requiredVariables,
			title: 'A025FSDifference',
			cardBatchTitle: 'A025FSDifference',
			unit: 'A027Percent',
			displayVariable: CardDisplayVariables.rvt8rotopackerCardDisplayVariables().spreadBagCounter,
		}
	}

	getRejectionsTodayCardDefinition(): ISimpleContentCardDefinition {
		const requiredVariables = ['u32RejectCnt', 'u32RejectCntBatch'];
		return <ISimpleContentCardDefinition>{
			neededValues: [requiredVariables],
			historyVariables: requiredVariables,
			title: 'A028RejectionsToday',
			cardBatchTitle: 'A028RejectionsTodayBatch',
			unit: 'A020Count',
			displayVariable: CardDisplayVariables.rvt8rotopackerCardDisplayVariables().rejectsToday,
		}
	}

	getInterruptsCardDefinition(): IInterruptsTableCardDefinition {
		const requiredVariables = [...Identifiers.rvt8InterruptCard.countParams,
			...Identifiers.rvt8InterruptCard.timeParams];
		return {
			neededValues: requiredVariables,
			historyVariables: ['u16StopFilterCnt'],
			title: 'A284Interrupts',
			cardBatchTitle: 'A284InterruptsBatch',
			unit: 'A124Minutes',
			machineType: '',
			countParams: Identifiers.rvt8InterruptCard.countParams,
			timeParams: Identifiers.rvt8InterruptCard.timeParams,
			titleParams: Identifiers.rvt8InterruptCard.titleParams,
			displayVariable: CardDisplayVariables.rvt8rotopackerCardDisplayVariables().interruptionsToday,
		}
	}

	getSpeedRelatedProductionCardDefinition(): IGaugeCardDefinition {
		return <IGaugeCardDefinition>{
			chartOptions: this.chartConfigService.anyGaugeChartSpec(
				this.machine,
				'leistungPackerDrehzahlSpec',
				0,
				100,
				Identifiers.rvt8RotoPckerSpeedRelatedProductionCard,
				null
			).options,
			title: 'A036ProductionRate',
			unit: 'A130BagsPerHour',
			historyVariables: Identifiers.rvt8RotoPckerSpeedRelatedProductionCard[2],
			colorHistoryVariables: Identifiers.rvt8RotoPckerSpeedRelatedProductionCard,
			displayVariable: CardDisplayVariables.rvt8rotopackerCardDisplayVariables().speedRelatedProduction
		};
	}

	getProductionChartDefinition(): IGaugeCardDefinition {
		return <IGaugeCardDefinition>{
			chartOptions: this.chartConfigService.anyGaugeChartSpec(
				this.machine,
				'produktionGutsaeckeSpec',
				0,
				100,
				Identifiers.rvt8RotoPackerGoodProductionCard,
				null
			).options,
			title: 'A039GoodWeights',
			unit: 'A027Percent',
			historyVariables: Identifiers.rvt8RotoPackerGoodProductionCard[2],
			colorHistoryVariables: Identifiers.rvt8RotoPackerGoodProductionCard,
			displayVariable: CardDisplayVariables.rvt8rotopackerCardDisplayVariables().goodProduction
		};
	}

	getWeightFillingStationDataCardDefinition(): IWeightDataTableCardDefinition {
		const requiredVariables = Identifiers.fillingStationWeightCard;
		return {
			neededValues: requiredVariables,
			historyVariables: ['s32MeanNetScale'],
			netScalePrefix: 's32MeanNetScale',
			netWeightsPrefix: 's32MeanWeights',
			decimals: 's16MeanDecimals',
			title: 'A422WeightData',
			cardBatchTitle: 'A422WeightData',
			unit: 's16MeanUnit',
			multilineChartSpec: this.chartConfigService.fillingStationNetWeighterMultiLineChartConfig(
				Identifiers.fillingStationWeightCard,
				['u8NetWeighersCnt', 'u8SpoutCnt']
			),
			displayVariable: CardDisplayVariables.rvt8rotopackerCardDisplayVariables().weightData,
		}
	}

	getStandardDeviationDataCardDefinition(): IWeightDataTableCardDefinition {
		const requiredVariables = Identifiers.fillingStationStandardDeviationCard;
		return {
			neededValues: requiredVariables,
			historyVariables: ['s32StdDevNetScale'],
			netScalePrefix: 's32StdDevNetScale',
			netWeightsPrefix: 's32StdDev',
			decimals: 's16StdDevDecimals',
			title: 'A423StdDevData',
			cardBatchTitle: 'A423StdDevData',
			unit: 's16StdDevUnit',
			multilineChartSpec: this.chartConfigService.fillingStationNetWeighterMultiLineChartConfig(
				Identifiers.fillingStationStandardDeviationCard,
				['u8NetWeighersCnt', 'u8SpoutCnt']
			),
			displayVariable: CardDisplayVariables.rvt8rotopackerCardDisplayVariables().stdDevData,
		}
	}

	getNotPlacedCardDefinition(): INotPlacedTableCardDefinition {
		const requiredVariables = Identifiers.notPlacedCard;
		return {
			neededValues: requiredVariables,
			historyVariables: ['u32SpoutPlacedCnt'],
			title: 'A234NotPlacedBags',
			cardBatchTitle: 'A234NotPlacedBags',
			unit: 'A016Units',
			labelPrefix: 'A024FillingStation',
			stackedAreaChartSpec: this.chartConfigService.bagCounterStackedAreaChartConfig(
				Identifiers.rvt8RotoPackerNotplacedBagsCard,
				'u8SpoutCnt',
				'A016Units'
			),
			displayVariable: CardDisplayVariables.rvt8rotopackerCardDisplayVariables().notPlacedBags,
		}
	}
}
