import { Injectable } from '@angular/core';
import { ChartDefinitionBase } from './chart-definition-base';
import { IMachine } from '../api/domain-model';
import { MachineService } from '../api/machine.service';
import { NotificationService } from '../shared/notifications/notification.service';
import { ChartConfigService } from '../company/chart-config.service';
import { AuthService } from '../shared/auth-service';
import { TranslateService } from '../shared/translate';
import { IGaugeCardDefinition, IInterruptsTableCardDefinition, ISimpleContentCardDefinition } from '../charts/definition-model';
import { Identifiers } from '../common/domain/bindings';

@Injectable()
export class ChartDefinitionFeigeService extends ChartDefinitionBase {

	private machine: IMachine;

	constructor(
		public machineService: MachineService,
		public notificationService: NotificationService,
		public chartConfigService: ChartConfigService,
		public authService: AuthService,
		public translateService: TranslateService
	) {
		super();
	}

	setMachine(machine: IMachine): void {
		this.machine = machine;
	}

	getMachine(): IMachine {
		return this.machine;
	}

	getBatchStartingTime(): ISimpleContentCardDefinition {
		const requiredVariables = ['sResetTime', 'bResetDay'];
		return <ISimpleContentCardDefinition>{
			neededValues: [requiredVariables],
			historyVariables: requiredVariables,
			title: 'A110StartOfOrder',
			cardBatchTitle: 'A110StartOfOrder',
			unit: '',
			displayVariable: '',
		}
	}

	getProductIdCardDefinition(): ISimpleContentCardDefinition {
		const requiredVariables = ['u16ProductID', 'u16ProductID'];
		return <ISimpleContentCardDefinition>{
			neededValues: [requiredVariables],
			historyVariables: requiredVariables,
			title: 'A111ProductID',
			cardBatchTitle: 'A111ProductID',
			unit: 'A016Units',
			displayVariable: '',
		}
	}

	getTargetWeightCardDefinition(): ISimpleContentCardDefinition {
		const requiredVariables = ['u16TargetWeight', 'u16TargetWeight'];
		return <ISimpleContentCardDefinition>{
			neededValues: [requiredVariables],
			historyVariables: requiredVariables,
			title: 'A112TargetWeight',
			cardBatchTitle: 'A112TargetWeight',
			unit: 'A113Kg',
			displayVariable: '',
		}
	}

	getFillingCountCardDefinition(): ISimpleContentCardDefinition {
		const requiredVariables = ['u16FillingCnt', 'u16FillingCnt'];
		return <ISimpleContentCardDefinition>{
			neededValues: [requiredVariables],
			historyVariables: requiredVariables,
			title: 'A115FillingCount',
			cardBatchTitle: 'A115FillingCount',
			unit: 'A016Units',
			displayVariable: '',
		}
	}

	getRemainingNumberFillingsCardDefinition(): ISimpleContentCardDefinition {
		const requiredVariables = ['u16FillingRemain', 'u16FillingRemain'];
		return <ISimpleContentCardDefinition>{
			neededValues: [requiredVariables],
			historyVariables: requiredVariables,
			title: 'A203RemainingNumberFillings',
			cardBatchTitle: 'A203RemainingNumberFillings',
			unit: 'A020Count',
			displayVariable: '',
		}
	}

	getFaultLengthCardDefinition(): ISimpleContentCardDefinition {
		const requiredVariables = ['u16FaultMachTime', 'u16FaultMachTime'];
		return <ISimpleContentCardDefinition>{
			neededValues: [requiredVariables],
			historyVariables: requiredVariables,
			title: 'A123FaultLength',
			cardBatchTitle: 'A123FaultLength',
			unit: 'A124Minutes',
			displayVariable: '',
		}
	}

	getExtFaultLengthCardDefinition(): ISimpleContentCardDefinition {
		const requiredVariables = ['u16FaultExternTime', 'u16FaultExternTime'];
		return <ISimpleContentCardDefinition>{
			neededValues: [requiredVariables],
			historyVariables: requiredVariables,
			title: 'A125ExtFaultLength',
			cardBatchTitle: 'A125ExtFaultLength',
			unit: 'A124Minutes',
			displayVariable: '',
		}
	}

	getFillingCountProdCardDefinition(): ISimpleContentCardDefinition {
		const requiredVariables = ['u32ProdAll', 'u32ProdAll'];
		return <ISimpleContentCardDefinition>{
			neededValues: [requiredVariables],
			historyVariables: requiredVariables,
			title: 'A126FillingCount',
			cardBatchTitle: 'A126FillingCount',
			unit: 'A020Count',
			displayVariable: '',
		}
	}

	getWithinToleranceCardDefinition(): ISimpleContentCardDefinition {
		const requiredVariables = ['u16InsideLimit', 'u16InsideLimit'];
		return <ISimpleContentCardDefinition>{
			neededValues: [requiredVariables],
			historyVariables: requiredVariables,
			title: 'A127WithinTolerance',
			cardBatchTitle: 'A127WithinTolerance',
			unit: 'A020Count',
			displayVariable: '',
		}
	}

	getOutsideToleranceCardDefinition(): ISimpleContentCardDefinition {
		const requiredVariables = ['u16OutsideLimit', 'u16OutsideLimit'];
		return <ISimpleContentCardDefinition>{
			neededValues: [requiredVariables],
			historyVariables: requiredVariables,
			title: 'A128OutsideTolerance',
			cardBatchTitle: 'A128OutsideTolerance',
			unit: 'A020Count',
			displayVariable: '',
		}
	}

	getFillingsChartDefinition(): IGaugeCardDefinition {
		return <IGaugeCardDefinition>{
			chartOptions: this.chartConfigService.anyGaugeChartSpec(
				this.machine,
				'fillingsFeigeSpec',
				0,
				100,
				Identifiers.fiegeFillingCard,
				null
			).options,
			title: 'A106Fillings',
			unit: 'A107FillingsPerHour',
			historyVariables: Identifiers.fiegeFillingCard[2],
			colorHistoryVariables: Identifiers.fiegeFillingCard,
			displayVariable: ''
		};
	}

	getFeigeMaintenanceChartDefinition(): IGaugeCardDefinition {
		return <IGaugeCardDefinition>{
			chartOptions: this.chartConfigService.anyGaugeChartSpec(
				this.machine,
				'maintenanceFeigeSpec',
				0,
				100,
				Identifiers.fiegeMaintenanceCard,
				null
			).options,
			title: 'A012Maintenance',
			unit: 'A016Units',
			historyVariables: Identifiers.fiegeMaintenanceCard[2],
			colorHistoryVariables: Identifiers.fiegeMaintenanceCard,
			displayVariable: ''
		};
	}

	getCycleTimeCardDefinition(): IInterruptsTableCardDefinition {
		const requiredVariables = [
			...Identifiers.feigeCycleTimeCard.timeParams
		];
		return {
			neededValues: requiredVariables,
			historyVariables: ['u16GeneralTime'],
			title: 'A116CycleTime',
			cardBatchTitle: 'A116CycleTime',
			unit: 'A122Seconds',
			countParams: [],
			machineType: '',
			timeParams: Identifiers.feigeCycleTimeCard.timeParams,
			titleParams: Identifiers.feigeCycleTimeCard.titleParams,
			displayVariable: '',
		}
	}

	getInterruptsCardDefinition(): IInterruptsTableCardDefinition {
		const requiredVariables = [
			...Identifiers.feigeInterruptCard.countParams,
			...Identifiers.feigeInterruptCard.timeParams
		];
		return {
			neededValues: requiredVariables,
			historyVariables: ['u16FaultCenteringCnt'],
			title: 'A123FaultLength',
			cardBatchTitle: 'A123FaultLength',
			unit: 'A124Minutes',
			machineType: '',
			countParams: Identifiers.feigeInterruptCard.countParams,
			timeParams: Identifiers.feigeInterruptCard.timeParams,
			titleParams: Identifiers.feigeInterruptCard.titleParams,
			displayVariable: '',
		}
	}
}

