export const LANG_ES_NAME = 'es';
export const LANG_ES_TRANS = {
	'0030565001AE':'ELEMENTRA® ',
	'003056500368':'INTEGRA®',
	'003056500394':'ROTO-PACKER®',
	'003056500398':'ROTO-PACKER®',
	'0030565011A8':'ROTO-PACKER®',
	'0030565011AE':'ELEMENTRA®',
	'003056501200':'ROTO-PACKER®',
	'00305650121E':'INTEGRA®',
	'003056A98058':'ROTO-PACKER®',
	'057X1to2Rpm':'1X-2X Rpm',
	'057X2to3Rpm':'2.5X - 3X Rpm',
	'A000Key':'Llave',
	'A001Country':'Español',
	'A002German':'Deutsch',
	'A003English':'English',
	'A004Username':'Correo electrónico',
	'A005Login':'Acceso a su cuenta',
	'A006Password':'Contraseña',
	'A007RemPassword':'Mantenme conectado',
	'A008Login':'Iniciar sesión',
	'A009Logout':'Cerrar sesión',
	'A010Production':'Producción',
	'A011Availability':'Disponibilidad',
	'A012Maintenance':'Mantenimiento de máquina',
	'A013RemainingUnits':'Unidades restantes',
	'A014ProductionTime':'Tiempo total',
	'A015ProductionTodayGeneral':'Producción hoy',
	'A015ProductionToday':'Producción hoy',
	'A016Units':'Unidades',
	'A017RemProduction':'Producción total',
	'A018LastReset':'Último reinicio',
	'A019Faults':'Fallos hoy',
	'A019FaultsBatch':'Fallos',
	'A020Count':'Contar',
	'A021FaultDuration':'Duración del fallo hoy',
	'A022Minutes':'min',
	'A023BagCounterPerFS':'Contador de sacos hoy',
	'A023BagCounterPerFSBatch':'Contador de sacos ',
	'A024FillingStation':'Estación de servicio',
	'A025FSDifference':'Contador de sacos de dispersión',
	'A026AtLeast1Caps':'al menos 1 letra mayúscula',
	'A026FSworstspout':'y peor boca de envasado ',
	'A027Percent':'Por ciento',
	'A028RejectionsToday':'Devoluciones hoy',
	'A028RejectionsTodayBatch':'Devoluciones',
	'A029FilterStop':'Filtro',
	'A030RotaryDriveStop':'ROTO-PACKER®',
	'A031ProductFeedStop':'Alimentación del producto',
	'A032RadimatStop':'RADIMAT®',
	'A033PalletizerStop':'Paletizador',
	'A034CompAirFault':'Aire comprimido',
	'A035TypeChange':'Cambio de productos hoy',
	'A035TypeChangeBatch':'Cambio de productos',
	'A036ProductionRate':'Producción relacionada con la velocidad',
	'A037SpeedRelated':'Sacos / hora',
	'A038UnitsPerHour':'Unidades / hora',
	'A039GoodWeights':'Buena producción',
	'A040ScissorHose':'Manguera de tijera',
	'A041ConnectingHose':'Manguera de conexión',
	'A042ConeGasket':'Junta de cono',
	'A043AerationPlates':'Placas de aireación',
	'A043ScissorHose':'Manguera de tijera',
	'A044ConnectingHose':'Manguera de conexión',
	'A044RegulationValve':'Válvula de regulación de transporte de aire',
	'A045ConeFeeding':'Alimentación de productos de cono',
	'A045ConeGasket':'Junta de cono',
	'A046AerationPlates':'Placas de aireación',
	'A046BagTransBelt':'Cinta transportadora de sacos',
	'A047BagTransBearing':'Rodamiento de anillo de sujeción para sacos',
	'A047RegulationValve':'Válvula de regulación',
	'A048ConeFeeding':'Alimentación de productos de cono',
	'A048SuctionCup':'Ventosa',
	'A049BagTransBelt':'Cinturón de transporte de bolsa',
	'A049BagTransCyl':'Transporte de bolsa de cilindro',
	'A050BagTransBearing':'Insertar rodamiento',
	'A050SucRailCyl':'Carril de succión del cilindro',
	'A051SuctionCup':'Ventosa',
	'A051VacuumPump':'Bomba de vacío',
	'A052BagTransCyl':'Cilindro de transporte de sacos ',
	'A052BagTransCylinde':'Cilindro de transporte de sacos ',
	'A052CableHightAdj':'Cable de ajuste de altura del asiento',
	'A053HullMinSensor':'Funda de sonda de nivel mínimo',
	'A053SucRailCyl':'Cilindro de ventosa',
	'A053SucRailCylinder':'Cilndro de ventosa',
	'A054Last48Hours':'Últimas 48 horas',
	'A054VacuumPump':'Bomba de vacío',
	'A055CableHightAdj':'Secador de aire comprimido',
	'A055Last14Days':'Últimos 14 días',
	'A056HullMinSensor':'Compresor de pistón',
	'A056off':'apagado',
	'A057InOperation':'Operando',
	'A058Faulty':'Fallo',
	'A059InMaintenance':'en mantenimiento',
	'A060HourlyValues':'Tasa horaria para',
	'A061DischargesTotal':'Total de sacos descargados',
	'A062CreationDate':'Fecha de creación',
	'A062Period':'período',
	'A063To':'para',
	'A064BatchValue':'Valores de lote',
	'A064DailyValue':'Valores diarios',
	'A064DailyValues':'Valores diarios para',
	'A065supply':'suministrar',
	'A066Transport':'Transporte ',
	'A067Feeding':'Suministrando producto',
	'A068PackagesMissing':'Saco ausente',
	'A069NoHistoricalData':'No hay datos históricos para el período comprendido entre',
	'A070And':'y',
	'A071Available':'disponible',
	'A072ReallyReset':'¿Reiniciar realmente?',
	'A073Details':'detalles',
	'A074General':'en general',
	'A075Machines':'Máquinas ',
	'A076Dashborad':'Panel de control',
	'A077Administration':'administración',
	'A078UserRoles':'Usuarios y roles',
	'A079Companies':'compañías',
	'A080Machines':'Máquinas (dev)',
	'A081Date':'Fecha',
	'A082Transport':'Transporte',
	'A083Containermissing':'Falta el contenedor ',
	'A084Availability':'disponibilidad',
	'A085TypeChanges':'Escriba los cambios hoy',
	'A086EmptyRejects':'Sacos vacíos rechazados hoy',
	'A086EmptyRejectsBatch':'Sacos vacíos rechazados',
	'A087FullRejects':'Sacos llenos rechazados hoy',
	'A087FullRejectsBatch':'Sacos llenos rechazados',
	'A088OpRelease':'Lanzamiento inicial ',
	'A089ProdFeedStop':'DETENER el flujo de producto',
	'A090AuxDrive':'accionamiento auxiliar',
	'A091Consumables':'consumibles',
	'A092DischLineStop':'DETENER la línea de descarga de sacos',
	'A093TypeChange':'Cambio de tipo',
	'A094DailyProduction':'Producción del día',
	'A094DailyProductionBatch':'Producción',
	'A095Energy':'Energía',
	'A096EffectivePower':'Potencia efectiva',
	'A097ApparentPower':'Potencia aparente',
	'A098CompAirFlow':'Indice de flujo de aire comprimido',
	'A099ResetFailed':'Error al restablacer la métrica',
	'A1000Spanish':'Español',
	'A1000UploadMaintenanceAid':'Descargar ayudas de mantenimiento',
	'A1001UploadMaintenanceAidSuccess':'Ayudas de mantenimiento descargadas con éxito',
	'A1002UploadMaintenanceAidFailure':'Error al descargar las ayudas de mantenimiento',
	'A1005MachineServiceRequest':'Petición de servicio',
	'A1006RequestService':'Solicitud de servicio',
	'A1007ServiceContract':'contrato de servicio',
	'A1008ServiceRequestSuccesful':'Solicitud de servicio procesada con éxito',
	'A1009ServiceRequestFailure':'Solicitud de servicio fallida',
	'A100AcessDenied':'Acceso denegado.',
	'A100AlertmailSubject':'Alerta QSI: MAAQUINA_NOMBRE',
	'A1010LogBook':'Libro de registro',
	'A1011CreateLogBook':'Crear entrada de libro de registro',
	'A1012EditLogBook':'Editar entrada del libro de registro',
	'A1013LogBookItemType':'Tipo de entrada del libro de registro',
	'A1014LogBookItem':'Entrada del libro de registro',
	'A1015LogBookItemSaved':'Entrada de libro de registro guardada',
	'A1016ErrorInSavingLogBookItem':'Error al guardar la entrada del libro de registro',
	'A1017ErrorInRetrievingLogBookItem':'Error al recuperar el libro de registro',
	'A1018ErrorInDeletingLogBookItem':'Error al eliminar el elemento del libro de registro',
	'A1019LogBookItemDeleted':'Elemento de libro de registro eliminado',
	'A101MailSalutation':'Hola, NOMBRE DE USUARIO:',
	'A101ResetSuccessful':'La métrica ha sido restablecida',
	'A1020LogBookItemIsDone':'Hecho',
	'A1021LogBookItemType':'Tipo de entrada',
	'A1022ServiceEmail':'Servicio de correo electrónico',
	'A1023CannotMakeServieRequest':'No puede solicitar un servicio ya que esta máquina no tiene ningún correo electrónico de servicio',
	'A1024MaxUsers':'Usuarios máximos',
	'A1024MaxUsersReached':'Se ha alcanzado el límite máximo de usuarios de la empresa',
	'A1025AtLeast8Characters':'Al menos 8 carácteres',
	'A1027AtLeast1Lowercase':'al menos 1 letra minúscula',
	'A1028AtLeast1Number':'al menos 1 número (0-9)',
	'A1029AtLeast1SpecialChar':'al menos 1 carácter especial (!,. [])',
	'A102CompanyAddressNewtec':'NEWTEC BAG PALLETIZING <br>32 avenue de Suisee <br>ZI Ile Napoléon - BP 256 <br>68315 Illzach cedex - France',
	'A102CompanyAddressAventus':'AVENTUS GmbH & Co. KG <br>Katzheide 33 <br>D-48231 Warendorf <br>Germany / Deutschland',
	'A102CompanyAddressNiagaraCanada':'HAVER & BOECKER Niagara Canada <br>225 Ontario St. <br>St. Catharines, ON, L2R 7B6 <br>Canada',
	'A102CompanyAddressNiagaraChile':'HAVER & BOECKER Andina <br>Av. El salto 4001, piso 7, of. 71 Comuna Huechuraba <br>8580641 – Santiago <br>Chile',
	'A102CompanyAddressNiagaraBrazil':'HAVER & BOECKER NIAGARA Latinoamericana <br>Av. Dra. Celia Marli S. Salgado Matos, 192 <br>Pedro Leopoldo - MG - 33600-000 <br>Brazil',
	'A102CompanyAddressNiagara':'HAVER & BOECKER NIAGARA <br>Robert-Bosch-Straße 6 <br>D-48153 Münster <br>Germany / Deutschland',
	'A102CompanyAddressFeige':'Feige FILLING GmbH <br> Rogen 6a <br> D-23843 Bad Oldesloe <br> Germany / Deutschland',
	'A102CompanyAddressH_B':' HAVER & BOECKER OHG en Carl-Haver-Platz 3 en D-59302 Oelde en Alemania / Alemania ',
	'A102CompanyAddressBehnBates':'Behn Bates Maschinenfabrik GmbH & Co. KG <br> Robert-Bosch Str. 6 <br> 48153 Münster <br> Germany',
	'A102CompanyEmailAventus':'hotline@aventus.global',
	'A102CompanyEmailNiagaraCanada':'info@haverniagara.ca',
	'A102CompanyEmailNiagaraChile':'haverandina@haverniagara.cl',
	'A102CompanyEmailNiagaraBrazil':'info@haverniagara.com.br',
	'A102CompanyEmailNiagara':'info@haverniagara.com',
	'A102CompanyEmailNewtec':'service@newtecbag.com',
	'A102CompanyEmailFeige':'hotline@feige.com',
	'A102CompanyEmailH_B':'hotline@haverboecker.com',
	'A102CompanyEmailBehnBates':'service@behnbates.com',
	'A102CompanyPhoneNumberNewtec':'Phone: +33 389633753',
	'A102CompanyPhoneNumberFeige':'Phone: +49 4531 89 09 222',
	'A102CompanyPhoneNumberAventus':'Phone: +49 2581 4591 2222',
	'A102CompanyPhoneNumberNiagaraCanada':'Phone: +1 800-325-5993',
	'A102CompanyPhoneNumberNiagaraChile':'Telefon: +56 (2) 2307-0440',
	'A102CompanyPhoneNumberNiagaraBrazil':'Telefon: +55 (31) 3661-1371',
	'A102CompanyPhoneNumberNiagara':'Phone: +49 251 9793 164',
	'A102CompanyPhoneNumberH_B':'Phone: +49 2522 30 371 ',
	'A102CompanyPhoneNumberBehnBates':'Phone: +49 251 979- 0',
	'A102CompanyWebsiteNewtec':'www.newtecbag.com',
	'A102CompanyWebsiteFeige':'www.feige.com',
	'A102CompanyWebsiteAventus':'www.aventus.global',
	'A102CompanyWebsiteNiagaraCanada':'https://haverniagara.com',
	'A102CompanyWebsiteNiagaraChile':'https://haverniagara.com',
	'A102CompanyWebsiteNiagaraBrazil':'www.brazilniagara.com',
	'A102CompanyWebsiteNiagara':'www.haverniagara.com',
	'A102CompanyWebsiteH_B':'www.haverboecker.com',
	'A102CompanyWebsiteBehnBates':'www.behnbates.com',
	'A102MailContent':'<p>There occured an alert in one of your machines: <br> <br>Machine name: <b>MACHINE_NAME</b> <br>Alert message: <b>ALERT_TYPE</b> <br> Machine link:  <a href=\"MACHINE_LINK_TO_DETAIL\">MACHINE_LINK</a> <br> Alert value: ALERT_VALUE_TEXT <em>ALERT_VALUE_SUMMARY  </em><br> Alert time: ALERT_TIME_TEXT<br>ALERT_TIP</p><p style=\'font-size: 12px;\'>This e-mails was generated automatically because of a subscription at <a href=\'TYP_WEBSITE\' style=\'text-decoration: none; font-color: blue;\'>TYP_WEBSITE</a>. To unsubscribe login on the page and select <b>Settings</b> below the menu <b>Alerting</b>. <br>Please do not reply to this mail. For questions please contact <a href=\"mailto:COMPANY_EMAIL\">COMPANY_EMAIL</a>. <br> <br></p><p style=\'font-size: 12px;\'>Diese E-Mail wurde aufgrund eines Abos automatisch von <a href=\'TYP_WEBSITE\' style=\'text-decoration: none; font-color: blue;\'>TYP_WEBSITE</a> generiert. Zum Beenden des Abos loggen Sie sich auf der Seite ein und klicken unter <b>Alarmmeldungen</b> auf den Menüpunkt <b>Einstellungen</b>. <br>Bitte Antworten Sie nicht auf diese Mail. Bei Fragen können Sie sich an <a href=\"mailto:COMPANY_EMAIL\">COMPANY_EMAIL</a> wenden. <br> <br></p><p> Best regards, <br> <br>COMPANY_ADDRESS <br> <br>COMPANY_PHONE_NUMBER <br>E-Mail: <a href=\"mailto:COMPANY_EMAIL\">COMPANY_EMAIL</a> <br>Internet: <a href=\'COMPANY_WEBSITE\'>COMPANY_WEBSITE</a> <br><small style=\"color:white;\">Alert Source Id: ALERT_SOURCE_ID</small></p>',
	'A102TypeChanges':'Cambios de producto hoy',
	'A102TypeChangesBatch':'Cambios de producto',
	'A102TYPWebsite':'https://trackyourplant.com',
	'A1030Description':'Descripción',
	'A103EndOfBatch':'Fin de lote',
	'A103ResetmailSubject':'Restablecer: NOMBRE_MAQUINA',
	'A104FullPalletCount':'Palets llenos',
	'A104ResetMailContent':'<p> Se produjo una alerta en la máquina NOMBRE_MAQUINA, ahora se resuelve mediante reinicio. <br> <p> No responda a este correo. Si tiene preguntas, comuníquese con COMPANY_EMAIL. </p> <br> <br> Saludos cordiales, <br> <br> COMPANY_ADDRESS <br> <br> COMPANY_PHONE_NUMBER <br> Correo electrónico: COMPANY_EMAIL <br> Internet: COMPANY_WEBSITE <br> <small style = \"color : white; \"> ID de fuente de alerta: ALERT_SOURCE_ID </small>',
	'A105ConnectivityMailSubject':'Error de conexión: NOMBRE_MAQUINA',
	'A105LoadEmptyPallets':'Carga de palets vacíos',
	'A106Fillings':'llenados',
	'A106PLCMailSubject':'Alerta del PLC: NOMBRE_MAQUINA',
	'A107FillingsPerHour':'LLenados / hora',
	'A107PLCResolvedMailContent':'<p> La siguiente alerta se resolvió en la máquina NOMBRE_MAQUINA :. <br> <br> ALERT_TYPE <br> <br> Estos correos electrónicos se generaron automáticamente debido a una suscripción en <a href=\'TYP_WEBSITE\'style =\' text-decoration: none; font-color: blue; \'> TYP_WEBSITE </a>. Para cancelar la suscripción, inicie sesión en la página y seleccione <b> Configuración </b> debajo del menú <b> Alertas </b>. <br> <p> Por favor, no responda a este correo. Si tiene preguntas, comuníquese con COMPANY_EMAIL. </p> <br> <br> Saludos cordiales, <br> <br>COMPANY_ADDRESS<br> <br>COMPANY_PHONE_NUMBER<br> Correo electrónico: COMPANY_EMAIL <br> COMPANY_WEBSITE',
	'A108Availability':'Disponibilidad',
	'A108AvailabilityMailSubject':'Alerta de disponibilidad: NOMBRE_MAQUINA',
	'A109FillingsRemain':'LLenados restantes',
	'A110StartOfOrder':'Hora de inicio de lote',
	'A111ProductID':'Identificación de producto',
	'A112TargetWeight':'Peso objetivo',
	'A113Kg':'kilogramos',
	'A114CurrentOrder':'Orden actual',
	'A115FillingCount':'Lote real ',
	'A116CycleTime':'Tiempos de ciclo de la máquina',
	'A117Total':'total',
	'A118CentreStation':'Mesa de centrado',
	'A119N2Station':'Estacion n ° 2',
	'A120FillingStation':'Estación de llenado',
	'A121SealingStation':'Estacion de cerrado',
	'A122Seconds':'Segundos',
	'A123FaultLength':'Fallas de la máquina ',
	'A124Minutes':'Minutos',
	'A124Minute':'Minutos',
	'A125ExtFaultLength':'Fallos Externos',
	'A126FillingCount':'Número total de llenados',
	'A127WithinTolerance':'Llenados fuera de Tolerancia',
	'A128OutsideTolerance':'Llenados dentro de Tolerancia',
	'A129BagManufacture':'Buena producción',
	'A130BagsPerHour':'Sacos / hora',
	'A131BagQuality':'Calidad del saco',
	'A132CorrPlacedbags':'Sacos colocados correctamente',
	'A133TwoAttempts':'2º intento de apertura',
	'A134ThreeAttempts':'3er intento de apertura',
	'A135DiscardeBags':'Sacos descartados',
	'A136Faults':'Fallos',
	'A137CompAir':'Aire comprimido',
	'A138ProductFeed':'Alimentación del producto (pesadora)',
	'A139EmptyBagMag':'Almacén de sacos vacíos',
	'A140Separation':'Separación',
	'A141Vacuum':'Vacío',
	'A142FillingStation':'Estación de llenado',
	'A143FullBagTransport':'Transporte de sacos llenos',
	'A144BagSealing':'Sellado de saco',
	'A145Disrupt':'Interrupciones',
	'A145AccessDoor':'Puerta de acceso',
	'A145DSafetyLightBarrier':'Barrera de luz de seguridad',
	'A145UpstreamSupply':'Suministro ascendente',
	'A145EmergencyStop':'Parada de emergencia',
	'A146BunChangeAbs':'Número total de cambios de fardo de sacos',
	'A147BunChangeToday':'Número total de cambios de fardo de sacos hoy',
	'A147BunChangeTodayBatch':'Número de cambios de fardo de sacos',
	'A148BagsPerBundle':'Cantidad de sacos por fardo',
	'A149TypeChanges':'Escriba los cambios hoy',
	'A149TypeChangesBatch':'Escriba los cambios ',
	'A150FillingTime':'Tiempo de llenado',
	'A151DosingUnit1':'Suministro de producto',
	'A152DosingUnit2':'Suministro de producto 2',
	'A153AerInletBox1':'Caja de entrada de aireación',
	'A154AerInletBox2':'Caja de entrada de aireación 2',
	'A154DosingUnit1':'Suministro de producto',
	'A155DosingUnit2':'Suministro de producto 2',
	'A155RotaryDrive':'Accionamiento rotativo',
	'A156AerInletBox1':'Caja de entrada de aireación',
	'A156DischargeBelt':'Cinta de descarga',
	'A157AerInletBox2':'Caja de entrada de aireación 2',
	'A157AlignmentBelt':'Cinta de alineación de sacos',
	'A158BagCleaning':'Limpieza de sacos',
	'A158RotaryDrive':'Accionamiento rotativo',
	'A159AccelerationBelt':'Cinta de aceleración',
	'A159DischargeBelt':'Cinta de descarga',
	'A160AlignmentBelt':'Cinta de alineación de sacos',
	'A160CheckBelt':'Cinta de control de peso',
	'A161BagCleaning':'Limpieza de sacos',
	'A161FlatBelt':'Cinta transportadora plana',
	'A162AccelerationBelt':'Cinta de aceleración',
	'A162RejectionBelt':'Cinta de rechazo',
	'A163CheckBelt':'Cinta de control de peso',
	'A163RejectionDrive':'Elevación accionamiento de rechazo',
	'A164FlatBelt':'Cinta transportadora plana',
	'A164RotaryCutter':'Destructor de sacos',
	'A165RejectionBelt':'Cinta de rechazo',
	'A165ScreenDrum':'Tambor de cribado',
	'A166RejectionDrive':'Elevación accionamiento de rechazo',
	'A166Welding':'Dispositivo de soldadura',
	'A167Cutter':'Cortador',
	'A167RotaryCutter':'Destructor de sacos',
	'A168ScreenDrum':'Tambor de cribado',
	'A168SuctionCup':'Ventosas',
	'A169Brakes':'Frenos',
	'A170VacuumPump':'Bomba de vacío',
	'A171Filter':'Extracción de filtro',
	'A172BagDisConveyor':'Transportador de distribución de sacos',
	'A173BagTurnConveyor':'Transportador de giro de sacos',
	'A174BagTurnCross':'Cruz de orientación de sacos',
	'A175BagDisConveyor':'Transportador de distribución de sacos',
	'A175BagTurnClamp':'Abrazadera de giro de sacos',
	'A176BagTurnConveyor':'Transportador de giro de bolsas',
	'A176GroupingBelt':'Cinta de agrupamiento',
	'A177BagTurnCross':'Cruz de orientación de sacos',
	'A177RowPusher':'Empujador de fila',
	'A178DoubleStripPlate':'Doble placa separadora',
	'A179ConformingPlate':'Placa de conformación',
	'A179GroupingBelt':'Cinta de agrupamiento',
	'A180GantrySafety':'Andamio de seguridad',
	'A180RowPusher':'Empujador de sacos',
	'A181DoubleStripPlate':'Placa de doble separador',
	'A181Gantry':'Bastidor',
	'A182ConformingPlate':'Placa de formación',
	'A182PalletAlignment':'Alineación de palet vacios',
	'A183GantrySafety':'Andamio de seguridad',
	'A183PalletRoller1':'Transportador de rodillos',
	'A184Gantry':'Bastidor',
	'A184PalletDispenser':'Dispensador de Palet',
	'A185PalletAlignment':'Alineación de palet vacios',
	'A185PalletRoller2':'Transportador de rodillos',
	'A186PalletRoller1':'Transportador de rodillos',
	'A186PalletRoller3':'Transportador de rodillos',
	'A187CyclesChain':'Cadena de ciclos producción',
	'A187PalletDispenser':'Dispensador de Palet',
	'A188BundlePusher':'Cilindro empujador de fardos',
	'A188PalletRoller2':'Transportador de rodillos',
	'A189BundlePartition':'Cilindro empujador de fardos',
	'A189PalletRoller3':'Transportador de rodillos',
	'A190BagLifter':'Cilindro elevador de sacos',
	'A190CyclesChain':'Cadena de ciclos producción',
	'A191BundlePusher':'Cilindro empujador de fardos',
	'A191PressRolls':'Cilindro rodillos de presión',
	'A192BundlePartition':'Cilindro particion de fardos',
	'A192OpeningStation':'Cilindro estación de apertura',
	'A193BagApplicator':'Cilindro aplicador de sacos',
	'A193BagLifter':'Cilindro elevador de sacos',
	'A194BagSupport':'Cilindro apoyo de sacos',
	'A194PressRolls':'Cilindro rodillos de presión',
	'A195OpeningStation':'Cilindro estación de apertura',
	'A195Pusher':'Cilindro empujador',
	'A196BagApplicator':'Cilindro aplicador de sacos',
	'A196StretchPliers':'Cinlindro alicates elásticos',
	'A197BagSupport':'Cilindro apoyo de sacos',
	'A197FillingStation':'Cilindro estacion de llenado',
	'A198FilledBagGripper':'Cilindro pinza de bolsa llena',
	'A198Pusher':'Cilindro empujador',
	'A199StretchPliers':'Cinlindro alicates elásticos',
	'A199SucBagSeperation':'Ventosa separacion de sacos',
	'A200FillingStation':'Cilindro estacion de llenado',
	'A200SucOpenStation':'Ventosa de vacio estacion de apertura',
	'A201FilledBagGripper':'Cilindro pinza de bolsa llena',
	'A201Remaininghours':'Horas restantes',
	'A202PriceTotal':'Piezas en total',
	'A202SucBagSeperation':'Ventosa separacion de sacos',
	'A203RemainingNumberFillings':'Resto numeros de llenados',
	'A203SucOpenStation':'Ventosa de vacio estacion de apertura',
	'A204SafetyDoor':'Puerta de protección ',
	'A205SafetyLightBarrier':'Barrera de luz de seguridad',
	'A206BagTransport':'Transporte de sacos',
	'A207EmptyPalletTrans':'Transporte de palet vacios',
	'A208Palletizer':'Paletizador',
	'A209FullPalletTrans':'Transporte de palet completos',
	'A210NumberTodayPiece':'Número hoy / pieza.',
	'A211Programme':'Programa',
	'A212Efficiency':'Eficiencia de Boca',
	'A213Loginsuccessful':'Inicio de sesión con exitoso',
	'A214CurrentPerformance':'Produccion actual',
	'A215CyclesToday':'Total ciclos hoy',
	'A215CyclesTodayBatch':'Total ciclos',
	'A216RejectsToday':'Rechazos hoy',
	'A216RejectsTodayBatch':'Rechazos',
	'A217MetalDetectorToday':'Detector de metales hoy',
	'A217MetalDetectorTodayBatch':'Detector de metales',
	'A218BrokenBagsToday':'Deteccion de sacos rotos hoy',
	'A218BrokenBagsTodayBatch':'Deteccion de sacos rotos',
	'A219FaultPerShift':'Turno actual',
	'A220FaultBagManufacture':'Fabricacion de sacos',
	'A221FaultBagFilling':'Llenado de sacos',
	'A222FaultBagTransport':'Transporte de sacos',
	'A223FaultSealing':'Soldadura de sacos',
	'A224FaultWeigher':'Estacion de llenado',
	'A225FaultPeriphery':'Otra periferia',
	'A226Shift':'Turno',
	'A227Container':'Envase',
	'A228Hours':'Horas',
	'A229NoPackage':'Sin Envase',
	'A230NoProduct':'Sin Material',
	'A231NoOutlet':'Sin Descarga',
	'A232GoodPerformance':'Produccion',
	'A233GoodWeights':'Produccion sacos buenos',
	'A234NotPlacedBags':'Sacos no colocados',
	'A234NotPlacedBagsAutomatic':'Sacos no colocados en Automatico',
	'A235CollectiveFaultsToday':'Averia Genaral de hoy',
	'A235CollectiveFaultsTodayBatch':'Averia General',
	'A236Applicator':'Aplicador',
	'A237NetWeigher':'Bascula Netto',
	'A238IncorrectWeight':'Peso incorrecto',
	'A239Hours':'Horas',
	'A240QrCode':'Código QR',
	'A241OpenBags':'Sacos abiertos',
	'A242FaultCheckWeigher':'Bascula de Control',
	'A243DashboardAllMachines':'Dashboard / Todas maquinas',
	'A244DashboardState':'Estado',
	'A245DashboardMachineStateRunning':'Encendido',
	'A246DashboardMachineStateOffline':'Apagado',
	'A247DashboardMachineStateMaintenance':'Mantenimiento necesario',
	'A248DashboardMachineStateError':'Fallo',
	'A249SortChangeToday':'Cambio de tipos hoy',
	'A249SortChangeTodayBatch':'Cambio de tipos',
	'A250EmptyBagMagazineToday':'Recargas de sacos vacios hoy',
	'A250EmptyBagMagazineTodayBatch':'Recargas de sacos vacios',
	'A251FaultCheckWeigher':'Fallos hoy',
	'A252IVTSTOP':'INTEGRA®',
	'A253N/A':'N/A',
	'A254CyclePumpFS1':'Ciclo de Bomba FS 1',
	'A255CyclePumpFS2':'Ciclo de Bomba FS 2',
	'A256CyclePumpFS3':'Ciclo de Bomba FS 3',
	'A257ConHoseFS1':'Conexión de manguitos FS1',
	'A258ConHoseFS2':'Conexión de manguitos FS2',
	'A259ConHoseFS3':'Conexión de manguitos FS3',
	'A260InfSleeveFS1':'Manguito inflable FS1',
	'A261InfSleeveFS2':'Manguito inflable FS2',
	'A262InfSleeveFS3':'Manguito inflable FS3',
	'A263BundlePusher':'Empujador de fardos',
	'A264VacuumUnit':'Unidad de Vacio',
	'A265TravellingDrive':'Unidad acc.de traslación',
	'A266CenteringUnit':'Unidad de centrado',
	'A267EmptyBagMagazine':'Almacen de sacos vacios',
	'A268BeltPlant':'Cinta de transporte',
	'A269IvtTurbine':'Turbina de llenado',
	'A270IvtTripSaddle':'Silla basculante',
	'A271IvtCoarseFeed':'Dosificacion flujo grueso',
	'A272IvtBagHolder':'Sujeta sacos al frente',
	'A273IvtFillBoxAir':'Aireacion de la tapa en artese de llenado',
	'A274IvtFillChanAir':'Aireacion canal de llenado',
	'A275IvtInflSleeve':'Maguito inflable',
	'A276IvtInletBoxAir':'Aireacion caja de entrada ',
	'A277IvtFillTubeBlow':'Soplar tubo de llenado',
	'A278IvtOutlChanAir':'Aireacion canal de soplado',
	'A279IvtPusher':'Empujador',
	'A280IvtSaddleHeight':'Ajuste altura silla',
	'A281IvtCloseAnvil':'Cerrar Yunque',
	'A282IvtBagSeal':'Sellado de sacos',
	'A283IvtFineFeedAdj':'Ajuste de flujo fino',
	'A284Interrupts':'Interrupciones hoy',
	'A284InterruptsGeneralPage':'Interrupciones hoy',
	'A284InterruptsBatch':'Interrupciones',
	'A285BagCounterShift':'Contador de sacos por turno',
	'A286WrongBags':'Sacos mal aplicados',
	'A287ReelChanges':'Cambio de bobina',
	'A287ReelChangesBatch':'Cambio de bobina',
	'A288VibrationDuration':'Tiempo de vibración extendido',
	'A289Total':'Total',
	'A290HopperMin':'por Silo minimo',
	'A291ManualActivation':'Activacion manual',
	'A292FillingRelease':'Autorizacion llenado',
	'A293EGF':'EGF®',
	'A294PalletTrasport':'Transporte de palet',
	'A295SafetyCircuit':'Circuito de seguridad',
	'A296FltCompAir':'Fallo Aire comprimido hoy',
	'A296FltCompAirBatch':'Fallo Aire comprimido hoy',
	'A297StrokesPump':'Golpes Bomba de polvo hoy',
	'A297StrokesPumpBatch':'Golpes Bomba de polvo',
	'A298MaxStrokeDur':'Tiempo máximo de elevación hoy',
	'A298MaxStrokeDurBatch':'Tiempo máximo de elevación',
	'A299AvgStrokes':'La media de golpes por sacos hoy',
	'A299AvgStrokesBatch':'La media de golpes por sacos',
	'A300InternalPressure':'Presion interna',
	'A301Max':'maximo',
	'A301MessageAdministration':'Administración de mensajes',
	'A302ErrorNumber':'Numero eroneo',
	'A302Millibar':'Mbar',
	'A303ActivateAlertMail':'Activa notificacion para el correo electrónico',
	'A303SetValueMax':'Establecer valor maximo',
	'A304AlertText':'Texto de alertas',
	'A304SetValueMin':'Establecer valor mínimo',
	'A305ActivateResolvedMail':'Activar correo electrónico resuelto',
	'A305SetValueChanged':'Se han guardado los cambios',
	'A305SetValueChangedRefreshWindow':'Se han guardado los cambios. Los cambios serán visibles después de la actualización de la ventana del navegador.',
	'A306KW':'kW',
	'A306Resolved Text':'Texto deshecho',
	'A306ResolvedText':'Texto deshecho',
	'A307AddMessage':'Añadir menaje',
	'A307KVA':'kVA',
	'A308EditMessage':'Editar mensajes',
	'A308Nm3PerH':'Nm³/h',
	'A309KWH':'kWh',
	'A310NM3':'Nm³',
	'A311CompAirVol':'Volumen del aire comprimido',
	'A312ActiveEnergy':'Energia activa',
	'A313ReactivePower':'Energia reactiva',
	'A314ChartConfiguration':'Configuracion de grafico',
	'A315SetDateValueMax':'Definir fecha maxima',
	'A316SetDateValueMin':'Definir fecha minima',
	'A317InvalidData':'Datos incorrectos',
	'A318DeleteUser':'Está seguro de eliminar al usuario?',
	'A319EnterAllFields':'Por favor, rellene todos los campos de entrada!',
	'A320EnterValidEmail':'Por favor, introduzca una dirección de correo electrónico válida!',
	'A321DeleteCompany':'Está seguro de que desea eliminar la empresa?',
	'A322CouldNotDeleteUser':'¡El usuario-administrador no podía ser eliminado! Por favor, cambie a usuario estándar primero.',
	'A323LoginSuccess':'Login con exito!',
	'A324Selected':'Seleccionado',
	'A325Total':'Total',
	'A326Reset':'Reset',
	'A327Reporting':'Reportes',
	'A328ReportingActive':'Activado',
	'A329ReportingActiveHelpPrefix':'Si está activado, los informes se envían regularmente',
	'A330ReportingActiveHelpSuffix':'enviado.',
	'A331ReportingFrequency':'Frecuencia',
	'A332ReportingFrequencyDaily':'Diario',
	'A333ReportingFrequencyWeekly':'Semanal',
	'A334ReportingFrequencyMonthly':'Mensual',
	'A335ReportingDynContent':'Contenido dinámico',
	'A336ReportingDynContIncludeElapsedMI':'Incluidos los intervalos de mantenimiento caducados',
	'A337ReportingLanguage':'Idioma',
	'A338ReportingProdDayEnd':'Final dia de produccion',
	'A339ReportingFirstReport':'Fecha del primer reporte',
	'A340CardCtxMenuReport':'Incluir en el reporte',
	'A341CardCtxMenuHistoReport':'Incluir como histograma en el reporte',
	'A342CardCtxMenuReset':'Reset Datos métricos',
	'A343CardCtxMenuSettings':'Ajustes',
	'A344CardLabelReport':'Reporte',
	'A345CardLabelHistoReport':'Informe de histograma',
	'A346CardLabelMaintenReport':'Reporte de Mantenimiento',
	'A347ReportSettings':'Ajustes',
	'A348Alerting':'Alarmas',
	'A349AlertingSettings':'Ajustes',
	'A350AlertingActive':'Activo',
	'A351AlertingActiveHelpPrefix':'Cuando se activa, aparecerán notificaciones de los tipos seleccionados a continuación.',
	'A352AlertingActiveHelpSuffix':'enviado',
	'A353AlertingTypes':'Notificar en',
	'A354AlertTypeMainten':'Intervalo de mantenimiento vencido:',
	'A355AlertTypeAvail':'Disponibilidad',
	'A356AlertTypePLC':'Trigger-PLC',
	'A357AlertTypeConError':'Error de conexión',
	'A358DeleteAdminUser':'El usuario administrador no pudo ser eliminado.',
	'A359MaintAids':'Ayudas de mantenimiento',
	'A360MaintInt':'Intervalos de mantenimiento',
	'A361ChooseMaschine':'Selecciona maquina',
	'A362Video':'Video',
	'A363InstructionManual':'Manual de instrucciones',
	'A364ExplodedviewDrawing':'Vista explosionada',
	'A364SparePartCatalog':'Catálogo de repuestos',
	'A365OpenVideoHelp':'Abre la ayuda para el video',
	'A366OpenInstructionManual':'Abrir el manual de instrucciones',
	'A367OpenExploadedView':'Abrir la vista explosionada',
	'A368ResetMaschineData':'Resetear datos de maquina',
	'A369BotWeld':'Soldadura de costura inferior',
	'A370BotWeldTef':'Teflon para soldadura de costura inferior',
	'A371TopWeld':'Soldadura de costura superior',
	'A372TopWeldTef':'Teflon para soldadura de costura superior',
	'A373LeftWeld':'Soldadura esquinas izquierda',
	'A374LefWeldTef':'Teflon de soldadura esquinas izquierda',
	'A375RigWeld':'Soldadura esquinas derecha',
	'A376RigWeldTef':'Teflon de soldadura esquinas derecha',
	'A377CutKnife':'Herramienta para cortar',
	'A378ReallyClearReport':'Esta acción elimina todos los elementos del informe y no se puede deshacer. Está seguro de que desea eliminar todos los elementos del informe?',
	'A379ProdCurrShift':'Produccion turno actual',
	'A380ReallyDeleteItemReport':'Esta acción elimina todos los elementos del informe y no se puede deshacer. Está seguro de que desea eliminar todos los elementos del informe?',
	'A380TypeCurrent':'Tipo actual',
	'A381SampleBags':'Sacos de muestra expulsados',
	'A382OpenBags':'Sacos abiertos expulsados',
	'A383IncorrectBags':'Sacos expulsados con peso incorrecto',
	'A384NotPlacedBagsMan':'Sacos no colocados en manual',
	'A385Spout1':'Mantenimiento Boca de llenado 1',
	'A386LastUpdate':'Ultima actualizacion',
	'A386Spout2':'Mantenimiento Boca de llenado 2',
	'A387Spout3':'Mantenimiento Boca de llenado 3',
	'A388Spout4':'Mantenimiento Boca de llenado 4',
	'A389LastUpdate':'Ultima actualizacion',
	'A390FilledBagTrans':'Transporte sacos llenos',
	'A391ConveyerSystem':'Cinta de transporte',
	'A392BeltBundleTrans':'Correas de transporte fardos',
	'A393BagHolderBuffer':'Sinebloc del Sujetasacos',
	'A394SlidePlates':'Placas deslizantes tajadera',
	'A395ImpellerShaft':'Cojinete del eje turbina de llenado',
	'A396ShaftAssembly':'Rodamiento parte inferior',
	'A397ThreePosCylinder':'Cilindro de tres posiciones',
	'A398DateFormatNoSeconds':'MM-DD-YYYY hh: mm ',
	'A399DateFormat':'MM-DD-YYYY hh: mm: ss ',
	'A399DateFormatAngularPipe':'MM-dd-yyyy hh: mm: ss ',
	'A400ConveyorSystem':'Sitema de transporte',
	'A400History':'Historial',
	'A401ReportHistory':'Historial de reporte',
	'A402ReportId':'Id del Informe',
	'A403ReportGeneratedTime':'Reporte generado por tiempo',
	'A404DosingFeeder':'Alimentador de dosificación',
	'A404ReportGeneratedBy':'Reporte generado por ',
	'A405DownloadReport':'Descagar reporte ',
	'A406TriggeredHistoryReport':'Generacion de reporte iniciada',
	'A407ViewReport':'Ver reporte',
	'A408BagHolder':'Soporte de sacos',
	'A408BagHolder ':'Soporte de sacos',
	'A414BundleTransport':'Transporte de Paquete',
	'A415HoistingDevice':'Unidad de alojamiento',
	'A416PlacerArm':'Brazo de Posicionamiento',
	'A417CrossProcess':'Proceso cruzado',
	'A418OpeningStation':'Estacion de apertura',
	'A421Claw':'Garra',
	'A422WeightData':'Datos de peso Ø',
	'A423StdDevData':'Datos de desviacion estandar',
	'A430kg':'Kg',
	'A431lb':'lb',
	'A432t':'t ',
	'A433klb':'Klb',
	'A434g':'g',
	'A435BagManufacture':'Fabricacion de sacos vacios',
	'A436Aux_Pump':'Bomba de vacio',
	'A437TopWelding':'Soldadura superior',
	'A438PunchingDeviceWelding':'Perforacion Asa de agarre',
	'A439Aux_Vibrator1':'Vibrador 1',
	'A440Aux_Vibrator2':'Vibrador 2',
	'A441Aux_Vibrator3':'Vibrador 3',
	'A442Truck_Loading':'Cargando camion',
	'A443Truck_Available':'Sin camiones disponibles hoy',
	'A444FS1':'Estacion de llenado 1',
	'A445FS2':'Estacion de llenado 2',
	'A446FS3':'Estacion de llenado 3',
	'A447FS4':'Estacion de llenado 4',
	'A448FS5':'Estacion de llenado 5',
	'A449FS6':'Estacion de llenado 6',
	'A450FS7':'Estacion de llenado 7',
	'A451FS8':'Estacion de llenado 8',
	'A452FS9':'Estacion de llenado 9',
	'A453FS10':'Estacion de llenado 10',
	'A454FS11':'Estacion de llenado 11',
	'A455FS12':'Estacion de llenado 12',
	'A456FS13':'Estacion de llenado 13',
	'A457FS14':'Estacion de llenado 14',
	'A458FS15':'Estacion de llenado 15',
	'A459FS16':'Estacion de llenado 16',
	'A460OeeValue':'Valor OEE',
	'A460Oee':'OEE',
	'A460PushAlert':'<h1>Alert: ALERT_TYPE</h1><p>Machine name: <b>MACHINE_NAME</b> <br>Alert message: <b>ALERT_TYPE</b>',
	'A461OeeAvailable':'undefined',
	'A461PushAlertResolved':'<h1>Resolved: ALERT_TYPE</h1><p>Machine name: <b>MACHINE_NAME</b> <br>Alert message: <b>ALERT_TYPE</b>',
	'A462OeePerformance':'Factor de rendimiento ',
	'A463DowntimePlaned':'Tiempos de inactividad planificados',
	'A464DowntimeMachine':'Tiempos de inactividad de la máquina',
	'A465DowntimeOther':'Otros tiempos de inactividad',
	'A466OperatingFactor':'Factor de funcionamiento ',
	'A467Throughput':'Factor de rendimiento ',
	'A468OeeRejects':'Rechazos de hoy',
	'A469OeeQuality':'Factor de calidad',
	'A470OeeDowntime':'Tiempos de inactividad ',
	'A471RejectsTotal':'Total de rechazos ',
	'A472RejectsIncorrect':'Rechazos de peso incorrecto ',
	'A473RejectsOpen':'Rechazo saco abierto',
	'A474RejectsSample':'Rechazo saco de muestra',
	'A475RejectsMetal':'Rechazo deteccion de metales',
	'A476ConfigTable':'Configuración',
	'A477OEERowActive':'Activo',
	'A478OEEProduction':'Producción',
	'A479OEEBreak':'Rotura',
	'A480OEEMaint':'Mantenimiento',
	'A481OEETypeChg':'Tipo de cambio ',
	'A482OEEOthers':'Otros tiempos de inactividad ',
	'A483OEEFrequ':'Frecuencia ',
	'A484OEEUnique':'Único ',
	'A485OEEDaily':'Diario ',
	'A486OEEWeekly':'Semanal ',
	'A487Days':'Dias',
	'A488Monday':'Lunes',
	'A489Tuesday':'Martes',
	'A490Wednesday':'Miércoles',
	'A491Thursday':'Jueves',
	'A492Friday':'Viernes',
	'A494Saturday':'Sábado',
	'A498Comment':'Comentario',
	'A495Sunday':'Domingo',
	'A496From':'de',
	'A497To':'A',
	'A499TabMachineMessages':'Mensaje de máquina',
	'A500MMLive':'Mensaje ne vivo',
	'A500VbeltDrive':'Correa de trasmision en \"V\" \"rotativo\"',
	'A501MMStatistic':'Estadística de mensaje',
	'A501VbeltTurbine':'Correa de trasmision en \"V\" \"turbina\"',
	'A502Impeller':'Rotor de la turbina',
	'A502MMLive':'Estadística de mensaje en vivo',
	'A503FillingTube':'Tubo de llenado',
	'A503MMStatistic':'Estadística de mensajes de la máquina',
	'A504BagGuide':'Chapa guia de saco',
	'A504Type':'Tipo',
	'A505Control':'Control',
	'A505Saddle':'Silla de montar',
	'A506BoltBagChair':'Pin de silla',
	'A506Number':'Número',
	'A507BushBagChair':'Buje de silla',
	'A507Message':'Mensaje',
	'A508Amount':'Cantidad',
	'A508BearingPusher':'Cojinete de empuje',
	'A509Duration':'Duracion en minutos',
	'A509RearSlidePlate':'Placa deslizante trasera',
	'A510SlideValve':'Placa deslizante ',
	'A510TonsPerHour':'t/h',
	'A511FrontSlidePlate':'Placa deslizante frontal',
	'A511Tons':'tonos',
	'A512RepairKitSlidePlates':'Kit de reparacion para placas deslizantes',
	'A512RPM':'Rondas por minuto',
	'A513BagChairChange':'Saco de cambio de silla ',
	'A513BagTransBeltServo':'Correa acanalada para servo',
	'A514InfeedBelt':'Correa de alimentacion',
	'A515FlattBelt':'Correa aplanadora',
	'A516BagTakingBelt':'Correa de entrada del saco',
	'A517RollerConveyor':'Transportador de rodillos',
	'A518LiftingGantry':'Torre de elevacion',
	'A519LinearDrive':'Movimiento linear',
	'A520SwivelJoint':'Articulacion giratoria',
	'A521LiftingGantry':'Transportador de rodillos de elevación de pórtico',
	'A522FullPallets':'Transporte de rodillos de pallets llenos',
	'A523EvacConveyor':'Transportador de evacuacion',
	'A524SafetyBarrier':'Barreras de seguridad / Sensores',
	'A525BasicCleaning':'Limpieza Basica',
	'A526SafetyDevices':'Dispositivos de seguridad',
	'A530CylSucBar':'Cierre de la barra de aspiración del cilindro',
	'A531FilledBagGripper1':'Pinzas para bolsas llenas de cilindro 1',
	'A532FilledBagGripper2':'Pinzas para bolsas llenas de cilindro 2',
	'A533CylCutKnife':'cuchillo de corte cilíndrico',
	'A534CylBotWelding':'sistema de soldadura de fondo de cilindro',
	'A535SpoutLift':'elevacion del pico',
	'A536CylClampTopWeld':'soldadura superior de la barra de la abrazadera del cilindro',
	'A537CylTopWeld':'sistema de soldadura de la parte superior del cilindro',
	'A538CylCornerWeld':'soldadura de la esquina del cilindro',
	'A539AdjHeightDischargebelt':'cinturón de descarga de ajuste de altura',
	'A540EmptyBagTransport':'transporte de bolsa vacía',
	'A541Virbator':'vibrador de horas de funcionamiento',
	'A542CylCoolPlatesCornerWeld':'Soldadura de esquina de placas de enfriador de cilindro',
	'A543CylBagGuide':'placa de guía de la bolsa cilíndrica',
	'A544CylClampBotWeld':'soldadura inferior de la barra de la abrazadera del cilindro',
	'A545CylCoolBotWeld':'contacto cilindro refrigeración costura inferior',
	'A546CylFilledBagGripper3':'pinzas para bolsas llenas de cilindro 3',
	'A547CylStatBagGripper':'pinzas de bolsa estacionarias cilíndricas',
	'A548CylEmptyBagGripper1':'pinza cilindro bolsa vacía 1',
	'A549CylStretching':'estiramiento del cilindro',
	'A550CylOpenSpout':'boca de llenado del cilindro abierta',
	'A551CylBagAplicatorLift':'elevador del aplicador de bolsa cilíndrica',
	'A552CylStretcherOpen':'tenazas para camillas cilíndricas abiertas',
	'A553CylStretcherInwards':'tenazas para camillas cilíndricas hacia adentro',
	'A554FoilTrackingDevice':'dispositivo de seguimiento de papel de aluminio',
	'A555DischargeBelt2':'horas de funcionamiento cinta de descarga BP2',
	'A556CylStretching':'dispositivo de estiramiento del cilindro',
	'A557CylEmptyBagGripper2':'pinza cilindro bolsa vacía 2',
	'A558UnwindingDevice':'horas de funcionamiento rodillos de desenrollado',
	'A559BagLenghtAdj':'ajuste de la longitud de la bolsa',
	'A560CylVibratorLift':'elevador vibrador',
	'A561CylFilmGuide':'guiado de película cilíndrica',
	'A562CylPushFlaps':'solapas de empuje del cilindro',
	'A563CylWeldReelChanger':'cambiador de bobinas de soldadura',
	'A564CylWeldReelChangerTef':'cambiador de bobina de soldadura de teflón',
	'A565DriveReelChanger':'cambiador de carretes de accionamiento',
	'A566DriveAdjCornerWeld':'soldadura de esquina de ajuste de ancho de accionamiento',
	'A567CylThreadingInwards':'el dispositivo de roscado del cilindro sucede hacia adentro',
	'A568CylThreadingOutwards':'El límite del dispositivo de roscado del cilindro sucede hacia el exterior',
	'A569CylThreadingDownwards':'dispositivo de roscado del cilindro placa de separación hacia abajo',
	'A570CylSucRailClose':'cierre de la barra de aspiración del cilindro',
	'A571DischargeBelt2':'cinta de descarga BP2',
	'A572CylSpoutOpen':'boca de llenado del cilindro abierta',
	'A573Drives':'conducción',
	'A574Cylinders':'cilindros',
	'A575LowAttrition':'Poco desgaste y rotura',
	'A576HighAttrition':'Alto desgaste',
	'A577SetUpTimeAvBatch':'Tiempo de configuración Ø',
	'A578SetUpTimeAv':'Tiempo de configuración Ø Hoy',
	'A579GrossTimeBatch':'Tiempo bruto',
	'A580GrossTime':'Tiempo bruto hoy',
	'A581SetUpTime':'Tiempo de configuración',
	'A582Downtime':'Falta del tiempo',
	'A583MonthBestPerf':'Mejor desempeño mensual',
	'A584PressureChamperSeal':'Cámara de presión de sellado',
	'A585Aeration':'Aireación',
	'A586SpillageRejector':'Rechazador de derrames ',
	'A587VibrationBottle':'botella vibradora',
	'A588VibrationTable':'vibrador ',
	'A589BagPlacing':'aplicador de saco',
	'A590RotaryFeeder':'esclusa de rueda celular',
	'A591TopSeamCleaning':'Limpieza de la costura superior',
	'A592Beltplant1':'Cinta de transporte 1',
	'A593Beltplant2':'Cinta de transporte 2',
	'A594HydraulikPump':'bomba hidraulica',
	'A595DriveEBTS':'undefined',
	'A596HostingEBTS':'undefined',
	'A597Ventilator':'undefined',
	'A598RD':'undefined',
	'A599SpoutCoarse':'undefined',
	'A600RotaryGateCoarse':'undefined',
	'A601HostingVIC':'undefined',
	'A602SpoutFine':'undefined',
	'A603RotaryGateFine':'undefined',
	'A604HostingVSC1':'undefined',
	'A605HostingVSC2':'undefined',
	'A606FBTransfer':'undefined',
	'A607FBTHost':'undefined',
	'A608FBTswivel':'undefined',
	'A609FBTClamp':'undefined',
	'A610FBTransport':'undefined',
	'A611FBTransportClamp':'undefined',
	'A612HostBFS':'undefined',
	'A613SwivelBFS':'undefined',
	'A614SideGussetblade':'undefined',
	'A615HostTopwelding':'undefined',
	'A616CloseTopWelding':'undefined',
	'A617Folding1':'undefined',
	'A618Folding2':'undefined',
	'A619Folding3 ':'undefined',
	'A620PUD':'undefined',
	'A621PUDWeld':'undefined',
	'A622BeltReject':'undefined',
	'A623RejectingDevice':'undefined',
	'A624BagLenght':'undefined',
	'A624PerfToday':'El rendimiento hoy en día',
	'A625PerfTodayBatch':'Rendimiento',
	'A626Output':'Salida de hoy',
	'A627OutputBatch':'Salida',
	'A628UnitperH':'S/h',
	'A629Percent':'%',
	'A630BagJunction':'Placa de unión de la bolsa',
	'A631ToothedBelt':'Correa dentada tensada',
	'A632LevellingFoot':'Comprobar el pie de nivelación RAD',
	'A633PolymerGuide':'Guía de polímero + rodillo',
	'A634BallBushing':'Acoplamiento del casquillo de bolas',
	'A635ScissorHoseReplace':'Manguera de tijera',
	'A636ConnectingHoseReplace':'Manguera de conexión',
	'A637Bellow':'Fuelle de ajuste de altura del asiento',
	'A638TipperFrame':'Bastidor basculante',
	'A639HosePressureChamber':'Comprobar la cámara de presión de sellado',
	'A640FlapFillingBox':'Marque la casilla de llenado de solapas',
	'A641RotoLock':'ROTO-LOCK®',
	'A642Hours':'Horas',
	'A643Temp':'°C',
	'A644Millisecond':'ms',
	'A645KgPerSecond':'kg/s',
	'A646RoundsperMinute':'rpm',
	'A647Meter':'m',
	'A648Millimeter':'mm',
	'A649Cubicmeter':'impeller',
	'A650Liter':'l',
	'A651Counts':'pcs',
	'A652WeldingTemperature':'Temperatura de soldadura',
	'A653WeldingDuration':'Duración de la soldadura',
	'A654FillingSystem':'Sistema de llenado',
	'A655EmailSignature':'Firma de correo electrónico',
	'A656NumberWasteBags':'Número de bolsas de basura',
	'A657FreqConverter':'Convertidor de frecuencia',
	'A658ServoDrives':'Servoaccionamientos',
	'A656Voltage':'Voltaje',
	'A657Volt':'V',
	'A658U1':'U1',
	'A659U2':'U2',
	'A660U3':'U3',
	'A661U12':'U12',
	'A662U23':'U23',
	'A663U31':'U31',
	'A664Current':'Potencia',
	'A665Amps':'A',
	'A666I1':'I1',
	'A667I2':'I2',
	'A668I3':'I3',
	'A669Frequency':'Frecuencia',
	'A670Hertz':'Hz',
	'A671Pressure':'Presión',
	'A672Bar':'bar',
	'A673Moisture':'punto de rocío a presión',
	'A674AirFlowUnit':'l/s',
	'A675AnnualConsum':'Consumo anual',
	'A676MonthlyConsum':'Consumo mensual',
	'A677FreeConsum':'Consumo',
	'A678PowerEfficiency':'Eficiencia energética',
	'A679BagsPerKWH':'Bolsas por kW/h',
	'A681SinceReset':'Desde el reinicio',
	'A682LastTimeSortUse':'Desde el último uso',
	'A683SortNumber':'Número de la variedad',
	'A684Overall':'En general',
	'A685AirEfficiency':'Eficiencia del aire',
	'A686BagsPerAirFlowUnit':'Bolsas por l/s',
	'A687Jan':'Enero',
	'A688Feb':'Febrero',
	'A689Mar':'Marzo',
	'A690Apr':'Abril',
	'A691Mai':'Mayo',
	'A692Jun':'Junio',
	'A693Jul':'Julio',
	'A694Aug':'Agosto',
	'A695Sep':'Septiembre',
	'A696Oct':'Octubre',
	'A697Nov':'Noviembre',
	'A698Dec':'Diciembre',
	'A699Year':'Año',
	'A700EnterDateRange':'Introduzca el rango de fechas',
	'A701PowerConsumption':'Consumo de energía',
	'A702AirConsumption':'Consumo de aire',
	'A703FlowControlGate':'Puerta de control de flujo',
	'A704BagDirectionConv':'Transportador de dirección de bolsas',
	'A705BottomBearing':'Rodamientos inferiores',
	'A706PressureSwitch':'Interruptor de presión',
	'A707ElecModules':'Módulos electrónicos',
	'A708SpoutWeight':'Escala de componentes',
	'A709Search':'Buscar en',
	'A710Overweight':'Bolsas con sobrepeso',
	'A711Underweight':'Bolsas con poco peso',
	'A712RotaryDriveFreqConLoad':'Convertidor de frecuencia de accionamiento rotativo',
	'A713MeanValueLoadInPercent':'Valor medio / Carga en porcentaje',
	'A714EmptyBagDischarge':'Descarga de bolsas vacías',
	'A715Bellow1':'Fuelle de protección',
	'A716TotalCounter':'Contador total',
	'A717Spout0Counter':'Estación de servicio 0',
	'A718LSdirtyCounter':'Barrera de luz CW sucio',
	'A719Peripherie':'undefined',
	'A720Robot':'undefined',
	'A721DepositBelt':'undefined',
	'A722RollerConveyor1':'undefined',
	'A723PalletCentering1':'undefined',
	'A724RollerConveyor2':'undefined',
	'A725PalletCentering2':'undefined',
	'A726GripperClamp':'undefined',
	'A727GripperMotor':'undefined',
	'A728AdjustmentCamera':'undefined',
	'A729BagSize':'undefined',
	'A730PalletSupply':'undefined',
	'A731Length':'undefined',
	'A732Width':'undefined',
	'A733DepalPallets':'undefined',
	'A734DepalBundles':'undefined',
	'A735RejectedBundles':'undefined',
	'A736BagsPerBundle':'undefined',
	'A737CountsOfDataPoints':'undefined',
	'A738EmptyKey':' ',
	'A739TrendMeanWeight':'Datos de peso Ø Tendencia',
	'A740TrendSD':'Datos de desviacion estandar Tendencia',
	'A741PistonBlower1':'Compresor de pistón 1',
	'A742PistonBlower2':'Compresor de pistón 2',
	'A743RotaryFlap':'undefined',
	'A744CurrentLayer':'undefined',
	'A745CurrentBag':'undefined',
	'A746Days':'undefined',
	'A747LayerNo':'undefined',
	'A748BagNo':'undefined',
	'A749AnnualMaint':'undefined',
	'A901MachineCustomerOverview':'Vision general de la maquina / Cliente',
	'A902Machines':'Maquinas',
	'A903Permissions':'Permisos',
	'A904CompanyName':'Nombre de la empresa',
	'A905MachineName':'Nombre de la maquina',
	'A906MachineType':'Tipo de maquina',
	'A907Order':'Orden',
	'A908Action':'Accion',
	'A909Remove':'Eliminar',
	'A910ChooseCustomer':'Escoja el cliente',
	'A911ConnectMachines':'Conecte las maquinas',
	'A912User':'Usuario',
	'A913FirstName':'Primer nombre',
	'A914Surname':'Apellido',
	'A915Login':'Iniciar sesion',
	'A916Roles':'Rollos',
	'A917Company':'Empresa  ',
	'A918CreateUser':'Crear usurio',
	'A919Username':'Nombre del usuario',
	'A920Password':'Clave',
	'A921Name':'Nombre',
	'A922ParentCompany':'Empresa Matriz',
	'A923Create':'Crear  ',
	'A924Edit':'Editar',
	'A925SubCompany':'Sub empresa',
	'A926Companies':'Empresas',
	'A927EditMachine':'Editar maquina',
	'A928CompaniesFunctionality':'Esmpresas / Funcionalidad ',
	'A930SelectAll':'Seleccionar todo',
	'A931DetailView':'Visualizar detalles',
	'A932EditUser':'Editar usuario',
	'A933Delete':'Eliminar',
	'A934Cancel':'Cancelar',
	'A935Save':'Salvar',
	'A936LoginFailed':'Error de inicio de sesion.  Credenciales incorrectas',
	'A936Malayalam':'Malayalam',
	'A937DisplayName1':'Nombre para mostrar 1',
	'A938DisplayName2':'Nombre para mostrar 2',
	'A939DisplayName3':'Nombre para mostrar 3',
	'A940ForgotPassword':'Olvido la contrasena',
	'A941ForgotText':'No hay problema.Intruduzca su nombre de usuario y recibira un email con un link donde podra redefinir su contrasena',
	'A942Submit':'Enviar',
	'A943EmailAddress':'Direccion de correo electronico  ',
	'A944ChangePassword':'Cambiar contrasena',
	'A945CurrentPassword':'Contrasena actual',
	'A946NewPassword':'Nueva contrasena',
	'A947ConfirmPassword':'Confirme nueva contrasena',
	'A948Update':'Actualizar',
	'A949IncorrectPassword':'Contrase incorrecta',
	'A950Metrics':'Especificaciones Metricas',
	'A950PasswordsNotMatch':'Las nuevas contrasenas no coinciden',
	'A951EmailNotFound':'Direccion de correo electronico no encontrada',
	'A952InvalidEmail':'Introduzca una direccion de correo electronico valida',
	'A953EmailSent':'Si su correo electrónico existe en nuestra base de datos, se enviará un correo electrónico a su identificación de correo con el enlace de reinicio.',
	'A954ForgetPasswordMailSubject':'Reiniciar su contrasena',
	'A955ForgetPasswordMailTitle':'Hola USER_NAME',
	'A956ForgetPasswordMailBody':'<p>We received a request to reset your password.<br>Use the link below to set up a new password for your account. If you did not request to reset your password, ignore this email and the link will expire in 24 hours on its own.<p><a href=\'RESET_LINK\' style=\'text-decoration: none; font-color: white;\'><span style=\'padding: 10px; display: inline; border-radius: 2px; border: 0; margin: 0 10px; background: #09174a; color:white; line-height: 15px; width: auto; height: auto; box-sizing: content-box;\'>Set new password</span></a><br><p>Best regards from the TrackYourPlant-Team</p>',
	'A957DateFormat':'dd/MM/yyyy',
	'A958D3DateFormat':'%d/%m/%Y',
	'A959DashboardMachineStateStandBy':'Espera',
	'A959MessageUpdateSuccess':'Mensaje actualizado correctamente',
	'A960MessageSaveSuccess':'Mensaje salvado correctamente',
	'A961Yes':'Si ',
	'A962No':'No',
	'A963ReportOverview':'Vision general del repote.',
	'A964Machine':'Maquina ',
	'A965Card':'Tarjeta',
	'A966ReportType':'Tipo de reporte',
	'A967HistogramReport':'Reporte de histarograma',
	'A968Report':'Reporte',
	'A969Day':'1 Dia',
	'A969French':'Français',
	'A970Week':'1 Semana',
	'A971Month':'1 Mes',
	'A972HistoryChart':'Grafico historico',
	'A972HistoryChartBacth':'Grafico historico del lote',
	'A973Unit':'[Unidades]',
	'A974Time':'Tiempo',
	'A975ShowBagCounterStackChart':'Ver tgrafico de pila',
	'A976TestReportStarted':'Generacion del reporte de prueba iniciada',
	'A977ActiveSettingsRequired':'Configuración del reporte activo requerida para generar el informe de prueba',
	'A978FirstDayRequired':'Fecha del primer reporte requerido',
	'A979CompanyDeleted':'Empresa eliminada.',
	'A980CompanyDeleteError':'No se puede eleminar la empresa',
	'A981CompanyParentShouldDifferent':'Empresa y empresa matriz deben ser diferentes',
	'A982CompanyCreated':'Empresa Salvada.',
	'A983CompanyCreateError':'La empresa no se puede salvar',
	'A984CompanyAllFields':'Intruduzca todo los campos',
	'A985UserCreated':'Usuario creado',
	'A986UserExists':'El correo electronico ya existe',
	'A987UserDeleted':'Usuario eliminido',
	'A988IncludedInReport':'Incluido en el reporte',
	'A989IncludedInHistogram':'Incluido como histograma en el reporte',
	'A990DateFormat':'DD / MM / YYYY',
	'A991MachineTime':'Tiempo de la maquina',
	'A992LocalTime':'Tiempo del ordenador',
	'A993NoMachines':'Ninguna maquina para mostrar',
	'A994Imprint':'Imprimir',
	'A995PrivacyPolicy':'Politica de privacidad',
	'A996Chinese':'中文',
	'A997Portuguese':'Português',
	'A998Vietnamese':'Tiếng Việt ',
	'A999CompanyNameExists':'EL nombre de la empresa ya existe',
	'B100ActivatingReports':'La activacion enviara reportes regulares para los siguientes  IDs de email:',
	'B101EnterValidEmailIds':'Ingrese una identificacion de correo electronico valida',
	'B101ValidFrom':'Válida desde',
	'B102AtleastOneEmailIdRequired':'al menos una identificacion de correo electronico es requerido',
	'B102ValidTo':'Válido hasta',
	'B103MaximumLimitReached':'Limite maximo alcanzado',
	'C001FetchingWait':'Bucando datos. Por favor espere',
	'C002PreparingWait':'Preparando datos. Por favor espere',
	'C003RenderingWait':' Representando grafico . Por facor espere  ',
	'C004PixelScale':'1 pixel  ≈ {{intervzlo de tiempo}}segundos',
	'C005ChartError':'Ocurrio un error!',
	'C006ChartOk':'Ok',
	'D001PortalType':'Portal',
	'D002CompanyDomain':'undefined',
	'E001AlertSettingSavedSuccessfully':'Alerta Configuraciones salvadas correctamente',
	'E002SelectAtLeastOneAlertTypeToActivateAlerting':'Seleccione al menos un tipo de  para activar la alerta',
	'E003ErrorNumberMustBePositive':'El numero de error debe ser positivo',
	'E004AlertEmailNeedsToBeActive':'El correo electronico de alerta necesita ser activado',
	'E005AnErrorHasOccured':'Ha ocurrido un error.',
	'E005ErrorMessageAlreadyExists':'El mensaje Ya existe',
	'E005OperationSuccesful':'Operacion exitosa',
	'E006ErrorMessageSave':'Ocurrio un error. El mensaje no ha sido slavado',
	'E006ErrorOccurredSettingsWasNotSaved':'Ocurrio un error. Las configuraciones no han sido salvadas',
	'E007ReportSettingsSuccessfullySaved':'Configuraciones del reporte han sido salvadas con exito.',
	'E008ErrorSettingsDoNotExists':'Error. Las configuraciones no existen',
	'E009ReportSettingsSuccessfullyUpdated':'Configuraciones del reporte cargadas con exito',
	'E010InvalidPassword':'Cintrasena invalida',
	'E011UserUpdated':'Usuario actualizado',
	'E012InvalidPassword':'La contrasena no cumple con los criterios de la contrasena',
	'E013UserUpdated':'El usuario fue actualizado con éxito.',
	'E015CompanyMaxUsersLowerThanActiveUsers':'La empresa ya tiene más usuarios activos que el número máximo de usuarios establecido',
	'E016PasswordChangedSuccessfully':'La contrasena fue cambiada con exito.',
	'F001_CurrentOrder':'Pedido actual',
	'F002_ProductID':'ID del producto',
	'F003_BatchStart':'Inicio del lote',
	'F004_Targetweight':'Peso deseado',
	'F005_RemainingFill':'Operaciones de llenado restantes',
	'F006_CurrentProduction':'Produccion actual',
	'F007_FillStatistic':'Rellenar estadisticas',
	'F010_Sum':'Total',
	'F011_Centering':'Centrando',
	'F012_Debunging':'Debunging',
	'F013_Inert':'Purga',
	'F014_Filling_1':'Llenado 1',
	'F015_Filling_2':'Llenado 2',
	'F016_Filling_3':'Llenado 3',
	'F017_Filling_4':'Llenado 4',
	'F018_Bunging':'Bunging',
	'F019_Sealing':'Sellando',
	'F020_Position':'Posicionando',
	'F021_TransportCycle':'Ciclo de transporte',
	'F022_CheckWeigher':'Balanza de control',
	'F023_Closing':'Cerrando',
	'F024_Palletizing':'Paletizador',
	'F025_EmptyPaletMagazin':'Dispenzador  de pallets vacios',
	'F026_DePalletizing':'De-Palitazador',
	'F027_Transport':'Transporte de Paquete',
	'F028_PailSeperator':'separador de cubo',
	'F029_HangUpCap':'Posicionador de tapa',
	'F030_PushInCap':'Prensa telas',
	'F031_TestConsoles':'Estacion de prueba',
	'F050_TU2':'TU 2',
	'F051_TU1':'TU 1',
	'F052_LowerTol':'Por debajo de la tolerancia',
	'F053_InTol':'Dentro de la tolerancia',
	'F054_UpperTol':'Por arriba de la tolerancia',
	'F055_TO1':'TO 1',
	'F056_TO2':'TO 2',
	'F060_ProductInfeed':'limentacion del producto',
	'F061_EmptyTruss':'Contenedor vacio',
	'F062DetailedDateFormat':'DD / MM / YYYY HH:mm:ss',
	'F090_Minuten':'Minutos',
	'F091_Stunden':'Horas',
	'F100_Eichung':'siguiente calibracion',
	'F101_TransKette':'Cadena transportadora',
	'F102_Hubtor':'Puertas levadizas',
	'F103_ZentRollen':'Rodillos de centrado',
	'F104_SpundSuch':'Localizador de bunghole',
	'F105_Aushub':'Elevador de contenedores',
	'F106_WerkSchlitten':'Carro de herramientas',
	'F107_Aufschrauber':'Eliminación de tapones en tiempo de ejecución',
	'F108_VakuSys':'Eliminación de tapones del sistema de vacío',
	'F109_HubAufSchraub':'Eliminación de tapones de cilindros',
	'F110_OelAufSchraub':'Eliminación de tapones de lubricación',
	'F111_Inertgas':'Inertes',
	'F112_Aushub_F1':'Elevador de contenedores',
	'F113_HE_F1':'Unidad de elevación',
	'F114_TFE_F1':'Cuchara de goteo',
	'F115_Aushub_F2':'Elevador de contenedores',
	'F116_HE_F2':'Unidad de elevación',
	'F117_TFE_F2':'Cuchara de goteo',
	'F118_ZentDorn':'Mandril de centrado',
	'F119_Schiebetisch':'Mesa deslizante',
	'F120_Kappenband':'Transportador de tapones',
	'F121_VakuKap':'Separación del enchufe del sistema de vacío',
	'F122_KapSort':'Olla de clasificación',
	'F123_VerKap':'Separación de tapones',
	'F124_KapZu':'Alimentación de enchufe',
	'F125_Schrauber':'Taponar',
	'F126_VakuSchrauber':'Taponamiento del sistema de vacío',
	'F127_HubSchrauber':'Taponamiento del cilindro',
	'F128_OelZuSchraub':'Tapones de lubricación',
	'F129_Dichtung':'Sellar la cabeza taponadora',
	'F130_HubClinch':'Sellador de cilindros',
	'F131_Clinchen':'Sellando',
	'F132_OelClincher':'Sellado de lubricación',
	'F133_VakuClincher':'Sellado del sistema de vacío ',
	'G001BatchReport':'Reportes del lote',
	'G002ActivateBatchReport':'Activar los reportes del lote',
	'Hülsen MIN-Melder':'Sonda de fijación a pozo nivel MIN ',
	'IVT-Simulation':'INTEGRA® ',
	'Kabel Sattelhöhenv':'Cable Saddle Height Adjustment',
	'MACHINE_AVAILABILITY_RED_ZONE':'Disponibilidad e la maquina en la zona roja',
	'MACHINE_AVAILABILITY_YELLOW_ZONE':'Disponibilidad e la maquina en la zona Amarilla',
	'Maint Interval 0':'Intervalo de mantenimiento 0',
	'Maint Interval 1':'Intervalo de mantenimiento 1',
	'Maint Interval 10':'Intervalo de mantenimiento 10',
	'Maint Interval 11':'Intervalo de mantenimiento 11',
	'Maint Interval 12':'Intervalo de mantenimiento 12',
	'Maint Interval 13':'Intervalo de mantenimiento 13',
	'Maint Interval 14':'Intervalo de mantenimiento 14',
	'Maint Interval 2':'Intervalo de mantenimiento 2',
	'Maint Interval 3':'Intervalo de mantenimiento 3',
	'Maint Interval 4':'Intervalo de mantenimiento 4',
	'Maint Interval 5':'Intervalo de mantenimiento 5',
	'Maint Interval 6':'Intervalo de mantenimiento 6',
	'Maint Interval 7':'Intervalo de mantenimiento 7',
	'Maint Interval 8':'Intervalo de mantenimiento 8',
	'Maint Interval 9':'Intervalo de mantenimiento 9',
	'miniADAMS-Messe':'ROTO-PACKER®',
	'N042BrazAlertGlobalAccL0':'La aceleración global cruzó L0',
	'N042BrazAlertGlobalAccL1':'La aceleración global cruzó L1',
	'N042BrazAlertGlobalAccL2':'La aceleración global cruzó L2',
	'N042BrazAlertGlobalAccL3':'La aceleración global cruzó L3',
	'N042BrazAlertGlobalAccL4':'La aceleración global cruzó L4',
	'N042BrazAlertGlobalAmplL0':'La amplitud global cruzó L0',
	'N042BrazAlertGlobalAmplL1':'La amplitud global cruzó L1',
	'N042BrazAlertGlobalAmplL2':'La amplitud global cruzó L2',
	'N042BrazAlertGlobalAmplL3':'La amplitud global cruzó L3',
	'N042BrazAlertGlobalAmplL4':'La amplitud global cruzó L4',
	'N042BrazAlertMaxRmsAccL0':'La aceleración rms máxima de los sensores LB, CB y RB cruzó L0',
	'N042BrazAlertMaxRmsAccL1':'La aceleración rms máxima de los sensores LB, CB y RB cruzó L1',
	'N042BrazAlertMaxRmsAccL2':'La aceleración rms máxima de los sensores LB, CB y RB cruzó L2',
	'N042BrazAlertMaxTempBearingSensL0':'Temperatura máxima de los sensores de los rodamientos cruzados L0',
	'N042BrazAlertMaxTempBearingSensL1':'Temperatura máxima de los sensores de los rodamientos cruzados L1',
	'N042BrazAlertMaxTempBearingSensL2':'Temperatura máxima de los sensores de los rodamientos cruzados L2',
	'N042BrazAlertMaxZAxisSensorsL0':'Aceleración máxima del eje z de lfs, rfs lds y sensores rojos cruzados L0',
	'N042BrazAlertMaxZAxisSensorsL1':'Aceleración máxima del eje z de lfs, rfs lds y sensores rojos cruzados L1',
	'N042BrazAlertMaxZAxisSensorsL2':'Aceleración máxima del eje z de lfs, rfs lds y sensores rojos cruzados L2',
	'N042BrazAlertRotFreqL0':'Frecuencia de rotación cruzada L0',
	'N042BrazAlertRotFreqL1':'Frecuencia de rotación cruzada L1',
	'N042BrazAlertRotFreqL2':'Frecuencia de rotación cruzada L2',
	'N042BrazAlertRotFreqL3':'Frecuencia de rotación cruzada L3',
	'N042BrazAlertRotFreqL4':'Frecuencia de rotación cruzada L4',
	'N042BrazAlertType':'Alerta brasileña',
	'N042GlobalAcceleration':'La aceleración global',
	'N042GlobalAmplitude':'Amplitud global',
	'N042GlobalFrequency':'Frecuencia global',
	'N043CardCtxMenuShowConfigScreen':'Configurar tarjeta',
	'N043ConfigSaveFailure':'Error al actualizar la configuración',
	'N043ConfigSaveSuccess':'Configuración actualizada exitosamente',
	'N044BrazilAlertMailSubject':'Pulse CM Alert : MACHINE_NAME',
	'N045AlertWhenThresholdCrossed':'Avísame cuando se cruce este límite',
	'N046RawDataTab':'Datos brutos',
	'N047SensorL1':'Sensor L 1',
	'N047SensorL4':'Sensor L 4',
	'N047SensorR1':'Sensor R 1',
	'N047SensorR4':'Sensor R 4',
	'N048Acceleration':'Aceleración',
	'N048AccelerationLocal':'Aceleración local',
	'N048AccelerationX':'Aceleración X',
	'N048AccelerationY':'Aceleración Y',
	'N048AccelerationZ':'Aceleración Z',
	'N048Amplitude':'Amplitud',
	'N048AmplitudeLocal':'Amplitude Local',
	'N048AmplitudeX':'Amplitud X',
	'N048AmplitudeY':'Amplitud Y',
	'N048AmplitudeZ':'Amplitud Z',
	'N048ChooseEndDate':'Elija una fecha de finalización',
	'N048ChooseStartDate':'Elija una fecha de inicio',
	'N048Constant':'Constante',
	'N048CourseAngle':'Ángulo de rumbo',
	'N048DeltaTemperature':'Temperatura delta',
	'N048Frequency':'Frecuencia',
	'N048LastSeen':'Ultima vez visto',
	'N048MovementAngle':'Ángulo de movimiento',
	'N048OffsetX':'X Compensar',
	'N048OffsetY':'Y Compensar',
	'N048OffsetZ':'Z Compensar',
	'N048PhaseAngle':'Ángulo de fase',
	'N048MotionAngle':'Ángulo de movimiento',
	'N048RMS':'RMS',
	'N048Rpm':'RPM',
	'N048SensorDescription':'Descripción del sensor',
	'N048SensorId':'Identificación del sensor',
	'N048SensorTemperature':'Temperatura del sensor',
	'N048Side':'Lado',
	'N048SiteName':'Nombre del sitio',
	'N048UpdatedTime':'Hora actualizada',
	'N049accelFDCompLeft':'Accel.FDComp.Left',
	'N049accelFDCompRight':'Accel.FDComp.Right',
	'N049accelLRCompDisch':'Accel.LRComp.Disch',
	'N049accelLRCompFeed':'Accel.LRComp.Feed',
	'N049Date':'Fecha',
	'N049ErrorFetchingData':'Error al obtener datos',
	'N049FrequencyDaily':'Día',
	'N049FrequencyHourly':'Horas',
	'N049FrequencyWeekly':'Semana',
	'N049Length':'Longitud',
	'N049ModelName':'Nombre del modelo',
	'N049pitch':'Tono',
	'N049roll':'Rodar',
	'N049Rpm':'Rpm',
	'N049strokeDeparture':'Salida del accidente cerebrovascular',
	'N049TuningWizard':'Asistente de tuning',
	'N049twistG':'Twist.G',
	'N049twistXG':'Twist.XG',
	'N049twistYG':'Twist.YG',
	'N049twistZG':'Twist.ZG',
	'N049Width':'Anchura',
	'N049xgFDCompLeft':'X.G.FDComp.Left',
	'N049xgFDCompRight':'X.G.FDComp.Right',
	'N049xgLrCompDisch':'X.G.LRComp.Disch',
	'N049xgLrCompFeed':'X.G.LRComp.Feed',
	'N049ygFDCompLeft':'Y.G.FDComp.Left',
	'N049ygFDCompRight':'Y.G.FDComp.Right',
	'N049ygLrCompDisch':'Y.G.LRComp.Disch',
	'N049ygLrCompFeed':'Y.G.LRComp.Feed',
	'N049zgFDCompLeft':'Z.G.FDComp.Left',
	'N049zgFDCompRight':'Z.G.FDComp.Right',
	'N049zgLrCompDisch':'Z.G.LRComp.Disch',
	'N049zgLrCompFeed':'Z.G.LRComp.Feed',
	'N049zgMax':'ZG.Max',
	'N050AnalystCommentary':'Comentario de la analista',
	'N050mAvgLong':'Mavg larga',
	'N050mAvgShort':'Mavg corta',
	'N050TrendAnalysis':'Análisis de tendencia',
	'N051SensorL1':'Sensor L1',
	'N051SensorL2':'Sensor L2',
	'N051SensorL3':'Sensor L3',
	'N051SensorL4':'Sensor L4',
	'N051SensorMovement':'Movimiento del sensor',
	'N051SensorR1':'Sensor R1',
	'N051SensorR2':'Sensor R2',
	'N051SensorR3':'Sensor R3',
	'N051SensorR4':'Sensor R4',
	'N052Anomaly':'Anomalía',
	'N052AnomalyDetectionTab':'Detección de anomalías',
	'N052BrazAlertTypeSL1LocalAmplitudeL0':'Sensor L1 amplitud local L0',
	'N052BrazAlertTypeSL1LocalAmplitudeL1':'Sensor L1 amplitud local L1',
	'N052BrazAlertTypeSL1LocalAmplitudeL2':'Sensor L1 amplitud local L2',
	'N052BrazAlertTypeSL1LocalAmplitudeL3':'Sensor L1 amplitud local L3',
	'N052BrazAlertTypeSL1LocalAmplitudeL4':'Sensor L1 amplitud local L4',
	'N052BrazAlertTypeSL1LocalAmplitudeL5':'Sensor L1 amplitud local L5',
	'N052BrazAlertTypeSL1SensorTempL0':'Sensor L1 temperatura del sensor cruzado L0',
	'N052BrazAlertTypeSL1SensorTempL1':'Sensor L1 temperatura del sensor cruzado L1',
	'N052BrazAlertTypeSL1SensorTempL2':'Sensor L1 temperatura del sensor cruzado L2',
	'N052BrazAlertTypeSL1SensorTempL3':'Sensor L1 temperatura del sensor cruzado L3',
	'N052ObservedValue':'Observada',
	'N053Angle':'Ángulo (0)',
	'N053BrazAlertTypeSL1LocalAccelerationL0':'Sensor L1 aceleración local cruzada L0',
	'N053BrazAlertTypeSL1LocalAccelerationL1':'Sensor L1 aceleración local cruzada L1',
	'N053BrazAlertTypeSL1LocalAccelerationL2':'Sensor L1 aceleración local cruzada L2',
	'N053BrazAlertTypeSL1LocalAccelerationL3':'Sensor L1 aceleración local cruzada L3',
	'N053BrazAlertTypeSL1LocalAccelerationL4':'Sensor L1 aceleración local cruzada L4',
	'N053BrazAlertTypeSL1LocalAccelerationL5':'Sensor L1 aceleración local cruzada L5',
	'N053BrazAlertTypeSl1RotationFreqL0':'Sensor L1 Frecuencia de rotación local L0',
	'N053BrazAlertTypeSl1RotationFreqL1':'Sensor L1 Frecuencia de rotación local L1',
	'N053BrazAlertTypeSl1RotationFreqL2':'Sensor L1 Frecuencia de rotación local L2',
	'N053BrazAlertTypeSl1RotationFreqL3':'Sensor L1 Frecuencia de rotación local L3',
	'N053BrazAlertTypeSl1RotationFreqL4':'Sensor L1 Frecuencia de rotación local L4',
	'N053BrazAlertTypeSl1RotationFreqL5':'Sensor L1 Frecuencia de rotación local L5',
	'N053BrazAlertTypeSL1ZAxisL0':'Sensor L1 Aceleración del eje z cruzado L0',
	'N053BrazAlertTypeSL1ZAxisL1':'Sensor L1 Aceleración del eje z cruzado L1',
	'N053BrazAlertTypeSL1ZAxisL2':'Sensor L1 Aceleración del eje z cruzado L2',
	'N053BrazAlertTypeSL1ZAxisL3':'Sensor L1 Aceleración del eje z cruzado L3',
	'N053Displacement':'Desplazamiento',
	'N053G':'g',
	'N053LocalAmplitude':'Amplitud local',
	'N053LocalRotationFrequency':'Rotación local (frecuencia)',
	'N053MM':'mm',
	'N053XAxis':'Eje X',
	'N053YAxis':'Eje Y',
	'N053ZAxis':'Eje Z',
	'N053ZAxisAcceleration':'Aceleración del eje Z',
	'N054Higher':'Superior',
	'N054Lower':'Más bajo',
	'N054StructuralAnalysis':'Análisis estructural',
	'N054TWvarsForecasts':'Pronósticos de TW vars',
	'N054TWvarsFull':'TW vars completo',
	'N054Value':'Valor',
	'N0551On0Off':'1 = ENCENDIDO: 0 = APAGADO',
	'N055BrazTabGeneral':'General',
	'N055ChooseStartEndDate':'Elija la fecha de inicio y la fecha de finalización',
	'N055Close':'Cerrar',
	'N055Custom':'Personalizada',
	'N055MaterialFlow':'Flujo de materiales',
	'N055MAxBearingTemperature':'Temperatura máxima del rodamiento',
	'N055MaxRmsAccelBearingSensors':'Aceleración máxima de Rms de los sensores de rodamientos',
	'N055MazAccelZAxis':'Aceleración máxima del eje Z de los sensores de estructura',
	'N055Off':'APAGADA',
	'N055On':'EN',
	'N055RDCenter':'Vista de la máquina',
	'N055ShowHistory':'Mostrar historial',
	'N055StartDateCannotBeAfterEndDate':'La fecha de inicio no puede ser posterior a la fecha de finalización',
	'N056Commentary':'Comentario',
	'N056EvaluateEquipment':'Condiciones a evaluar en el equipo',
	'N056High80':'Alto 80',
	'N056High95':'Alto 95',
	'N056Importance':'importancia',
	'N056Low80':'Bajo 80',
	'N056Low95':'Bajo 95',
	'N056MaxLatAccelZgMax':'Aceleración lateral máxima ZG.Max',
	'N056Pred':'Pred',
	'N056Recommendations':'Recomendaciones',
	'N056Type':'Escribe',
	'N056Report':'Informe',
	'N0571Year':'1 año',
	'N057AccelGms':'Aceleración (g)',
	'N057AccelRms':'Aceleración RMS',
	'N057AccelSpectrum':'Espectro de aceleración',
	'N057AccelTimeWForm':'Forma de onda de tiempo de aceleración',
	'N057Axial':'Axial',
	'N057CrestFactor':'Factor de cresta',
	'N057DegreeCelcius':'°C',
	'N057FreqHz':'Frecuencia (Hz)',
	'N057FullSpectrum':'Espectro completo',
	'N057FundBearingDefectFreq':'Fondo, rodamiento, frecuencias de defectos',
	'N057HighHarBearFreq':'Frecuencias de rodamientos armónicos más altas',
	'N057HighRes':'High Res',
	'N057HistoricTemperature':'Temperatura histórica',
	'N057Horizontal':'Horizontal',
	'N057Latest':'Última',
	'N057LowHarBearFreq':'Frecuencias de rodamientos armónicos más bajos',
	'N057mms':'mm/s',
	'N057PeakToPeak':'Pico a pico',
	'N057PeakToPeakMg':'Peak to peak(mg)',
	'N057PowerBands':'Bandas de poder',
	'N057RedAlert':'alerta roja',
	'N057Rmsmg':'Rms(mg)',
	'N057Rmsmms':'Rms(mm/s)',
	'N057SensorLB1':'Sensor LB1',
	'N057SensorLB2':'Sensor LB2',
	'N057SensorRB1':'Sensor RB1',
	'N057SensorRB2':'Sensor RB2',
	'N057SensorCB1':'Sensor CB1',
	'N057SensorCB2':'Sensor CB2',
	'N057Spectrum':'Espectro',
	'N057Temperature':'La temperatura',
	'N057TimeMs':'Tiempo (ms)',
	'N057Trending':'Tendencias',
	'N057VelocityRms':'Velocidad RMS',
	'N057Vertical':'Vertical',
	'N057X1Rpm':'1X Rpm',
	'N057YellowAlert':'Alerta amarilla',
	'N058ImageUploadedFailure':'Error de carga de imagen',
	'N058ImageUploadedSuccesfully':'Imagen cargada con éxito',
	'N058UploadImage':'Cargar imagen',
	'N059Print':'Imprimir',
	'N060MachineHistoricalData':'Datos históricos de la máquina',
	'N061HistoricalBearingData':'Datos históricos de rumbo',
	'N062HistoricalSpectrumData':'Datos históricos del espectro',
	'N063GlobalAcceleration':'Aceleración global',
	'N064RotationFrequency':'Rotación (frecuencia)',
	'N065GlobalBreadth':'Amplitud global',
	'N066LocalAccelerationsEachStructureSensor':'Aceleraciones locales de cada sensor de estructura',
	'N067MotionAngleEachStructureSensor':'Ángulo de movimiento de cada sensor de estructura',
	'N067PhaseAngleEachStructureSensor':'Ángulo de fase de cada sensor de estructura',
	'N067LocalAmplitudesEachStructureSensor':'Amplitudes locales de cada sensor de estructura.',
	'N068LateralAccelerationsStructureSensor':'Aceleraciones laterales (eje Z) de cada sensor de estructura',
	'N069TemperatureEachBearingSensor':'Temperatura de cada sensor de rodamiento',
	'N070PeakToPeakEachBearingSensor':'Pico a pico de cada sensor de cojinete',
	'N071AccelerationRMS':'Aceleración RMS',
	'N072VelocityRMS':'Velocidad RMS',
	'N073CrestFactor':'Factor de cresta',
	'N074PowerBandsSensorLB1':'Bandas de potencia - Sensor LB1',
	'N075PowerBandsSensorRB1':'Bandas de potencia - Sensor RB1',
	'N076PowerBandsSensorLB2':'Bandas de potencia - Sensor LB2',
	'N077PowerBandsSensorRB2':'Bandas de potencia - Sensor RB2',
	'N077PowerBandsSensorCB1':'Bandas de potencia - Sensor CB1',
	'N077PowerBandsSensorCB2':'Bandas de potencia - Sensor CB2',
	'N078TimeWaveformDataAcceleration':'Datos de forma de onda de tiempo: aceleración',
	'N079FrequencyDomainAcceleration':'Dominio de la frecuencia: aceleración',
	'N080Select':'Seleccione',
	'N081SelectedPeriod':'Periodo seleccionado',
	'N100Capacity':'Capacidad',
	'N101Product':'Producto',
	'N102Undersized':'Tamaño reducido',
	'N103Oversized':'tamaño sobredimensionado ',
	'N104Speed':'Velocidad RMS',
	'N105Inclination':'Inclinación',
	'N106BoardHeigh':'Altura de la tabla',
	'N107Moisture':'Humedad',
	'N108WaterAdd':'Adición de agua',
	'N109OpMode':'Modo de operación',
	'N110Auto':'Modo automatico',
	'N110Hand':'Modo manual',
	'N111OpHours':'Horas de funcionamiento',
	'N112Temp':'Temperatura',
	'N113BearingTemp1':'Temperatura del cojinete 1',
	'N114BearingTemp2':'Temperatura del cojinete 2',
	'N115DriveTemp':'Temperatura de conducción',
	'N116ConvTemp':'Temperatura del convertidor',
	'N117PowerInput':'Entrada de alimentación',
	'New001BagTransArea':'Área de transporte de sacos',
	'New002EmptyPalArea':'Área de paleta vacía',
	'New003FullPalArea':'Área de paleta completa',
	'New004SheetDispArea':'Área del dispensador de hojas',
	'New005PneuFalut':'Fallo neumático',
	'New006ElectricFault':'Falla eléctrica',
	'New007Introduction':'Introducción',
	'New008Orientation':'Orientación',
	'New009PreGrouping':'Preagrupación',
	'New010Grouping':'Agrupamiento',
	'New011RowPusher':'Empujador de hileras',
	'New012SlideTable':'Mesa deslizante y placa de conformación',
	'New013Elevator':'Ascensor',
	'New014Gantry':'Portal',
	'New015Gripper':'Pinza',
	'New016FilmDispenser':'Dispensador de película',
	'New017FullPalTrans':'Transporte de paletas completas',
	'New018EmpPalDisp':'Dispensador de paletas vacías',
	'New019FilmDisp':'Dispensador de película',
	'New020EmpCardDisp':'Dispensador de cartón',
	'New021EmpPalTrans':'Transporte de paletas vacías',
	'New022EjectingDev':'Dispositivo de expulsión',
	'New023MetDetector':'Detector de metales',
	'New024MarkingDev':'Dispositivo de marcado',
	'New025LabellingDev':'Dispositivo de etiquetado',
	'New026PalletStracker':'Stracker de paletas',
	'New027PalletPress':'Prensa de paletas',
	'New028Wrapper':'Dispositivo de envoltura / encapuchado',
	'New029Fault':'fallo',
	'New030StandBy':'Pausa',
	'New031BagProduction':'Producción de sacos',
	'New032WeightDetection':'Detección de peso',
	'New033LeakingDetection':'Detección de fugas',
	'New034PrintingFault':'Fallo de impresión',
	'New035BagProduced':'saco producido',
	'New036SamplingEjection':'Eyección de muestreo',
	'New037PalletProduction':'Producción de palets',
	'New038PalletInfeed':'Entrada de palet',
	'New039PalletOutfeed':'salida de palet',
	'New040PalletIncomplete':'palet incompleto',
	'New041Checkweigher':'Báscula',
	'New041PalledProduced':'Palet producido',
	'New042ProgramChngsToday':'Cambios de programa de hoy',
	'New042ProgramChngs':'Cambios de programa',
	'New043EndofBatch':'final de serie',
	'New044ProductonTime':'Tiempo de producción',
	'RVT8-Messe':'ROTO-PACKER® ',
	'RVT8-Simulation':'ROTO-PACKER® ',
	'TERAM2-Messe':'TERRAM®',
	'TERAM-Messe':'TERRAM®',
	'Typ84-Messe':'INTEGRA® ',
	'Waagenabgleich':'Ajuste de Balanza',
	'Zyklen':'Ciclos',
	'N082AlertMe':'Avisarme cuando esta tarjeta tenga alguna alerta',
	'N083TimeFormat':'HH:mm',
	'N090Synced':'sincronizado',
	'N091NotSynced':'no sincroizado',
	'N084New':'último',
	'N085ConfigSettingSavedSuccessfully':'Ajustes de configuración guardados correctamente.',
	'N86ErrorOccurredSettingsWasNotSaved':'Error al guardar ajustes de configuración.',
	'A496OEEWeek':'cada dos semanas',
	'A497OEEMonthly':'mensual',
	'N87ServiceRequestUploadFiles':'subir archivos',
	'N88ServiceRequestFileSizeError':'El archivo es demasiado grande',
	'N89ServiceRequestFileTypeError':'Los archivos osn incompatibles',
	'N90FilesUploadedSuccesfully':'archivo cargado con éxito',
	'N91FilesUploadedFailure':'Error al cargar archivos ',
	'N932IncludeExcelReport':'Incluir informes de Excel',
	'N94ServiceRequestFileLimitError':'Se ha exedido el límite de archivos',
	'N055MaxHAxisRmsAccelBearingSensors':'undefined',
	'N055MaxAxialRmsAccelBearingSensors':'undefined',
	'N053MG':'mg',
	'N056DummyMachine':'Dummy machines',
	'N057NiagaraAlertYellow':'Alerta amarilla',
	'N058NiagaraAlertOrange':'Alerta Naranja',
	'N059NiagaraAlertRed':'Alerta Roja',
	'N060AddDummyMachine':'undefined',
	'N96ConfigureValue':'Valor de configuración',
	'N97NoDataAvailable':'Datos no disponibles',
	'N98TimeStamp':'Timestamps',
	'N99Documentation':'Documentation',
	'N100ErrorFileHeader':'undefined',
	'N101DocumentationHeader':'undefined',
	'N102DownloadMachineTypeErrorMessageFile':'undefined',
	'N102DownloadMachineErrorMessageFile':'undefined',
	'N103ErrorInRetrievingErrorMessages':'undefined',
	'E011MaintIntervalSubmitSuccess':'undefined',
	'A368OpenPartsShop':'undefined',
	'N104ExtSparePartCatalog':'undefined',
	'N105OpenExtSparePartCatalog':'undefined',
	'N106LperH':'l/h',
	'N107Degree':'°',
	'N107DegreeText':'° (Degree)',
	'N108UseRelativeValues':'undefined',
	'N109PartsShopUsername':'undefined',
	'N110Tons':'t',
	'AX_VibratingBodyCondition':'undefined',
	'AX_BearingCondition':'undefined',
	'AX_AlarmsCount':'undefined',
	'AX_Last24Hours':'undefined',
	'D001TransBearingCondition':'undefined',
	'D002TransBearingCondition':'undefined',
	'D003TransBearingCondition':'undefined',
	'D004TransBearingCondition':'undefined',
	'D005TransBearingCondition':'undefined',
	'D006TransBearingCondition':'undefined',
	'D007TransBearingCondition':'undefined',
	'D008TransBearingCondition':'undefined',
	'D009TransBearingCondition':'undefined',
	'D010TransBearingCondition':'undefined',
	'D011TransBearingCondition':'undefined',
	'D012TransBearingCondition':'undefined',
	'D013TransBearingCondition':'undefined',
	'D014TransBearingCondition':'undefined',
	'D015TransBearingCondition':'undefined',
	'D016TransBearingCondition':'undefined',
	'D001TransVBCondition':'undefined',
	'D002TransVBCondition':'undefined',
	'D003TransVBCondition':'undefined',
	'D004TransVBCondition':'undefined',
	'D005TransVBCondition':'undefined',
	'D006TransVBCondition':'undefined',
	'D007TransVBCondition':'undefined',
	'D008TransVBCondition':'undefined',
	'D009TransVBCondition':'undefined',
	'D010TransVBCondition':'undefined',
	'D011TransVBCondition':'undefined',
	'D012TransVBCondition':'undefined',
	'D013TransVBCondition':'undefined',
	'D014TransVBCondition':'undefined',
	'D015TransVBCondition':'undefined',
	'D016TransVBCondition':'undefined',
	'F01CardExpProduction':'***Coming Soon***',
	'F01CardExpAvailability':'***Coming Soon***',
	'F01CardExpMaintenance':'***Coming Soon***',
	'F01CardExpProductionTime':'***Coming Soon***',
	'F01CardExpProductionTimeBaumit':'***Coming Soon***',
	'F01CardExpProductionToday':'***Coming Soon***',
	'F01CardExpRemProduction':'***Coming Soon***',
	'F01CardExpLastReset':'***Coming Soon***',
	'F01CardExpFaults_C':'***Coming Soon***',
	'F01CardExpFaults_M':'***Coming Soon***',
	'F01CardExpEnergy':'***Coming Soon***',
	'F01CardExpFillingTime':'***Coming Soon***',
	'F01CardExpBagManufacture':'***Coming Soon***',
	'F01CardExpWeightData':'***Coming Soon***',
	'F01CardExpStdDevData':'***Coming Soon***',
	'F01CardExpIncorrectWeight':'***Coming Soon***',
	'F01CardExpInterrupts':'***Coming Soon***',
	'F01CardExpTypeChange':'***Coming Soon***',
	'F01CardExpBagCounterPerFS':'***Coming Soon***',
	'F01CardExpNotPlacedBags':'***Coming Soon***',
	'F01CardExpEmptyBagMagazineToday':'***Coming Soon***',
	'F01CardExpRejectsToday':'***Coming Soon***',
	'F01CardExpCollectiveFaultsToday':'***Coming Soon***',
	'F01CardExpGoodWeights':'***Coming Soon***',
	'F01CardExpFltCompAir':'***Coming Soon***',
	'F01CardExpStrokesPump':'***Coming Soon***',
	'F01CardExpMaxStrokeDur':'***Coming Soon***',
	'F01CardExpAvgStrokes':'***Coming Soon***',
	'F01CardExpInternalPressure':'***Coming Soon***',
	'F01CardExpFillings':'***Coming Soon***',
	'F01CardExpStartOfOrder':'***Coming Soon***',
	'F01CardExpProductID':'***Coming Soon***',
	'F01CardExpTargetWeight':'***Coming Soon***',
	'F01CardExpFillingCount':'***Coming Soon***',
	'F01CardExpRemainingNumberFillings':'***Coming Soon***',
	'F01CardExpCycleTime':'***Coming Soon***',
	'F01CardExpFaultLength':'***Coming Soon***',
	'F01CardExpExtFaultLength':'***Coming Soon***',
	'F01CardExpWithinTolerance':'***Coming Soon***',
	'F01CardExpOutsideTolerance':'***Coming Soon***',
	'F01CardExpCurrentProduction':'***Coming Soon***',
	'F01CardExpFillStatistic':'***Coming Soon***',
	'F01CardExpRDCenter':'***Coming Soon***',
	'F01CardExpGlobalAcceleration':'***Coming Soon***',
	'F01CardExpGlobalFrequency':'***Coming Soon***',
	'F01CardExpGlobalAmplitude':'***Coming Soon***',
	'F01CardExpMazAccelZAxis':'***Coming Soon***',
	'F01CardExpMaxHAxisRmsAccelBearingSensors':'***Coming Soon***',
	'F01CardExpMaxRmsAccelBearingSensors':'***Coming Soon***',
	'F01CardExpMaxAxialRmsAccelBearingSensors':'***Coming Soon***',
	'F01CardExpMAxBearingTemperature':'***Coming Soon***',
	'F01CardExpSensorMovement':'***Coming Soon***',
	'F01CardExpAccelerationLocal':'***Coming Soon***',
	'F01CardExpAcceleration':'***Coming Soon***',
	'F01CardExpAxisAcceleration':'***Coming Soon***',
	'F01CardExpLocalRotationFrequency':'***Coming Soon***',
	'F01CardExpLocalAmplitude':'***Coming Soon***',
	'F01CardExpDisplacement':'***Coming Soon***',
	'F01CardExpPhaseAngle':'***Coming Soon***',
	'F01CardExpMotionAngle':'***Coming Soon***',
	'F01CardExpSensorTemperature':'***Coming Soon***',
	'F01CardExpBagQuality':'***Coming Soon***',
	'F01CardExpFaults':'***Coming Soon***',
	'F01CardExpBunChangeAbs':'***Coming Soon***',
	'F01CardExpBunChangeToday':'***Coming Soon***',
	'F01CardExpBagsPerBundle':'***Coming Soon***',
	'F01CardExpTypeChanges':'***Coming Soon***',
	'F01CardExpSortChangeToday':'***Coming Soon***',
	'F01CardExpCyclesToday':'***Coming Soon***',
	'F01CardExpMetalDetectorToday':'***Coming Soon***',
	'F01CardExpIncorrectBags':'***Coming Soon***',
	'F01CardExpOpenBags':'***Coming Soon***',
	'F01CardExpSampleBags':'***Coming Soon***',
	'F01CardExpRejectionsToday':'***Coming Soon***',
	'F01CardExpContainer':'***Coming Soon***',
	'F01CardExpEmptyRejects':'***Coming Soon***',
	'F01CardExpFullRejects':'***Coming Soon***',
	'F01CardExpProductionRate':'***Coming Soon***',
	'F01CardExpDailyProduction':'***Coming Soon***',
	'F01CardExpFillingStation':'***Coming Soon***',
	'F01CardExpPalletizer':'***Coming Soon***',
	'F01CardExpEmptyPalArea':'***Coming Soon***',
	'F01CardExpBagTransArea':'***Coming Soon***',
	'F01CardExpFullPalArea':'***Coming Soon***',
	'F01CardExpSheetDispArea':'***Coming Soon***',
	'F01CardExpProductonTime':'***Coming Soon***',
	'F01CardExpProgramChngsToday':'***Coming Soon***',
	'F01CardExpEndofBatch':'***Coming Soon***',
	'F01CardExpEmptyPallets':'***Coming Soon***',
	'F01CardExpBagProduction':'***Coming Soon***',
	'F01CardExpPalletProduction':'***Coming Soon***',
	'F01CardExpFSDifference':'***Coming Soon***',
	'F01CardExpProduct':'***Coming Soon***',
	'F01CardExpCapacity':'***Coming Soon***',
	'F01CardExpUndersized':'***Coming Soon***',
	'F01CardExpOversized':'***Coming Soon***',
	'F01CardExpSpeed':'***Coming Soon***',
	'F01CardExpInclination':'***Coming Soon***',
	'F01CardExpBoardHeigh':'***Coming Soon***',
	'F01CardExpMoisture':'***Coming Soon***',
	'F01CardExpWaterAdd':'***Coming Soon***',
	'F01CardExpOpMode':'***Coming Soon***',
	'F01CardExpOpHours':'***Coming Soon***',
	'F01CardExpTemp':'***Coming Soon***',
	'F01CardExpPowerInput':'***Coming Soon***',
	'F01CardExpFullPalletCount':'***Coming Soon***',
	'F01CardExpLoadEmptyPallets':'***Coming Soon***',
	'F01CardExpOeeValue':'***Coming Soon***',
	'F01CardExpOeeAvailable':'***Coming Soon***',
	'F01CardExpOeePerformance':'***Coming Soon***',
	'F01CardExpOeeQuality':'***Coming Soon***',
	'F01CardExpOeeDowntime':'***Coming Soon***',
	'F01CardExpOperatingFactor':'***Coming Soon***',
	'F01CardExpThroughput':'***Coming Soon***',
	'F01CardExpOeeRejects':'***Coming Soon***',
	'F01CardExpMachineDetailPage':'***Coming Soon***',
	'F01CardExpReportSettingsPage':'***Coming Soon***',
	'F01CardExpReportOverviewPage':'***Coming Soon***',
	'F01CardExpCompanyOverviewPage':'***Coming Soon***',
	'F01CardExpAlertSettingsPage':'***Coming Soon***',
	'F01CardExpMsgAdminPage':'***Coming Soon***',
	'F01CardExpUserRolesPage':'***Coming Soon***',
	'F01CardExpCompaniesPage':'***Coming Soon***',
	'F01CardExpDummyMachinePage':'***Coming Soon***',
	'F01CardExpPermissionsPage':'***Coming Soon***',
	'F01CardExpMaintenanceAidPage':'***Coming Soon***',
	'F01CardExpMachinePage':'***Coming Soon***',
	'F01CardExpLogBookTab':'***Coming Soon***',
	'F01CardExpServiceRequestTab':'***Coming Soon***',
	'F01CardExpMessagesTab':'***Coming Soon***',
	'F01CardExpDocumentSTab':'***Coming Soon***',
	'F01CardExpOeeTab':'***Coming Soon***',
	'F01CardExpMaintenanceTab':'***Coming Soon***',
	'F01CardExpMachineSpecificTab':'***Coming Soon***',
	'F01CardExpGeneralTab':'***Coming Soon***',
	'F01CardExpGoodPerformance':'***Coming Soon***',
	'F01CardExpWrongApplBags':'***Coming Soon***',
	'F01CardExpReelChanges':'***Coming Soon***',
	'F01CardExpTypeCurrent':'***Coming Soon***',
	'F01CardExpInterruptsFeigeGeneral':'***Coming Soon***',
	'F01CardExpInterruptsAdams':'***Coming Soon***',
	'F01CardExpInterruptsISF':'***Coming Soon***',
	'F01CardExpInterruptsIVT':'***Coming Soon***',
	'F01CardExpInterruptsMiniAdams':'***Coming Soon***',
	'F01CardExpInterruptsRVT':'***Coming Soon***',
	'F01CardExpInterruptsNewtecTerram':'***Coming Soon***',
	'A-AlertTip-main_acc-ORANGE':'Aceleración principal Alerta naranja',
	'A-AlertTip-main_acc-YELLOW':'Aceleración principal Alerta amarilla',
	'A-AlertTip-main_acc-RED':'Alerta roja de aceleración principal',
	'A-AlertTip-u8MachAvail-AVAIL':'Alerta de disponibilidad',
};