export const LANG_FR_NAME = 'fr';
export const LANG_FR_TRANS = {
	'0030565001AE':'ELEMENTRA® ',
	'003056500368':'INTEGRA®',
	'003056500394':'ROTO-PACKER®',
	'003056500398':'ROTO-PACKER®',
	'0030565011A8':'ROTO-PACKER®',
	'0030565011AE':'ELEMENTRA®',
	'003056501200':'ROTO-PACKER®',
	'00305650121E':'INTEGRA®',
	'003056A98058':'ROTO-PACKER®',
	'057X1to2Rpm':'1X-2X tr/min',
	'057X2to3Rpm':'2.5X - 3X tr/min',
	'A000Key':'Clé',
	'A001Country':'Français',
	'A002German':'Allemand',
	'A003English':'Anglais',
	'A004Username':'Courriel',
	'A005Login':'Connectez-vous avec votre identifiant',
	'A006Password':'Mot de passe',
	'A007RemPassword':'Rester connecté',
	'A008Login':'Se connecter',
	'A009Logout':'Se déconnecter',
	'A010Production':'Capacité de production',
	'A011Availability':'Disponibilité',
	'A012Maintenance':'Maintenance de la machine',
	'A013RemainingUnits':'Unités restantes',
	'A014ProductionTime':'Temps total',
	'A015ProductionTodayGeneral':'Production du jour',
	'A015ProductionToday':'Production du jour',
	'A016Units':'Unités   ',
	'A017RemProduction':'Production totale',
	'A018LastReset':'Dernière initialisation',
	'A019Faults':'Interruptions du jour',
	'A019FaultsBatch':'Interruptions',
	'A020Count':'Nombre',
	'A021FaultDuration':'Durée des pannes du jour',
	'A022Minutes':'min',
	'A023BagCounterPerFS':'Compteur de sacs du jour',
	'A023BagCounterPerFSBatch':'Compteur de sacs',
	'A024FillingStation':'Station de remplissage',
	'A025FSDifference':'Répartition compteur des sacs',
	'A026AtLeast1Caps':'Au moins 1 lettre majuscule',
	'A026FSworstspout':'et plus mauvais bec',
	'A027Percent':'Pourcent',
	'A028RejectionsToday':'Ejection',
	'A028RejectionsTodayBatch':'Ejection',
	'A029FilterStop':'filtre de dépoussiérage',
	'A030RotaryDriveStop':'ROTO-PACKER®',
	'A031ProductFeedStop':'Alimentation en produit',
	'A032RadimatStop':'RADIMAT®',
	'A033PalletizerStop':'Transporteurs en aval',
	'A034CompAirFault':'Air comprimé',
	'A035TypeChange':'Changement de sorte',
	'A035TypeChangeBatch':'Changement de sorte',
	'A036ProductionRate':'Cadence de production correspondante',
	'A037SpeedRelated':'Sacs / heure',
	'A038UnitsPerHour':'Unités / heure',
	'A039GoodWeights':'Production acceptée',
	'A040ScissorHose':'Manchon pour vanne a ciseaux',
	'A041ConnectingHose':'Tuyau de raccordement',
	'A042ConeGasket':'Joint d\'étanchéité de cône',
	'A043AerationPlates':'Plaques de fluidisation',
	'A043ScissorHose':'Manchon pour vanne a ciseaux',
	'A044ConnectingHose':'Tuyau de raccordement',
	'A044RegulationValve':'Vanne de régulation de pression d\'air de fluidisation',
	'A045ConeFeeding':'Cône entrée ensacheuse',
	'A045ConeGasket':'Joint d\'étanchéité de cône',
	'A046AerationPlates':'Plaques de fluidisation',
	'A046BagTransBelt':'Transporteur de sacs à bande texturée',
	'A047BagTransBearing':'Palier à bague de serrage transport de sacs',
	'A047RegulationValve':'Vanne de régulation de pression',
	'A048ConeFeeding':'Cône entrée alimentation ensacheuse',
	'A048SuctionCup':'Ventouse Radimat',
	'A049BagTransBelt':'Courroie de transport de sacs',
	'A049BagTransCyl':'Vérin transport de sacs',
	'A050BagTransBearing':'Roulement auto-aligneur',
	'A050SucRailCyl':'Vérin barre à ventouses',
	'A051SuctionCup':'Ventouse Radimat',
	'A051VacuumPump':'Pompe à vide/générateur de vide',
	'A052BagTransCyl':'Vérin transport de sacs',
	'A052BagTransCylinde':'Vérin transport de sacs',
	'A052CableHightAdj':'Câble réglage hauteur de selle',
	'A053HullMinSensor':'Fourreau sonde niveau min',
	'A053SucRailCyl':'Vérin barre à ventouses',
	'A053SucRailCylinder':'Vérin barre à ventouses',
	'A054Last48Hours':'Dernières 48 heures',
	'A054VacuumPump':'Pompe à vide',
	'A055CableHightAdj':'Sécheur d\'air comprimé',
	'A055Last14Days':'14 derniers jours',
	'A056HullMinSensor':'Surpresseurs à pistons rotatifs',
	'A056off':'En provenance de',
	'A057InOperation':'En fonctionnement',
	'A058Faulty':'En défaut',
	'A059InMaintenance':'En maintenance',
	'A060HourlyValues':'Valeurs horaires pour',
	'A061DischargesTotal':'Total sacs éjectés',
	'A062CreationDate':'Date de création',
	'A062Period':'Période',
	'A063To':'jusqu\'à ',
	'A064BatchValue':'Valeurs de sorte',
	'A064DailyValue':'Valeurs quotidiennes',
	'A064DailyValues':'Valeurs journalières pour',
	'A065supply':'Alimentation',
	'A066Transport':'Evacuation',
	'A067Feeding':'Alimentation en produit',
	'A068PackagesMissing':'Paquets manquants',
	'A069NoHistoricalData':'entre',
	'A070And':'et',
	'A071Available':'disponible',
	'A072ReallyReset':'Vraiment réinitialiser ?',
	'A073Details':'Détails',
	'A074General':'en général',
	'A075Machines':'Machines',
	'A076Dashborad':'Tableau de bord',
	'A077Administration':'Administration',
	'A078UserRoles':'Utilisateurs et rôles',
	'A079Companies':'Entreprises',
	'A080Machines':'Machines (dev)',
	'A081Date':'Date',
	'A082Transport':'évacuation',
	'A083Containermissing':'Paquet manquant',
	'A084Availability':'disponibilité',
	'A085TypeChanges':'Changement de sortes du jour',
	'A086EmptyRejects':'Rebut de sacs vides du jour',
	'A086EmptyRejectsBatch':'Rebut  de sacs vides ',
	'A087FullRejects':'Rebut de sacs pleins du jour',
	'A087FullRejectsBatch':'Rebut de sacs pleins',
	'A088OpRelease':'Autorisation fonctionnement',
	'A089ProdFeedStop':'Arrêt alimentation produit',
	'A090AuxDrive':'Entraînement auxiliaire',
	'A091Consumables':'consommables',
	'A092DischLineStop':'Arrêt ligne évacuation sacs',
	'A093TypeChange':'changements de sorte',
	'A094DailyProduction':'Production du jour',
	'A094DailyProductionBatch':'Production',
	'A095Energy':'énergie',
	'A096EffectivePower':'Puissance active',
	'A097ApparentPower':'puissance apparente',
	'A098CompAirFlow':'Débit air comprimé',
	'A099ResetFailed':'Le métrique n\'a pas pu être réinitialisé',
	'A1000Spanish':'Espagnol',
	'A1000UploadMaintenanceAid':'Télécharger des aides à la maintenance',
	'A1001UploadMaintenanceAidSuccess':'Maintenance des aides téléchargées avec succès',
	'A1002UploadMaintenanceAidFailure':'Erreur lors du téléchargement des aides à la maintenance',
	'A1005MachineServiceRequest':'demande de service',
	'A1006RequestService':'demande de service',
	'A1007ServiceContract':'contrat de maintenance',
	'A1008ServiceRequestSuccesful':'Demande de service traitée avec succès',
	'A1009ServiceRequestFailure':'Echec de la demande de service',
	'A100AcessDenied':'Accès refusé.',
	'A100AlertmailSubject':'Alerte QSI : MACHINE_NAME',
	'A1010LogBook':'Journal de bord',
	'A1011CreateLogBook':'Créer une entrée du journal de bord',
	'A1012EditLogBook':'Modifier une entrée du journal de bord',
	'A1013LogBookItemType':'Type d\'élément du journal de bord',
	'A1014LogBookItem':'Entrée du journal de bord',
	'A1015LogBookItemSaved':'Entrée du journal de bord sauvegardée',
	'A1016ErrorInSavingLogBookItem':'Erreur lors de l\'enregistrement d\'une entrée du journal de bord',
	'A1017ErrorInRetrievingLogBookItem':'Erreur lors de la récupération du journal de bord',
	'A1018ErrorInDeletingLogBookItem':'Erreur lors de la suppression d\'une entrée du journal de bord',
	'A1019LogBookItemDeleted':'Élément du journal de bord supprimé',
	'A101MailSalutation':'Bonjour USER_NAME,',
	'A101ResetSuccessful':'La métrique a été réinitialisé',
	'A1020LogBookItemIsDone':'réalisé',
	'A1021LogBookItemType':'Type d\'entrée',
	'A1022ServiceEmail':'Email de service',
	'A1023CannotMakeServieRequest':'Vous ne pouvez pas demander de service, cette machine ne disposant d\'aucun courriel de service.',
	'A1024MaxUsers':'Nombre maximum d\'utilisateurs',
	'A1024MaxUsersReached':'La limite maximum d\'utilisateurs de la société est atteinte',
	'A1025AtLeast8Characters':'au moins 8 caractères',
	'A1027AtLeast1Lowercase':'au moins 1 lettre minuscule',
	'A1028AtLeast1Number':'au moins 1 chiffre (0-9)',
	'A1029AtLeast1SpecialChar':'au moins 1 caractère spécial (!,. [])',
	'A102CompanyAddressNewtec':'NEWTEC BAG PALLETIZING <br>32 avenue de Suisee <br>ZI Ile Napoléon - BP 256 <br>68315 Illzach cedex - France',
	'A102CompanyAddressAventus':'AVENTUS GmbH & Co. KG <br>Katzheide 33 <br>D-48231 Warendorf <br>Allemagne / Deutschland',
	'A102CompanyAddressNiagaraCanada':'HAVER & BOECKER Niagara Canada <br>225 Ontario St. <br>St. Catharines, ON, L2R 7B6 <br>Canada',
	'A102CompanyAddressNiagaraChile':'HAVER & BOECKER Andina <br>Av. El salto 4001, piso 7, of. 71 Comuna Huechuraba <br>8580641 – Santiago <br>Chili',
	'A102CompanyAddressNiagaraBrazil':'HAVER & BOECKER NIAGARA Latinoamericana <br>Av. Dra. Celia Marli S. Salgado Matos, 192 <br>Pedro Leopoldo - MG - 33600-000 <br>Brazil',
	'A102CompanyAddressNiagara':'HAVER & BOECKER NIAGARA <br>Robert-Bosch-Straße 6 <br>D-48153 Münster <br>Germany / Deutschland',
	'A102CompanyAddressFeige':'Feige FILLING GmbH <br> Rogen 6a <br> D-23843 Bad Oldesloe <br> Germany / Deutschland',
	'A102CompanyAddressH_B':'HAVER & BOECKER OHG <br>Carl-Haver-Platz 3 <br>D-59302 Oelde <br>Germany / Deutschland',
	'A102CompanyAddressBehnBates':'Behn Bates Maschinenfabrik GmbH & Co. KG <br> Robert-Bosch Str. 6 <br> 48153 Münster <br> Germany',
	'A102CompanyEmailAventus':'hotline@aventus.global',
	'A102CompanyEmailNiagaraCanada':'info@haverniagara.ca',
	'A102CompanyEmailNiagaraChile':'haverandina@haverniagara.cl',
	'A102CompanyEmailNiagaraBrazil':'info@haverniagara.com.br',
	'A102CompanyEmailNiagara':'info@haverniagara.com',
	'A102CompanyEmailNewtec':'service@newtecbag.com',
	'A102CompanyEmailFeige':'hotline@feige.com',
	'A102CompanyEmailH_B':'hotline@haverboecker.com',
	'A102CompanyEmailBehnBates':'service@behnbates.com',
	'A102CompanyPhoneNumberNewtec':'Téléléphone: +33 389633753',
	'A102CompanyPhoneNumberFeige':'Téléphone: +49 4531 89 09 222',
	'A102CompanyPhoneNumberAventus':'Téléphone: +49 2581 4591 2222',
	'A102CompanyPhoneNumberNiagaraCanada':'Téléphone: +1 800-325-5993',
	'A102CompanyPhoneNumberNiagaraChile':'Téléphone: +56 (2) 2307-0440',
	'A102CompanyPhoneNumberNiagaraBrazil':'Téléphone: +55 (31) 3661-1371',
	'A102CompanyPhoneNumberNiagara':'Téléphone: +49 251 9793 164',
	'A102CompanyPhoneNumberH_B':'Téléphone: +49 2522 30 371 ',
	'A102CompanyPhoneNumberBehnBates':'Téléphone: +49 251 979- 0',
	'A102CompanyWebsiteNewtec':'www.newtecbag.com',
	'A102CompanyWebsiteFeige':'www.feige.com',
	'A102CompanyWebsiteAventus':'www.aventus.global',
	'A102CompanyWebsiteNiagaraCanada':'https://haverniagara.com',
	'A102CompanyWebsiteNiagaraChile':'https://haverniagara.com',
	'A102CompanyWebsiteNiagaraBrazil':'www.brazilniagara.com',
	'A102CompanyWebsiteNiagara':'www.haverniagara.com',
	'A102CompanyWebsiteH_B':'www.haverboecker.com',
	'A102CompanyWebsiteBehnBates':'www.behnbates.com',
	'A102MailContent':'<p>There occured an alert in one of your machines: <br> <br>Machine name: <b>MACHINE_NAME</b> <br>Alert message: <b>ALERT_TYPE</b> <br> Machine link:  <a href=\"MACHINE_LINK_TO_DETAIL\">MACHINE_LINK</a> <br> Alert value: ALERT_VALUE_TEXT <em>ALERT_VALUE_SUMMARY  </em><br> Alert time: ALERT_TIME_TEXT<br>ALERT_TIP</p><p style=\'font-size: 12px;\'>This e-mails was generated automatically because of a subscription at <a href=\'TYP_WEBSITE\' style=\'text-decoration: none; font-color: blue;\'>TYP_WEBSITE</a>. To unsubscribe login on the page and select <b>Settings</b> below the menu <b>Alerting</b>. <br>Please do not reply to this mail. For questions please contact <a href=\"mailto:COMPANY_EMAIL\">COMPANY_EMAIL</a>. <br> <br></p><p style=\'font-size: 12px;\'>Diese E-Mail wurde aufgrund eines Abos automatisch von <a href=\'TYP_WEBSITE\' style=\'text-decoration: none; font-color: blue;\'>TYP_WEBSITE</a> generiert. Zum Beenden des Abos loggen Sie sich auf der Seite ein und klicken unter <b>Alarmmeldungen</b> auf den Menüpunkt <b>Einstellungen</b>. <br>Bitte Antworten Sie nicht auf diese Mail. Bei Fragen können Sie sich an <a href=\"mailto:COMPANY_EMAIL\">COMPANY_EMAIL</a> wenden. <br> <br></p><p> Best regards, <br> <br>COMPANY_ADDRESS <br> <br>COMPANY_PHONE_NUMBER <br>E-Mail: <a href=\"mailto:COMPANY_EMAIL\">COMPANY_EMAIL</a> <br>Internet: <a href=\'COMPANY_WEBSITE\'>COMPANY_WEBSITE</a> <br><small style=\"color:white;\">Alert Source Id: ALERT_SOURCE_ID</small></p>',
	'A102TypeChanges':'Changement de produit ce jour',
	'A102TypeChangesBatch':'Changement de produit',
	'A102TYPWebsite':'https://trackyourplant.com',
	'A1030Description':'Description',
	'A103EndOfBatch':'Fin de lot',
	'A103ResetmailSubject':'Réinitialiser : MACHINE_NAME',
	'A104FullPalletCount':'Palettes pleines',
	'A104ResetMailContent':'<p>The occurred alert is resolved: </p><p>Machine name: <b>MACHINE_NAME</b><br>Alert message: <b>ALERT_TYPE</b><br>Machine link: MACHINE_LINK<br><p style=\'font-size: 12px;\'>This e-mails was generated automatically because of a subscription at <a href=\'TYP_WEBSITE\' style=\'text-decoration: none; font-color: blue;\'>TYP_WEBSITE</a>. To unsubscribe login on the page and select <b>Settings</b> below the menu <b>Alerting</b><br>Please do not reply to this mail. For questions please contact COMPANY_EMAIL.</p><p style=\'font-size: 12px;\'>Diese E-Mail wurde aufgrund eines Abos automatisch von <a href=\'TYP_WEBSITE\' style=\'text-decoration: none; font-color: blue;\'>TYP_WEBSITE</a> generiert. Zum Beenden des Abos loggen Sie sich auf der Seite ein und klicken unter Alarmmeldungen auf den Menüpunkt Einstellungen.<br>Bitte Antworten Sie nicht auf diese Mail. Bei Fragen können Sie sich an COMPANY_EMAIL wenden.</p><p>Best regards,<br>COMPANY_ADDRESS<br></p><p>COMPANY_PHONE_NUMBER<br>E-Mail: COMPANY_EMAIL<br>Internet: COMPANY_WEBSITE <br></p><small style=\"color:white;\">Alert Source Id: ALERT_SOURCE_ID</small>',
	'A105ConnectivityMailSubject':'Erreur de connexion : MACHINE_NAME',
	'A105LoadEmptyPallets':'Chargement  palettes vides',
	'A106Fillings':'remplissages',
	'A106PLCMailSubject':'Alerte PLC : MACHINE_NAME',
	'A107FillingsPerHour':'Remplissages / heure',
	'A107PLCResolvedMailContent':'<p>The following alert was resolved  at machine MACHINE_NAME:.<br><br> ALERT_TYPE <br><br> MACHINE_LINK <br><br> This e-mails was generated automaticly because of a subscription at <a href=\'TYP_WEBSITE\' style=\'text-decoration: none; font-color: blue;\'>TYP_WEBSITE</a>. To unsubscribe login on the page and select <b>Settings</b> below the menu <b>Alerting</b>.<br><p>Please do not reply to this mail. For questions please contact COMPANY_EMAIL.</p><br><br>Best regards,<br><br>COMPANY_ADDRESS<br><br>COMPANY_PHONE_NUMBER<br>E-Mail: COMPANY_EMAIL<br>Internet: COMPANY_WEBSITE ',
	'A108Availability':'disponibilité',
	'A108AvailabilityMailSubject':'Alerte de disponibilité : MACHINE_NAME',
	'A109FillingsRemain':'Remplissages restants',
	'A110StartOfOrder':'Heure début ordre de remplissage',
	'A111ProductID':'ID produit',
	'A112TargetWeight':'Poids nominal',
	'A113Kg':'Kilogramme',
	'A114CurrentOrder':'Ordre de production en cours',
	'A115FillingCount':'Ordre de production en cours',
	'A116CycleTime':'Temps de cycle machine',
	'A117Total':'Total ',
	'A118CentreStation':'Table de centrage',
	'A119N2Station':'Station n° 2',
	'A120FillingStation':'Station de remplissage',
	'A121SealingStation':'Station de fermeture',
	'A122Seconds':'secondes',
	'A123FaultLength':'Défauts machine',
	'A124Minutes':'procès-verbal',
	'A124Minute':'procès-verbal',
	'A125ExtFaultLength':'Perturbations externes',
	'A126FillingCount':'Nombre total de remplissages',
	'A127WithinTolerance':'Remplissages dans les tolérances',
	'A128OutsideTolerance':'Remplissages hors tolérances',
	'A129BagManufacture':'Production acceptée',
	'A130BagsPerHour':'Sacs / heure',
	'A131BagQuality':'Qualité du sac',
	'A132CorrPlacedbags':'Sacs correctement appliqués',
	'A133TwoAttempts':'2ème tentative d\'ouverture',
	'A134ThreeAttempts':'3ème tentative d\'ouverture',
	'A135DiscardeBags':'Sacs rejetés',
	'A136Faults':'Interruptions',
	'A137CompAir':'air comprimé',
	'A138ProductFeed':'Alimentation produit (balance)',
	'A139EmptyBagMag':'Magasin de sacs vides',
	'A140Separation':'Séparation',
	'A141Vacuum':'Vide',
	'A142FillingStation':'Station de remplissage',
	'A143FullBagTransport':'Transport de sacs pleins',
	'A144BagSealing':'Fermeture du sac',
	'A145Disrupt':'Perturbations',
	'A145AccessDoor':'Porte d\'acces',
	'A145DSafetyLightBarrier':'Barrière lumineuse de sécurité',
	'A145UpstreamSupply':'Approvisionnement en amont',
	'A145EmergencyStop':'Arrêt d\'urgence',
	'A146BunChangeAbs':'Nombre total de changements de liasses',
	'A147BunChangeToday':'Nombre de changements de liasses ce jour',
	'A147BunChangeTodayBatch':'Nombre de changements de liasses',
	'A148BagsPerBundle':'Nombre de sacs par liasse',
	'A149TypeChanges':'Changement de sorte ce jour',
	'A149TypeChangesBatch':'Changement de sorte ',
	'A150FillingTime':'Temps de remplissage',
	'A151DosingUnit1':'alimentation en produit',
	'A152DosingUnit2':'Alimentation en produit 2',
	'A153AerInletBox1':'fluidisation pot de remplissage',
	'A154AerInletBox2':'fluidisation pot de remplissage 2',
	'A154DosingUnit1':'Alimentation en produit',
	'A155DosingUnit2':'Alimentation en produit 2',
	'A155RotaryDrive':'entraînement en rotation',
	'A156AerInletBox1':'fluidisation pot de remplissage',
	'A156DischargeBelt':'Tapis de sortie',
	'A157AerInletBox2':'fluidisation pot de remplissage 2',
	'A157AlignmentBelt':'Tapis d\'alignement de sacs',
	'A158BagCleaning':'Nettoyeur de sacs',
	'A158RotaryDrive':'entraînement en rotation',
	'A159AccelerationBelt':'Tapis d\'accélération',
	'A159DischargeBelt':'Tapis de sortie',
	'A160AlignmentBelt':'Tapis d\'alignement de  sacs',
	'A160CheckBelt':'Bande de la trieuse pondérale',
	'A161BagCleaning':'Nettoyage sacs',
	'A161FlatBelt':'convoyeur à courroie plate',
	'A162AccelerationBelt':'Tapis d\'accélération',
	'A162RejectionBelt':'Entraînement éliminateur de sacs',
	'A163CheckBelt':'Bande de la trieuse pondérale',
	'A163RejectionDrive':'Entraînement dispositif de levage',
	'A164FlatBelt':'convoyeur à courroie plate',
	'A164RotaryCutter':'Déchiqueteur de sacs',
	'A165RejectionBelt':'Entraînement éliminateur de sacs',
	'A165ScreenDrum':'Tambour de criblage',
	'A166RejectionDrive':'Entraînement dispositif de levage',
	'A166Welding':'Dispositif de soudage',
	'A167Cutter':'Couteau',
	'A167RotaryCutter':'Déchiqueteur de sacs',
	'A168ScreenDrum':'Tambour de criblage',
	'A168SuctionCup':'Ventouse   ',
	'A169Brakes':'freins',
	'A170VacuumPump':'pompe à vide',
	'A171Filter':'Filtre d\'aspiration',
	'A172BagDisConveyor':'Bande de distribution',
	'A173BagTurnConveyor':'Tapis d\'orientation des sacs',
	'A174BagTurnCross':'Croix d\'orientation des sacs',
	'A175BagDisConveyor':'Tapis de distribution des sacs',
	'A175BagTurnClamp':'Pince d\'orientation des sacs',
	'A176BagTurnConveyor':'Tapis d\'orientation des sacs',
	'A176GroupingBelt':'Bande groupeuse',
	'A177BagTurnCross':'Croix d\'orientation des sacs',
	'A177RowPusher':'Pousseur ',
	'A178DoubleStripPlate':'Double tôle de dépose',
	'A179ConformingPlate':'Guide ',
	'A179GroupingBelt':'Bande groupeuse',
	'A180GantrySafety':'Dispositif de sécurité',
	'A180RowPusher':'Pousseur   ',
	'A181DoubleStripPlate':'Double tôle de dépose',
	'A181Gantry':'Châssis',
	'A182ConformingPlate':'Rive de conformation',
	'A182PalletAlignment':'Aligneur de palettes vides',
	'A183GantrySafety':'Dispositif de sécurité',
	'A183PalletRoller1':'convoyeur à rouleaux',
	'A184Gantry':'Châssis',
	'A184PalletDispenser':'gerbeur',
	'A185PalletAlignment':'Aligneur palettes vides',
	'A185PalletRoller2':'convoyeur à rouleaux',
	'A186PalletRoller1':'convoyeur à rouleaux',
	'A186PalletRoller3':'convoyeur à rouleaux',
	'A187CyclesChain':'Chaîne cadence de production',
	'A187PalletDispenser':'gerbeur',
	'A188BundlePusher':'Vérin pousseur de liasses',
	'A188PalletRoller2':'convoyeur à rouleaux',
	'A189BundlePartition':'Vérin répartiteur de liasses',
	'A189PalletRoller3':'convoyeur à rouleaux',
	'A190BagLifter':'Vérin élévateur de sacs ',
	'A190CyclesChain':'cadence chaîne',
	'A191BundlePusher':'Vérin pousseur de liasses',
	'A191PressRolls':'Vérin rouleaux presseurs',
	'A192BundlePartition':'Vérin séparateur de liasses',
	'A192OpeningStation':'Vérin station d\'ouverture',
	'A193BagApplicator':'Vérin applicateur de sacs',
	'A193BagLifter':'Vérin levage de sacs',
	'A194BagSupport':'Vérin soutien de sacs',
	'A194PressRolls':'Vérin rouleaux presseurs',
	'A195OpeningStation':'Vérin poste d\'ouverture',
	'A195Pusher':'Vérin pousseur',
	'A196BagApplicator':'Vérin applicateur de sacs',
	'A196StretchPliers':'Vérin pince d\'écartement',
	'A197BagSupport':'Vérin soutien de sac',
	'A197FillingStation':'Vérin poste de remplissage',
	'A198FilledBagGripper':'Vérin pinces sacs pleins',
	'A198Pusher':'Vérin pousseur',
	'A199StretchPliers':'Vérin pince d\'écartement',
	'A199SucBagSeperation':'Ventouse séparation de sacs',
	'A200FillingStation':'Vérin poste de remplissage',
	'A200SucOpenStation':'Ventouse poste d\'ouverture',
	'A201FilledBagGripper':'Vérin pinces sacs pleins',
	'A201Remaininghours':'Heures restantes',
	'A202PriceTotal':'Nombre total de pièces',
	'A202SucBagSeperation':'Ventouse séparation de sacs',
	'A203RemainingNumberFillings':'Remplissages restants',
	'A203SucOpenStation':'Ventouse poste d\'ouverture',
	'A204SafetyDoor':'porte de sécurité',
	'A205SafetyLightBarrier':'Barrière immatérielle de sécurité',
	'A206BagTransport':'Transport de sacs   ',
	'A207EmptyPalletTrans':'Transport palettes pleines',
	'A208Palletizer':'Palettiseur',
	'A209FullPalletTrans':'Transport palettes pleines',
	'A210NumberTodayPiece':'Quantité produite ce jour',
	'A211Programme':'Programme',
	'A212Efficiency':'Efficacité du bec',
	'A213Loginsuccessful':'Connection réussie',
	'A214CurrentPerformance':'Performance actuelle',
	'A215CyclesToday':'Nombre total cycles du jour',
	'A215CyclesTodayBatch':'Nombre total cycles',
	'A216RejectsToday':'Rejets du jour',
	'A216RejectsTodayBatch':'Rejets',
	'A217MetalDetectorToday':'Détection de métaux ce jour',
	'A217MetalDetectorTodayBatch':'Détecteur de métaux',
	'A218BrokenBagsToday':'Sacs déchirés ce jour',
	'A218BrokenBagsTodayBatch':'Sacs déchirés et détectés',
	'A219FaultPerShift':'Poste en cours',
	'A220FaultBagManufacture':'Fabrication sac',
	'A221FaultBagFilling':'Remplissage sac',
	'A222FaultBagTransport':'Transport sac',
	'A223FaultSealing':'Fermeture   ',
	'A224FaultWeigher':'Station de remplissage',
	'A225FaultPeriphery':'Autres périphériques',
	'A226Shift':'couche',
	'A227Container':'récipient',
	'A228Hours':'heures',
	'A229NoPackage':'pas d\'emballage',
	'A230NoProduct':'pas de produit',
	'A231NoOutlet':'Pas d\'évacuation',
	'A232GoodPerformance':'production',
	'A233GoodWeights':'production sacs acceptés',
	'A234NotPlacedBags':'Sacs non appliqués',
	'A234NotPlacedBagsAutomatic':'Sacs non appliqués autom.',
	'A235CollectiveFaultsToday':'Total défauts du jour',
	'A235CollectiveFaultsTodayBatch':'Total défauts',
	'A236Applicator':'Applicateur',
	'A237NetWeigher':'Balance à pesée nette',
	'A238IncorrectWeight':'poids incorrect',
	'A239Hours':'heures',
	'A240QrCode':'QR Code',
	'A241OpenBags':'Sacs ouverts',
	'A242FaultCheckWeigher':'trieuse pondérale',
	'A243DashboardAllMachines':'Tableau de bord / Toutes les machines',
	'A244DashboardState':'Statut',
	'A245DashboardMachineStateRunning':'Connecté',
	'A246DashboardMachineStateOffline':'Déconnecté',
	'A247DashboardMachineStateMaintenance':'Maintenance nécessaire',
	'A248DashboardMachineStateError':'Défaut',
	'A249SortChangeToday':'Changement de sorte du jour',
	'A249SortChangeTodayBatch':'Changement de sorte',
	'A250EmptyBagMagazineToday':'Réapprovisionnement magasin sacs vides du jour',
	'A250EmptyBagMagazineTodayBatch':'Réapprovisionnement magasin sacs vides',
	'A251FaultCheckWeigher':'Pannes du jour',
	'A252IVTSTOP':'INTEGRA®',
	'A253N/A':'N/A',
	'A254CyclePumpFS1':'Cadence pompe FS 1',
	'A255CyclePumpFS2':'Cadence pompe FS 2',
	'A256CyclePumpFS3':'Cadence pompe FS 3',
	'A257ConHoseFS1':'Tuyaux de raccordement FS1',
	'A258ConHoseFS2':'Tuyaux de raccordement  FS2',
	'A259ConHoseFS3':'Tuyaux de raccordement FS3',
	'A260InfSleeveFS1':'Manchette gonflable FS 1',
	'A261InfSleeveFS2':'Manchette gonflable FS 2',
	'A262InfSleeveFS3':'Manchette gonflable FS 3',
	'A263BundlePusher':'Pousseur de liasses',
	'A264VacuumUnit':'Unité decréation de vide',
	'A265TravellingDrive':'Chariot mobile',
	'A266CenteringUnit':'centrage',
	'A267EmptyBagMagazine':'magasin de sacs vides',
	'A268BeltPlant':'Système convoyage',
	'A269IvtTurbine':'turbine de remplissage',
	'A270IvtTripSaddle':'Selle basculante',
	'A271IvtCoarseFeed':'Dosage gros débit',
	'A272IvtBagHolder':'Porte-sacs',
	'A273IvtFillBoxAir':'Fluidisation du flasque latéral du corps de turbine',
	'A274IvtFillChanAir':'Aération canal remplissage',
	'A275IvtInflSleeve':'Manchette gonflable',
	'A276IvtInletBoxAir':'Boîte d\'entrée d\'air',
	'A277IvtFillTubeBlow':'Soufflage bec de remplissage',
	'A278IvtOutlChanAir':'Conduit de soufflage',
	'A279IvtPusher':'Pousseur',
	'A280IvtSaddleHeight':'Réglage hauteur de selle',
	'A281IvtCloseAnvil':'Fermer enclume',
	'A282IvtBagSeal':'Fermeture du sac',
	'A283IvtFineFeedAdj':'Réglage débit fin',
	'A284Interrupts':'Interruptions',
	'A284InterruptsGeneralPage':'Interruptions',
	'A284InterruptsBatch':'Interruptions',
	'A285BagCounterShift':'Compteurs de sacs par poste ',
	'A286WrongBags':'Sacs mal appliqués',
	'A287ReelChanges':'Changement de bobine',
	'A287ReelChangesBatch':'Changement de bobine',
	'A288VibrationDuration':'Durée de vibration prolongée',
	'A289Total':'Total   ',
	'A290HopperMin':'au moyen du minimum trémie',
	'A291ManualActivation':'Activation manuelle',
	'A292FillingRelease':'Autorisation remplissage',
	'A293EGF':'EGF®',
	'A294PalletTrasport':'Transport palettes',
	'A295SafetyCircuit':'Circuit de sécurité',
	'A296FltCompAir':'Défauts air comprimé ce jour',
	'A296FltCompAirBatch':'Défauts air comprimé',
	'A297StrokesPump':'Coups pompe à poudre ce jour',
	'A297StrokesPumpBatch':'Coups pompe à poudre',
	'A298MaxStrokeDur':'temps de levage maximum ce jour',
	'A298MaxStrokeDurBatch':'temps de levage maximum',
	'A299AvgStrokes':'Moyenne des coups par sac ce jour',
	'A299AvgStrokesBatch':'Moyenne des coups par sac',
	'A300InternalPressure':'pression interne',
	'A301Max':'max',
	'A301MessageAdministration':'Gestion des messages',
	'A302ErrorNumber':'numéro d\'erreur',
	'A302Millibar':'mbar',
	'A303ActivateAlertMail':'Activer l\'e-mail de notification',
	'A303SetValueMax':'Définir valeur maximale',
	'A304AlertText':'texte d\'avertissement',
	'A304SetValueMin':'Définir la valeur minimale',
	'A305ActivateResolvedMail':'Activer E-mail résolu',
	'A305SetValueChanged':'Vos modifications ont été enregistrées.',
	'A305SetValueChangedRefreshWindow':'Vos changements ont été enregistrés. Les modifications seront visibles après l\'actualisation de la fenêtre du navigateur.',
	'A306KW':'kW',
	'A306Resolved Text':'Texte , résolu',
	'A306ResolvedText':'Texte , résolu',
	'A307AddMessage':'Ajouter un message',
	'A307KVA':'kVA',
	'A308EditMessage':'Modifier le message',
	'A308Nm3PerH':'Nm³/h',
	'A309KWH':'kWh',
	'A310NM3':'Nm³',
	'A311CompAirVol':'Volume air comprimé',
	'A312ActiveEnergy':'énergie active',
	'A313ReactivePower':'puissance réactive',
	'A314ChartConfiguration':'Configuration de la vignette',
	'A315SetDateValueMax':'Fixer la date maximum',
	'A316SetDateValueMin':'Fixer la date minimum',
	'A317InvalidData':'Données non valides',
	'A318DeleteUser':'Voulez-vous vraiment supprimer l\'utilisateur?',
	'A319EnterAllFields':'Veuillez remplir tous les champs.',
	'A320EnterValidEmail':'Veuillez entrer une adresse E-mail valable.',
	'A321DeleteCompany':'Voulez-vous vraiment supprimer la société?',
	'A322CouldNotDeleteUser':'L\'administrateur n\'a pas pu être supprimé. Merci de le convertir d\'abord en utilisateur standard.',
	'A323LoginSuccess':'Connexion réussie!',
	'A324Selected':'sélectionné',
	'A325Total':'Total',
	'A326Reset':'Réinitialiser   ',
	'A327Reporting':'rapport',
	'A328ReportingActive':'activé',
	'A329ReportingActiveHelpPrefix':'Si activé, des rapports réguliers seront générés',
	'A330ReportingActiveHelpSuffix':'envoyé.',
	'A331ReportingFrequency':'fréquence',
	'A332ReportingFrequencyDaily':'journalier',
	'A333ReportingFrequencyWeekly':'hebdomadaire',
	'A334ReportingFrequencyMonthly':'mensuel',
	'A335ReportingDynContent':'Contenus dynamiques',
	'A336ReportingDynContIncludeElapsedMI':'intervalles de maintenance dépassés',
	'A337ReportingLanguage':'langue',
	'A338ReportingProdDayEnd':'Fin d\'une journée de production',
	'A339ReportingFirstReport':'Date du premier rapport',
	'A340CardCtxMenuReport':'Inclure dans le rapport',
	'A341CardCtxMenuHistoReport':'enregistrer sous forme d\'histogramme dans le rapport',
	'A342CardCtxMenuReset':'Réinitialiser le compteur',
	'A343CardCtxMenuSettings':'paramètres',
	'A344CardLabelReport':'rapport',
	'A345CardLabelHistoReport':'Rapport histogramme',
	'A346CardLabelMaintenReport':'Rapport de maintenance',
	'A347ReportSettings':'paramètres',
	'A348Alerting':'alarmes',
	'A349AlertingSettings':'Réglages',
	'A350AlertingActive':'activé',
	'A351AlertingActiveHelpPrefix':'Si activé, les notifications des types sélectionnés ci-dessous seront envoyées',
	'A352AlertingActiveHelpSuffix':'à',
	'A353AlertingTypes':'Avertir en cas de ',
	'A354AlertTypeMainten':'Intervalle de maintenance expiré le :',
	'A355AlertTypeAvail':'disponibilité',
	'A356AlertTypePLC':'Trigger API',
	'A357AlertTypeConError':'erreur de connexion',
	'A358DeleteAdminUser':'L\'utilisateur administrateur n\'a pas pu être supprimé.',
	'A359MaintAids':'Aide à la maintenance',
	'A360MaintInt':'Intervalles de maintenance',
	'A361ChooseMaschine':'Sélectionnez la machine',
	'A362Video':'vidéo ',
	'A363InstructionManual':'mode d\'emploi',
	'A364ExplodedviewDrawing':'Vue éclatée',
	'A364SparePartCatalog':'catalogue pièces détachées',
	'A365OpenVideoHelp':'Ouvrez l\'aide vidéo',
	'A366OpenInstructionManual':'Ouvrez le manuel d\'instructions',
	'A367OpenExploadedView':'Ouvrez la vue éclatée',
	'A368ResetMaschineData':'Réinitialiser les données de la machine',
	'A369BotWeld':'soudure de fond',
	'A370BotWeldTef':'téflon soudure de fond',
	'A371TopWeld':'soudure de tête',
	'A372TopWeldTef':'téflon soudure de tête',
	'A373LeftWeld':'soudure d\'angle gauche',
	'A374LefWeldTef':'téflon soudure d\'angle gauche',
	'A375RigWeld':'soudure d\'angle droit',
	'A376RigWeldTef':'téflon soudure d\'angle droit',
	'A377CutKnife':'outil de coupe',
	'A378ReallyClearReport':'Cette action supprime tous les éléments du rapport et ne peut pas être annulée. Voulez-vous vraiment supprimer tous les éléments du rapport ?',
	'A379ProdCurrShift':'Production équipe en cours',
	'A380ReallyDeleteItemReport':'Cette action supprime l\'élément du rapport et ne peut être annulée.  Voulez-vous vraiment supprimer cet élément ?',
	'A380TypeCurrent':'Sorte/qualité en cours',
	'A381SampleBags':'Sacs échantillons rejetés',
	'A382OpenBags':'Sacs ouverts rejetés',
	'A383IncorrectBags':'Sacs mauvais poids rejetés',
	'A384NotPlacedBagsMan':'Sacs non appliqués manuel',
	'A385Spout1':'Maintenance bec de remplissage 1',
	'A386LastUpdate':'Dernière mise à jour :',
	'A386Spout2':'Maintenance bec de remplissage 2',
	'A387Spout3':'Maintenance bec de remplissage 3',
	'A388Spout4':'Maintenance bec de remplissage 4',
	'A389LastUpdate':'Dernière mise à jour :',
	'A390FilledBagTrans':'Transport de sacs pleins',
	'A391ConveyerSystem':'Système de convoyage',
	'A392BeltBundleTrans':'Transport de liasses entrainé par courroie',
	'A393BagHolderBuffer':'Tampon caoutchouc support de sacs',
	'A394SlidePlates':'Plaques coulissantes',
	'A395ImpellerShaft':'Boîtier de roulement arbre porte-turbine',
	'A396ShaftAssembly':'Roulement inférieur',
	'A397ThreePosCylinder':'Vérin trois positions',
	'A398DateFormatNoSeconds':'DD.MM.YYYY HH:mm',
	'A399DateFormat':'DD.MM.YYYY HH:mm:ss',
	'A399DateFormatAngularPipe':'dd.MM.yyyy HH:mm:ss',
	'A400ConveyorSystem':'Ligne de convoyage',
	'A400History':'évolution',
	'A401ReportHistory':'Rapport d\'évolution',
	'A402ReportId':'Identifiant du rapport',
	'A403ReportGeneratedTime':'Déclarée l\'heure générée',
	'A404DosingFeeder':'Organe de dosage',
	'A404ReportGeneratedBy':'Rapport créé par',
	'A405DownloadReport':'Télécharger rapport',
	'A406TriggeredHistoryReport':'L\'établissement  du rapport est en cours !',
	'A407ViewReport':'Afficher le rapport',
	'A408BagHolder':'Porte-sacs  ',
	'A408BagHolder ':'Porte-sacs  ',
	'A414BundleTransport':'Transport de liasses',
	'A415HoistingDevice':'Dispositif de levage',
	'A416PlacerArm':'Bras applicateur',
	'A417CrossProcess':'Déplacement latéral',
	'A418OpeningStation':'Station d\'ouverture',
	'A421Claw':'griffe',
	'A422WeightData':'Moyenne',
	'A423StdDevData':'Écart-type',
	'A430kg':'kg',
	'A431lb':'lb',
	'A432t':'t',
	'A433klb':'klb',
	'A434g':'g',
	'A435BagManufacture':'Fabrication de sacs vides',
	'A436Aux_Pump':'Pompe à vide',
	'A437TopWelding':'Soudure de tête',
	'A438PunchingDeviceWelding':'Emporte-pièce soudure',
	'A439Aux_Vibrator1':'Vibreur 1',
	'A440Aux_Vibrator2':'Vibreur 2',
	'A441Aux_Vibrator3':'Vibreur 3',
	'A442Truck_Loading':'Chargement de camion',
	'A443Truck_Available':'Aucun camion disponible aujourd\'hui',
	'A444FS1':'Station de remplissage 1',
	'A445FS2':'Station de remplissage 2',
	'A446FS3':'Station de remplissage 3',
	'A447FS4':'Station de remplissage 4',
	'A448FS5':'Station de remplissage 5',
	'A449FS6':'Station de remplissage 6',
	'A450FS7':'Station de remplissage 7',
	'A451FS8':'Station de remplissage 8',
	'A452FS9':'Station de remplissage 9',
	'A453FS10':'Station de remplissage 10',
	'A454FS11':'Station de remplissage 11',
	'A455FS12':'Station de remplissage 12',
	'A456FS13':'Station de remplissage 13',
	'A457FS14':'Station de remplissage 14',
	'A458FS15':'Station de remplissage 15',
	'A459FS16':'Station de remplissage 16',
	'A460OeeValue':'Valeur de l\'efficacité globale de l\'installation',
	'A460Oee':'Efficacité globale de l\'installation',
	'A460PushAlert':'<h1>Alert: ALERT_TYPE</h1><p>Machine name: <b>MACHINE_NAME</b> <br>Alert message: <b>ALERT_TYPE</b>',
	'A461OeeAvailable':'Facteur de disponibilité',
	'A461PushAlertResolved':'<h1>Resolved: ALERT_TYPE</h1><p>Machine name: <b>MACHINE_NAME</b> <br>Alert message: <b>ALERT_TYPE</b>',
	'A462OeePerformance':'Indice de performance',
	'A463DowntimePlaned':'Arrêts programmés',
	'A464DowntimeMachine':'Arrêts machine',
	'A465DowntimeOther':'Autres arrêts',
	'A466OperatingFactor':'Facteur de service',
	'A467Throughput':'Facteur de débit',
	'A468OeeRejects':'Rebuts du jour',
	'A469OeeQuality':'Facteur de qualité',
	'A470OeeDowntime':'Temps d\'arrêt',
	'A471RejectsTotal':'Total des éjections',
	'A472RejectsIncorrect':'Eliminations mauvais poids',
	'A473RejectsOpen':'Eliminations sac ouvert',
	'A474RejectsSample':'Ejections sac échantillon',
	'A475RejectsMetal':'Eliminations contamination métal',
	'A476ConfigTable':'Configuration',
	'A477OEERowActive':'Actif',
	'A478OEEProduction':'Production',
	'A479OEEBreak':'Arrêt / Pause',
	'A480OEEMaint':'Maintenance',
	'A481OEETypeChg':'Changement de qualité',
	'A482OEEOthers':'Autres temps d\'arrêt',
	'A483OEEFrequ':'Fréquence',
	'A484OEEUnique':'Unique',
	'A485OEEDaily':'Journalier',
	'A486OEEWeekly':'Hebdomadaire',
	'A487Days':'Jours',
	'A488Monday':'Lundi',
	'A489Tuesday':'Mardi',
	'A490Wednesday':'Mercredi',
	'A491Thursday':'Jeudi',
	'A492Friday':'Vendredi',
	'A494Saturday':'Samedi',
	'A498Comment':'Commentaire',
	'A495Sunday':'Dimanche',
	'A496From':'De',
	'A497To':'Jusqu\'à',
	'A499TabMachineMessages':'Message machine',
	'A500MMLive':'Message en direct',
	'A500VbeltDrive':'Courroie d\'entraînement servomoteur rotative',
	'A501MMStatistic':'Message statique',
	'A501VbeltTurbine':'Courroies trapézoidales turbine',
	'A502Impeller':'Roue à palettes',
	'A502MMLive':'Messages de la machine en direct',
	'A503FillingTube':'tuyère de remplissage',
	'A503MMStatistic':'Statistiques des messages de la machine',
	'A504BagGuide':'tôle de guidage des sacs',
	'A504Type':'Type',
	'A505Control':'Contrôle/commande',
	'A505Saddle':'Selle à barreaux',
	'A506BoltBagChair':'Boulon chaise porte-sac',
	'A506Number':'Numéro',
	'A507BushBagChair':'Douille selle porte-sac',
	'A507Message':'Message',
	'A508Amount':'Nombre de',
	'A508BearingPusher':'Roulement dispositif pousseur',
	'A509Duration':'Durée en minutes  ',
	'A509RearSlidePlate':'Plaque coulissante arrière',
	'A510SlideValve':'guillotine',
	'A510TonsPerHour':'t/h',
	'A511FrontSlidePlate':'Plaque coulissante avant',
	'A511Tons':'tonnes',
	'A512RepairKitSlidePlates':'Kit de réparation plaques coulissantes',
	'A512RPM':'Tours par minute',
	'A513BagChairChange':'Changement de chaise porte-sac',
	'A513BagTransBeltServo':'Courroie crantée Servo',
	'A514InfeedBelt':'Tapis d\'alimentation',
	'A515FlattBelt':'Tapis aplatisseur',
	'A516BagTakingBelt':'Convoyeur d\'alimentation',
	'A517RollerConveyor':'convoyeur à rouleaux',
	'A518LiftingGantry':'Dispositif de levage',
	'A519LinearDrive':'Entraînement linéaire',
	'A520SwivelJoint':'Joint tournant',
	'A521LiftingGantry':'Dispositif de levage convoyeur',
	'A522FullPallets':'Convoyeur palette pleine',
	'A523EvacConveyor':'Convoyeur à rouleaux d’accumulation',
	'A524SafetyBarrier':'Barrières lumineuses/capteurs',
	'A525BasicCleaning':'Nettoyage de base',
	'A526SafetyDevices':'Dispositifs de sécurité',
	'A530CylSucBar':'Fermeture vérin de barre à ventouses',
	'A531FilledBagGripper1':'Vérin préhenseur de sac plein 1',
	'A532FilledBagGripper2':'Vérin préhenseur de sac plein 2',
	'A533CylCutKnife':'Vérin outil de découpe',
	'A534CylBotWelding':'Vérin soudure de fond',
	'A535SpoutLift':'Levage bouche de remplissage',
	'A536CylClampTopWeld':'Vérin barre de soudure de tête',
	'A537CylTopWeld':'Vérin de soudure de tête',
	'A538CylCornerWeld':'Vérin de soudure d\'angle',
	'A539AdjHeightDischargebelt':'Réglage en hauteur du tapis de sortie',
	'A540EmptyBagTransport':'Transporteur de sacs vides',
	'A541Virbator':'Heure de fonctionnement du vibreur',
	'A542CylCoolPlatesCornerWeld':'Vérin plaque de refroidissement soudure d\'angle',
	'A543CylBagGuide':'Vérin guide sac',
	'A544CylClampBotWeld':'Vérin barre de soudure de fond',
	'A545CylCoolBotWeld':'Vérin refroidissement par contact soudure de fond',
	'A546CylFilledBagGripper3':'Vérin préhenseur de sac plein 3',
	'A547CylStatBagGripper':'Vérin pince stationnaire',
	'A548CylEmptyBagGripper1':'Vérin préhenseur de sac vide 1',
	'A549CylStretching':'Vérin pré-tension',
	'A550CylOpenSpout':'Vérin bouche de remplissage ouverte',
	'A551CylBagAplicatorLift':'Vérin levage présentateur de sac',
	'A552CylStretcherOpen':'Vérin pinces d\'écartement ouvert',
	'A553CylStretcherInwards':'Vérin pinces d\'écartement vers l\'intérieur',
	'A554FoilTrackingDevice':'Tirage de gaine',
	'A555DischargeBelt2':'Heures de fonctionnement du tapis d\'évacuation BP2',
	'A556CylStretching':'Vérin dispositif de ré-écartement',
	'A557CylEmptyBagGripper2':'Vérin préhenseur de sac vide 2',
	'A558UnwindingDevice':'Heures de fonctionnement rouleaux de déroulage de gaine',
	'A559BagLenghtAdj':'Compensateur de longueur de sac',
	'A560CylVibratorLift':'Levage du vibreur',
	'A561CylFilmGuide':'Verin guidage de gaine',
	'A562CylPushFlaps':'Vérin volet coulissant',
	'A563CylWeldReelChanger':'Changeur de bobine de soudage',
	'A564CylWeldReelChangerTef':'Changeur de téflon pour bobine de soudage',
	'A565DriveReelChanger':'Entraínement pour changeur de rouleaux',
	'A566DriveAdjCornerWeld':'Entraînement réglage de largeur de soudure d\'angle',
	'A567CylThreadingInwards':'Rapprochement vérin de la ventouse de la station d\'ouverture',
	'A568CylThreadingOutwards':'Ecartement vérin de la ventouse de la station d\'ouverture',
	'A569CylThreadingDownwards':'Abaissement du vérin de tôle de séparation de la station d\'ouverture',
	'A570CylSucRailClose':'Vérin barre à ventouse fermé',
	'A571DischargeBelt2':'Tapis d\'évacuation BP2',
	'A572CylSpoutOpen':'Vérin bouche de remplissage ouverte',
	'A573Drives':'Entraînements',
	'A574Cylinders':'Vérins',
	'A575LowAttrition':'usure faible',
	'A576HighAttrition':'Usure élevée',
	'A577SetUpTimeAvBatch':'Temps de préparation Ø',
	'A578SetUpTimeAv':'Temps de préparation Ø aujourd\'hui',
	'A579GrossTimeBatch':'Temps brut',
	'A580GrossTime':'Temps brut aujourd\'hui',
	'A581SetUpTime':'Temps de préparation',
	'A582Downtime':'Arrêt',
	'A583MonthBestPerf':'Meilleure performance mensuelle',
	'A584PressureChamperSeal':'Joint de chambre de pression',
	'A585Aeration':'Aération ',
	'A586SpillageRejector':'Déviateur de refus',
	'A587VibrationBottle':'bouteille vibrante',
	'A588VibrationTable':'Table vibrante',
	'A589BagPlacing':'application de sac',
	'A590RotaryFeeder':'écluse rotative',
	'A591TopSeamCleaning':'Nettoyage soudure de tête',
	'A592Beltplant1':'Système convoyage 1',
	'A593Beltplant2':'Système convoyage 2',
	'A594HydraulikPump':'pompe hydraulique',
	'A595DriveEBTS':'undefined',
	'A596HostingEBTS':'undefined',
	'A597Ventilator':'undefined',
	'A598RD':'undefined',
	'A599SpoutCoarse':'undefined',
	'A600RotaryGateCoarse':'undefined',
	'A601HostingVIC':'undefined',
	'A602SpoutFine':'undefined',
	'A603RotaryGateFine':'undefined',
	'A604HostingVSC1':'undefined',
	'A605HostingVSC2':'undefined',
	'A606FBTransfer':'undefined',
	'A607FBTHost':'undefined',
	'A608FBTswivel':'undefined',
	'A609FBTClamp':'undefined',
	'A610FBTransport':'undefined',
	'A611FBTransportClamp':'undefined',
	'A612HostBFS':'undefined',
	'A613SwivelBFS':'undefined',
	'A614SideGussetblade':'undefined',
	'A615HostTopwelding':'undefined',
	'A616CloseTopWelding':'undefined',
	'A617Folding1':'undefined',
	'A618Folding2':'undefined',
	'A619Folding3 ':'undefined',
	'A620PUD':'undefined',
	'A621PUDWeld':'undefined',
	'A622BeltReject':'undefined',
	'A623RejectingDevice':'undefined',
	'A624BagLenght':'undefined',
	'A624PerfToday':'Performance aujourd\'hui',
	'A625PerfTodayBatch':'Performance',
	'A626Output':'Production du jour',
	'A627OutputBatch':'Production',
	'A628UnitperH':'S/h',
	'A629Percent':'%',
	'A630BagJunction':'Plaque de jonction des sacs',
	'A631ToothedBelt':'Tendre courroie crantée',
	'A632LevellingFoot':'Vérifier le pied articulé RAD',
	'A633PolymerGuide':'Guide polymère + rouleau',
	'A634BallBushing':'Douille à billes coupleur',
	'A635ScissorHoseReplace':'Remplacement manchon de la vanne a ciseaux',
	'A636ConnectingHoseReplace':'Remplacement du tuyau de jonction',
	'A637Bellow':'Soufflet réglage en hauteur',
	'A638TipperFrame':'vérifier châssis selle basculante',
	'A639HosePressureChamber':'Vérifier joint d\'étanchéité de la chambre de pression',
	'A640FlapFillingBox':'Contrôler clapet du pot de fuidisation',
	'A641RotoLock':'ROTO-LOCK®',
	'A642Hours':'Heures',
	'A643Temp':'°C',
	'A644Millisecond':'ms',
	'A645KgPerSecond':'kg/s',
	'A646RoundsperMinute':'tr/min',
	'A647Meter':'m',
	'A648Millimeter':'mm',
	'A649Cubicmeter':'m³',
	'A650Liter':'l',
	'A651Counts':'pcs',
	'A652WeldingTemperature':'Température de soudage',
	'A653WeldingDuration':'Durée de soudage',
	'A654FillingSystem':'Système de remplissage',
	'A655EmailSignature':'Signature E-mail',
	'A656NumberWasteBags':'Nombre de sacs rejetés',
	'A657FreqConverter':'Convertisseur de fréquence',
	'A658ServoDrives':'Servomoteurs',
	'A656Voltage':'Voltage',
	'A657Volt':'V',
	'A658U1':'U1',
	'A659U2':'U2',
	'A660U3':'U3',
	'A661U12':'U12',
	'A662U23':'U23',
	'A663U31':'U31',
	'A664Current':'Courant',
	'A665Amps':'A',
	'A666I1':'I1',
	'A667I2':'I2',
	'A668I3':'I3',
	'A669Frequency':'Fréquence',
	'A670Hertz':'Hz',
	'A671Pressure':'Pression',
	'A672Bar':'bar',
	'A673Moisture':'point de rosée sous pression',
	'A674AirFlowUnit':'l/s',
	'A675AnnualConsum':'Consommation annuelle',
	'A676MonthlyConsum':'Consommation mensuelle',
	'A677FreeConsum':'Consommation',
	'A678PowerEfficiency':'Efficacité énergétique',
	'A679BagsPerKWH':'Sacs par kW/h',
	'A681SinceReset':'Depuis la dernière remise à zéro',
	'A682LastTimeSortUse':'Depuis la dernière utilisation',
	'A683SortNumber':'Numéro de sorte',
	'A684Overall':'au total',
	'A685AirEfficiency':'Efficacité de l\'air comprimé',
	'A686BagsPerAirFlowUnit':'Sacs par l/s',
	'A687Jan':'Janvier',
	'A688Feb':'Février',
	'A689Mar':'Mars',
	'A690Apr':'Avril',
	'A691Mai':'Mai',
	'A692Jun':'Juin',
	'A693Jul':'Juillet',
	'A694Aug':'Août',
	'A695Sep':'Septembre',
	'A696Oct':'Octobre',
	'A697Nov':'Novembre',
	'A698Dec':'Décembre',
	'A699Year':'Année',
	'A700EnterDateRange':'Entrer la plage de dates',
	'A701PowerConsumption':'Consommation d\'énergie',
	'A702AirConsumption':'Consommation d\'air',
	'A703FlowControlGate':'Vanne de dosage',
	'A704BagDirectionConv':'Convoyeur d\'alignement des sacs',
	'A705BottomBearing':'Roulement inférieur',
	'A706PressureSwitch':'Pressostat',
	'A707ElecModules':'Modules électroniques',
	'A708SpoutWeight':'Composants balance',
	'A709Search':'Recherche',
	'A710Overweight':'Sacs en surpoids rejeté',
	'A711Underweight':'Sacs en sous poids rejeté',
	'A712RotaryDriveFreqConLoad':'Variateur de vitesse entraînement en rotation',
	'A713MeanValueLoadInPercent':'Valeur moyenne / pourcentage d\'utilisation',
	'A714EmptyBagDischarge':'Décharge des sacs vides',
	'A715Bellow1':'Soufflet d\'étanchéité',
	'A716TotalCounter':'Compteur total',
	'A717Spout0Counter':'Station de remplissage 0',
	'A718LSdirtyCounter':'barrière lumineuse CW sale',
	'A719Peripherie':'undefined',
	'A720Robot':'undefined',
	'A721DepositBelt':'undefined',
	'A722RollerConveyor1':'undefined',
	'A723PalletCentering1':'undefined',
	'A724RollerConveyor2':'undefined',
	'A725PalletCentering2':'undefined',
	'A726GripperClamp':'undefined',
	'A727GripperMotor':'undefined',
	'A728AdjustmentCamera':'undefined',
	'A729BagSize':'undefined',
	'A730PalletSupply':'undefined',
	'A731Length':'undefined',
	'A732Width':'undefined',
	'A733DepalPallets':'undefined',
	'A734DepalBundles':'undefined',
	'A735RejectedBundles':'undefined',
	'A736BagsPerBundle':'undefined',
	'A737CountsOfDataPoints':'undefined',
	'A738EmptyKey':' ',
	'A739TrendMeanWeight':'Moyenne Tendance',
	'A740TrendSD':'Écart-type Tendance',
	'A741PistonBlower1':'Surpresseurs à pistons rotatifs 1',
	'A742PistonBlower2':'Surpresseurs à pistons rotatifs 2',
	'A743RotaryFlap':'undefined',
	'A744CurrentLayer':'undefined',
	'A745CurrentBag':'undefined',
	'A746Days':'undefined',
	'A747LayerNo':'undefined',
	'A748BagNo':'undefined',
	'A749AnnualMaint':'undefined',
	'A901MachineCustomerOverview':'Machine / Surveillance client',
	'A902Machines':'machines   ',
	'A903Permissions':'autorisations',
	'A904CompanyName':'raison sociale',
	'A905MachineName':'nom de la machine',
	'A906MachineType':'type de machine',
	'A907Order':'ordre',
	'A908Action':'action',
	'A909Remove':'supprimer',
	'A910ChooseCustomer':'Choisir le client',
	'A911ConnectMachines':'Connecter les machines',
	'A912User':'utilisateur',
	'A913FirstName':'prénom',
	'A914Surname':'nom de famille',
	'A915Login':'login',
	'A916Roles':'rouleaux',
	'A917Company':'société',
	'A918CreateUser':'Créer un utilisateur',
	'A919Username':'nom d\'utilisateur',
	'A920Password':'mot de passe',
	'A921Name':'Nom',
	'A922ParentCompany':'Maison-mère',
	'A923Create':'Créer  ',
	'A924Edit':'éditer',
	'A925SubCompany':'Sous-traitants',
	'A926Companies':'Sociétés',
	'A927EditMachine':'Modifier machine',
	'A928CompaniesFunctionality':'Société / fonctionnalité',
	'A930SelectAll':'Tout sélectionner',
	'A931DetailView':'vue détaillée',
	'A932EditUser':'Modifier l\'utilisateur',
	'A933Delete':'Annuler',
	'A934Cancel':'Interrompre',
	'A935Save':'Sauvegarder',
	'A936LoginFailed':'Échec de la connexion. Identifiants non valides',
	'A936Malayalam':'malayalam',
	'A937DisplayName1':'Nom d\'affichage 1',
	'A938DisplayName2':'Nom d\'affichage 2',
	'A939DisplayName3':'Nom d\'affichage 3',
	'A940ForgotPassword':'Mot de passe oublié',
	'A941ForgotText':'Saisissez votre nom d\'utilisateur et vous recevrez un e-mail avec un lien pour réinitialiser votre mot de passe',
	'A942Submit':'Envoyer',
	'A943EmailAddress':'Adresse e-mail',
	'A944ChangePassword':'Changer le mot de passe',
	'A945CurrentPassword':'Ancien mot de passe',
	'A946NewPassword':'Nouveau mot de passe',
	'A947ConfirmPassword':'Répéter le nouveau mot de passe',
	'A948Update':'Sauvegarder',
	'A949IncorrectPassword':'Mot de passe incorrect',
	'A950Metrics':'Métrique',
	'A950PasswordsNotMatch':'Les nouveaux mots de passe ne correspondent pas',
	'A951EmailNotFound':'Adresse e-mail introuvable',
	'A952InvalidEmail':'Veuillez saisir une adresse e-mail valide',
	'A953EmailSent':'Si votre e-mail existe dans notre base de données, un e-mail sera envoyé à votre identifiant de messagerie avec le lien de réinitialisation.',
	'A954ForgetPasswordMailSubject':'Réinitialisez votre mot de passe',
	'A955ForgetPasswordMailTitle':'Bonjour USER_NAME,',
	'A956ForgetPasswordMailBody':'<p> Nous avons reçu une demande de réinitialisation de votre mot de passe. <br> Utilisez le lien ci-dessous pour définir un nouveau mot de passe pour votre compte.Si vous n\'avez pas demandé de réinitialiser votre mot de passe, ignorez cet e-mail et le lien expirera automatiquement au bout de 24 heures. <P> <a href = \'RESET%LINK\' style = \'text-decoration: none;font-color: white; \'> <span style =\' padding: 10px;affichage: en ligne;border-radius: 2px;bordure: 0;marge: 0 10 px;arrière-plan: # 09174a;couleur:blanc;hauteur de ligne: 15px;largeur: auto;hauteur: auto;box-sizing: content-box; \'> Réinitialiser le mot de passe </ span> </ a> <br> <p> Meilleures salutations, <br> l\'équipe TrackYourPlant </ p>',
	'A957DateFormat':'jj.mm.aaaa',
	'A958D3DateFormat':'%d.%m.%a',
	'A959DashboardMachineStateStandBy':'Stand by',
	'A959MessageUpdateSuccess':'Message mis à jour avec succès',
	'A960MessageSaveSuccess':'Le message a été enregistré avec succès',
	'A961Yes':'oui',
	'A962No':'non',
	'A963ReportOverview':'Aperçu du rapport',
	'A964Machine':'machine',
	'A965Card':'carte',
	'A966ReportType':'type de rapport',
	'A967HistogramReport':'Histogramme',
	'A968Report':'rapport',
	'A969Day':'1 jour',
	'A969French':'Français',
	'A970Week':'1 semaine',
	'A971Month':'1 mois',
	'A972HistoryChart':'Graphique de l\'histoire',
	'A972HistoryChartBacth':'Graphique historique lot cur.',
	'A973Unit':'[Unité]',
	'A974Time':'Heure',
	'A975ShowBagCounterStackChart':'Cf. diagramme en batons',
	'A976TestReportStarted':'La génération du rapport-test a commencé',
	'A977ActiveSettingsRequired':'Pour créer un rapport de test, la fonction doit être activée dans les paramètres de reporting.',
	'A978FirstDayRequired':'Date du premier rapport requis.',
	'A979CompanyDeleted':'La société a été supprimée.',
	'A980CompanyDeleteError':'La société n\'a pas pu être supprimée :',
	'A981CompanyParentShouldDifferent':'la société et la maison-mère doivent être différents',
	'A982CompanyCreated':'La société a été enregistrée.',
	'A983CompanyCreateError':'La société n\'a pas pu être enregistrée',
	'A984CompanyAllFields':'Remplissez tous les champs',
	'A985UserCreated':'L\'utilisateur a été créé',
	'A986UserExists':'Cet E-mail existe déjà',
	'A987UserDeleted':'L\'utilisateur a été supprimé',
	'A988IncludedInReport':'inclus dans le rapport',
	'A989IncludedInHistogram':'inclus dans le rapport sous forme d\'histogramme',
	'A990DateFormat':'JJ.MM.AAAA',
	'A991MachineTime':'Heure de l\'ordinateur',
	'A992LocalTime':'Heure de l\'utilisateur',
	'A993NoMachines':'Aucune machine à afficher',
	'A994Imprint':'Mentions légales',
	'A995PrivacyPolicy':'Politique de confidentialité',
	'A996Chinese':'中文',
	'A997Portuguese':'Portugais',
	'A998Vietnamese':'Tiếng Việt ',
	'A999CompanyNameExists':'Le nom de l\'entreprise existe déjà.',
	'B100ActivatingReports':'L\'activation enverra des rapports réguliers aux adresses électroniques suivantes:',
	'B101EnterValidEmailIds':'Saisir des identifiants de messagerie valides',
	'B101ValidFrom':'valable à partir de…',
	'B102AtleastOneEmailIdRequired':'Au moins un email ID requis',
	'B102ValidTo':'valable jusqu\'à…',
	'B103MaximumLimitReached':'Limite maximale atteinte',
	'C001FetchingWait':'Récupération des données en cours. Veuillez patienter.',
	'C002PreparingWait':'Les données sont prêtes. Veuillez patienter.',
	'C003RenderingWait':'Le graphique est créé. Veuillez patienter.',
	'C004PixelScale':'1 pixel ≈ {{intervalle de temps}} secondes.',
	'C005ChartError':'Une erreur est survenue!',
	'C006ChartOk':'OK',
	'D001PortalType':'Portal',
	'D002CompanyDomain':'Aspect et convivialité',
	'E001AlertSettingSavedSuccessfully':'Les messages d\'alarme ont été enregistrés avec succès',
	'E002SelectAtLeastOneAlertTypeToActivateAlerting':'Sélectionnez au moins un type d\'alarme pour activer l\'alerte.',
	'E003ErrorNumberMustBePositive':'Le numéro d\'erreur doit être positif',
	'E004AlertEmailNeedsToBeActive':'L\'E-mail de notification doit être activé',
	'E005AnErrorHasOccured':'Une erreur est survenue.',
	'E005ErrorMessageAlreadyExists':'Le message existe déjà',
	'E005OperationSuccesful':'Opération réussie',
	'E006ErrorMessageSave':'Une erreur est survenue. Message non enregistré',
	'E006ErrorOccurredSettingsWasNotSaved':'Une erreur est survenue. Les paramètres n\'ont pas été enregistrés',
	'E007ReportSettingsSuccessfullySaved':'Paramètres de rapport enregistrés avec succès',
	'E008ErrorSettingsDoNotExists':'Les paramètres d\'erreur n\'existent pas.',
	'E009ReportSettingsSuccessfullyUpdated':'Paramètres rapport mis à jour avec succès',
	'E010InvalidPassword':'Mot de passe invalide',
	'E011UserUpdated':'Utilisateur mis à jour',
	'E012InvalidPassword':'Le mot de passe ne répond pas aux critères de mot de passe indiqués.',
	'E013UserUpdated':'L\'utilisateur a été mis à jour avec succès',
	'E015CompanyMaxUsersLowerThanActiveUsers':'La société a déjà plus d\'utilisateurs actifs que le nombre maximal d\'utilisateurs définis.',
	'E016PasswordChangedSuccessfully':'Le mot de passe a été modifié avec succès',
	'F001_CurrentOrder':'Commande en cours',
	'F002_ProductID':'ID produit ',
	'F003_BatchStart':'Heure de démarrage du lot',
	'F004_Targetweight':'Poids de consigne ',
	'F005_RemainingFill':'Remplissages restants',
	'F006_CurrentProduction':'production actuelle',
	'F007_FillStatistic':'Statistiques de remplissage',
	'F010_Sum':'Total',
	'F011_Centering':'Centrage',
	'F012_Debunging':'Dévissage',
	'F013_Inert':'Inertage',
	'F014_Filling_1':'Remplissage 1',
	'F015_Filling_2':'Remplissage 2',
	'F016_Filling_3':'Remplissage 3',
	'F017_Filling_4':'Remplissage 4',
	'F018_Bunging':'Vissage ',
	'F019_Sealing':'Sertissage',
	'F020_Position':'Positionnement ',
	'F021_TransportCycle':'Cycle de transport ',
	'F022_CheckWeigher':'Trieuse pondérale',
	'F023_Closing':'Fermeture',
	'F024_Palletizing':'Palettisation',
	'F025_EmptyPaletMagazin':'Magasin de palettes vides',
	'F026_DePalletizing':'Dépalettisation ',
	'F027_Transport':'Transport',
	'F028_PailSeperator':'Dépileur de seaux',
	'F029_HangUpCap':'Positionneur de couvercles',
	'F030_PushInCap':'Presse-couvercles',
	'F031_TestConsoles':'Station de contrôle',
	'F050_TU2':'TU 2',
	'F051_TU1':'TU 1',
	'F052_LowerTol':'En dessous de la tolérance',
	'F053_InTol':'Dans la tolérance',
	'F054_UpperTol':'Au-dessus de la tolérance',
	'F055_TO1':'TO 1',
	'F056_TO2':'TO 2',
	'F060_ProductInfeed':'Alimentation  produit',
	'F061_EmptyTruss':'Alimentation des récipients vides',
	'F062DetailedDateFormat':'DD.MM.YYYY HH:mm:ss',
	'F090_Minuten':'Minutes ',
	'F091_Stunden':'Heures ',
	'F100_Eichung':'Prochain étalonnage ',
	'F101_TransKette':'Chaîne transporteuse',
	'F102_Hubtor':'Portes relevables',
	'F103_ZentRollen':'Rouleaux de centrage',
	'F104_SpundSuch':'Détecteur de bonde',
	'F105_Aushub':'Dispositif de levage ',
	'F106_WerkSchlitten':'Porte-outils ',
	'F107_Aufschrauber':'Durée d\'exécution dévissage',
	'F108_VakuSys':'Système de vide dévissage',
	'F109_HubAufSchraub':'Vérin dévissage',
	'F110_OelAufSchraub':'Lubrification dévissage',
	'F111_Inertgas':'Inertage',
	'F112_Aushub_F1':'Dispositif de levage ',
	'F113_HE_F1':'Unité de levage ',
	'F114_TFE_F1':'Bac collecteur de gouttes',
	'F115_Aushub_F2':'Dispositif de levage ',
	'F116_HE_F2':'Unité de levage ',
	'F117_TFE_F2':'Bac collecteur de gouttes',
	'F118_ZentDorn':'Mandrin de centrage',
	'F119_Schiebetisch':'Table coulissante ',
	'F120_Kappenband':'Bande transporteuse des bouchons',
	'F121_VakuKap':'Système de vide séparation des bouchons',
	'F122_KapSort':'Convoyeur vibrant ',
	'F123_VerKap':'Séparation des bouchons',
	'F124_KapZu':'Amenée des bouchons ',
	'F125_Schrauber':'Visseuse',
	'F126_VakuSchrauber':'Visseuse à système par le vide',
	'F127_HubSchrauber':'Vérin vissage',
	'F128_OelZuSchraub':'Lubrification vissage',
	'F129_Dichtung':'Joint visseuse',
	'F130_HubClinch':'Vérin sertisseuse',
	'F131_Clinchen':'Sertissage',
	'F132_OelClincher':'Lubrification sertissage',
	'F133_VakuClincher':'Système de vide sertissage ',
	'G001BatchReport':'Rapports sur les lots',
	'G002ActivateBatchReport':'Activer la génération de rapports par lots',
	'Hülsen MIN-Melder':'Manchon de fixation sonde de niveau MIN',
	'IVT-Simulation':'INTEGRA® ',
	'Kabel Sattelhöhenv':'Câble réglage hauteur de selle',
	'MACHINE_AVAILABILITY_RED_ZONE':'Disponibilité de la machine dans la zone rouge',
	'MACHINE_AVAILABILITY_YELLOW_ZONE':'Disponibilité de la machine dans la zone jaune',
	'Maint Interval 0':'Intervalle maintenance 0',
	'Maint Interval 1':'Intervalle maintenance 1',
	'Maint Interval 10':'Intervalle maintenance 10',
	'Maint Interval 11':'Intervalle maintenance 11',
	'Maint Interval 12':'Intervalle maintenance 12',
	'Maint Interval 13':'Intervalle maintenance 13',
	'Maint Interval 14':'Intervalle maintenance 14',
	'Maint Interval 2':'Intervalle maintenance 2',
	'Maint Interval 3':'Intervalle maintenance 3',
	'Maint Interval 4':'Intervalle maintenance 4',
	'Maint Interval 5':'Intervalle maintenance 5',
	'Maint Interval 6':'Intervalle maintenance 6',
	'Maint Interval 7':'Intervalle maintenance 7',
	'Maint Interval 8':'Intervalle maintenance 8',
	'Maint Interval 9':'Intervalle maintenance 9',
	'miniADAMS-Messe':'ROTO-PACKER®',
	'N042BrazAlertGlobalAccL0':'Accélération globale franchie L0',
	'N042BrazAlertGlobalAccL1':'Accélération globale franchie L1',
	'N042BrazAlertGlobalAccL2':'Accélération globale franchie L2',
	'N042BrazAlertGlobalAccL3':'Accélération globale franchie L3',
	'N042BrazAlertGlobalAccL4':'Accélération globale franchie L4',
	'N042BrazAlertGlobalAmplL0':'Amplitude globale croisée L0',
	'N042BrazAlertGlobalAmplL1':'Amplitude globale croisée L1',
	'N042BrazAlertGlobalAmplL2':'Amplitude globale croisée L2',
	'N042BrazAlertGlobalAmplL3':'Amplitude globale croisée L3',
	'N042BrazAlertGlobalAmplL4':'Amplitude globale croisée L4',
	'N042BrazAlertMaxRmsAccL0':'Accélération efficace maximale des capteurs LB, CB et RB croisée L0',
	'N042BrazAlertMaxRmsAccL1':'Accélération efficace maximale des capteurs LB, CB et RB croisée L1',
	'N042BrazAlertMaxRmsAccL2':'Accélération efficace maximale des capteurs LB, CB et RB croisée L2',
	'N042BrazAlertMaxTempBearingSensL0':'Température maximale des paliers capteurs traversés L0',
	'N042BrazAlertMaxTempBearingSensL1':'Température maximale des paliers capteurs traversés L1',
	'N042BrazAlertMaxTempBearingSensL2':'Température maximale des paliers capteurs traversés L2',
	'N042BrazAlertMaxZAxisSensorsL0':'Accélération maximale de l\'axe z des capteurs lfs, rfs lds et rouges croisés L0',
	'N042BrazAlertMaxZAxisSensorsL1':'Accélération maximale de l\'axe z des capteurs lfs, rfs lds et rouges croisés L1',
	'N042BrazAlertMaxZAxisSensorsL2':'Accélération maximale de l\'axe z des capteurs lfs, rfs lds et rouges croisés L2',
	'N042BrazAlertRotFreqL0':'Fréquence de rotation croisée L0',
	'N042BrazAlertRotFreqL1':'Fréquence de rotation croisée L1',
	'N042BrazAlertRotFreqL2':'Fréquence de rotation croisée L2',
	'N042BrazAlertRotFreqL3':'Fréquence de rotation croisée L3',
	'N042BrazAlertRotFreqL4':'Fréquence de rotation croisée L4',
	'N042BrazAlertType':'Alerte brésilienne',
	'N042GlobalAcceleration':'Accélération globale',
	'N042GlobalAmplitude':'Amplitude globale',
	'N042GlobalFrequency':'Fréquence globale',
	'N043CardCtxMenuShowConfigScreen':'Configurer la carte',
	'N043ConfigSaveFailure':'La mise à jour de la configuration a échouée',
	'N043ConfigSaveSuccess':'Configuration mise à jour avec succès',
	'N044BrazilAlertMailSubject':'Pulse CM Alert : MACHINE_NAME',
	'N045AlertWhenThresholdCrossed':'Alertez-moi lorsque cette limite est franchie.',
	'N046RawDataTab':'Données brutes',
	'N047SensorL1':'Capteur L 1',
	'N047SensorL4':'Capteur L 4',
	'N047SensorR1':'Capteur R 1',
	'N047SensorR4':'Capteur R 4',
	'N048Acceleration':'Accélération',
	'N048AccelerationLocal':'Accélération locale',
	'N048AccelerationX':'Accélération X',
	'N048AccelerationY':'Accélération Y',
	'N048AccelerationZ':'Accélération Z',
	'N048Amplitude':'Amplitude',
	'N048AmplitudeLocal':'Amplitude Local',
	'N048AmplitudeX':'Amplitude X',
	'N048AmplitudeY':'Amplitude Y',
	'N048AmplitudeZ':'Amplitude Z',
	'N048ChooseEndDate':'Choisissez une date de fin',
	'N048ChooseStartDate':'Choisissez une date de début',
	'N048Constant':'Constante',
	'N048CourseAngle':'Angle de cap',
	'N048DeltaTemperature':'Delta-Température',
	'N048Frequency':'fréquence',
	'N048LastSeen':'Dernière vue',
	'N048MovementAngle':'Angle de mouvement',
	'N048OffsetX':'Décalage X',
	'N048OffsetY':'Décalage Y',
	'N048OffsetZ':'Décalage Z',
	'N048PhaseAngle':'Angle de phase',
	'N048MotionAngle':'Angle de mouvement',
	'N048RMS':'RMS',
	'N048Rpm':'Tr/min',
	'N048SensorDescription':'Description du capteur',
	'N048SensorId':'Id du capteur',
	'N048SensorTemperature':'Température du capteur',
	'N048Side':'lieu',
	'N048SiteName':'Nom du site',
	'N048UpdatedTime':'Heure mise à jour',
	'N049accelFDCompLeft':'Accel.FDComp.Gauche',
	'N049accelFDCompRight':'Accel.FDComp.Droite',
	'N049accelLRCompDisch':'Accel.LRComp.Sortie',
	'N049accelLRCompFeed':'Accel.LRComp.Alimentation',
	'N049Date':'Date',
	'N049ErrorFetchingData':'Erreur lors de la récupération des données',
	'N049FrequencyDaily':'Jour',
	'N049FrequencyHourly':'Heures',
	'N049FrequencyWeekly':'Semaine',
	'N049Length':'Longueur',
	'N049ModelName':'Nom du modèle',
	'N049pitch':'Hauteur de son',
	'N049roll':'Rouleau',
	'N049Rpm':'Tr/min',
	'N049strokeDeparture':'Départ de la course',
	'N049TuningWizard':'Asisstant d\'optimisation',
	'N049twistG':'Twist.G',
	'N049twistXG':'Twist.XG',
	'N049twistYG':'Twist.YG',
	'N049twistZG':'Twist.ZG',
	'N049Width':'Largeur',
	'N049xgFDCompLeft':'X.G.FDComp.Gauche',
	'N049xgFDCompRight':'X.G.FDComp.Droit',
	'N049xgLrCompDisch':'X.G.LRComp.Sortie',
	'N049xgLrCompFeed':'X.G.LRComp.Alimentation',
	'N049ygFDCompLeft':'Y.G.FDComp.Gauche',
	'N049ygFDCompRight':'Y.G.FDComp.Droit',
	'N049ygLrCompDisch':'Y.G.LRComp.Sortie',
	'N049ygLrCompFeed':'Y.G.LRComp.Alimentation',
	'N049zgFDCompLeft':'Z.G.FDComp.Gauche',
	'N049zgFDCompRight':'Z.G.FDComp.Droite',
	'N049zgLrCompDisch':'Z.G.LRComp.Sortie',
	'N049zgLrCompFeed':'Z.G.LRComp.Alimentation',
	'N049zgMax':'ZG.Max',
	'N050AnalystCommentary':'Commentaire d\'analyste',
	'N050mAvgLong':'mavg long',
	'N050mAvgShort':'mavg court',
	'N050TrendAnalysis':'Analyse de tendance',
	'N051SensorL1':'Capteur L1',
	'N051SensorL2':'Capteur L2',
	'N051SensorL3':'Capteur L3',
	'N051SensorL4':'Capteur L4',
	'N051SensorMovement':'Mouvement du capteur',
	'N051SensorR1':'Capteur R1',
	'N051SensorR2':'Capteur R2',
	'N051SensorR3':'Capteur R3',
	'N051SensorR4':'Capteur R4',
	'N052Anomaly':'Anomalie',
	'N052AnomalyDetectionTab':'Détection d\'une anomalie',
	'N052BrazAlertTypeSL1LocalAmplitudeL0':'Capteur L1 amplitude locale L0',
	'N052BrazAlertTypeSL1LocalAmplitudeL1':'Capteur L1 amplitude locale L1',
	'N052BrazAlertTypeSL1LocalAmplitudeL2':'Capteur L1 amplitude locale L2',
	'N052BrazAlertTypeSL1LocalAmplitudeL3':'Capteur L1 amplitude locale L3',
	'N052BrazAlertTypeSL1LocalAmplitudeL4':'Capteur L1 amplitude locale L4',
	'N052BrazAlertTypeSL1LocalAmplitudeL5':'Capteur L1 amplitude locale L5',
	'N052BrazAlertTypeSL1SensorTempL0':'Capteur L1 capteur température croisée L0',
	'N052BrazAlertTypeSL1SensorTempL1':'Capteur L1 capteur température croisée L1',
	'N052BrazAlertTypeSL1SensorTempL2':'Capteur L1 capteur température croisée L2',
	'N052BrazAlertTypeSL1SensorTempL3':'Capteur L1 capteur température croisée L3',
	'N052ObservedValue':'Observé',
	'N053Angle':'Angle (0)',
	'N053BrazAlertTypeSL1LocalAccelerationL0':'Accélération locale du capteur L1 franchie L0',
	'N053BrazAlertTypeSL1LocalAccelerationL1':'Accélération locale du capteur L1 franchie L1',
	'N053BrazAlertTypeSL1LocalAccelerationL2':'Accélération locale du capteur L1 franchie L2',
	'N053BrazAlertTypeSL1LocalAccelerationL3':'Accélération locale du capteur L1 franchie L3',
	'N053BrazAlertTypeSL1LocalAccelerationL4':'Accélération locale du capteur L1 franchie L4',
	'N053BrazAlertTypeSL1LocalAccelerationL5':'Accélération locale du capteur L1 franchie L5',
	'N053BrazAlertTypeSl1RotationFreqL0':'Fréquence de rotation locale L0 du capteur L1',
	'N053BrazAlertTypeSl1RotationFreqL1':'Fréquence de rotation locale L1 du capteur L1',
	'N053BrazAlertTypeSl1RotationFreqL2':'Fréquence de rotation locale L2 du capteur L1',
	'N053BrazAlertTypeSl1RotationFreqL3':'Fréquence de rotation locale L3 du capteur L1',
	'N053BrazAlertTypeSl1RotationFreqL4':'Fréquence de rotation locale L4 du capteur L1',
	'N053BrazAlertTypeSl1RotationFreqL5':'Fréquence de rotation locale L5 du capteur L1',
	'N053BrazAlertTypeSL1ZAxisL0':'Accélération de l\'axe z du capteur L1 croisée L0',
	'N053BrazAlertTypeSL1ZAxisL1':'Accélération de l\'axe z du capteur L1 croisée L1',
	'N053BrazAlertTypeSL1ZAxisL2':'Accélération de l\'axe z du capteur L1 croisée L2',
	'N053BrazAlertTypeSL1ZAxisL3':'Accélération de l\'axe z du capteur L1 croisée L3',
	'N053Displacement':'décalage',
	'N053G':'g',
	'N053LocalAmplitude':'Amplitude locale',
	'N053LocalRotationFrequency':'Rotation locale (fréquence)',
	'N053MM':'mm',
	'N053XAxis':'Axe X',
	'N053YAxis':'Axe Y',
	'N053ZAxis':'Axe Z',
	'N053ZAxisAcceleration':'Accélération de l\'axe Z',
	'N054Higher':'Plus haut',
	'N054Lower':'Plus bas',
	'N054StructuralAnalysis':'Analyse structurelle',
	'N054TWvarsForecasts':'Prévisions TW vars',
	'N054TWvarsFull':'TW vars plein',
	'N054Value':'valeur',
	'N0551On0Off':'1 = ON: 0 = OFF',
	'N055BrazTabGeneral':'Générale',
	'N055ChooseStartEndDate':'Choisissez la date de début et la date de fin',
	'N055Close':'Fermer',
	'N055Custom':'Personnalisé',
	'N055MaterialFlow':'Flux de matières',
	'N055MAxBearingTemperature':'Température maximale du roulement',
	'N055MaxRmsAccelBearingSensors':'Accélération effective RMS des capteurs de roulement',
	'N055MazAccelZAxis':'Capteurs de structure',
	'N055Off':'OFF',
	'N055On':'ON',
	'N055RDCenter':'Vue de la machine',
	'N055ShowHistory':'Afficher l\'historique',
	'N055StartDateCannotBeAfterEndDate':'La date de début ne peut pas être postérieure à la date de fin',
	'N056Commentary':'Commentaire',
	'N056EvaluateEquipment':'Conditions à évaluer sur l\'équipement',
	'N056High80':'Élevé 80',
	'N056High95':'Élevé 95',
	'N056Importance':'importance',
	'N056Low80':'Faible 80',
	'N056Low95':'Faible 95',
	'N056MaxLatAccelZgMax':'Accélération latérale maximale ZG.Max',
	'N056Pred':'Prévision',
	'N056Recommendations':'Recommandations',
	'N056Type':'Type de fichier non supporté',
	'N056Report':'Rapport',
	'N0571Year':'1 année',
	'N057AccelGms':'Accélération (g)',
	'N057AccelRms':'Accélération RMS',
	'N057AccelSpectrum':'Spectre d\'accélération',
	'N057AccelTimeWForm':'Forme d\'onde de temps d\'accélération',
	'N057Axial':'Axiale',
	'N057CrestFactor':'Facteur de crête',
	'N057DegreeCelcius':'°C',
	'N057FreqHz':'Fréquence (Hz)',
	'N057FullSpectrum':'Spectre complet',
	'N057FundBearingDefectFreq':'Fréquences de fonds, de stockage et de défaut',
	'N057HighHarBearFreq':'Fréquences de roulement harmoniques plus élevées',
	'N057HighRes':'haute résolution',
	'N057HistoricTemperature':'Température historique',
	'N057Horizontal':'Horizontale',
	'N057Latest':'Dernière',
	'N057LowHarBearFreq':'Fréquences de palier harmoniques inférieures',
	'N057mms':'mm/s',
	'N057PeakToPeak':'de crête à crête',
	'N057PeakToPeakMg':'de crête à crête (mg)',
	'N057PowerBands':'Bandes de puissance',
	'N057RedAlert':'alerte rouge',
	'N057Rmsmg':'Rms(mg)',
	'N057Rmsmms':'Rms(mm/s)',
	'N057SensorLB1':'Capteur LB1',
	'N057SensorLB2':'Capteur LB2',
	'N057SensorRB1':'Capteur RB1',
	'N057SensorRB2':'Capteur RB2',
	'N057SensorCB1':'Capteur CB1',
	'N057SensorCB2':'Capteur CB2',
	'N057Spectrum':'Spectre',
	'N057Temperature':'Température',
	'N057TimeMs':'Temps (ms)',
	'N057Trending':'Tendance',
	'N057VelocityRms':'Vitesse RMS',
	'N057Vertical':'Verticale',
	'N057X1Rpm':'1X tr/min',
	'N057YellowAlert':'Alerte jaune',
	'N058ImageUploadedFailure':'Échec du téléchargement de l\'image',
	'N058ImageUploadedSuccesfully':'Image téléchargée avec succès',
	'N058UploadImage':'Télécharger l\'image',
	'N059Print':'Impression',
	'N060MachineHistoricalData':'Données historiques machine',
	'N061HistoricalBearingData':'Données historiques roulements',
	'N062HistoricalSpectrumData':'Données historiques spectre',
	'N063GlobalAcceleration':'Accélération globale',
	'N064RotationFrequency':'Rotation (fréquence)',
	'N065GlobalBreadth':'Largeur globale',
	'N066LocalAccelerationsEachStructureSensor':'Accélérations locales de chaque capteur de la structure',
	'N067MotionAngleEachStructureSensor':'Angle de mouvement de chaque capteur de la structure',
	'N067PhaseAngleEachStructureSensor':'Angle de phase de chaque capteur de la structure',
	'N067LocalAmplitudesEachStructureSensor':'Amplitudes locales de chaque capteur de la structure',
	'N068LateralAccelerationsStructureSensor':'Accélérations latérales (Axz Z) de chaque capteur de la structure',
	'N069TemperatureEachBearingSensor':'Température de chaque roulement de capteur',
	'N070PeakToPeakEachBearingSensor':'Valeur crête à crête de chaque capteur de roulement',
	'N071AccelerationRMS':'Accélération quadratique moyenne (RMS)',
	'N072VelocityRMS':'Vitesse quadratique moyenne (RMS)',
	'N073CrestFactor':'Facteur de crête',
	'N074PowerBandsSensorLB1':'Bandes de puissance - Capteur LB1',
	'N075PowerBandsSensorRB1':'Bandes de puissance - Capteur RB1',
	'N076PowerBandsSensorLB2':'Bandes de puissance - Capteur LB2',
	'N077PowerBandsSensorRB2':'Bandes de puissance - Capteur RB2',
	'N077PowerBandsSensorCB1':'Bandes de puissance - Capteur CB1',
	'N077PowerBandsSensorCB2':'Bandes de puissance - Capteur CB2',
	'N078TimeWaveformDataAcceleration':'Données de la forme d\'onde temporelle - Accélération',
	'N079FrequencyDomainAcceleration':'Plage de fréquences - accélération',
	'N080Select':'Sélectionnez',
	'N081SelectedPeriod':'Période sélectionnée',
	'N100Capacity':'Capacité',
	'N101Product':'Produit',
	'N102Undersized':'Sous-dimensionné',
	'N103Oversized':'Surdimensionné',
	'N104Speed':'Vitesse',
	'N105Inclination':'Inclinaison',
	'N106BoardHeigh':'hauteur de la plaque',
	'N107Moisture':'Humidité de l\'air',
	'N108WaterAdd':'Addition d\'eau',
	'N109OpMode':'Mode de fonctionnement',
	'N110Auto':'Fonctionnement automatique',
	'N110Hand':'Fonctionnement manuel',
	'N111OpHours':'Heures de fonctionnement',
	'N112Temp':'Température',
	'N113BearingTemp1':'Tempétature roulement 1',
	'N114BearingTemp2':'Température roulement 2',
	'N115DriveTemp':'Température groupe de commande',
	'N116ConvTemp':'température variateur de vitesse',
	'N117PowerInput':'Puissance absorbée',
	'New001BagTransArea':'Zone de transport de sacs',
	'New002EmptyPalArea':'Zone palettes vides',
	'New003FullPalArea':'Zone palettes pleines',
	'New004SheetDispArea':'Zone de stockage de film',
	'New005PneuFalut':'Défaut pneumatique',
	'New006ElectricFault':'Défauit électrique',
	'New007Introduction':'Introduction',
	'New008Orientation':'Orientation',
	'New009PreGrouping':'Pré-groupage',
	'New010Grouping':'Groupage',
	'New011RowPusher':'Pousseur de rangée',
	'New012SlideTable':'Fausse-palette  & plaque de conformation',
	'New013Elevator':'Elévateur',
	'New014Gantry':'Portique',
	'New015Gripper':'Pince',
	'New016FilmDispenser':'Dérouleur de film',
	'New017FullPalTrans':'Transport palettes pleines',
	'New018EmpPalDisp':'Magasin palettes vides',
	'New019FilmDisp':'Dérouleur de film',
	'New020EmpCardDisp':'Dsitributeur de carton',
	'New021EmpPalTrans':'Transport palettes vides',
	'New022EjectingDev':'Eliminateur de sacs',
	'New023MetDetector':'Détecteur de métaux',
	'New024MarkingDev':'Dispositif de marquage',
	'New025LabellingDev':'Etiqueteuse',
	'New026PalletStracker':'Gerbeur de palettes',
	'New027PalletPress':'Presse à palettes',
	'New028Wrapper':'Banderoleuse / housseuse',
	'New029Fault':'Erreur / Défaut',
	'New030StandBy':'En attente',
	'New031BagProduction':'Production de sacs',
	'New032WeightDetection':'Détection du poids',
	'New033LeakingDetection':'Détection de fuite produit',
	'New034PrintingFault':'Défaut imprimante',
	'New035BagProduced':'Sacs produits',
	'New036SamplingEjection':'Ejection échantillons',
	'New037PalletProduction':'Production palettes',
	'New038PalletInfeed':'Alimentation palettes',
	'New039PalletOutfeed':'Sortie palettes',
	'New040PalletIncomplete':'Palette incomplète',
	'New041Checkweigher':'Trieuse pondérale',
	'New041PalledProduced':'Palettes produites',
	'New042ProgramChngsToday':'Changement de programme aujourd\'hui',
	'New042ProgramChngs':'Changement de programme',
	'New043EndofBatch':'Fin du lot',
	'New044ProductonTime':'temps de production',
	'RVT8-Messe':'ROTO-PACKER® ',
	'RVT8-Simulation':'ROTO-PACKER® ',
	'TERAM2-Messe':'TERRAM®',
	'TERAM-Messe':'TERRAM®',
	'Typ84-Messe':'INTEGRA® ',
	'Waagenabgleich':'Equilibrage balance',
	'Zyklen':'cycles',
	'N082AlertMe':'M\'alerter lorsque cette carte contient des notifications',
	'N083TimeFormat':'HH:mm',
	'N090Synced':'Synchronisé',
	'N091NotSynced':'Non synchronisé',
	'N084New':'nouveau',
	'N085ConfigSettingSavedSuccessfully':'Paramètres de configuration enregistrés avec succès',
	'N86ErrorOccurredSettingsWasNotSaved':'Erreur lors de la sauvegarde des paramètres de configuration',
	'A496OEEWeek':'Toutes les deux semaines',
	'A497OEEMonthly':'Mensuel',
	'N87ServiceRequestUploadFiles':'Télécharger les fichiers',
	'N88ServiceRequestFileSizeError':'Fichier trop volumineux',
	'N89ServiceRequestFileTypeError':'Type de fichier non supporté',
	'N90FilesUploadedSuccesfully':'Fichier téléchargé avec succès',
	'N91FilesUploadedFailure':'Erreur lors du chargement des données',
	'N932IncludeExcelReport':'Inclure les rapports Excel',
	'N94ServiceRequestFileLimitError':'Donnée au delà de la limite',
	'N055MaxHAxisRmsAccelBearingSensors':'Max. horiz. RMS Accélération',
	'N055MaxAxialRmsAccelBearingSensors':'Max. axiale RMS Accélération',
	'N053MG':'mg',
	'N056DummyMachine':'machines factices',
	'N057NiagaraAlertYellow':'Alerte jaune',
	'N058NiagaraAlertOrange':'Alert orange',
	'N059NiagaraAlertRed':'Alert rouge',
	'N060AddDummyMachine':'Ajouter une machine factice',
	'N96ConfigureValue':'Valeur de configuration',
	'N97NoDataAvailable':'Pas de données disponibles',
	'N98TimeStamp':'Horodatage',
	'N99Documentation':'Documentation',
	'N100ErrorFileHeader':'Messages d\'erreur',
	'N101DocumentationHeader':'En-tête de la documentation',
	'N102DownloadMachineTypeErrorMessageFile':'Type de machine par défaut',
	'N102DownloadMachineErrorMessageFile':'Fichier d\'erreur de la machine',
	'N103ErrorInRetrievingErrorMessages':'Erreur lors de la récupération des messages d\'erreur.',
	'E011MaintIntervalSubmitSuccess':'Intervalle de maintenance enregistré avec succès.',
	'A368OpenPartsShop':'Ouvrir un magasin de pièces détachées',
	'N104ExtSparePartCatalog':'Magasin de pièces détachées HAVER',
	'N105OpenExtSparePartCatalog':'Ouvrir un magasin de pièces détachées HAVER',
	'N106LperH':'l/h',
	'N107Degree':'°',
	'N107DegreeText':'° (Degré)',
	'N108UseRelativeValues':'Utiliser des valeurs relatives',
	'N109PartsShopUsername':'Magasin de pièces détachées Nom d\'utilisateur',
	'N110Tons':'t',
	'AX_VibratingBodyCondition':' ',
	'AX_BearingCondition':' ',
	'AX_AlarmsCount':' ',
	'AX_Last24Hours':' ',
	'D001TransBearingCondition':' ',
	'D002TransBearingCondition':' ',
	'D003TransBearingCondition':' ',
	'D004TransBearingCondition':' ',
	'D005TransBearingCondition':' ',
	'D006TransBearingCondition':' ',
	'D007TransBearingCondition':' ',
	'D008TransBearingCondition':' ',
	'D009TransBearingCondition':' ',
	'D010TransBearingCondition':' ',
	'D011TransBearingCondition':' ',
	'D012TransBearingCondition':' ',
	'D013TransBearingCondition':' ',
	'D014TransBearingCondition':' ',
	'D015TransBearingCondition':' ',
	'D016TransBearingCondition':' ',
	'D001TransVBCondition':' ',
	'D002TransVBCondition':' ',
	'D003TransVBCondition':' ',
	'D004TransVBCondition':' ',
	'D005TransVBCondition':' ',
	'D006TransVBCondition':' ',
	'D007TransVBCondition':' ',
	'D008TransVBCondition':' ',
	'D009TransVBCondition':' ',
	'D010TransVBCondition':' ',
	'D011TransVBCondition':' ',
	'D012TransVBCondition':' ',
	'D013TransVBCondition':' ',
	'D014TransVBCondition':' ',
	'D015TransVBCondition':' ',
	'D016TransVBCondition':' ',
	'F01CardExpProduction':'***bientôt disponible***',
	'F01CardExpAvailability':'***bientôt disponible***',
	'F01CardExpMaintenance':'***bientôt disponible***',
	'F01CardExpProductionTime':'***bientôt disponible***',
	'F01CardExpProductionTimeBaumit':'***bientôt disponible***',
	'F01CardExpProductionToday':'***bientôt disponible***',
	'F01CardExpRemProduction':'***bientôt disponible***',
	'F01CardExpLastReset':'***bientôt disponible***',
	'F01CardExpFaults_C':'***bientôt disponible***',
	'F01CardExpFaults_M':'***bientôt disponible***',
	'F01CardExpEnergy':'***bientôt disponible***',
	'F01CardExpFillingTime':'***bientôt disponible***',
	'F01CardExpBagManufacture':'***bientôt disponible***',
	'F01CardExpWeightData':'***bientôt disponible***',
	'F01CardExpStdDevData':'***bientôt disponible***',
	'F01CardExpIncorrectWeight':'***bientôt disponible***',
	'F01CardExpInterrupts':'***bientôt disponible***',
	'F01CardExpTypeChange':'***bientôt disponible***',
	'F01CardExpBagCounterPerFS':'***bientôt disponible***',
	'F01CardExpNotPlacedBags':'***bientôt disponible***',
	'F01CardExpEmptyBagMagazineToday':'***bientôt disponible***',
	'F01CardExpRejectsToday':'***bientôt disponible***',
	'F01CardExpCollectiveFaultsToday':'***bientôt disponible***',
	'F01CardExpGoodWeights':'***bientôt disponible***',
	'F01CardExpFltCompAir':'***bientôt disponible***',
	'F01CardExpStrokesPump':'***bientôt disponible***',
	'F01CardExpMaxStrokeDur':'***bientôt disponible***',
	'F01CardExpAvgStrokes':'***bientôt disponible***',
	'F01CardExpInternalPressure':'***bientôt disponible***',
	'F01CardExpFillings':'***bientôt disponible***',
	'F01CardExpStartOfOrder':'***bientôt disponible***',
	'F01CardExpProductID':'***bientôt disponible***',
	'F01CardExpTargetWeight':'***bientôt disponible***',
	'F01CardExpFillingCount':'***bientôt disponible***',
	'F01CardExpRemainingNumberFillings':'***bientôt disponible***',
	'F01CardExpCycleTime':'***bientôt disponible***',
	'F01CardExpFaultLength':'***bientôt disponible***',
	'F01CardExpExtFaultLength':'***bientôt disponible***',
	'F01CardExpWithinTolerance':'***bientôt disponible***',
	'F01CardExpOutsideTolerance':'***bientôt disponible***',
	'F01CardExpCurrentProduction':'***bientôt disponible***',
	'F01CardExpFillStatistic':'***bientôt disponible***',
	'F01CardExpRDCenter':'***bientôt disponible***',
	'F01CardExpGlobalAcceleration':'***bientôt disponible***',
	'F01CardExpGlobalFrequency':'***bientôt disponible***',
	'F01CardExpGlobalAmplitude':'***bientôt disponible***',
	'F01CardExpMazAccelZAxis':'***bientôt disponible***',
	'F01CardExpMaxHAxisRmsAccelBearingSensors':'***bientôt disponible***',
	'F01CardExpMaxRmsAccelBearingSensors':'***bientôt disponible***',
	'F01CardExpMaxAxialRmsAccelBearingSensors':'***bientôt disponible***',
	'F01CardExpMAxBearingTemperature':'***bientôt disponible***',
	'F01CardExpSensorMovement':'***bientôt disponible***',
	'F01CardExpAccelerationLocal':'***bientôt disponible***',
	'F01CardExpAcceleration':'***bientôt disponible***',
	'F01CardExpAxisAcceleration':'***bientôt disponible***',
	'F01CardExpLocalRotationFrequency':'***bientôt disponible***',
	'F01CardExpLocalAmplitude':'***bientôt disponible***',
	'F01CardExpDisplacement':'***bientôt disponible***',
	'F01CardExpPhaseAngle':'***bientôt disponible***',
	'F01CardExpMotionAngle':'***bientôt disponible***',
	'F01CardExpSensorTemperature':'***bientôt disponible***',
	'F01CardExpBagQuality':'***bientôt disponible***',
	'F01CardExpFaults':'***bientôt disponible***',
	'F01CardExpBunChangeAbs':'***bientôt disponible***',
	'F01CardExpBunChangeToday':'***bientôt disponible***',
	'F01CardExpBagsPerBundle':'***bientôt disponible***',
	'F01CardExpTypeChanges':'***bientôt disponible***',
	'F01CardExpSortChangeToday':'***bientôt disponible***',
	'F01CardExpCyclesToday':'***bientôt disponible***',
	'F01CardExpMetalDetectorToday':'***bientôt disponible***',
	'F01CardExpIncorrectBags':'***bientôt disponible***',
	'F01CardExpOpenBags':'***bientôt disponible***',
	'F01CardExpSampleBags':'***bientôt disponible***',
	'F01CardExpRejectionsToday':'***bientôt disponible***',
	'F01CardExpContainer':'***bientôt disponible***',
	'F01CardExpEmptyRejects':'***bientôt disponible***',
	'F01CardExpFullRejects':'***bientôt disponible***',
	'F01CardExpProductionRate':'***bientôt disponible***',
	'F01CardExpDailyProduction':'***bientôt disponible***',
	'F01CardExpFillingStation':'***bientôt disponible***',
	'F01CardExpPalletizer':'***bientôt disponible***',
	'F01CardExpEmptyPalArea':'***bientôt disponible***',
	'F01CardExpBagTransArea':'***bientôt disponible***',
	'F01CardExpFullPalArea':'***bientôt disponible***',
	'F01CardExpSheetDispArea':'***bientôt disponible***',
	'F01CardExpProductonTime':'***bientôt disponible***',
	'F01CardExpProgramChngsToday':'***bientôt disponible***',
	'F01CardExpEndofBatch':'***bientôt disponible***',
	'F01CardExpEmptyPallets':'***bientôt disponible***',
	'F01CardExpBagProduction':'***bientôt disponible***',
	'F01CardExpPalletProduction':'***bientôt disponible***',
	'F01CardExpFSDifference':'***bientôt disponible***',
	'F01CardExpProduct':'***bientôt disponible***',
	'F01CardExpCapacity':'***bientôt disponible***',
	'F01CardExpUndersized':'***bientôt disponible***',
	'F01CardExpOversized':'***bientôt disponible***',
	'F01CardExpSpeed':'***bientôt disponible***',
	'F01CardExpInclination':'***bientôt disponible***',
	'F01CardExpBoardHeigh':'***bientôt disponible***',
	'F01CardExpMoisture':'***bientôt disponible***',
	'F01CardExpWaterAdd':'***bientôt disponible***',
	'F01CardExpOpMode':'***bientôt disponible***',
	'F01CardExpOpHours':'***bientôt disponible***',
	'F01CardExpTemp':'***bientôt disponible***',
	'F01CardExpPowerInput':'***bientôt disponible***',
	'F01CardExpFullPalletCount':'***bientôt disponible***',
	'F01CardExpLoadEmptyPallets':'***bientôt disponible***',
	'F01CardExpOeeValue':'***bientôt disponible***',
	'F01CardExpOeeAvailable':'***bientôt disponible***',
	'F01CardExpOeePerformance':'***bientôt disponible***',
	'F01CardExpOeeQuality':'***bientôt disponible***',
	'F01CardExpOeeDowntime':'***bientôt disponible***',
	'F01CardExpOperatingFactor':'***bientôt disponible***',
	'F01CardExpThroughput':'***bientôt disponible***',
	'F01CardExpOeeRejects':'***bientôt disponible***',
	'F01CardExpMachineDetailPage':'***bientôt disponible***',
	'F01CardExpReportSettingsPage':'***bientôt disponible***',
	'F01CardExpReportOverviewPage':'***bientôt disponible***',
	'F01CardExpCompanyOverviewPage':'***bientôt disponible***',
	'F01CardExpAlertSettingsPage':'***bientôt disponible***',
	'F01CardExpMsgAdminPage':'***bientôt disponible***',
	'F01CardExpUserRolesPage':'***bientôt disponible***',
	'F01CardExpCompaniesPage':'***bientôt disponible***',
	'F01CardExpDummyMachinePage':'***bientôt disponible***',
	'F01CardExpPermissionsPage':'***bientôt disponible***',
	'F01CardExpMaintenanceAidPage':'***bientôt disponible***',
	'F01CardExpMachinePage':'***bientôt disponible***',
	'F01CardExpLogBookTab':'***bientôt disponible***',
	'F01CardExpServiceRequestTab':'***bientôt disponible***',
	'F01CardExpMessagesTab':'***bientôt disponible***',
	'F01CardExpDocumentSTab':'***bientôt disponible***',
	'F01CardExpOeeTab':'***bientôt disponible***',
	'F01CardExpMaintenanceTab':'***bientôt disponible***',
	'F01CardExpMachineSpecificTab':'***bientôt disponible***',
	'F01CardExpGeneralTab':'***bientôt disponible***',
	'F01CardExpGoodPerformance':'***bientôt disponible***',
	'F01CardExpWrongApplBags':'***bientôt disponible***',
	'F01CardExpReelChanges':'***bientôt disponible***',
	'F01CardExpTypeCurrent':'***bientôt disponible***',
	'F01CardExpInterruptsFeigeGeneral':'***Coming Soon***',
	'F01CardExpInterruptsAdams':'***Coming Soon***',
	'F01CardExpInterruptsISF':'***Coming Soon***',
	'F01CardExpInterruptsIVT':'***Coming Soon***',
	'F01CardExpInterruptsMiniAdams':'***Coming Soon***',
	'F01CardExpInterruptsRVT':'***Coming Soon***',
	'F01CardExpInterruptsNewtecTerram':'***Coming Soon***',
	'A-AlertTip-main_acc-ORANGE':'Accélération principale Alerte orange',
	'A-AlertTip-main_acc-YELLOW':'Accélération principale Alerte jaune',
	'A-AlertTip-main_acc-RED':'Accélération principale Alerte rouge',
	'A-AlertTip-u8MachAvail-AVAIL':'Alerte de disponibilité',
};