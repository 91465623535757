import { Injectable } from '@angular/core';
import { ChartDefinitionBase } from './chart-definition-base';
import { IMachine } from '../api/domain-model';
import { MachineService } from '../api/machine.service';
import { NotificationService } from '../shared/notifications/notification.service';
import { ChartConfigService } from '../company/chart-config.service';
import { AuthService } from '../shared/auth-service';
import { TranslateService } from '../shared/translate';
import {
	ICollectiveFaultsTableCardDefinition,
	IGaugeCardDefinition,
	IInterruptsTableCardDefinition,
	INotPlacedTableCardDefinition,
	ISimpleContentCardDefinition,
	ISpoutTableCardDefinition,
	IWeightDataTableCardDefinition
} from '../charts/definition-model';
import CardDisplayVariables from '../common/domain/card-display-variables';
import { Identifiers } from '../common/domain/bindings';

@Injectable()
export class ChartDefinitionEvpService extends ChartDefinitionBase {

	private machine: IMachine;

	constructor(
		public machineService: MachineService,
		public notificationService: NotificationService,
		public chartConfigService: ChartConfigService,
		public authService: AuthService,
		public translateService: TranslateService
	) {
		super();
	}

	setMachine(machine: IMachine): void {
		this.machine = machine;
	}

	getMachine(): IMachine {
		return this.machine;
	}

	getNotPlacedCardDefinition(): INotPlacedTableCardDefinition {
		const requiredVariables = Identifiers.notPlacedCard;
		return {
			neededValues: requiredVariables,
			historyVariables: ['u16SortChgDay'],
			title: 'A234NotPlacedBags',
			cardBatchTitle: 'A234NotPlacedBags',
			unit: 'A020Count',
			labelPrefix: 'A024FillingStation',
			stackedAreaChartSpec: this.chartConfigService.bagCounterStackedAreaChartConfig(
				Identifiers.notPlacedCard,
				'u8SpoutCnt',
				'A024FillingStation'
			),
			displayVariable: CardDisplayVariables.evpCardDisplayVariables().notPlacedBags,
		}
	}

	getTypeChangeCardDefinition() {
		const requiredVariables = ['u16SortChgDay', 'u16SortChgBatch'];
		return <ISimpleContentCardDefinition>{
			neededValues: [requiredVariables],
			historyVariables: requiredVariables,
			title: 'A035TypeChange',
			cardBatchTitle: 'A035TypeChangeBatch',
			unit: 'A020Count',
			displayVariable: CardDisplayVariables.evpCardDisplayVariables().typeChangesToday,
		}
	}

	getEmptyBagMagazineTodayCardDefinition() {
		const requiredVariables = ['u8ReloadBagMagazine', 'u8ReloadBagMagazineBatch'];
		return <ISimpleContentCardDefinition>{
			neededValues: [requiredVariables],
			historyVariables: requiredVariables,
			title: 'A250EmptyBagMagazineToday',
			cardBatchTitle: 'A250EmptyBagMagazineTodayBatch',
			unit: 'A020Count',
			displayVariable: CardDisplayVariables.evpCardDisplayVariables().reloadEmptyBagMagazineToday,
		}
	}

	getRejectsTodayCardDefinition() {
		const requiredVariables = ['u32RejectCnt', 'u32RejectCntBatch'];
		return <ISimpleContentCardDefinition>{
			neededValues: [requiredVariables],
			historyVariables: requiredVariables,
			title: 'A216RejectsToday',
			cardBatchTitle: 'A216RejectsTodayBatch',
			unit: 'A020Count',
			displayVariable: CardDisplayVariables.evpCardDisplayVariables().rejectsToday,
		}
	}

	getProductionChartDefinition(): IGaugeCardDefinition {
		return <IGaugeCardDefinition>{
			chartOptions: this.chartConfigService.anyGaugeChartSpec(
				this.machine,
				'produktionGutsaeckeSpec',
				0,
				100,
				Identifiers.evpGoodProductionCards,
				null
			).options,
			title: 'A039GoodWeights',
			unit: 'A027Percent',
			historyVariables: Identifiers.evpGoodProductionCards[2],
			colorHistoryVariables: Identifiers.generalPageProductionCardVariables,
			displayVariable: CardDisplayVariables.evpCardDisplayVariables().goodProduction
		};
	}


	getSpoutCardDefinition(): ISpoutTableCardDefinition {
		const requiredVariables = Identifiers.spoutCard;
		return {
			neededValues: requiredVariables,
			historyVariables: ['u32SpoutProdCnt'],
			title: 'A023BagCounterPerFS',
			cardBatchTitle: 'A023BagCounterPerFSBatch',
			unit: 'A027Percent',
			labelPrefix: 'A024FillingStation',
			displayVariable: CardDisplayVariables.evpCardDisplayVariables().bagCounterToday,
		}
	}

	getInterruptsCardDefinition(): IInterruptsTableCardDefinition {
		const requiredVariables = [
			...Identifiers.evpInterruptCard.countParams,
			...Identifiers.evpInterruptCard.timeParams
		];
		return {
			neededValues: requiredVariables,
			historyVariables: ['u16StopFilterCnt'],
			title: 'A284Interrupts',
			cardBatchTitle: 'A284InterruptsBatch',
			unit: 'A124Minutes',
			machineType: '',
			countParams: Identifiers.evpInterruptCard.countParams,
			timeParams: Identifiers.evpInterruptCard.timeParams,
			titleParams: Identifiers.evpInterruptCard.titleParams,
			displayVariable: CardDisplayVariables.evpCardDisplayVariables().interruptionsToday,
		}
	}

	getWeightFillingStationDataCardDefinition(): IWeightDataTableCardDefinition {
		const requiredVariables = Identifiers.fillingStationWeightCard;
		return {
			neededValues: requiredVariables,
			historyVariables: ['s32MeanNetScale'],
			netScalePrefix: 's32MeanNetScale',
			netWeightsPrefix: 's32MeanWeights',
			decimals: 's16MeanDecimals',
			title: 'A422WeightData',
			cardBatchTitle: 'A422WeightData',
			unit: 's16MeanUnit',
			multilineChartSpec: this.chartConfigService.fillingStationNetWeighterMultiLineChartConfig(
				Identifiers.fillingStationWeightCard,
				['u8NetWeighersCnt', 'u8SpoutCnt']
			),
			displayVariable: CardDisplayVariables.evpCardDisplayVariables().weightData,
		}
	}

	getStandardDeviationDataCardDefinition(): IWeightDataTableCardDefinition {
		const requiredVariables = Identifiers.fillingStationStandardDeviationCard;
		return {
			neededValues: requiredVariables,
			historyVariables: ['s32StdDevNetScale'],
			netScalePrefix: 's32StdDevNetScale',
			netWeightsPrefix: 's32StdDev',
			decimals: 's16StdDevDecimals',
			title: 'A423StdDevData',
			cardBatchTitle: 'A423StdDevData',
			unit: 's16StdDevUnit',
			multilineChartSpec: this.chartConfigService.fillingStationNetWeighterMultiLineChartConfig(
				Identifiers.fillingStationStandardDeviationCard,
				['u8NetWeighersCnt', 'u8SpoutCnt']
			),
			displayVariable: CardDisplayVariables.evpCardDisplayVariables().stdDevData,
		}
	}

	getCollectiveFaultsCardDefinition(): ICollectiveFaultsTableCardDefinition {
		const requiredVariables = Identifiers.collectiveFaultsCard;
		const aliases: Array<Array<string>> = Identifiers.collectiveFaultsCard;
		const countVariables = aliases.shift(); // remove cnt variable
		const multilineChartSpec = this.chartConfigService.getCollectiveFaultsMultiLineChartConfig('A024FillingStation', countVariables, aliases);

		return {
			neededValues: requiredVariables,
			historyVariables: ['u16FaultGeneralSpout'],
			title: 'A235CollectiveFaultsToday',
			cardBatchTitle: 'A235CollectiveFaultsTodayBatch',
			unit: 'A124Minutes',
			placerCount: ['u16FaultGeneralCnt', 'u16FaultGeneralCntBatch'],
			placerTime: ['u16FaultGeneralTime', 'u16FaultGeneralTimeBatch'],
			multilineChartSpec,
			displayVariable: CardDisplayVariables.evpCardDisplayVariables().collectedFaultsToday,
		}
	}

	getFaultAirCardDefinition(): ICollectiveFaultsTableCardDefinition {
		const requiredVariables = Identifiers.pressureFaultsCard;
		const aliases: Array<Array<string>> = Identifiers.pressureFaultsCard;
		const countVariables = aliases.shift(); // remove cnt variable
		const multilineChartSpec = this.chartConfigService.getCollectiveFaultsMultiLineChartConfig('A024FillingStation', countVariables, aliases);

		return {
			neededValues: requiredVariables,
			historyVariables: ['u16PressureApplicatorCnt'],
			title: 'A296FltCompAir',
			cardBatchTitle: 'A296FltCompAirBatch',
			unit: 'A124Minutes',
			placerCount: ['u16PressureApplicatorCnt', 'u16PressureApplicatorCntBatch'],
			placerTime: ['u16PressureApplicatorTime', 'u16PressureApplicatorTimeBatch'],
			multilineChartSpec,
			displayVariable: CardDisplayVariables.evpCardDisplayVariables().faultsPressureToday,
		}
	}
}
