export const LANG_VI_NAME = 'vi';
export const LANG_VI_TRANS = {
	'0030565001AE':'ELEMENTRA® ',
	'003056500368':'INTEGRA®',
	'003056500394':'ROTO-PACKER®',
	'003056500398':'ROTO-PACKER®',
	'0030565011A8':'ROTO-PACKER®',
	'0030565011AE':'ELEMENTRA®',
	'003056501200':'ROTO-PACKER®',
	'00305650121E':'INTEGRA®',
	'003056A98058':'ROTO-PACKER®',
	'057X1to2Rpm':'1X-2X Rpm',
	'057X2to3Rpm':'2.5X - 3X Rpm',
	'A000Key':'chìa khóa',
	'A001Country':'Tiếng Việt ',
	'A002German':'Deutsch',
	'A003English':'English',
	'A004Username':'Email',
	'A005Login':'Đăng nhập mã số của bạn',
	'A006Password':'Mật khẩu',
	'A007RemPassword':'Giữ đăng nhập ',
	'A008Login':'Đăng nhập',
	'A009Logout':'Ra khỏi hệ thống',
	'A010Production':'Sản xuất',
	'A011Availability':'Khả dụng',
	'A012Maintenance':'Bảo trì máy',
	'A013RemainingUnits':'Số lượng còn lại',
	'A014ProductionTime':'Thời gian sản xuất',
	'A015ProductionTodayGeneral':'Năng suất hôm nay',
	'A015ProductionToday':'Năng suất hôm nay',
	'A016Units':'số máy',
	'A017RemProduction':'Tổng sản lượng',
	'A018LastReset':'Chỉnh lần cuối',
	'A019Faults':'Lỗi ngày hôm nay',
	'A019FaultsBatch':'Lỗi ngày hôm',
	'A020Count':'đếm',
	'A021FaultDuration':'Thời gian lỗi hôm nay',
	'A022Minutes':'Phút',
	'A023BagCounterPerFS':'Bao đếm được hôm nay',
	'A023BagCounterPerFSBatch':'Bao đếm được hôm nay',
	'A024FillingStation':'Bộ cấp liệu',
	'A025FSDifference':'Bộ đếm bao',
	'A026AtLeast1Caps':'ít nhất 1 chữ in hoa',
	'A026FSworstspout':'Vòi kém nhất',
	'A027Percent':'Phần trăm',
	'A028RejectionsToday':'Phần bị bỏ hôm nay',
	'A028RejectionsTodayBatch':'Phần bị bỏ hôm',
	'A029FilterStop':'Lọc',
	'A030RotaryDriveStop':'ROTO-PACKER®',
	'A031ProductFeedStop':'Sản phẩm cấp vào',
	'A032RadimatStop':'RADIMAT®',
	'A033PalletizerStop':'Máy xếp palet/máy xếp sling',
	'A034CompAirFault':'Khí nén',
	'A035TypeChange':'Số lượng sản phẩm thay đổi hôm nay',
	'A035TypeChangeBatch':'Số lượng sản phẩm thay đổi hôm nay',
	'A036ProductionRate':'tốc độ sản xuất liên quan',
	'A037SpeedRelated':'bao/giờ',
	'A038UnitsPerHour':'máy/giờ',
	'A039GoodWeights':'Sản xuất đạt yêu cầu',
	'A040ScissorHose':'Ống cắt',
	'A041ConnectingHose':'Ống nối',
	'A042ConeGasket':'Cơ cấu đệm dạng hình côn',
	'A043AerationPlates':'Tấm sục',
	'A043ScissorHose':'Ống cắt',
	'A044ConnectingHose':'Ống nối',
	'A044RegulationValve':'Van điều tiết khí cho máng khí động',
	'A045ConeFeeding':'Bộ cấp liệu côn',
	'A045ConeGasket':'Bộ vòng đệm côn',
	'A046AerationPlates':'Tấm sục',
	'A046BagTransBelt':'bộ vận chuyển bao- cơ cấu dây curoa',
	'A047BagTransBearing':'Bộ vận chuyển bao- cơ cấu vòng bi',
	'A047RegulationValve':'Van điều tiết',
	'A048ConeFeeding':'Kiểu cấp liệu dạng côn',
	'A048SuctionCup':'Núm hút ',
	'A049BagTransBelt':'Bộ vận chuyển bao- cơ cấu dây cu roa',
	'A049BagTransCyl':'Bộ vận chuyển bao- cơ cấu xy lanh',
	'A050BagTransBearing':'Vòng bi',
	'A050SucRailCyl':'Cơ cấu đường day có phần sục, xy lanh',
	'A051SuctionCup':'Núm hút bao',
	'A051VacuumPump':'Bơm chân không',
	'A052BagTransCyl':'Cơ cấu vận chuyển bao - xy lanh',
	'A052BagTransCylinde':'Cơ cấu vận chuyển bao - xy lanh',
	'A052CableHightAdj':'Cáp Điều chỉnh chiều cao ghế đỡ',
	'A053HullMinSensor':'sensor hull min',
	'A053SucRailCyl':'Cơ cấu đường day có phần sục, xy lanh',
	'A053SucRailCylinder':'Cơ cấu đường day có phần sục, xy lanh',
	'A054Last48Hours':'48 h trước',
	'A054VacuumPump':'Bơm chân không',
	'A055CableHightAdj':'Bộ sấy khí',
	'A055Last14Days':'14 ngày trước',
	'A056HullMinSensor':'Bộ thổi Pisten',
	'A056off':'off',
	'A057InOperation':'Đang vận hành',
	'A058Faulty':'Lỗi',
	'A059InMaintenance':'Đang bảo dưỡng',
	'A060HourlyValues':'Tốc độ sản xuất liên quan',
	'A061DischargesTotal':'Tổng lượng xả',
	'A062CreationDate':'Ngày thành lập',
	'A062Period':'Chu kỳ',
	'A063To':'Tới',
	'A064BatchValue':'Giá trị hàng loạt',
	'A064DailyValue':'Giá trị hàng ngày',
	'A064DailyValues':'Giá trị hàng ngày',
	'A065supply':'Cung cấp',
	'A066Transport':'Vận chuyển',
	'A067Feeding':'Cấp liệu',
	'A068PackagesMissing':'Gói/ kiện bị mất',
	'A069NoHistoricalData':'Không lưu dữ liệu cho chu kỳ giữa',
	'A070And':'Và',
	'A071Available':'Có sẵn',
	'A072ReallyReset':' Muốn reset lại?',
	'A073Details':'Chi tiết',
	'A074General':'Thông thường',
	'A075Machines':'Máy',
	'A076Dashborad':'Bảng điều khiển',
	'A077Administration':'Quản trị',
	'A078UserRoles':'Người sử dụng và vai trò',
	'A079Companies':'Công ty',
	'A080Machines':'Máy ',
	'A081Date':'Ngày',
	'A082Transport':'Vận chuyển',
	'A083Containermissing':'Phần chứa bị mất',
	'A084Availability':'Có sẵn',
	'A085TypeChanges':'Kiểu thay đổi hôm nay',
	'A086EmptyRejects':'Lượng vỏ bao bị bỏ hôm nay',
	'A086EmptyRejectsBatch':'Lượng vỏ bao bị bỏ',
	'A087FullRejects':'Lượng bao đóng bị bỏ hôm nay',
	'A087FullRejectsBatch':'Lượng bao đóng bị bỏ ',
	'A088OpRelease':'Loại bỏ việc chạy máy',
	'A089ProdFeedStop':'Cấp liệu dừng',
	'A090AuxDrive':'Bộ nhớ phụ',
	'A091Consumables':'Vật tư tiêu hao',
	'A092DischLineStop':'Đường xả dừng',
	'A093TypeChange':'Kiểu chuyển giao',
	'A094DailyProduction':'Sản lượng hôm nay',
	'A094DailyProductionBatch':'Sản lượng',
	'A095Energy':'Năng lượng',
	'A096EffectivePower':'Công suất hữu ích',
	'A097ApparentPower':'Công suất toàn phần',
	'A098CompAirFlow':'Lưu lượng khí nén',
	'A099ResetFailed':'Thất bại khi reset metric',
	'A1000Spanish':'Español',
	'A1000UploadMaintenanceAid':'Tải lên hỗ trợ bảo trì',
	'A1001UploadMaintenanceAidSuccess':'Bảo trì tải lên thành công',
	'A1002UploadMaintenanceAidFailure':'Lỗi khi tải lên hỗ trợ bảo trì',
	'A1005MachineServiceRequest':'yêu cầu dịch vụ',
	'A1006RequestService':'yêu cầu dịch vụ',
	'A1007ServiceContract':'hợp đồng dịch vụ',
	'A1008ServiceRequestSuccesful':'Yêu cầu dịch vụ được xử lý thành công',
	'A1009ServiceRequestFailure':'Lỗi trong yêu cầu dịch vụ',
	'A100AcessDenied':'Truy cập bị từ chối',
	'A100AlertmailSubject':'Cảnh báo QSI : MACHINE_NAME',
	'A1010LogBook':'Nhật ký sổ sách',
	'A1011CreateLogBook':'Tạo mục nhật ký',
	'A1012EditLogBook':'Chỉnh sửa mục nhật ký',
	'A1013LogBookItemType':'Nhật ký loại mục sách',
	'A1014LogBookItem':'Nhật ký sổ sách',
	'A1015LogBookItemSaved':'Mục nhật ký đã lưu',
	'A1016ErrorInSavingLogBookItem':'Lỗi khi lưu mục nhật ký',
	'A1017ErrorInRetrievingLogBookItem':'Lỗi trong khi truy xuất Sổ nhật ký',
	'A1018ErrorInDeletingLogBookItem':'Lỗi trong khi xóa mục nhật ký',
	'A1019LogBookItemDeleted':'Nhật ký mục đã xóa',
	'A101MailSalutation':'Xin chào người sử dụng',
	'A101ResetSuccessful':'undefined',
	'A1020LogBookItemIsDone':'Làm xong',
	'A1021LogBookItemType':'Loại mặt hàng',
	'A1022ServiceEmail':'Email dịch vụ',
	'A1023CannotMakeServieRequest':'Bạn không thể yêu cầu dịch vụ vì máy này không có bất kỳ email dịch vụ nào',
	'A1024MaxUsers':'Người dùng tối đa',
	'A1024MaxUsersReached':'Dã đạt đến giới hạn người dùng tối đa của công ty',
	'A1025AtLeast8Characters':'ít nhất 8 ký tự',
	'A1027AtLeast1Lowercase':'ít nhất 1 chữ cái viết thường',
	'A1028AtLeast1Number':'ít nhất 1 số (0-9)',
	'A1029AtLeast1SpecialChar':'ít nhất là một nhân vật đặc biệt (!,. [])',
	'A102CompanyAddressNewtec':'NEWTEC BAG PALLETIZING <br>32 avenue de Suisee <br>ZI Ile Napoléon - BP 256 <br>68315 Illzach cedex - France',
	'A102CompanyAddressAventus':'AVENTUS GmbH & Co. KG <br>Katzheide 33 <br>D-48231 Warendorf <br>Germany / Deutschland',
	'A102CompanyAddressNiagaraCanada':'HAVER & BOECKER Niagara Canada <br>225 Ontario St. <br>St. Catharines, ON, L2R 7B6 <br>Canada',
	'A102CompanyAddressNiagaraChile':'HAVER & BOECKER Andina <br>Av. El salto 4001, piso 7, of. 71 Comuna Huechuraba <br>8580641 – Santiago <br>Chile',
	'A102CompanyAddressNiagaraBrazil':'HAVER & BOECKER NIAGARA Latinoamericana <br>Av. Dra. Celia Marli S. Salgado Matos, 192 <br>Pedro Leopoldo - MG - 33600-000 <br>Brazil',
	'A102CompanyAddressNiagara':'HAVER & BOECKER NIAGARA <br>Robert-Bosch-Straße 6 <br>D-48153 Münster <br>Germany / Deutschland',
	'A102CompanyAddressFeige':'Feige FILLING GmbH <br> Rogen 6a <br> D-23843 Bad Oldesloe <br> Germany / Deutschland',
	'A102CompanyAddressH_B':'HAVER & BOECKER OHG <br>Carl-Haver-Platz 3 <br>D-59302 Oelde <br>Germany / Deutschland',
	'A102CompanyAddressBehnBates':'Behn Bates Maschinenfabrik GmbH & Co. KG <br> Robert-Bosch Str. 6 <br> 48153 Münster <br> Germany',
	'A102CompanyEmailAventus':'hotline@aventus.global',
	'A102CompanyEmailNiagaraCanada':'info@haverniagara.ca',
	'A102CompanyEmailNiagaraChile':'haverandina@haverniagara.cl',
	'A102CompanyEmailNiagaraBrazil':'info@haverniagara.com.br',
	'A102CompanyEmailNiagara':'info@haverniagara.com',
	'A102CompanyEmailNewtec':'service@newtecbag.com',
	'A102CompanyEmailFeige':'hotline@feige.com',
	'A102CompanyEmailH_B':'hotline@haverboecker.com',
	'A102CompanyEmailBehnBates':'service@behnbates.com',
	'A102CompanyPhoneNumberNewtec':'Phone: +33 389633753',
	'A102CompanyPhoneNumberFeige':'Phone: +49 4531 89 09 222',
	'A102CompanyPhoneNumberAventus':'Phone: +49 2581 4591 2222',
	'A102CompanyPhoneNumberNiagaraCanada':'Phone: +1 800-325-5993',
	'A102CompanyPhoneNumberNiagaraChile':'Telefon: +56 (2) 2307-0440',
	'A102CompanyPhoneNumberNiagaraBrazil':'Telefon: +55 (31) 3661-1371',
	'A102CompanyPhoneNumberNiagara':'Phone: +49 251 9793 164',
	'A102CompanyPhoneNumberH_B':'Phone: +49 2522 30 371 ',
	'A102CompanyPhoneNumberBehnBates':'Phone: +49 251 979- 0',
	'A102CompanyWebsiteNewtec':'www.newtecbag.com',
	'A102CompanyWebsiteFeige':'www.feige.com',
	'A102CompanyWebsiteAventus':'www.aventus.global',
	'A102CompanyWebsiteNiagaraCanada':'https://haverniagara.com',
	'A102CompanyWebsiteNiagaraChile':'https://haverniagara.com',
	'A102CompanyWebsiteNiagaraBrazil':'www.brazilniagara.com',
	'A102CompanyWebsiteNiagara':'www.haverniagara.com',
	'A102CompanyWebsiteH_B':'www.haverboecker.com',
	'A102CompanyWebsiteBehnBates':'www.behnbates.com',
	'A102MailContent':'<p>There occured an alert in one of your machines: <br> <br>Machine name: <b>MACHINE_NAME</b> <br>Alert message: <b>ALERT_TYPE</b> <br> Machine link:  <a href=\"MACHINE_LINK_TO_DETAIL\">MACHINE_LINK</a> <br> Alert value: ALERT_VALUE_TEXT <em>ALERT_VALUE_SUMMARY  </em><br> Alert time: ALERT_TIME_TEXT<br>ALERT_TIP</p><p style=\'font-size: 12px;\'>This e-mails was generated automatically because of a subscription at <a href=\'TYP_WEBSITE\' style=\'text-decoration: none; font-color: blue;\'>TYP_WEBSITE</a>. To unsubscribe login on the page and select <b>Settings</b> below the menu <b>Alerting</b>. <br>Please do not reply to this mail. For questions please contact <a href=\"mailto:COMPANY_EMAIL\">COMPANY_EMAIL</a>. <br> <br></p><p style=\'font-size: 12px;\'>Diese E-Mail wurde aufgrund eines Abos automatisch von <a href=\'TYP_WEBSITE\' style=\'text-decoration: none; font-color: blue;\'>TYP_WEBSITE</a> generiert. Zum Beenden des Abos loggen Sie sich auf der Seite ein und klicken unter <b>Alarmmeldungen</b> auf den Menüpunkt <b>Einstellungen</b>. <br>Bitte Antworten Sie nicht auf diese Mail. Bei Fragen können Sie sich an <a href=\"mailto:COMPANY_EMAIL\">COMPANY_EMAIL</a> wenden. <br> <br></p><p> Best regards, <br> <br>COMPANY_ADDRESS <br> <br>COMPANY_PHONE_NUMBER <br>E-Mail: <a href=\"mailto:COMPANY_EMAIL\">COMPANY_EMAIL</a> <br>Internet: <a href=\'COMPANY_WEBSITE\'>COMPANY_WEBSITE</a> <br><small style=\"color:white;\">Alert Source Id: ALERT_SOURCE_ID</small></p>',
	'A102TypeChanges':'undefined',
	'A102TypeChangesBatch':'undefined',
	'A102TYPWebsite':'https://trackyourplant.com',
	'A1030Description':'sự miêu tả',
	'A103EndOfBatch':'undefined',
	'A103ResetmailSubject':'Reset : MACHINE_NAME',
	'A104FullPalletCount':'Pallet đầy',
	'A104ResetMailContent':'<p>The occurred alert is resolved: </p><p>Machine name: <b>MACHINE_NAME</b><br>Alert message: <b>ALERT_TYPE</b><br>Machine link: MACHINE_LINK<br><p style=\'font-size: 12px;\'>This e-mails was generated automatically because of a subscription at <a href=\'TYP_WEBSITE\' style=\'text-decoration: none; font-color: blue;\'>TYP_WEBSITE</a>. To unsubscribe login on the page and select <b>Settings</b> below the menu <b>Alerting</b><br>Please do not reply to this mail. For questions please contact COMPANY_EMAIL.</p><p style=\'font-size: 12px;\'>Diese E-Mail wurde aufgrund eines Abos automatisch von <a href=\'TYP_WEBSITE\' style=\'text-decoration: none; font-color: blue;\'>TYP_WEBSITE</a> generiert. Zum Beenden des Abos loggen Sie sich auf der Seite ein und klicken unter Alarmmeldungen auf den Menüpunkt Einstellungen.<br>Bitte Antworten Sie nicht auf diese Mail. Bei Fragen können Sie sich an COMPANY_EMAIL wenden.</p><p>Best regards,<br>COMPANY_ADDRESS<br></p><p>COMPANY_PHONE_NUMBER<br>E-Mail: COMPANY_EMAIL<br>Internet: COMPANY_WEBSITE <br></p><small style=\"color:white;\">Alert Source Id: ALERT_SOURCE_ID</small>',
	'A105ConnectivityMailSubject':'Kết nối bị lỗi: Tên máy',
	'A105LoadEmptyPallets':'Cấp các pallet rỗng',
	'A106Fillings':'Cấp liệu',
	'A106PLCMailSubject':'Cảnh báo PLC: Tên máy',
	'A107FillingsPerHour':'Cấp liệu/ giờ',
	'A107PLCResolvedMailContent':'<p>The following alert was resolved  at machine MACHINE_NAME:.<br><br> ALERT_TYPE <br><br> MACHINE_LINK <br><br> This e-mails was generated automaticly because of a subscription at <a href=\'TYP_WEBSITE\' style=\'text-decoration: none; font-color: blue;\'>TYP_WEBSITE</a>. To unsubscribe login on the page and select <b>Settings</b> below the menu <b>Alerting</b>.<br><p>Please do not reply to this mail. For questions please contact COMPANY_EMAIL.</p><br><br>Best regards,<br><br>COMPANY_ADDRESS<br><br>COMPANY_PHONE_NUMBER<br>E-Mail: COMPANY_EMAIL<br>Internet: COMPANY_WEBSITE ',
	'A108Availability':'Tính có sẵn',
	'A108AvailabilityMailSubject':'Cảnh báo về tính có sẵn: Tên máy',
	'A109FillingsRemain':'Duy trì Cấp liệu ',
	'A110StartOfOrder':'Thời gian bắt đầu mẻ',
	'A111ProductID':'Mã sản xuất',
	'A112TargetWeight':'Cân nặng mục tiêu',
	'A113Kg':'Kg',
	'A114CurrentOrder':'Đặt hàng hiện tại',
	'A115FillingCount':'Mẻ thực tế',
	'A116CycleTime':'Máy- chu kỳ',
	'A117Total':'Ngày hôm nay',
	'A118CentreStation':'Trạm trung tâm',
	'A119N2Station':'Trạm N2',
	'A120FillingStation':'Bộ cấp liệu',
	'A121SealingStation':'Vùng đóng liệu',
	'A122Seconds':'Giây',
	'A123FaultLength':'Máy lỗi',
	'A124Minutes':'Phút',
	'A124Minute':'Phút',
	'A125ExtFaultLength':'Lỗi bên ngoài',
	'A126FillingCount':'Số lượng vòi cấp liệu',
	'A127WithinTolerance':'Cấp liệu trong dung sai cho phép',
	'A128OutsideTolerance':'Cấp liệu nằm ngoài dung sai cho phép',
	'A129BagManufacture':'Sản xuất đạt yêu cầu',
	'A130BagsPerHour':'Bao/giờ',
	'A131BagQuality':'Chất lượng bao',
	'A132CorrPlacedbags':'Bắn bao chính xác',
	'A133TwoAttempts':'Thử lần thứ 2 để mở',
	'A134ThreeAttempts':'Thử lần thứ 3 để mở',
	'A135DiscardeBags':'Bao bị bỏ',
	'A136Faults':'Lỗi',
	'A137CompAir':'Khí nén',
	'A138ProductFeed':'Cấp liệu cho cân',
	'A139EmptyBagMag':'Khay chứa vỏ bao',
	'A140Separation':'Tách riêng',
	'A141Vacuum':'Chân không',
	'A142FillingStation':'Bộ cấp liệu',
	'A143FullBagTransport':'vận chuyển toàn bao',
	'A144BagSealing':'Hàn miệng bao',
	'A145Disrupt':'Sự gián đoạn',
	'A145AccessDoor':'Cửa ra vào',
	'A145DSafetyLightBarrier':'Rào chắn ánh sáng an toàn',
	'A145UpstreamSupply':'Nguồn cung cấp thượng nguồn',
	'A145EmergencyStop':'Dừng khẩn cấp',
	'A146BunChangeAbs':'Số lượng tổng cộng việc thay đổi bó',
	'A147BunChangeToday':'Số lượng tổng cộng thay đổi bó ngày hôm nay',
	'A147BunChangeTodayBatch':'Số lượng tổng cộng thay đổi bó ngày',
	'A148BagsPerBundle':'Số lượng bao mỗi bó',
	'A149TypeChanges':'Kiểu thay đổi',
	'A149TypeChangesBatch':'Kiểu thay đổi hôm nay',
	'A150FillingTime':'Thời gian cấp liệu',
	'A151DosingUnit1':'Cấp liệu',
	'A152DosingUnit2':'Cấp liệu 2',
	'A153AerInletBox1':'Hộp đầu vào sục',
	'A154AerInletBox2':'Hộp đầu vào sục 2',
	'A154DosingUnit1':'Cấp liệu',
	'A155DosingUnit2':'Cấp liệu 2',
	'A155RotaryDrive':'Trục quay',
	'A156AerInletBox1':'Hộp đầu vào sục',
	'A156DischargeBelt':'Băng tải xả',
	'A157AerInletBox2':'Hộp đầu vào sục 2',
	'A157AlignmentBelt':'Băng  tải căn chỉnh bao',
	'A158BagCleaning':'Làm sạch bao',
	'A158RotaryDrive':'Trục quay',
	'A159AccelerationBelt':'Băng tải điều chỉnh độ sai lệch',
	'A159DischargeBelt':'Băng tải xả bao',
	'A160AlignmentBelt':'Băng  tải căn chỉnh bao',
	'A160CheckBelt':'Băng tải cân băng',
	'A161BagCleaning':'Làm sạch bao',
	'A161FlatBelt':'Băng tải phẳng',
	'A162AccelerationBelt':'Băng tải điều chỉnh độ sai lệch',
	'A162RejectionBelt':'Băng tải bỏ bao',
	'A163CheckBelt':'Băng tải cân băng',
	'A163RejectionDrive':'Cơ cấu động cơ  pa lăng bỏ ',
	'A164FlatBelt':'Băng tải phẳng',
	'A164RotaryCutter':'Bộ phá bao',
	'A165RejectionBelt':'Băng tải bỏ bao',
	'A165ScreenDrum':'Màng của bộ tang trống',
	'A166RejectionDrive':'Cơ cấu động cơ  pa lăng bỏ ',
	'A166Welding':'Thiết bị hàn',
	'A167Cutter':'Bộ cắt',
	'A167RotaryCutter':'Bộ phá bao',
	'A168ScreenDrum':'Màng của bộ tang trống',
	'A168SuctionCup':'Bộ hút bao',
	'A169Brakes':'Bộ phanh',
	'A170VacuumPump':'Bơm chân không',
	'A171Filter':'Trích xuất bộ lọc',
	'A172BagDisConveyor':'Chuyển đổi cấp phối bao',
	'A173BagTurnConveyor':'Bộ vận chuyển đổi bao',
	'A174BagTurnCross':'Bộ vận chuyển  xoay bao',
	'A175BagDisConveyor':'Chuyển đổi cấp phối bao',
	'A175BagTurnClamp':'Kẹp chuyển bao',
	'A176BagTurnConveyor':'Bộ vận chuyển đổi bao',
	'A176GroupingBelt':'Băng tải gộp',
	'A177BagTurnCross':'Bộ vận chuyển  xoay bao',
	'A177RowPusher':'Bộ dẩy bao theo hàng',
	'A178DoubleStripPlate':'Mũi cắt kép',
	'A179ConformingPlate':'Tấm ép khuôn',
	'A179GroupingBelt':'Băng tải gộp',
	'A180GantrySafety':'An toàn cho khung cẩu',
	'A180RowPusher':'Bộ đẩy hàng',
	'A181DoubleStripPlate':'Mũi cắt kép',
	'A181Gantry':'Khung cẩu',
	'A182ConformingPlate':'Tâm ép khuôn',
	'A182PalletAlignment':'Căn chỉnh pallet rỗng',
	'A183GantrySafety':'An toàn cho khung cẩu',
	'A183PalletRoller1':'Bộ vận chuyển- con lăn pallet',
	'A184Gantry':'Khung cẩu',
	'A184PalletDispenser':'Cơ cấu đóng pallet',
	'A185PalletAlignment':'Căn chỉnh pallet rỗng',
	'A185PalletRoller2':'Con lăn Pallet- Vận chuyển',
	'A186PalletRoller1':'Con lăn Pallet- Vận chuyển',
	'A186PalletRoller3':'Con lăn Pallet- Vận chuyển',
	'A187CyclesChain':'Chuỗi chu kỳ',
	'A187PalletDispenser':'Bộ xả Pallet',
	'A188BundlePusher':'Bộ đẩy bó xy lanh',
	'A188PalletRoller2':'Con lăn Pallet- Vận chuyển',
	'A189BundlePartition':'xy lanh- vùng xếp bao thành bó',
	'A189PalletRoller3':'Con lăn Pallet- Vận chuyển',
	'A190BagLifter':'Xy lanh- bộ nâng bao',
	'A190CyclesChain':'chu kỳ chuỗi',
	'A191BundlePusher':'Xy lanh - cơ cấu đẩy bỏ',
	'A191PressRolls':'Xy lanh- con lăn ép',
	'A192BundlePartition':'Phân vùng bó xy lanh',
	'A192OpeningStation':'Trạm mở xy lanh',
	'A193BagApplicator':'Xy lanh- cơ cấu dùng bao',
	'A193BagLifter':'Bộ nâng bao xy lanh',
	'A194BagSupport':'Hỗ trợ bao xy lanh',
	'A194PressRolls':'Xy lanh- con lăn ép',
	'A195OpeningStation':'Trạm mở xy lanh',
	'A195Pusher':'Bộ đẩy xy lanh',
	'A196BagApplicator':'Xy lanh- cơ cấu bao',
	'A196StretchPliers':'Xy lanh- cơ cấu kéo căng',
	'A197BagSupport':'Xy lanh - cơ cấu hỗ trợ bao',
	'A197FillingStation':'Xy lanh- bộ cấp liệu',
	'A198FilledBagGripper':'Xy lanh- cơ cấu kẹp bao đã đóng',
	'A198Pusher':'Xy lanh- cơ cấu đẩy',
	'A199StretchPliers':'Xy lanh- cơ cấu kìm kéo căng',
	'A199SucBagSeperation':'Bộ hút bao- cơ cấu phân tách bao',
	'A200FillingStation':'Bộ cấp liệu xy lanh',
	'A200SucOpenStation':'Xy lanh- bộ cấp liệu',
	'A201FilledBagGripper':'Xy lanh-  cơ cấu kẹp bao ',
	'A201Remaininghours':'Giờ duy trì',
	'A202PriceTotal':'Tổng các miếng',
	'A202SucBagSeperation':'bộ hút bao- cơ cấu phân tách bao',
	'A203RemainingNumberFillings':'Duy trì cấp liệu',
	'A203SucOpenStation':'Bộ kẹp bao- cơ cấu mở bao',
	'A204SafetyDoor':'Cửa an toàn',
	'A205SafetyLightBarrier':'Đèn an toàn',
	'A206BagTransport':'Vận chuyển bao',
	'A207EmptyPalletTrans':'Vận chuyển Pallet rỗng',
	'A208Palletizer':'Máy đóng Pallet',
	'A209FullPalletTrans':'Vận chuyển pallet đầy',
	'A210NumberTodayPiece':'Số lượng hôm nay/ miếng',
	'A211Programme':'Chương trình',
	'A212Efficiency':'Hiệu quả',
	'A213Loginsuccessful':'Đăng nhập thành công',
	'A214CurrentPerformance':'Hiệu suất hiện tại',
	'A215CyclesToday':'Tổng cộng các chu kỳ hôm nay',
	'A215CyclesTodayBatch':'Tổng cộng các chu kỳ',
	'A216RejectsToday':'các bao bỏ hôm nay',
	'A216RejectsTodayBatch':'các bao bỏ',
	'A217MetalDetectorToday':'Bộ dò kim loại hôm nay',
	'A217MetalDetectorTodayBatch':'Bộ dò kim loại',
	'A218BrokenBagsToday':'Việc dò phát hiện bao hỏng hôm nay',
	'A218BrokenBagsTodayBatch':'Việc dò phát hiện bao hỏng ',
	'A219FaultPerShift':'Ca hiện tại',
	'A220FaultBagManufacture':'Sản xuất bao',
	'A221FaultBagFilling':'Cấp liệu vào bao',
	'A222FaultBagTransport':'Vận chuyển bao',
	'A223FaultSealing':'Hàn miệng bao',
	'A224FaultWeigher':'Bộ cấp liệu',
	'A225FaultPeriphery':'Chế độ tuần tự khác',
	'A226Shift':'Ca ',
	'A227Container':'Container',
	'A228Hours':'Giờ',
	'A229NoPackage':'Không có container',
	'A230NoProduct':'Không có sản phẩm',
	'A231NoOutlet':'Không có xả',
	'A232GoodPerformance':'Sản xuất',
	'A233GoodWeights':'Sản xuất tốt',
	'A234NotPlacedBags':'Không có bắn bao',
	'A234NotPlacedBagsAutomatic':'Không có bắn bao tự động',
	'A235CollectiveFaultsToday':'Tập hợp các lỗi ngày hôm nay',
	'A235CollectiveFaultsTodayBatch':'Tập hợp các lỗi ngày',
	'A236Applicator':'Ứng dụng',
	'A237NetWeigher':'Cân  năng không tính vỏ bao( net)',
	'A238IncorrectWeight':'Cân năng không chính xác',
	'A239Hours':'Giờ',
	'A240QrCode':'Mã QR',
	'A241OpenBags':'Bao khác',
	'A242FaultCheckWeigher':'Bộ kiểm tra cân nặng',
	'A243DashboardAllMachines':'Bảng đánh gia/Tất cả các máy',
	'A244DashboardState':'Trạng thái',
	'A245DashboardMachineStateRunning':'Bật',
	'A246DashboardMachineStateOffline':'Tắt',
	'A247DashboardMachineStateMaintenance':'Bảo dưỡng cần thiết',
	'A248DashboardMachineStateError':'Thất bại',
	'A249SortChangeToday':'Thay đổi loại hôm nay',
	'A249SortChangeTodayBatch':'Thay đổi loại',
	'A250EmptyBagMagazineToday':'Cấp lại vỏ bao ngày hôm nay',
	'A250EmptyBagMagazineTodayBatch':'Cấp lại vỏ bao ngày',
	'A251FaultCheckWeigher':'Lỗi ngày hôm nay',
	'A252IVTSTOP':'INTEGRA®',
	'A253N/A':'Không Có',
	'A254CyclePumpFS1':'Chu kỳ bơm  FS 1',
	'A255CyclePumpFS2':'Chu kỳ bơm  FS 2',
	'A256CyclePumpFS3':'Chu kỳ bơm  FS 3',
	'A257ConHoseFS1':'Ống nối FS 1',
	'A258ConHoseFS2':'Ống nối FS 2',
	'A259ConHoseFS3':'Ống nối FS 3',
	'A260InfSleeveFS1':'Ống lót thổi phồng FS 1',
	'A261InfSleeveFS2':'Ống lót thổi phồng FS 2',
	'A262InfSleeveFS3':'Ống lót thổi phồng FS 2',
	'A263BundlePusher':'Bộ đẩy bó bao',
	'A264VacuumUnit':'Cơ cấu tạo Chân không',
	'A265TravellingDrive':'Động cơ tịnh tiến',
	'A266CenteringUnit':'Đơn vị trung tâm',
	'A267EmptyBagMagazine':'Khay đựng vỏ bao',
	'A268BeltPlant':'Băng tải',
	'A269IvtTurbine':'Turbin cấp liệu',
	'A270IvtTripSaddle':'Bộ tay gạt hình yên ngựa',
	'A271IvtCoarseFeed':'Cân cấp liệu thô',
	'A272IvtBagHolder':'Bộ giữ bao',
	'A273IvtFillBoxAir':'Bộ sục cho cơ cấp cấp liệu ',
	'A274IvtFillChanAir':'Đường sục cho phần cấp liệu',
	'A275IvtInflSleeve':'Ống lót thổi phồng',
	'A276IvtInletBoxAir':'Hộp  chứa đầu vào khí sục',
	'A277IvtFillTubeBlow':'Ống cấp liệu được thổi',
	'A278IvtOutlChanAir':'Đường ra hệ thống sục',
	'A279IvtPusher':'Bộ đẩy',
	'A280IvtSaddleHeight':'Điều chỉnh chiều bao giá đỡ hình yên ngựa',
	'A281IvtCloseAnvil':'Đóng cữ chặn',
	'A282IvtBagSeal':'Hàn miệng bao',
	'A283IvtFineFeedAdj':'Điều chỉnh chế độ cấp liệu tinh',
	'A284Interrupts':'Các gián đoạn dừng hoạt động hôm nay',
	'A284InterruptsGeneralPage':'Các gián đoạn dừng hoạt động hôm nay',
	'A284InterruptsBatch':'Các gián đoạn dừng hoạt động',
	'A285BagCounterShift':'Đếm bao theo ca sản xuất',
	'A286WrongBags':'Việc dùng bao không đúng',
	'A287ReelChanges':'Thay đổi tang cuốn ngày hôm nay',
	'A287ReelChangesBatch':'Thay đổi tang cuốn ngày',
	'A288VibrationDuration':'Thời gian rung mở rộng',
	'A289Total':'Tổng cộng',
	'A290HopperMin':'Bởi két trung gian tối thiểu',
	'A291ManualActivation':'Kích hoạt chế độ bằng tay',
	'A292FillingRelease':'Thoát khỏi việc cấp liệu',
	'A293EGF':'EGF®',
	'A294PalletTrasport':'Vận chuyển pallet',
	'A295SafetyCircuit':'Mạch bảo vệ',
	'A296FltCompAir':'Lỗi liên quan đến khí nén ngày hôm nay',
	'A296FltCompAirBatch':'Lỗi liên quan đến khí nén ngày',
	'A297StrokesPump':'Chu kỳ bơm ngày hôm nay',
	'A297StrokesPumpBatch':'Chu kỳ bơm ngày',
	'A298MaxStrokeDur':'Thời gian cho chu kỳ bơm tối đa',
	'A298MaxStrokeDurBatch':'Thời gian cho chu kỳ',
	'A299AvgStrokes':'Chu kỳ trung bình theo bao ngày hôm nay',
	'A299AvgStrokesBatch':'Chu kỳ trung bình theo bao ngày',
	'A300InternalPressure':'Áp suất điểm đến',
	'A301Max':'Tối đa',
	'A301MessageAdministration':'Quản trị thông báo',
	'A302ErrorNumber':'Số lượng lỗi',
	'A302Millibar':'Mbar',
	'A303ActivateAlertMail':'Kích hoạt email cảnh báo',
	'A303SetValueMax':'Thiết lập giá trị tối đa',
	'A304AlertText':'Dòng chữ cảnh báo',
	'A304SetValueMin':'Thiết lập giá trị thối thiểu',
	'A305ActivateResolvedMail':'Kích hoạt email xử lý vấn đề',
	'A305SetValueChanged':'Thay đổi của bạn đã được lưu lại',
	'A305SetValueChangedRefreshWindow':'Các thay đổi của bạn đã được lưu lại. Những thay đổi sẽ hiển thị sau khi làm mới cửa sổ trình duyệt.',
	'A306KW':'kW',
	'A306Resolved Text':'Dòng chữ miêu tả phương án xử lý vấn đề',
	'A306ResolvedText':'Dòng chữ miêu tả phương án xử lý vấn đề',
	'A307AddMessage':'Thêm bản thông báo',
	'A307KVA':'kVA',
	'A308EditMessage':'chỉnh sửa thông báo',
	'A308Nm3PerH':'Nm³/h',
	'A309KWH':'kWh',
	'A310NM3':'Nm³',
	'A311CompAirVol':'thể tích khí nén',
	'A312ActiveEnergy':'Năng lượng hữu ích',
	'A313ReactivePower':'Năng ',
	'A314ChartConfiguration':'Cấu hình thẻ nhớ',
	'A315SetDateValueMax':'Ngày thiết lập cao nhất',
	'A316SetDateValueMin':'Ngày thiết lập thấp nhất',
	'A317InvalidData':'Dữ liệu không đúng',
	'A318DeleteUser':'Bạn có muốn xóa tài khoản này không',
	'A319EnterAllFields':'Vui lòng điền tất cả các dữ liệu đầy đủ',
	'A320EnterValidEmail':'Vui lòng nhập địa chỉ email đúng',
	'A321DeleteCompany':'Bạn có muốn  xóa công ty này không?',
	'A322CouldNotDeleteUser':'undefined',
	'A323LoginSuccess':'Đăng nhập thành công!',
	'A324Selected':'Lựa chọn',
	'A325Total':'Tổng cộng',
	'A326Reset':'Reset',
	'A327Reporting':'Làm báo báo',
	'A328ReportingActive':'Kích hoạt',
	'A329ReportingActiveHelpPrefix':'Kích hoạt sẽ gửi báo cáo thường xuyên tới',
	'A330ReportingActiveHelpSuffix':'.',
	'A331ReportingFrequency':'Tần số',
	'A332ReportingFrequencyDaily':'Hàng ngày',
	'A333ReportingFrequencyWeekly':'Hàng tuần',
	'A334ReportingFrequencyMonthly':'Hàng tháng',
	'A335ReportingDynContent':'Nội dung động',
	'A336ReportingDynContIncludeElapsedMI':'bao gồm thời gian bảo trì đã qua',
	'A337ReportingLanguage':'Ngôn ngữ',
	'A338ReportingProdDayEnd':'Kết thúc ngày sản xuất',
	'A339ReportingFirstReport':'Ngày có báo cáo đầu tiên',
	'A340CardCtxMenuReport':'Bao gồm trong báo cáo',
	'A341CardCtxMenuHistoReport':'Bao gồm biểu đồ trong báo cáo',
	'A342CardCtxMenuReset':'Reset dữ liệu metric',
	'A343CardCtxMenuSettings':'Thiết lập',
	'A344CardLabelReport':'Báo cáo',
	'A345CardLabelHistoReport':'Báo cáo dạng biểu đồ',
	'A346CardLabelMaintenReport':'Báo cáo bảo trì',
	'A347ReportSettings':'Thiết lập',
	'A348Alerting':'Cảnh báo',
	'A349AlertingSettings':'Thiết lập',
	'A350AlertingActive':'Kích hoạt',
	'A351AlertingActiveHelpPrefix':'Kích hoạt sẽ gửi email cảnh báo tới',
	'A352AlertingActiveHelpSuffix':'Cho bất kỳ kiểu cảnh báo nào ở dưới',
	'A353AlertingTypes':'Thông báo về',
	'A354AlertTypeMainten':'Thời gian bảo dưỡng trôi qua',
	'A355AlertTypeAvail':'Tính sẵn có',
	'A356AlertTypePLC':'Kích hoạt PLC',
	'A357AlertTypeConError':'Lỗi kết nối',
	'A358DeleteAdminUser':'Tài khoản quản trị không thể bị xóa ',
	'A359MaintAids':'Gói bảo trì',
	'A360MaintInt':'Thời gian bảo trì',
	'A361ChooseMaschine':'Chọn máy',
	'A362Video':'Video',
	'A363InstructionManual':'Hướng dẫn sử dụng',
	'A364ExplodedviewDrawing':'Bản vẽ mở',
	'A364SparePartCatalog':'Catalog vật tư dự phòng',
	'A365OpenVideoHelp':'Mở video trợ giúp',
	'A366OpenInstructionManual':'Mở phần hướng dẫn ',
	'A367OpenExploadedView':'Mở chế độ xem mở rộng',
	'A368ResetMaschineData':'Reset dữ liệu máy',
	'A369BotWeld':'Hàn vết đáy',
	'A370BotWeldTef':'Hàn vết teflon phần đáy',
	'A371TopWeld':'Hàn vết đỉnh',
	'A372TopWeldTef':'hàn vết teflon',
	'A373LeftWeld':'Hàn góc trái',
	'A374LefWeldTef':'Hàn góc teflon trái',
	'A375RigWeld':'Hệ thống hàn góc teflon phải',
	'A376RigWeldTef':'Hàn góc teflon phải',
	'A377CutKnife':'Dao cắt',
	'A378ReallyClearReport':'Hành động này sẽ bỏ tất cả mục này khỏi bản báo cáo và không thể hoàn tác được.Bạn có muốn loại bỏ tất cả các mục này khỏi báo cáo',
	'A379ProdCurrShift':'Ca sản xuất hiện tại',
	'A380ReallyDeleteItemReport':'Hành động này sẽ bỏ mục này khỏi bản báo cáo và không thể hoàn tác được. Bạn có muốn loại bỏ mục này khỏi báo cáo',
	'A380TypeCurrent':'Kiểu hiện tại',
	'A381SampleBags':'Bảo mẫu bị bỏ',
	'A382OpenBags':'Bao mở bị bỏ',
	'A383IncorrectBags':'Bao cân nặng  không chính xác bị bỏ',
	'A384NotPlacedBagsMan':'chế độ điều khiển bằng tay Không bắn bao',
	'A385Spout1':'Bảo dưỡng cấp liệu vòi 1',
	'A386LastUpdate':'Lần cập nhật cuối',
	'A386Spout2':'Bảo dưỡng cấp liệu vòi 2',
	'A387Spout3':'Bảo dưỡng cấp liệu vòi 3',
	'A388Spout4':'Bảo dưỡng cấp liệu vòi 4',
	'A389LastUpdate':'Lần cập nhật cuối',
	'A390FilledBagTrans':'vận chuyển bao đã đóng',
	'A391ConveyerSystem':'Hệ thống vận chuyển',
	'A392BeltBundleTrans':'Băng tải- vận chuyển bó',
	'A393BagHolderBuffer':'Cái đệm giữ bao',
	'A394SlidePlates':'Tấm trượt',
	'A395ImpellerShaft':'Cơ cấu trục quay cấp liệu',
	'A396ShaftAssembly':'Cơ cấu trục thấp',
	'A397ThreePosCylinder':'xy lanh 3 vị trí',
	'A398DateFormatNoSeconds':'[ngày] DD [Tháng] MM [Năm] YYYY HH:MM ',
	'A399DateFormat':'[ngày] DD [Tháng] MM [Năm] YYYY HH:MM:SS ',
	'A399DateFormatAngularPipe':'[ngày] dd [Tháng] MM [Năm] yyyy HH:MM:SS ',
	'A400ConveyorSystem':'Hệ thống băng tải',
	'A400History':'Lịch sử',
	'A401ReportHistory':'Lịch sử báo cáo',
	'A402ReportId':'Báo cáo ID',
	'A403ReportGeneratedTime':'Thời gian tạo báo cáo',
	'A404DosingFeeder':'Bộ cân cấp liệu',
	'A404ReportGeneratedBy':'Báo cáo được tạo ra bởi',
	'A405DownloadReport':'Báo cáo tải về',
	'A406TriggeredHistoryReport':'Việc tạo báo cáo đã được bắt đầu',
	'A407ViewReport':'Xem báo cáo',
	'A408BagHolder':'Bộ giữ bao',
	'A408BagHolder ':'Bộ giữ bao',
	'A414BundleTransport':'Vận chuyển bó',
	'A415HoistingDevice':'Động cơ pa lăng',
	'A416PlacerArm':'Cánh tay xếp đặt bao',
	'A417CrossProcess':'Xử lý chéo',
	'A418OpeningStation':'Cơ cấu mở',
	'A421Claw':'Vấu',
	'A422WeightData':'Dữ liệu cân năng Ø',
	'A423StdDevData':'Dữ lliệu lệch tiêu chuẩn',
	'A430kg':'kg',
	'A431lb':'lb',
	'A432t':'t',
	'A433klb':'klb',
	'A434g':'g',
	'A435BagManufacture':'Sản xuất vỏ bao',
	'A436Aux_Pump':'Bơm chân không',
	'A437TopWelding':'Hàn đường nối trên đỉnh đầu bao',
	'A438PunchingDeviceWelding':'Đột lỗ xử lý hàn',
	'A439Aux_Vibrator1':'Bộ rung 1',
	'A440Aux_Vibrator2':'Bộ rung 2',
	'A441Aux_Vibrator3':'Bộ rung 3',
	'A442Truck_Loading':'Hệ thống xuất lên xe tải',
	'A443Truck_Available':'Không có xe tải ngày hôm nay',
	'A444FS1':'Bộ cấp liệu 1',
	'A445FS2':'Bộ cấp liệu 2',
	'A446FS3':'Bộ cấp liệu 3',
	'A447FS4':'Bộ cấp liệu 4',
	'A448FS5':'Bộ cấp liệu 5',
	'A449FS6':'Bộ cấp liệu 6',
	'A450FS7':'Bộ cấp liệu 7',
	'A451FS8':'Bộ cấp liệu 8',
	'A452FS9':'Bộ cấp liệu 9',
	'A453FS10':'Bộ cấp liệu 10',
	'A454FS11':'Bộ cấp liệu 11',
	'A455FS12':'Bộ cấp liệu 12',
	'A456FS13':'Bộ cấp liệu 13',
	'A457FS14':'Bộ cấp liệu 14',
	'A458FS15':'Bộ cấp liệu 15',
	'A459FS16':'Bộ cấp liệu 16',
	'A460OeeValue':'undefined',
	'A460Oee':'OEE',
	'A460PushAlert':'<h1>Alert: ALERT_TYPE</h1><p>Machine name: <b>MACHINE_NAME</b> <br>Alert message: <b>ALERT_TYPE</b>',
	'A461OeeAvailable':'undefined',
	'A461PushAlertResolved':'<h1>Resolved: ALERT_TYPE</h1><p>Machine name: <b>MACHINE_NAME</b> <br>Alert message: <b>ALERT_TYPE</b>',
	'A462OeePerformance':'undefined',
	'A463DowntimePlaned':'undefined',
	'A464DowntimeMachine':'undefined',
	'A465DowntimeOther':'undefined',
	'A466OperatingFactor':'undefined',
	'A467Throughput':'undefined',
	'A468OeeRejects':'undefined',
	'A469OeeQuality':'undefined',
	'A470OeeDowntime':'undefined',
	'A471RejectsTotal':'undefined',
	'A472RejectsIncorrect':'undefined',
	'A473RejectsOpen':'undefined',
	'A474RejectsSample':'undefined',
	'A475RejectsMetal':'undefined',
	'A476ConfigTable':'undefined',
	'A477OEERowActive':'undefined',
	'A478OEEProduction':'undefined',
	'A479OEEBreak':'undefined',
	'A480OEEMaint':'undefined',
	'A481OEETypeChg':'undefined',
	'A482OEEOthers':'undefined',
	'A483OEEFrequ':'undefined',
	'A484OEEUnique':'undefined',
	'A485OEEDaily':'undefined',
	'A486OEEWeekly':'undefined',
	'A487Days':'undefined',
	'A488Monday':'undefined',
	'A489Tuesday':'undefined',
	'A490Wednesday':'undefined',
	'A491Thursday':'undefined',
	'A492Friday':'undefined',
	'A494Saturday':'undefined',
	'A498Comment':'undefined',
	'A495Sunday':'undefined',
	'A496From':'undefined',
	'A497To':'undefined',
	'A499TabMachineMessages':'undefined',
	'A500MMLive':'undefined',
	'A500VbeltDrive':'undefined',
	'A501MMStatistic':'undefined',
	'A501VbeltTurbine':'undefined',
	'A502Impeller':'undefined',
	'A502MMLive':'undefined',
	'A503FillingTube':'undefined',
	'A503MMStatistic':'undefined',
	'A504BagGuide':'undefined',
	'A504Type':'undefined',
	'A505Control':'undefined',
	'A505Saddle':'undefined',
	'A506BoltBagChair':'undefined',
	'A506Number':'undefined',
	'A507BushBagChair':'undefined',
	'A507Message':'undefined',
	'A508Amount':'undefined',
	'A508BearingPusher':'undefined',
	'A509Duration':'undefined',
	'A509RearSlidePlate':'undefined',
	'A510SlideValve':'undefined',
	'A510TonsPerHour':'t/h',
	'A511FrontSlidePlate':'undefined',
	'A511Tons':'undefined',
	'A512RepairKitSlidePlates':'undefined',
	'A512RPM':'undefined',
	'A513BagChairChange':'undefined',
	'A513BagTransBeltServo':'undefined',
	'A514InfeedBelt':'undefined',
	'A515FlattBelt':'undefined',
	'A516BagTakingBelt':'undefined',
	'A517RollerConveyor':'undefined',
	'A518LiftingGantry':'undefined',
	'A519LinearDrive':'undefined',
	'A520SwivelJoint':'undefined',
	'A521LiftingGantry':'undefined',
	'A522FullPallets':'undefined',
	'A523EvacConveyor':'undefined',
	'A524SafetyBarrier':'undefined',
	'A525BasicCleaning':'undefined',
	'A526SafetyDevices':'undefined',
	'A530CylSucBar':'undefined',
	'A531FilledBagGripper1':'undefined',
	'A532FilledBagGripper2':'undefined',
	'A533CylCutKnife':'undefined',
	'A534CylBotWelding':'undefined',
	'A535SpoutLift':'undefined',
	'A536CylClampTopWeld':'undefined',
	'A537CylTopWeld':'undefined',
	'A538CylCornerWeld':'undefined',
	'A539AdjHeightDischargebelt':'undefined',
	'A540EmptyBagTransport':'undefined',
	'A541Virbator':'undefined',
	'A542CylCoolPlatesCornerWeld':'undefined',
	'A543CylBagGuide':'undefined',
	'A544CylClampBotWeld':'undefined',
	'A545CylCoolBotWeld':'undefined',
	'A546CylFilledBagGripper3':'undefined',
	'A547CylStatBagGripper':'undefined',
	'A548CylEmptyBagGripper1':'undefined',
	'A549CylStretching':'undefined',
	'A550CylOpenSpout':'undefined',
	'A551CylBagAplicatorLift':'undefined',
	'A552CylStretcherOpen':'undefined',
	'A553CylStretcherInwards':'undefined',
	'A554FoilTrackingDevice':'undefined',
	'A555DischargeBelt2':'undefined',
	'A556CylStretching':'undefined',
	'A557CylEmptyBagGripper2':'undefined',
	'A558UnwindingDevice':'undefined',
	'A559BagLenghtAdj':'undefined',
	'A560CylVibratorLift':'undefined',
	'A561CylFilmGuide':'undefined',
	'A562CylPushFlaps':'undefined',
	'A563CylWeldReelChanger':'undefined',
	'A564CylWeldReelChangerTef':'undefined',
	'A565DriveReelChanger':'undefined',
	'A566DriveAdjCornerWeld':'undefined',
	'A567CylThreadingInwards':'undefined',
	'A568CylThreadingOutwards':'undefined',
	'A569CylThreadingDownwards':'undefined',
	'A570CylSucRailClose':'undefined',
	'A571DischargeBelt2':'undefined',
	'A572CylSpoutOpen':'undefined',
	'A573Drives':'undefined',
	'A574Cylinders':'undefined',
	'A575LowAttrition':'undefined',
	'A576HighAttrition':'undefined',
	'A577SetUpTimeAvBatch':'undefined',
	'A578SetUpTimeAv':'undefined',
	'A579GrossTimeBatch':'undefined',
	'A580GrossTime':'undefined',
	'A581SetUpTime':'undefined',
	'A582Downtime':'undefined',
	'A583MonthBestPerf':'undefined',
	'A584PressureChamperSeal':'undefined',
	'A585Aeration':'undefined',
	'A586SpillageRejector':'undefined',
	'A587VibrationBottle':'undefined',
	'A588VibrationTable':'undefined',
	'A589BagPlacing':'undefined',
	'A590RotaryFeeder':'undefined',
	'A591TopSeamCleaning':'Làm sạch đường may hàng đầu',
	'A592Beltplant1':'Băng tải 1',
	'A593Beltplant2':'Băng tải 2',
	'A594HydraulikPump':'Bơm thủy lực',
	'A595DriveEBTS':'undefined',
	'A596HostingEBTS':'undefined',
	'A597Ventilator':'undefined',
	'A598RD':'undefined',
	'A599SpoutCoarse':'undefined',
	'A600RotaryGateCoarse':'undefined',
	'A601HostingVIC':'undefined',
	'A602SpoutFine':'undefined',
	'A603RotaryGateFine':'undefined',
	'A604HostingVSC1':'undefined',
	'A605HostingVSC2':'undefined',
	'A606FBTransfer':'undefined',
	'A607FBTHost':'undefined',
	'A608FBTswivel':'undefined',
	'A609FBTClamp':'undefined',
	'A610FBTransport':'undefined',
	'A611FBTransportClamp':'undefined',
	'A612HostBFS':'undefined',
	'A613SwivelBFS':'undefined',
	'A614SideGussetblade':'undefined',
	'A615HostTopwelding':'undefined',
	'A616CloseTopWelding':'undefined',
	'A617Folding1':'undefined',
	'A618Folding2':'undefined',
	'A619Folding3 ':'undefined',
	'A620PUD':'undefined',
	'A621PUDWeld':'undefined',
	'A622BeltReject':'undefined',
	'A623RejectingDevice':'undefined',
	'A624BagLenght':'undefined',
	'A624PerfToday':'Performance Today',
	'A625PerfTodayBatch':'Performance',
	'A626Output':'Output Today',
	'A627OutputBatch':'Output',
	'A628UnitperH':'S/h',
	'A629Percent':'%',
	'A630BagJunction':'Tấm nối túi',
	'A631ToothedBelt':'Đai răng căng',
	'A632LevellingFoot':'Kiểm tra chân san bằng RAD',
	'A633PolymerGuide':'Hướng dẫn polyme + con lăn',
	'A634BallBushing':'Bộ ghép ống lót bóng',
	'A635ScissorHoseReplace':'Ống cắt',
	'A636ConnectingHoseReplace':'Ống nối',
	'A637Bellow':'Cáp Điều chỉnh chiều cao ghế đỡ',
	'A638TipperFrame':'Kiểm tra khung tipper',
	'A639HosePressureChamber':'Kiểm tra buồng áp suất ống',
	'A640FlapFillingBox':'Kiểm tra hộp nạp nắp',
	'A641RotoLock':'ROTO-LOCK®',
	'A642Hours':'Giờ',
	'A643Temp':'°C',
	'A644Millisecond':'ms',
	'A645KgPerSecond':'kg/s',
	'A646RoundsperMinute':'rpm',
	'A647Meter':'m',
	'A648Millimeter':'mm',
	'A649Cubicmeter':'m³',
	'A650Liter':'l',
	'A651Counts':'pcs',
	'A652WeldingTemperature':'Nhiệt độ hàn',
	'A653WeldingDuration':'Thời gian hàn',
	'A654FillingSystem':'Hệ thống chiết rót',
	'A655EmailSignature':'Chữ ký email',
	'A656NumberWasteBags':'Số lượng túi chất thải',
	'A657FreqConverter':'Bộ chuyển đổi tần số',
	'A658ServoDrives':'Ổ đĩa servo',
	'A656Voltage':'Vôn',
	'A657Volt':'V',
	'A658U1':'U1',
	'A659U2':'U2',
	'A660U3':'U3',
	'A661U12':'U12',
	'A662U23':'U23',
	'A663U31':'U31',
	'A664Current':' ',
	'A665Amps':'A',
	'A666I1':'I1',
	'A667I2':'I2',
	'A668I3':'I3',
	'A669Frequency':' ',
	'A670Hertz':'Hz',
	'A671Pressure':' ',
	'A672Bar':'bar',
	'A673Moisture':' ',
	'A674AirFlowUnit':'l/s',
	'A675AnnualConsum':' ',
	'A676MonthlyConsum':' ',
	'A677FreeConsum':' ',
	'A678PowerEfficiency':' ',
	'A679BagsPerKWH':' ',
	'A681SinceReset':' ',
	'A682LastTimeSortUse':' ',
	'A683SortNumber':' ',
	'A684Overall':' ',
	'A685AirEfficiency':' ',
	'A686BagsPerAirFlowUnit':' ',
	'A687Jan':' ',
	'A688Feb':' ',
	'A689Mar':' ',
	'A690Apr':' ',
	'A691Mai':' ',
	'A692Jun':' ',
	'A693Jul':' ',
	'A694Aug':' ',
	'A695Sep':' ',
	'A696Oct':' ',
	'A697Nov':' ',
	'A698Dec':' ',
	'A699Year':' ',
	'A700EnterDateRange':' ',
	'A701PowerConsumption':' ',
	'A702AirConsumption':' ',
	'A703FlowControlGate':'Cổng kiểm soát dòng chảy',
	'A704BagDirectionConv':'Băng tải hướng túi',
	'A705BottomBearing':'Vòng bi dưới cùng',
	'A706PressureSwitch':'Công tắc áp suất',
	'A707ElecModules':'Mô-đun điện tử',
	'A708SpoutWeight':'Quy mô thành phần',
	'A709Search':'Tìm kiếm',
	'A710Overweight':'Túi thừa',
	'A711Underweight':'Túi nhẹ',
	'A712RotaryDriveFreqConLoad':'Truyền động quay biến tần',
	'A713MeanValueLoadInPercent':'Giá trị trung bình/Tải theo phần trăm',
	'A714EmptyBagDischarge':'Xả túi rỗng',
	'A715Bellow1':'con dấu ống thổi',
	'A716TotalCounter':'Tổng số lượt truy cập',
	'A717Spout0Counter':'Trạm nạp 0',
	'A718LSdirtyCounter':'Hàng rào ánh sáng CW bẩn',
	'A719Peripherie':'undefined',
	'A720Robot':'undefined',
	'A721DepositBelt':'undefined',
	'A722RollerConveyor1':'undefined',
	'A723PalletCentering1':'undefined',
	'A724RollerConveyor2':'undefined',
	'A725PalletCentering2':'undefined',
	'A726GripperClamp':'undefined',
	'A727GripperMotor':'undefined',
	'A728AdjustmentCamera':'undefined',
	'A729BagSize':'undefined',
	'A730PalletSupply':'undefined',
	'A731Length':'undefined',
	'A732Width':'undefined',
	'A733DepalPallets':'undefined',
	'A734DepalBundles':'undefined',
	'A735RejectedBundles':'undefined',
	'A736BagsPerBundle':'undefined',
	'A737CountsOfDataPoints':'undefined',
	'A738EmptyKey':' ',
	'A739TrendMeanWeight':'Dữ liệu cân năng Ø xu hướng',
	'A740TrendSD':'Dữ lliệu lệch tiêu chuẩn xu hướng',
	'A741PistonBlower1':'Bộ thổi Pisten 1',
	'A742PistonBlower2':'Bộ thổi Pisten 2',
	'A743RotaryFlap':'undefined',
	'A744CurrentLayer':'undefined',
	'A745CurrentBag':'undefined',
	'A746Days':'undefined',
	'A747LayerNo':'undefined',
	'A748BagNo':'undefined',
	'A749AnnualMaint':'undefined',
	'A901MachineCustomerOverview':'Máy/  tổng quan khách hàng',
	'A902Machines':'Máy',
	'A903Permissions':'Cho phép',
	'A904CompanyName':'Tên công ty',
	'A905MachineName':'Tên công ty',
	'A906MachineType':'Loại máy',
	'A907Order':'Đặt hàng',
	'A908Action':'Hành động',
	'A909Remove':'Bỏ đi ',
	'A910ChooseCustomer':'Chọn khách hàng',
	'A911ConnectMachines':'Kết nối các máy',
	'A912User':'Người sử dụng',
	'A913FirstName':'Tên đầu',
	'A914Surname':'Tên phụ',
	'A915Login':'Login',
	'A916Roles':'Vai trò',
	'A917Company':'Công ty',
	'A918CreateUser':'Tạo tài khoản',
	'A919Username':'Tên tài khoản',
	'A920Password':'Mật khẩu',
	'A921Name':'Tên',
	'A922ParentCompany':'Tên công ty mẹ',
	'A923Create':'Tạo',
	'A924Edit':'Chỉnh sửa',
	'A925SubCompany':'Công ty phụ',
	'A926Companies':'Các công ty',
	'A927EditMachine':'Chỉnh sửa máy',
	'A928CompaniesFunctionality':'Các công ty/ Chức năng',
	'A930SelectAll':'Lựa chọn tất cả',
	'A931DetailView':'Xem chi tiết',
	'A932EditUser':'Chỉnh sửa tên người sử dụng',
	'A933Delete':'Xóa',
	'A934Cancel':'Hủy',
	'A935Save':'Lưu lại',
	'A936LoginFailed':'Đăng nhập thất bại',
	'A936Malayalam':'Malayalam',
	'A937DisplayName1':'Tên hiển thị 1',
	'A938DisplayName2':'Tên hiển thị 2',
	'A939DisplayName3':'Tên hiển thị 3',
	'A940ForgotPassword':'Quên mật khẩu',
	'A941ForgotText':'Không có vấn đề gì xảy ra. Hãy nhập tài khoản của bạn và bạn sẽ nhận một thư chứa đường dẫn để reset mật khẩu',
	'A942Submit':'Đệ trình',
	'A943EmailAddress':'Địa chỉ email',
	'A944ChangePassword':'Thay đổi mật khẩu',
	'A945CurrentPassword':'Mật khẩu hiện tại',
	'A946NewPassword':'Mật khẩu mới',
	'A947ConfirmPassword':'Hãy chắc chắn về mật khẩu mới',
	'A948Update':'Cập nhật',
	'A949IncorrectPassword':'Mật khẩu không đúng',
	'A950Metrics':'Metricspecs',
	'A950PasswordsNotMatch':'Mật khẩu mới không phù hợp',
	'A951EmailNotFound':'Địa chỉ email không được tìm thấy',
	'A952InvalidEmail':'Nhập vào một địa chỉ email đúng',
	'A953EmailSent':'Nếu email của bạn tồn tại trong cơ sở dữ liệu của chúng tôi, một email sẽ được gửi đến id mail của bạn với liên kết đặt lại.',
	'A954ForgetPasswordMailSubject':'Reset mật khẩu',
	'A955ForgetPasswordMailTitle':'Xin chào ',
	'A956ForgetPasswordMailBody':'<p>Chúng tôi đã nhận được yêu cầu reset lại mật khẩu của bạn. <br> Sử dụng đường dẫn dưới để thiết lập một mật khẩu mới. Nếu bạn không muốn reset lại mật khẩu, hãy bỏ qua email này và đường dẫn sẽ hết hạn trong vòng 24 giờ.<p><a href=\'RESET_LINK\' style=\'text-decoration: none; font-color: white;\'><span style=\'padding: 10px; display: inline; border-radius: 2px; border: 0; margin: 0 10px; background: #09174a; color:white; line-height: 15px; width: auto; height: auto; box-sizing: content-box;\'>Set new password</span></a><br><p>Best regards from the TrackYourPlant-Team</p>',
	'A957DateFormat':'dd.MM.yyyy',
	'A958D3DateFormat':'%d/%m/%Y',
	'A959DashboardMachineStateStandBy':'Chế độ chờ',
	'A959MessageUpdateSuccess':'Thông điệp đã được cập nhật thành công',
	'A960MessageSaveSuccess':'Thông điệp đã được lưu thành công',
	'A961Yes':'Đồng ý/ xác nhận',
	'A962No':'Từ chối/ không xác nhận',
	'A963ReportOverview':'Tổng quan báo cáo',
	'A964Machine':'Máy',
	'A965Card':'Thẻ nhớ',
	'A966ReportType':'Kiểu báo cáo',
	'A967HistogramReport':'Biểu đồ',
	'A968Report':'Báo cáo',
	'A969Day':'1 ngày',
	'A969French':'Français',
	'A970Week':'1 tuần',
	'A971Month':'1 tháng',
	'A972HistoryChart':'Biểu đồ lịch sử',
	'A972HistoryChartBacth':'Biểu đồ lịch sử cur. lô hàng',
	'A973Unit':'[Đơn vị]',
	'A974Time':'Thời gian',
	'A975ShowBagCounterStackChart':'Kiểm tra bảng  được xếp',
	'A976TestReportStarted':'Đang tạo báo cáo kiểm thử',
	'A977ActiveSettingsRequired':'Yêu cầu kích hoạt thiết lập  cho báo cáo để tạo ra báo cáo kiểm thử',
	'A978FirstDayRequired':'Yêu cầu  cung cấp ngày có báo cáo đầu tiên',
	'A979CompanyDeleted':'Thông tin công ty đã bị xóa.',
	'A980CompanyDeleteError':'Thông tin công ty không thể xóa được',
	'A981CompanyParentShouldDifferent':'Công ty và công ty mẹ phải khác nhau',
	'A982CompanyCreated':'Thông tin công ty đã được lưu.',
	'A983CompanyCreateError':'Thông tin công ty không được lưu',
	'A984CompanyAllFields':'Hãy nhập vào tất cả các nội dung yêu cầu',
	'A985UserCreated':'Tài khoản đã được tạo',
	'A986UserExists':'Email này đã tồn tại',
	'A987UserDeleted':'Tài khoản người dùng bị xóa',
	'A988IncludedInReport':'Bao gồm trong báo cáo',
	'A989IncludedInHistogram':'Bao gồm biểu đồ trong báo cáo',
	'A990DateFormat':'DD.MM.YYYY',
	'A991MachineTime':'Thời gian máy ',
	'A992LocalTime':'Thời gian cho máy tính',
	'A993NoMachines':'Không có máy nào cả',
	'A994Imprint':'In',
	'A995PrivacyPolicy':'Quyền riêng tư',
	'A996Chinese':'中文',
	'A997Portuguese':'Português',
	'A998Vietnamese':'Tiếng Việt ',
	'A999CompanyNameExists':'Tên công ty đã tồn tại.',
	'B100ActivatingReports':'Việc kích hoạt sẽ gửi báo cáo thường xuyên đến email -Id(s) sau:',
	'B101EnterValidEmailIds':'Hãy nhập email ids đúng',
	'B101ValidFrom':'undefined',
	'B102AtleastOneEmailIdRequired':'Ít nhất một email đươc yêu cầu',
	'B102ValidTo':'undefined',
	'B103MaximumLimitReached':'Đã đạt đến Giới hạn tối đa ',
	'C001FetchingWait':'Đang truy suất dữ liệu. Vui lòng đợi',
	'C002PreparingWait':'Đang chuẩn bị dữ liệu. Vui lòng đợi',
	'C003RenderingWait':'Đang tạo bảng. Vui lòng đợi',
	'C004PixelScale':'1 pixel ≈ {{khoảng thời gian}} giây.',
	'C005ChartError':'Có lỗi xảy ra!',
	'C006ChartOk':'ok',
	'D001PortalType':'undefined',
	'D002CompanyDomain':'undefined',
	'E001AlertSettingSavedSuccessfully':'Các thiết lập nhắc nhở được lưu lại thành công',
	'E002SelectAtLeastOneAlertTypeToActivateAlerting':'Lựa chọn ít nhất một kiểu thiết lập nhắc nhở để kích hoạt chế động nhắc nhở',
	'E003ErrorNumberMustBePositive':'Số lượng lỗi phải là con số thực (không phải số âm)',
	'E004AlertEmailNeedsToBeActive':'Email nhắc cần phải được kích hoạt',
	'E005AnErrorHasOccured':'Một lỗi đã xảy ra.',
	'E005ErrorMessageAlreadyExists':'Thông điệp đã tồn tại',
	'E005OperationSuccesful':'Vận Hành thành công',
	'E006ErrorMessageSave':'Lỗi xảy ra. Thông điệp không được lưu lại',
	'E006ErrorOccurredSettingsWasNotSaved':'Lỗi xảy ra. Thông điệp không được lưu lại',
	'E007ReportSettingsSuccessfullySaved':'Điều chỉnh báo cáo được lưu thành công',
	'E008ErrorSettingsDoNotExists':'Lỗi. Cài đặt không tồn tại',
	'E009ReportSettingsSuccessfullyUpdated':'Thiết lập thông số báo cáo cập nhật thành công',
	'E010InvalidPassword':'undefined',
	'E011UserUpdated':'undefined',
	'E012InvalidPassword':'The password does not satisfy the password criteria',
	'E013UserUpdated':'Người dùng đã cập nhật thành công',
	'E015CompanyMaxUsersLowerThanActiveUsers':'Công ty đã có nhiều người dùng hoạt động hơn số người dùng tối đa đã đặt',
	'E016PasswordChangedSuccessfully':'Password was changed successfully',
	'F001_CurrentOrder':'Đơn hàng hiện tại',
	'F002_ProductID':'ID sản phẩm',
	'F003_BatchStart':'Bắt đầu hàng loạt',
	'F004_Targetweight':'Trọng lượng mục tiêu',
	'F005_RemainingFill':'Chất trám còn lại',
	'F006_CurrentProduction':'Sản xuất hiện nay',
	'F007_FillStatistic':'điền số liệu thống kê',
	'F010_Sum':'Tổng',
	'F011_Centering':'Định tâm',
	'F012_Debunging':'Gỡ rối',
	'F013_Inert':'Trơ',
	'F014_Filling_1':'Điền 1',
	'F015_Filling_2':'Điền 2',
	'F016_Filling_3':'Điền 3',
	'F017_Filling_4':'Điền 4',
	'F018_Bunging':'Bunging',
	'F019_Sealing':'Niêm phong',
	'F020_Position':'Chức vụ',
	'F021_TransportCycle':'Chu kỳ vận chuyển',
	'F022_CheckWeigher':'Kiểm tra trọng lượng',
	'F023_Closing':'Đóng cửa',
	'F024_Palletizing':'Xếp hàng',
	'F025_EmptyPaletMagazin':'PaletMagazin rỗng',
	'F026_DePalletizing':'Giảm xếp hàng',
	'F027_Transport':'Vận chuyển',
	'F028_PailSeperator':'Thùng rác',
	'F029_HangUpCap':'Treo mũ',
	'F030_PushInCap':'Đẩy vào nắp',
	'F031_TestConsoles':'Bàn kiểm tra',
	'F050_TU2':'TU 2',
	'F051_TU1':'TU 1',
	'F052_LowerTol':'Dưới dung sai',
	'F053_InTol':'Trong dung sai',
	'F054_UpperTol':'Quá sức chịu đựng',
	'F055_TO1':'ĐẾN 1',
	'F056_TO2':'ĐẾN 2',
	'F060_ProductInfeed':'Sản phẩm trong thức ăn chăn nuôi',
	'F061_EmptyTruss':'Giàn trống',
	'F062DetailedDateFormat':'[Ngày] DD/ [Tháng] MM/[Năm] YYYY HH:mm:ss',
	'F090_Minuten':'undefined',
	'F091_Stunden':'undefined',
	'F100_Eichung':'undefined',
	'F101_TransKette':'undefined',
	'F102_Hubtor':'undefined',
	'F103_ZentRollen':'undefined',
	'F104_SpundSuch':'undefined',
	'F105_Aushub':'undefined',
	'F106_WerkSchlitten':'undefined',
	'F107_Aufschrauber':'undefined',
	'F108_VakuSys':'undefined',
	'F109_HubAufSchraub':'undefined',
	'F110_OelAufSchraub':'undefined',
	'F111_Inertgas':'undefined',
	'F112_Aushub_F1':'undefined',
	'F113_HE_F1':'undefined',
	'F114_TFE_F1':'undefined',
	'F115_Aushub_F2':'undefined',
	'F116_HE_F2':'undefined',
	'F117_TFE_F2':'undefined',
	'F118_ZentDorn':'undefined',
	'F119_Schiebetisch':'undefined',
	'F120_Kappenband':'undefined',
	'F121_VakuKap':'undefined',
	'F122_KapSort':'undefined',
	'F123_VerKap':'undefined',
	'F124_KapZu':'undefined',
	'F125_Schrauber':'undefined',
	'F126_VakuSchrauber':'undefined',
	'F127_HubSchrauber':'undefined',
	'F128_OelZuSchraub':'undefined',
	'F129_Dichtung':'undefined',
	'F130_HubClinch':'undefined',
	'F131_Clinchen':'undefined',
	'F132_OelClincher':'undefined',
	'F133_VakuClincher':'undefined',
	'G001BatchReport':'Báo cáo hàng loạt',
	'G002ActivateBatchReport':'Kích hoạt báo cáo hàng loạt',
	'Hülsen MIN-Melder':'Cảm biến Hull MIN',
	'IVT-Simulation':'INTEGRA® ',
	'Kabel Sattelhöhenv':'cáp Điều chỉnh chiều cao ghế đỡ',
	'MACHINE_AVAILABILITY_RED_ZONE':'Khả năng sẵn sàng của máy trong vùng đỏ',
	'MACHINE_AVAILABILITY_YELLOW_ZONE':'Khả năng sẵn sàng của máy trong vùng vàng',
	'Maint Interval 0':'Khoảng thời gian chính 0',
	'Maint Interval 1':'Khoảng thời gian chính 1',
	'Maint Interval 10':'Khoảng thời gian chính 10',
	'Maint Interval 11':'Khoảng thời gian chính 11',
	'Maint Interval 12':'Khoảng thời gian chính 12',
	'Maint Interval 13':'Khoảng thời gian chính 13',
	'Maint Interval 14':'Khoảng thời gian chính 14',
	'Maint Interval 2':'Khoảng thời gian chính 2',
	'Maint Interval 3':'Khoảng thời gian chính 3',
	'Maint Interval 4':'Khoảng thời gian chính 4',
	'Maint Interval 5':'Khoảng thời gian chính 5',
	'Maint Interval 6':'Khoảng thời gian chính 6',
	'Maint Interval 7':'Khoảng thời gian chính 7',
	'Maint Interval 8':'Khoảng thời gian chính 8',
	'Maint Interval 9':'Khoảng thời gian chính 9',
	'miniADAMS-Messe':'ROTO-PACKER®',
	'N042BrazAlertGlobalAccL0':'Tăng tốc toàn cầu vượt qua L0',
	'N042BrazAlertGlobalAccL1':'Tăng tốc toàn cầu vượt qua L1',
	'N042BrazAlertGlobalAccL2':'Tăng tốc toàn cầu vượt qua L2',
	'N042BrazAlertGlobalAccL3':'Tăng tốc toàn cầu vượt qua L3',
	'N042BrazAlertGlobalAccL4':'Tăng tốc toàn cầu vượt qua L4',
	'N042BrazAlertGlobalAmplL0':'Biên độ toàn cầu vượt qua L0',
	'N042BrazAlertGlobalAmplL1':'Biên độ toàn cầu vượt qua L1',
	'N042BrazAlertGlobalAmplL2':'Biên độ toàn cầu vượt qua L2',
	'N042BrazAlertGlobalAmplL3':'Biên độ toàn cầu vượt qua L3',
	'N042BrazAlertGlobalAmplL4':'Biên độ toàn cầu vượt qua L4',
	'N042BrazAlertMaxRmsAccL0':'Gia tốc rms tối đa của cảm biến LB, CB và RB vượt qua L0',
	'N042BrazAlertMaxRmsAccL1':'Gia tốc rms tối đa của cảm biến LB, CB và RB vượt qua L1',
	'N042BrazAlertMaxRmsAccL2':'Gia tốc rms tối đa của cảm biến LB, CB và RB vượt qua L2',
	'N042BrazAlertMaxTempBearingSensL0':'Nhiệt độ tối đa của cảm biến vòng bi vượt qua L0',
	'N042BrazAlertMaxTempBearingSensL1':'Nhiệt độ tối đa của cảm biến vòng bi vượt qua L1',
	'N042BrazAlertMaxTempBearingSensL2':'Nhiệt độ tối đa của cảm biến vòng bi vượt qua L2',
	'N042BrazAlertMaxZAxisSensorsL0':'Gia tốc tối đa của trục z của lfs, rfs lds và cảm biến màu đỏ vượt qua L0',
	'N042BrazAlertMaxZAxisSensorsL1':'Gia tốc tối đa của trục z của lfs, rfs lds và cảm biến màu đỏ vượt qua L1',
	'N042BrazAlertMaxZAxisSensorsL2':'Gia tốc tối đa của trục z của lfs, rfs lds và cảm biến màu đỏ vượt qua L2',
	'N042BrazAlertRotFreqL0':'Tần số quay chéo L0',
	'N042BrazAlertRotFreqL1':'Tần số quay chéo L1',
	'N042BrazAlertRotFreqL2':'Tần số quay chéo L2',
	'N042BrazAlertRotFreqL3':'Tần số quay chéo L3',
	'N042BrazAlertRotFreqL4':'Tần số quay chéo L4',
	'N042BrazAlertType':'Cảnh báo Brazil',
	'N042GlobalAcceleration':'Tăng tốc toàn cầu ',
	'N042GlobalAmplitude':'Biên độ toàn cầu',
	'N042GlobalFrequency':'Tần số toàn cầu',
	'N043CardCtxMenuShowConfigScreen':'Định cấu hình thẻ',
	'N043ConfigSaveFailure':'Cập nhật cấu hình không thành công',
	'N043ConfigSaveSuccess':'Cấu hình đã được cập nhật thành công',
	'N044BrazilAlertMailSubject':'Pulse CM Alert : MACHINE_NAME',
	'N045AlertWhenThresholdCrossed':'Thông báo cho tôi khi vượt qua giới hạn này',
	'N046RawDataTab':'Dữ liệu thô',
	'N047SensorL1':'Sensor L 1',
	'N047SensorL4':'Sensor L 4',
	'N047SensorR1':'Sensor R 1',
	'N047SensorR4':'Sensor R 4',
	'N048Acceleration':'Sự tăng tốc',
	'N048AccelerationLocal':'Tăng tốc cục bộ',
	'N048AccelerationX':'Sự tăng tốc X',
	'N048AccelerationY':'Sự tăng tốc Y',
	'N048AccelerationZ':'Sự tăng tốc Z',
	'N048Amplitude':'Biên độ',
	'N048AmplitudeLocal':'Biên độ Local',
	'N048AmplitudeX':'Biên độ X',
	'N048AmplitudeY':'Biên độ Y',
	'N048AmplitudeZ':'Biên độ Z',
	'N048ChooseEndDate':'Chọn ngày kết thúc',
	'N048ChooseStartDate':'Chọn ngày bắt đầu',
	'N048Constant':'Không thay đổi',
	'N048CourseAngle':'Góc khóa học',
	'N048DeltaTemperature':'Nhiệt độ Delta',
	'N048Frequency':'Tần số',
	'N048LastSeen':'Nhìn thấy lần cuối',
	'N048MovementAngle':'Góc chuyển động',
	'N048OffsetX':'X Bù lại',
	'N048OffsetY':'Y Bù lại',
	'N048OffsetZ':'Z Bù lại',
	'N048PhaseAngle':'Góc pha',
	'N048MotionAngle':'Góc chuyển động',
	'N048RMS':'RMS',
	'N048Rpm':'RPM',
	'N048SensorDescription':'Mô tả cảm biến',
	'N048SensorId':'Id cảm biến',
	'N048SensorTemperature':'Nhiệt độ cảm biến',
	'N048Side':'Bên',
	'N048SiteName':'Tên trang web',
	'N048UpdatedTime':'Thời gian cập nhật',
	'N049accelFDCompLeft':'Accel.FDComp.Left',
	'N049accelFDCompRight':'Accel.FDComp.Right',
	'N049accelLRCompDisch':'Accel.LRComp.Disch',
	'N049accelLRCompFeed':'Accel.LRComp.Feed',
	'N049Date':'Ngày',
	'N049ErrorFetchingData':'Lỗi khi tìm nạp dữ liệu',
	'N049FrequencyDaily':'ngày',
	'N049FrequencyHourly':'Giờ',
	'N049FrequencyWeekly':'Tuần',
	'N049Length':'Chiều dài',
	'N049ModelName':'Tên mẫu',
	'N049pitch':'Sân cỏ',
	'N049roll':'Cuộn',
	'N049Rpm':'Rpm',
	'N049strokeDeparture':'Đột quỵ khởi hành',
	'N049TuningWizard':'Thuật sĩ điều chỉnh',
	'N049twistG':'Twist.G',
	'N049twistXG':'Twist.XG',
	'N049twistYG':'Twist.YG',
	'N049twistZG':'Twist.ZG',
	'N049Width':'Chiều rộng',
	'N049xgFDCompLeft':'X.G.FDComp.Left',
	'N049xgFDCompRight':'X.G.FDComp.Right',
	'N049xgLrCompDisch':'X.G.LRComp.Disch',
	'N049xgLrCompFeed':'X.G.LRComp.Feed',
	'N049ygFDCompLeft':'Y.G.FDComp.Left',
	'N049ygFDCompRight':'Y.G.FDComp.Right',
	'N049ygLrCompDisch':'Y.G.LRComp.Disch',
	'N049ygLrCompFeed':'Y.G.LRComp.Feed',
	'N049zgFDCompLeft':'Z.G.FDComp.Left',
	'N049zgFDCompRight':'Z.G.FDComp.Right',
	'N049zgLrCompDisch':'Z.G.LRComp.Disch',
	'N049zgLrCompFeed':'Z.G.LRComp.Feed',
	'N049zgMax':'ZG.Max',
	'N050AnalystCommentary':'Bình luận của nhà phân tích',
	'N050mAvgLong':'mavg dài',
	'N050mAvgShort':'mavg ngắn',
	'N050TrendAnalysis':'Phân tích xu hướng',
	'N051SensorL1':'Cảm biến L1',
	'N051SensorL2':'Cảm biến L2',
	'N051SensorL3':'Cảm biến L3',
	'N051SensorL4':'Cảm biến L4',
	'N051SensorMovement':'Cảm biến chuyển động',
	'N051SensorR1':'Cảm biến R1',
	'N051SensorR2':'Cảm biến R2',
	'N051SensorR3':'Cảm biến R3',
	'N051SensorR4':'Cảm biến R4',
	'N052Anomaly':'Nghĩa bóng',
	'N052AnomalyDetectionTab':'Phát hiện bất thường',
	'N052BrazAlertTypeSL1LocalAmplitudeL0':'Cảm biến L1 biên độ cục bộ L0',
	'N052BrazAlertTypeSL1LocalAmplitudeL1':'Cảm biến L1 biên độ cục bộ L1',
	'N052BrazAlertTypeSL1LocalAmplitudeL2':'Cảm biến L1 biên độ cục bộ L2',
	'N052BrazAlertTypeSL1LocalAmplitudeL3':'Cảm biến L1 biên độ cục bộ L3',
	'N052BrazAlertTypeSL1LocalAmplitudeL4':'Cảm biến L1 biên độ cục bộ L4',
	'N052BrazAlertTypeSL1LocalAmplitudeL5':'Cảm biến L1 biên độ cục bộ L5',
	'N052BrazAlertTypeSL1SensorTempL0':'Cảm biến L1 Cảm biến Nhiệt độ Chéo L0',
	'N052BrazAlertTypeSL1SensorTempL1':'Cảm biến L1 Cảm biến Nhiệt độ Chéo L1',
	'N052BrazAlertTypeSL1SensorTempL2':'Cảm biến L1 Cảm biến Nhiệt độ Chéo L2',
	'N052BrazAlertTypeSL1SensorTempL3':'Cảm biến L1 Cảm biến Nhiệt độ Chéo L3',
	'N052ObservedValue':'Được Quan sát',
	'N053Angle':'Góc (0)',
	'N053BrazAlertTypeSL1LocalAccelerationL0':'Cảm biến gia tốc cục bộ L1 vượt qua L0',
	'N053BrazAlertTypeSL1LocalAccelerationL1':'Cảm biến gia tốc cục bộ L1 vượt qua L1',
	'N053BrazAlertTypeSL1LocalAccelerationL2':'Cảm biến gia tốc cục bộ L1 vượt qua L2',
	'N053BrazAlertTypeSL1LocalAccelerationL3':'Cảm biến gia tốc cục bộ L1 vượt qua L3',
	'N053BrazAlertTypeSL1LocalAccelerationL4':'Cảm biến gia tốc cục bộ L1 vượt qua L4',
	'N053BrazAlertTypeSL1LocalAccelerationL5':'Cảm biến gia tốc cục bộ L1 vượt qua L5',
	'N053BrazAlertTypeSl1RotationFreqL0':'Cảm biến L1 tần số quay cục bộ L0',
	'N053BrazAlertTypeSl1RotationFreqL1':'Cảm biến L1 tần số quay cục bộ L1',
	'N053BrazAlertTypeSl1RotationFreqL2':'Cảm biến L1 tần số quay cục bộ L2',
	'N053BrazAlertTypeSl1RotationFreqL3':'Cảm biến L1 tần số quay cục bộ L3',
	'N053BrazAlertTypeSl1RotationFreqL4':'Cảm biến L1 tần số quay cục bộ L4',
	'N053BrazAlertTypeSl1RotationFreqL5':'Cảm biến L1 tần số quay cục bộ L5',
	'N053BrazAlertTypeSL1ZAxisL0':'Cảm biến gia tốc trục z L1 vượt qua L0',
	'N053BrazAlertTypeSL1ZAxisL1':'Cảm biến gia tốc trục z L1 vượt qua L1',
	'N053BrazAlertTypeSL1ZAxisL2':'Cảm biến gia tốc trục z L1 vượt qua L2',
	'N053BrazAlertTypeSL1ZAxisL3':'Cảm biến gia tốc trục z L1 vượt qua L3',
	'N053Displacement':'Dịch chuyển',
	'N053G':'g',
	'N053LocalAmplitude':'Biên độ cục bộ',
	'N053LocalRotationFrequency':'Xoay cục bộ (Tần suất)',
	'N053MM':'mm',
	'N053XAxis':'Trục X',
	'N053YAxis':'Trục Y',
	'N053ZAxis':'Trục Z',
	'N053ZAxisAcceleration':'Gia tốc trục Z',
	'N054Higher':'Phía trên',
	'N054Lower':'Thấp hơn',
	'N054StructuralAnalysis':'Phân tích cấu trúc',
	'N054TWvarsForecasts':'Dự báo TW vars',
	'N054TWvarsFull':'TW vars đầy',
	'N054Value':'Giá trị',
	'N0551On0Off':'1 = BẬT: 0 = TẮT',
	'N055BrazTabGeneral':'Chung',
	'N055ChooseStartEndDate':'Chọn ngày bắt đầu và ngày kết thúc',
	'N055Close':'Đóng',
	'N055Custom':'Tập quán',
	'N055MaterialFlow':'Dòng nguyên liệu',
	'N055MAxBearingTemperature':'Nhiệt độ ổ trục tối đa',
	'N055MaxRmsAccelBearingSensors':'Gia tốc tối đa Rms của cảm biến ổ trục',
	'N055MazAccelZAxis':'Gia tốc tối đa của trục Z của cảm biến cấu trúc',
	'N055Off':'TẮT',
	'N055On':'TRÊN',
	'N055RDCenter':'Chế độ xem máy',
	'N055ShowHistory':'Hiển thị lịch sử',
	'N055StartDateCannotBeAfterEndDate':'Ngày bắt đầu không được sau ngày kết thúc',
	'N056Commentary':'Bình luận',
	'N056EvaluateEquipment':'Điều kiện đánh giá thiết bị',
	'N056High80':'Cao 80',
	'N056High95':'Cao 95',
	'N056Importance':'tầm quan trọng',
	'N056Low80':'Thấp 80',
	'N056Low95':'Thấp 95',
	'N056MaxLatAccelZgMax':'Gia tốc bên tối đa ZG.Max',
	'N056Pred':'Pred',
	'N056Recommendations':'khuyến nghị',
	'N056Type':'Kiểu',
	'N056Report':'Báo cáo',
	'N0571Year':'1 năm',
	'N057AccelGms':'undefined',
	'N057AccelRms':'Tăng tốc RMS',
	'N057AccelSpectrum':'Phổ gia tốc',
	'N057AccelTimeWForm':'Thời gian gia tốc dạng sóng',
	'N057Axial':'Trục',
	'N057CrestFactor':'Yếu tố Crest',
	'N057DegreeCelcius':'°C',
	'N057FreqHz':'Tần số (Hz)',
	'N057FullSpectrum':'Toàn phổ',
	'N057FundBearingDefectFreq':'Tần suất quỹ, vòng bi, lỗi',
	'N057HighHarBearFreq':'Các tần số mang sóng hài cao hơn',
	'N057HighRes':'High Res',
	'N057HistoricTemperature':'Nhiệt độ lịch sử',
	'N057Horizontal':'Ngang',
	'N057Latest':'Muộn nhất',
	'N057LowHarBearFreq':'Các tần số mang sóng hài thấp hơn',
	'N057mms':'mm/s',
	'N057PeakToPeak':'Giao tiếp gián tiếp',
	'N057PeakToPeakMg':'Peak to peak(mg)',
	'N057PowerBands':'Dải điện',
	'N057RedAlert':'báo động đỏ',
	'N057Rmsmg':'Rms(mg)',
	'N057Rmsmms':'Rms(mm/s)',
	'N057SensorLB1':'Cảm biến LB1',
	'N057SensorLB2':'Cảm biến LB2',
	'N057SensorRB1':'Cảm biến RB1',
	'N057SensorRB2':'Cảm biến RB2',
	'N057SensorCB1':'Cảm biến CB1',
	'N057SensorCB2':'Cảm biến CB2',
	'N057Spectrum':'Quang phổ',
	'N057Temperature':'Nhiệt độ',
	'N057TimeMs':'Thời gian (mili giây)',
	'N057Trending':'Xu hướng',
	'N057VelocityRms':'Vận tốc RMS',
	'N057Vertical':'Theo chiều dọc',
	'N057X1Rpm':'1X Rpm',
	'N057YellowAlert':'Cảnh báo vàng',
	'N058ImageUploadedFailure':'Hình ảnh tải lên không thành công',
	'N058ImageUploadedSuccesfully':'Đã tải lên hình ảnh thành công',
	'N058UploadImage':'Tải lên hình ảnh',
	'N059Print':'undefined',
	'N060MachineHistoricalData':'undefined',
	'N061HistoricalBearingData':'undefined',
	'N062HistoricalSpectrumData':'undefined',
	'N063GlobalAcceleration':'undefined',
	'N064RotationFrequency':'undefined',
	'N065GlobalBreadth':'undefined',
	'N066LocalAccelerationsEachStructureSensor':'undefined',
	'N067MotionAngleEachStructureSensor':'Góc chuyển động của từng cảm biến cấu trúc',
	'N067PhaseAngleEachStructureSensor':'Góc pha của từng cảm biến cấu trúc',
	'N067LocalAmplitudesEachStructureSensor':'undefined',
	'N068LateralAccelerationsStructureSensor':'undefined',
	'N069TemperatureEachBearingSensor':'undefined',
	'N070PeakToPeakEachBearingSensor':'undefined',
	'N071AccelerationRMS':'undefined',
	'N072VelocityRMS':'undefined',
	'N073CrestFactor':'undefined',
	'N074PowerBandsSensorLB1':'undefined',
	'N075PowerBandsSensorRB1':'undefined',
	'N076PowerBandsSensorLB2':'undefined',
	'N077PowerBandsSensorRB2':'undefined',
	'N077PowerBandsSensorCB1':'undefined',
	'N077PowerBandsSensorCB2':'undefined',
	'N078TimeWaveformDataAcceleration':'undefined',
	'N079FrequencyDomainAcceleration':'undefined',
	'N080Select':'undefined',
	'N081SelectedPeriod':'undefined',
	'N100Capacity':'undefined',
	'N101Product':'undefined',
	'N102Undersized':'undefined',
	'N103Oversized':'undefined',
	'N104Speed':'undefined',
	'N105Inclination':'undefined',
	'N106BoardHeigh':'undefined',
	'N107Moisture':'undefined',
	'N108WaterAdd':'undefined',
	'N109OpMode':'undefined',
	'N110Auto':'undefined',
	'N110Hand':'undefined',
	'N111OpHours':'undefined',
	'N112Temp':'undefined',
	'N113BearingTemp1':'undefined',
	'N114BearingTemp2':'undefined',
	'N115DriveTemp':'undefined',
	'N116ConvTemp':'undefined',
	'N117PowerInput':'undefined',
	'New001BagTransArea':'undefined',
	'New002EmptyPalArea':'undefined',
	'New003FullPalArea':'undefined',
	'New004SheetDispArea':'undefined',
	'New005PneuFalut':'undefined',
	'New006ElectricFault':'undefined',
	'New007Introduction':'undefined',
	'New008Orientation':'undefined',
	'New009PreGrouping':'undefined',
	'New010Grouping':'undefined',
	'New011RowPusher':'undefined',
	'New012SlideTable':'undefined',
	'New013Elevator':'undefined',
	'New014Gantry':'undefined',
	'New015Gripper':'undefined',
	'New016FilmDispenser':'undefined',
	'New017FullPalTrans':'undefined',
	'New018EmpPalDisp':'undefined',
	'New019FilmDisp':'undefined',
	'New020EmpCardDisp':'undefined',
	'New021EmpPalTrans':'undefined',
	'New022EjectingDev':'undefined',
	'New023MetDetector':'undefined',
	'New024MarkingDev':'undefined',
	'New025LabellingDev':'undefined',
	'New026PalletStracker':'undefined',
	'New027PalletPress':'undefined',
	'New028Wrapper':'undefined',
	'New029Fault':'undefined',
	'New030StandBy':'undefined',
	'New031BagProduction':'undefined',
	'New032WeightDetection':'undefined',
	'New033LeakingDetection':'undefined',
	'New034PrintingFault':'undefined',
	'New035BagProduced':'undefined',
	'New036SamplingEjection':'undefined',
	'New037PalletProduction':'undefined',
	'New038PalletInfeed':'undefined',
	'New039PalletOutfeed':'undefined',
	'New040PalletIncomplete':'undefined',
	'New041Checkweigher':'undefined',
	'New041PalledProduced':'undefined',
	'New042ProgramChngsToday':'undefined',
	'New042ProgramChngs':'undefined',
	'New043EndofBatch':'undefined',
	'New044ProductonTime':'Thời gian sản xuất',
	'RVT8-Messe':'ROTO-PACKER® ',
	'RVT8-Simulation':'ROTO-PACKER® ',
	'TERAM2-Messe':'TERRAM®',
	'TERAM-Messe':'TERRAM®',
	'Typ84-Messe':'INTEGRA® ',
	'Waagenabgleich':'Điều chỉnh cân',
	'Zyklen':'Chu kỳ',
	'N082AlertMe':'Thông báo cho tôi khi thẻ này có bất kỳ cảnh báo nào',
	'N083TimeFormat':'HH:mm',
	'N090Synced':'undefined',
	'N091NotSynced':'undefined',
	'N084New':'undefined',
	'N085ConfigSettingSavedSuccessfully':'undefined',
	'N86ErrorOccurredSettingsWasNotSaved':'undefined',
	'A496OEEWeek':'undefined',
	'A497OEEMonthly':'undefined',
	'N87ServiceRequestUploadFiles':'undefined',
	'N88ServiceRequestFileSizeError':'undefined',
	'N89ServiceRequestFileTypeError':'undefined',
	'N90FilesUploadedSuccesfully':'undefined',
	'N91FilesUploadedFailure':'undefined',
	'N932IncludeExcelReport':'Bao gồm báo cáo excel',
	'N94ServiceRequestFileLimitError':'undefined',
	'N055MaxHAxisRmsAccelBearingSensors':'undefined',
	'N055MaxAxialRmsAccelBearingSensors':'undefined',
	'N053MG':'mg',
	'N056DummyMachine':'Dummy machines',
	'N057NiagaraAlertYellow':'Cảnh báo màu vàng',
	'N058NiagaraAlertOrange':'Cảnh báo màu cam',
	'N059NiagaraAlertRed':'Báo động đỏ',
	'N060AddDummyMachine':'undefined',
	'N96ConfigureValue':'Giá trị cấu hình',
	'N97NoDataAvailable':'Không có dữ liệu',
	'N98TimeStamp':'Timestamps',
	'N99Documentation':'Documentation',
	'N100ErrorFileHeader':'undefined',
	'N101DocumentationHeader':'undefined',
	'N102DownloadMachineTypeErrorMessageFile':'undefined',
	'N102DownloadMachineErrorMessageFile':'undefined',
	'N103ErrorInRetrievingErrorMessages':'undefined',
	'E011MaintIntervalSubmitSuccess':'undefined',
	'A368OpenPartsShop':'undefined',
	'N104ExtSparePartCatalog':'undefined',
	'N105OpenExtSparePartCatalog':'undefined',
	'N106LperH':'l/h',
	'N107Degree':'°',
	'N107DegreeText':'° (Degree)',
	'N108UseRelativeValues':'undefined',
	'N109PartsShopUsername':'undefined',
	'N110Tons':'t',
	'AX_VibratingBodyCondition':'undefined',
	'AX_BearingCondition':'undefined',
	'AX_AlarmsCount':'undefined',
	'AX_Last24Hours':'undefined',
	'D001TransBearingCondition':'undefined',
	'D002TransBearingCondition':'undefined',
	'D003TransBearingCondition':'undefined',
	'D004TransBearingCondition':'undefined',
	'D005TransBearingCondition':'undefined',
	'D006TransBearingCondition':'undefined',
	'D007TransBearingCondition':'undefined',
	'D008TransBearingCondition':'undefined',
	'D009TransBearingCondition':'undefined',
	'D010TransBearingCondition':'undefined',
	'D011TransBearingCondition':'undefined',
	'D012TransBearingCondition':'undefined',
	'D013TransBearingCondition':'undefined',
	'D014TransBearingCondition':'undefined',
	'D015TransBearingCondition':'undefined',
	'D016TransBearingCondition':'undefined',
	'D001TransVBCondition':'undefined',
	'D002TransVBCondition':'undefined',
	'D003TransVBCondition':'undefined',
	'D004TransVBCondition':'undefined',
	'D005TransVBCondition':'undefined',
	'D006TransVBCondition':'undefined',
	'D007TransVBCondition':'undefined',
	'D008TransVBCondition':'undefined',
	'D009TransVBCondition':'undefined',
	'D010TransVBCondition':'undefined',
	'D011TransVBCondition':'undefined',
	'D012TransVBCondition':'undefined',
	'D013TransVBCondition':'undefined',
	'D014TransVBCondition':'undefined',
	'D015TransVBCondition':'undefined',
	'D016TransVBCondition':'undefined',
	'F01CardExpProduction':'***Coming Soon***',
	'F01CardExpAvailability':'***Coming Soon***',
	'F01CardExpMaintenance':'***Coming Soon***',
	'F01CardExpProductionTime':'***Coming Soon***',
	'F01CardExpProductionTimeBaumit':'***Coming Soon***',
	'F01CardExpProductionToday':'***Coming Soon***',
	'F01CardExpRemProduction':'***Coming Soon***',
	'F01CardExpLastReset':'***Coming Soon***',
	'F01CardExpFaults_C':'***Coming Soon***',
	'F01CardExpFaults_M':'***Coming Soon***',
	'F01CardExpEnergy':'***Coming Soon***',
	'F01CardExpFillingTime':'***Coming Soon***',
	'F01CardExpBagManufacture':'***Coming Soon***',
	'F01CardExpWeightData':'***Coming Soon***',
	'F01CardExpStdDevData':'***Coming Soon***',
	'F01CardExpIncorrectWeight':'***Coming Soon***',
	'F01CardExpInterrupts':'***Coming Soon***',
	'F01CardExpTypeChange':'***Coming Soon***',
	'F01CardExpBagCounterPerFS':'***Coming Soon***',
	'F01CardExpNotPlacedBags':'***Coming Soon***',
	'F01CardExpEmptyBagMagazineToday':'***Coming Soon***',
	'F01CardExpRejectsToday':'***Coming Soon***',
	'F01CardExpCollectiveFaultsToday':'***Coming Soon***',
	'F01CardExpGoodWeights':'***Coming Soon***',
	'F01CardExpFltCompAir':'***Coming Soon***',
	'F01CardExpStrokesPump':'***Coming Soon***',
	'F01CardExpMaxStrokeDur':'***Coming Soon***',
	'F01CardExpAvgStrokes':'***Coming Soon***',
	'F01CardExpInternalPressure':'***Coming Soon***',
	'F01CardExpFillings':'***Coming Soon***',
	'F01CardExpStartOfOrder':'***Coming Soon***',
	'F01CardExpProductID':'***Coming Soon***',
	'F01CardExpTargetWeight':'***Coming Soon***',
	'F01CardExpFillingCount':'***Coming Soon***',
	'F01CardExpRemainingNumberFillings':'***Coming Soon***',
	'F01CardExpCycleTime':'***Coming Soon***',
	'F01CardExpFaultLength':'***Coming Soon***',
	'F01CardExpExtFaultLength':'***Coming Soon***',
	'F01CardExpWithinTolerance':'***Coming Soon***',
	'F01CardExpOutsideTolerance':'***Coming Soon***',
	'F01CardExpCurrentProduction':'***Coming Soon***',
	'F01CardExpFillStatistic':'***Coming Soon***',
	'F01CardExpRDCenter':'***Coming Soon***',
	'F01CardExpGlobalAcceleration':'***Coming Soon***',
	'F01CardExpGlobalFrequency':'***Coming Soon***',
	'F01CardExpGlobalAmplitude':'***Coming Soon***',
	'F01CardExpMazAccelZAxis':'***Coming Soon***',
	'F01CardExpMaxHAxisRmsAccelBearingSensors':'***Coming Soon***',
	'F01CardExpMaxRmsAccelBearingSensors':'***Coming Soon***',
	'F01CardExpMaxAxialRmsAccelBearingSensors':'***Coming Soon***',
	'F01CardExpMAxBearingTemperature':'***Coming Soon***',
	'F01CardExpSensorMovement':'***Coming Soon***',
	'F01CardExpAccelerationLocal':'***Coming Soon***',
	'F01CardExpAcceleration':'***Coming Soon***',
	'F01CardExpAxisAcceleration':'***Coming Soon***',
	'F01CardExpLocalRotationFrequency':'***Coming Soon***',
	'F01CardExpLocalAmplitude':'***Coming Soon***',
	'F01CardExpDisplacement':'***Coming Soon***',
	'F01CardExpPhaseAngle':'***Coming Soon***',
	'F01CardExpMotionAngle':'***Coming Soon***',
	'F01CardExpSensorTemperature':'***Coming Soon***',
	'F01CardExpBagQuality':'***Coming Soon***',
	'F01CardExpFaults':'***Coming Soon***',
	'F01CardExpBunChangeAbs':'***Coming Soon***',
	'F01CardExpBunChangeToday':'***Coming Soon***',
	'F01CardExpBagsPerBundle':'***Coming Soon***',
	'F01CardExpTypeChanges':'***Coming Soon***',
	'F01CardExpSortChangeToday':'***Coming Soon***',
	'F01CardExpCyclesToday':'***Coming Soon***',
	'F01CardExpMetalDetectorToday':'***Coming Soon***',
	'F01CardExpIncorrectBags':'***Coming Soon***',
	'F01CardExpOpenBags':'***Coming Soon***',
	'F01CardExpSampleBags':'***Coming Soon***',
	'F01CardExpRejectionsToday':'***Coming Soon***',
	'F01CardExpContainer':'***Coming Soon***',
	'F01CardExpEmptyRejects':'***Coming Soon***',
	'F01CardExpFullRejects':'***Coming Soon***',
	'F01CardExpProductionRate':'***Coming Soon***',
	'F01CardExpDailyProduction':'***Coming Soon***',
	'F01CardExpFillingStation':'***Coming Soon***',
	'F01CardExpPalletizer':'***Coming Soon***',
	'F01CardExpEmptyPalArea':'***Coming Soon***',
	'F01CardExpBagTransArea':'***Coming Soon***',
	'F01CardExpFullPalArea':'***Coming Soon***',
	'F01CardExpSheetDispArea':'***Coming Soon***',
	'F01CardExpProductonTime':'***Coming Soon***',
	'F01CardExpProgramChngsToday':'***Coming Soon***',
	'F01CardExpEndofBatch':'***Coming Soon***',
	'F01CardExpEmptyPallets':'***Coming Soon***',
	'F01CardExpBagProduction':'***Coming Soon***',
	'F01CardExpPalletProduction':'***Coming Soon***',
	'F01CardExpFSDifference':'***Coming Soon***',
	'F01CardExpProduct':'***Coming Soon***',
	'F01CardExpCapacity':'***Coming Soon***',
	'F01CardExpUndersized':'***Coming Soon***',
	'F01CardExpOversized':'***Coming Soon***',
	'F01CardExpSpeed':'***Coming Soon***',
	'F01CardExpInclination':'***Coming Soon***',
	'F01CardExpBoardHeigh':'***Coming Soon***',
	'F01CardExpMoisture':'***Coming Soon***',
	'F01CardExpWaterAdd':'***Coming Soon***',
	'F01CardExpOpMode':'***Coming Soon***',
	'F01CardExpOpHours':'***Coming Soon***',
	'F01CardExpTemp':'***Coming Soon***',
	'F01CardExpPowerInput':'***Coming Soon***',
	'F01CardExpFullPalletCount':'***Coming Soon***',
	'F01CardExpLoadEmptyPallets':'***Coming Soon***',
	'F01CardExpOeeValue':'***Coming Soon***',
	'F01CardExpOeeAvailable':'***Coming Soon***',
	'F01CardExpOeePerformance':'***Coming Soon***',
	'F01CardExpOeeQuality':'***Coming Soon***',
	'F01CardExpOeeDowntime':'***Coming Soon***',
	'F01CardExpOperatingFactor':'***Coming Soon***',
	'F01CardExpThroughput':'***Coming Soon***',
	'F01CardExpOeeRejects':'***Coming Soon***',
	'F01CardExpMachineDetailPage':'***Coming Soon***',
	'F01CardExpReportSettingsPage':'***Coming Soon***',
	'F01CardExpReportOverviewPage':'***Coming Soon***',
	'F01CardExpCompanyOverviewPage':'***Coming Soon***',
	'F01CardExpAlertSettingsPage':'***Coming Soon***',
	'F01CardExpMsgAdminPage':'***Coming Soon***',
	'F01CardExpUserRolesPage':'***Coming Soon***',
	'F01CardExpCompaniesPage':'***Coming Soon***',
	'F01CardExpDummyMachinePage':'***Coming Soon***',
	'F01CardExpPermissionsPage':'***Coming Soon***',
	'F01CardExpMaintenanceAidPage':'***Coming Soon***',
	'F01CardExpMachinePage':'***Coming Soon***',
	'F01CardExpLogBookTab':'***Coming Soon***',
	'F01CardExpServiceRequestTab':'***Coming Soon***',
	'F01CardExpMessagesTab':'***Coming Soon***',
	'F01CardExpDocumentSTab':'***Coming Soon***',
	'F01CardExpOeeTab':'***Coming Soon***',
	'F01CardExpMaintenanceTab':'***Coming Soon***',
	'F01CardExpMachineSpecificTab':'***Coming Soon***',
	'F01CardExpGeneralTab':'***Coming Soon***',
	'F01CardExpGoodPerformance':'***Coming Soon***',
	'F01CardExpWrongApplBags':'***Coming Soon***',
	'F01CardExpReelChanges':'***Coming Soon***',
	'F01CardExpTypeCurrent':'***Coming Soon***',
	'F01CardExpInterruptsFeigeGeneral':'***Coming Soon***',
	'F01CardExpInterruptsAdams':'***Coming Soon***',
	'F01CardExpInterruptsISF':'***Coming Soon***',
	'F01CardExpInterruptsIVT':'***Coming Soon***',
	'F01CardExpInterruptsMiniAdams':'***Coming Soon***',
	'F01CardExpInterruptsRVT':'***Coming Soon***',
	'F01CardExpInterruptsNewtecTerram':'***Coming Soon***',
	'A-AlertTip-main_acc-ORANGE':'Cảnh báo màu da cam khi tăng tốc chính',
	'A-AlertTip-main_acc-YELLOW':'Cảnh báo màu vàng tăng tốc chính',
	'A-AlertTip-main_acc-RED':'Báo động đỏ tăng tốc chính',
	'A-AlertTip-u8MachAvail-AVAIL':'Cảnh báo sẵn có',
};