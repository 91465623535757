export const LANG_ZH_NAME = 'zh';
export const LANG_ZH_TRANS = {
	'0030565001AE':'ELEMENTRA® ',
	'003056500368':'INTEGRA®',
	'003056500394':'ROTO-PACKER®',
	'003056500398':'ROTO-PACKER®',
	'0030565011A8':'ROTO-PACKER®',
	'0030565011AE':'ELEMENTRA®',
	'003056501200':'ROTO-PACKER®',
	'00305650121E':'INTEGRA®',
	'003056A98058':'ROTO-PACKER®',
	'057X1to2Rpm':'1X-2X Rpm',
	'057X2to3Rpm':'2.5X - 3X Rpm',
	'A000Key':'钥匙',
	'A001Country':'中文',
	'A002German':'Deutsch',
	'A003English':'English',
	'A004Username':'邮箱',
	'A005Login':'登录',
	'A006Password':'密码',
	'A007RemPassword':'记住密码',
	'A008Login':'登录',
	'A009Logout':'退出',
	'A010Production':'产量 ',
	'A011Availability':'生产能力',
	'A012Maintenance':'设备维护',
	'A013RemainingUnits':'剩余量',
	'A014ProductionTime':'总时间',
	'A015ProductionTodayGeneral':'今日产量',
	'A015ProductionToday':'今日产量',
	'A016Units':'单位',
	'A017RemProduction':'总产量',
	'A018LastReset':'上次设置',
	'A019Faults':'今日故障',
	'A019FaultsBatch':'今日故障',
	'A020Count':'计数',
	'A021FaultDuration':'今日故障时间',
	'A022Minutes':'分钟',
	'A023BagCounterPerFS':'今日累计包装数量',
	'A023BagCounterPerFSBatch':'今日累计包装数量',
	'A024FillingStation':'填充嘴',
	'A025FSDifference':'计数器扩展',
	'A026AtLeast1Caps':'至少1个大写字母',
	'A026FSworstspout':'有故障的出料嘴',
	'A027Percent':'百分比',
	'A028RejectionsToday':'今日剔除数',
	'A028RejectionsTodayBatch':'今日剔除数',
	'A029FilterStop':'除尘站',
	'A030RotaryDriveStop':'ROTO-PACKER®',
	'A031ProductFeedStop':'物料供给',
	'A032RadimatStop':'RADIMAT®',
	'A033PalletizerStop':'码垛机',
	'A034CompAirFault':'压缩空气',
	'A035TypeChange':'今日产品更换',
	'A035TypeChangeBatch':'今日产品更换',
	'A036ProductionRate':'生产率',
	'A037SpeedRelated':'包 / 小时',
	'A038UnitsPerHour':'产量 / 小时',
	'A039GoodWeights':'合格率',
	'A040ScissorHose':'剪刀阀软管',
	'A041ConnectingHose':'连接软管',
	'A042ConeGasket':'锥阀密封圈',
	'A043AerationPlates':'充气板',
	'A043ScissorHose':'剪刀阀软管',
	'A044ConnectingHose':'连接软管',
	'A044RegulationValve':'调节阀传输空气',
	'A045ConeFeeding':'锥阀给料',
	'A045ConeGasket':'锥阀密封圈',
	'A046AerationPlates':'充气板',
	'A046BagTransBelt':'皮带输送机',
	'A047BagTransBearing':'输送机轴承',
	'A047RegulationValve':'调节阀',
	'A048ConeFeeding':'锥阀给料',
	'A048SuctionCup':'吸盘',
	'A049BagTransBelt':'传输皮带',
	'A049BagTransCyl':'气缸',
	'A050BagTransBearing':'轴承',
	'A050SucRailCyl':'吸袋气缸',
	'A051SuctionCup':'吸盘',
	'A051VacuumPump':'真空泵',
	'A052BagTransCyl':'气缸',
	'A052BagTransCylinde':'气缸',
	'A052CableHightAdj':'鞍座高度调节电缆',
	'A053HullMinSensor':'感应器',
	'A053SucRailCyl':'吸袋气缸',
	'A053SucRailCylinder':'吸袋气缸',
	'A054Last48Hours':'持续48小时',
	'A054VacuumPump':'真空泵',
	'A055CableHightAdj':'干燥的压缩空气',
	'A055Last14Days':'持续14天',
	'A056HullMinSensor':'活塞式鼓风机',
	'A056off':'关闭',
	'A057InOperation':'使用中',
	'A058Faulty':'故障',
	'A059InMaintenance':'维护中',
	'A060HourlyValues':'产能 / 小时',
	'A061DischargesTotal':'共输出',
	'A062CreationDate':'创建日期',
	'A062Period':'期间',
	'A063To':'至',
	'A064BatchValue':'批次值',
	'A064DailyValue':'每日价值',
	'A064DailyValues':'每日效益',
	'A065supply':'供应',
	'A066Transport':'运输',
	'A067Feeding':'给料',
	'A068PackagesMissing':'无包装材料',
	'A069NoHistoricalData':'在此期间无历史数据',
	'A070And':'和',
	'A071Available':'准备就绪',
	'A072ReallyReset':' 确定重置？',
	'A073Details':'详情',
	'A074General':'总况',
	'A075Machines':'设备',
	'A076Dashborad':'指示板',
	'A077Administration':'管理',
	'A078UserRoles':'用户 & 职位',
	'A079Companies':'公司',
	'A080Machines':'设备',
	'A081Date':'日期',
	'A082Transport':'运输',
	'A083Containermissing':'包装材料缺失',
	'A084Availability':'生产能力',
	'A085TypeChanges':'今日产品更换',
	'A086EmptyRejects':'今日空袋剔除数',
	'A086EmptyRejectsBatch':'今日空袋剔除数',
	'A087FullRejects':'今日满袋剔除数',
	'A087FullRejectsBatch':'今日满袋剔除数',
	'A088OpRelease':'开始',
	'A089ProdFeedStop':'停止给料',
	'A090AuxDrive':'辅助驱动器',
	'A091Consumables':'易耗品',
	'A092DischLineStop':'输送线停止',
	'A093TypeChange':'产品转换',
	'A094DailyProduction':'今日产量',
	'A094DailyProductionBatch':'今日产量',
	'A095Energy':'能量',
	'A096EffectivePower':'有效功率',
	'A097ApparentPower':'表观功率',
	'A098CompAirFlow':'压缩空气耗量',
	'A099ResetFailed':'重置失败',
	'A1000Spanish':'Español',
	'A1000UploadMaintenanceAid':'上传维护辅助工具',
	'A1001UploadMaintenanceAidSuccess':'维护帮助已成功上传',
	'A1002UploadMaintenanceAidFailure':'上传维护辅助工具时出错',
	'A1005MachineServiceRequest':'服务请求',
	'A1006RequestService':'请求服务',
	'A1007ServiceContract':'服务合约',
	'A1008ServiceRequestSuccesful':'服务请求已成功处理',
	'A1009ServiceRequestFailure':'服务请求出错',
	'A100AcessDenied':'登录失败',
	'A100AlertmailSubject':'QSI警报：设备名称',
	'A1010LogBook':'日志',
	'A1011CreateLogBook':'创建日志项目',
	'A1012EditLogBook':'编辑日志项目',
	'A1013LogBookItemType':'日志项目类型',
	'A1014LogBookItem':'日志项目',
	'A1015LogBookItemSaved':'已保存日志项目',
	'A1016ErrorInSavingLogBookItem':'保存日志项目时出错',
	'A1017ErrorInRetrievingLogBookItem':'检索日志时出错',
	'A1018ErrorInDeletingLogBookItem':'删除日志项目时出错',
	'A1019LogBookItemDeleted':'日志项目已删除',
	'A101MailSalutation':'用户，USER_NAME,',
	'A101ResetSuccessful':'Métrica foi redefinida',
	'A1020LogBookItemIsDone':'完成',
	'A1021LogBookItemType':'物品种类',
	'A1022ServiceEmail':'服务邮箱',
	'A1023CannotMakeServieRequest':'您无法请求服务，因为此设备没有任何服务电子邮件',
	'A1024MaxUsers':'最大用户',
	'A1024MaxUsersReached':'已达到公司的最大用户数限制',
	'A1025AtLeast8Characters':'至少8个字符',
	'A1027AtLeast1Lowercase':'至少1个小写字母',
	'A1028AtLeast1Number':'至少1个数字（0-9）',
	'A1029AtLeast1SpecialChar':'至少一个特殊字符（！，。[]）',
	'A102CompanyAddressNewtec':'NEWTEC BAG PALLETIZING <br>32 avenue de Suisee <br>ZI Ile Napoléon - BP 256 <br>68315 Illzach cedex - France',
	'A102CompanyAddressAventus':'AVENTUS GmbH & Co. KG <br>Katzheide 33 <br>D-48231 Warendorf <br>Germany / Deutschland',
	'A102CompanyAddressNiagaraCanada':'HAVER & BOECKER Niagara Canada <br>225 Ontario St. <br>St. Catharines, ON, L2R 7B6 <br>Canada',
	'A102CompanyAddressNiagaraChile':'HAVER & BOECKER Andina <br>Av. El salto 4001, piso 7, of. 71 Comuna Huechuraba <br>8580641 – Santiago <br>Chile',
	'A102CompanyAddressNiagaraBrazil':'HAVER & BOECKER NIAGARA Latinoamericana <br>Av. Dra. Celia Marli S. Salgado Matos, 192 <br>Pedro Leopoldo - MG - 33600-000 <br>Brazil',
	'A102CompanyAddressNiagara':'HAVER & BOECKER NIAGARA <br>Robert-Bosch-Straße 6 <br>D-48153 Münster <br>Germany / Deutschland',
	'A102CompanyAddressFeige':'Feige FILLING GmbH <br> Rogen 6a <br> D-23843 Bad Oldesloe <br> Germany / Deutschland',
	'A102CompanyAddressH_B':'HAVER & BOECKER OHG <br>Carl-Haver-Platz 3 <br>D-59302 Oelde <br>Germany / Deutschland',
	'A102CompanyAddressBehnBates':'Behn Bates Maschinenfabrik GmbH & Co. KG <br> Robert-Bosch Str. 6 <br> 48153 Münster <br> Germany',
	'A102CompanyEmailAventus':'hotline@aventus.global',
	'A102CompanyEmailNiagaraCanada':'info@haverniagara.ca',
	'A102CompanyEmailNiagaraChile':'haverandina@haverniagara.cl',
	'A102CompanyEmailNiagaraBrazil':'info@haverniagara.com.br',
	'A102CompanyEmailNiagara':'info@haverniagara.com',
	'A102CompanyEmailNewtec':'service@newtecbag.com',
	'A102CompanyEmailFeige':'hotline@feige.com',
	'A102CompanyEmailH_B':'hotline@haverboecker.com',
	'A102CompanyEmailBehnBates':'service@behnbates.com',
	'A102CompanyPhoneNumberNewtec':'Phone: +33 389633753',
	'A102CompanyPhoneNumberFeige':'Phone: +49 4531 89 09 222',
	'A102CompanyPhoneNumberAventus':'Phone: +49 2581 4591 2222',
	'A102CompanyPhoneNumberNiagaraCanada':'Phone: +1 800-325-5993',
	'A102CompanyPhoneNumberNiagaraChile':'Telefon: +56 (2) 2307-0440',
	'A102CompanyPhoneNumberNiagaraBrazil':'Telefon: +55 (31) 3661-1371',
	'A102CompanyPhoneNumberNiagara':'Phone: +49 251 9793 164',
	'A102CompanyPhoneNumberH_B':'Phone: +49 2522 30 371 ',
	'A102CompanyPhoneNumberBehnBates':'Phone: +49 251 979- 0',
	'A102CompanyWebsiteNewtec':'www.newtecbag.com',
	'A102CompanyWebsiteFeige':'www.feige.com',
	'A102CompanyWebsiteAventus':'www.aventus.global',
	'A102CompanyWebsiteNiagaraCanada':'https://haverniagara.com',
	'A102CompanyWebsiteNiagaraChile':'https://haverniagara.com',
	'A102CompanyWebsiteNiagaraBrazil':'www.brazilniagara.com',
	'A102CompanyWebsiteNiagara':'www.haverniagara.com',
	'A102CompanyWebsiteH_B':'www.haverboecker.com',
	'A102CompanyWebsiteBehnBates':'www.behnbates.com',
	'A102MailContent':'<p>There occured an alert in one of your machines: <br> <br>Machine name: <b>MACHINE_NAME</b> <br>Alert message: <b>ALERT_TYPE</b> <br> Machine link:  <a href=\"MACHINE_LINK_TO_DETAIL\">MACHINE_LINK</a> <br> Alert value: ALERT_VALUE_TEXT <em>ALERT_VALUE_SUMMARY  </em><br> Alert time: ALERT_TIME_TEXT<br>ALERT_TIP</p><p style=\'font-size: 12px;\'>This e-mails was generated automatically because of a subscription at <a href=\'TYP_WEBSITE\' style=\'text-decoration: none; font-color: blue;\'>TYP_WEBSITE</a>. To unsubscribe login on the page and select <b>Settings</b> below the menu <b>Alerting</b>. <br>Please do not reply to this mail. For questions please contact <a href=\"mailto:COMPANY_EMAIL\">COMPANY_EMAIL</a>. <br> <br></p><p style=\'font-size: 12px;\'>Diese E-Mail wurde aufgrund eines Abos automatisch von <a href=\'TYP_WEBSITE\' style=\'text-decoration: none; font-color: blue;\'>TYP_WEBSITE</a> generiert. Zum Beenden des Abos loggen Sie sich auf der Seite ein und klicken unter <b>Alarmmeldungen</b> auf den Menüpunkt <b>Einstellungen</b>. <br>Bitte Antworten Sie nicht auf diese Mail. Bei Fragen können Sie sich an <a href=\"mailto:COMPANY_EMAIL\">COMPANY_EMAIL</a> wenden. <br> <br></p><p> Best regards, <br> <br>COMPANY_ADDRESS <br> <br>COMPANY_PHONE_NUMBER <br>E-Mail: <a href=\"mailto:COMPANY_EMAIL\">COMPANY_EMAIL</a> <br>Internet: <a href=\'COMPANY_WEBSITE\'>COMPANY_WEBSITE</a> <br><small style=\"color:white;\">Alert Source Id: ALERT_SOURCE_ID</small></p>',
	'A102TypeChanges':'今日产品切换',
	'A102TypeChangesBatch':'今日产品切换',
	'A102TYPWebsite':'https://trackyourplant.com',
	'A1030Description':'描述',
	'A103EndOfBatch':'最后一批',
	'A103ResetmailSubject':'重置：设备名称',
	'A104FullPalletCount':'满垛',
	'A104ResetMailContent':'<p>The occurred alert is resolved: </p><p>Machine name: <b>MACHINE_NAME</b><br>Alert message: <b>ALERT_TYPE</b><br>Machine link: MACHINE_LINK<br><p style=\'font-size: 12px;\'>This e-mails was generated automatically because of a subscription at <a href=\'TYP_WEBSITE\' style=\'text-decoration: none; font-color: blue;\'>TYP_WEBSITE</a>. To unsubscribe login on the page and select <b>Settings</b> below the menu <b>Alerting</b><br>Please do not reply to this mail. For questions please contact COMPANY_EMAIL.</p><p style=\'font-size: 12px;\'>Diese E-Mail wurde aufgrund eines Abos automatisch von <a href=\'TYP_WEBSITE\' style=\'text-decoration: none; font-color: blue;\'>TYP_WEBSITE</a> generiert. Zum Beenden des Abos loggen Sie sich auf der Seite ein und klicken unter Alarmmeldungen auf den Menüpunkt Einstellungen.<br>Bitte Antworten Sie nicht auf diese Mail. Bei Fragen können Sie sich an COMPANY_EMAIL wenden.</p><p>Best regards,<br>COMPANY_ADDRESS<br></p><p>COMPANY_PHONE_NUMBER<br>E-Mail: COMPANY_EMAIL<br>Internet: COMPANY_WEBSITE <br></p><small style=\"color:white;\">Alert Source Id: ALERT_SOURCE_ID</small>',
	'A105ConnectivityMailSubject':'连接错误：设备名称',
	'A105LoadEmptyPallets':'空托盘进给',
	'A106Fillings':'填充',
	'A106PLCMailSubject':'PLC 警报：设备名称',
	'A107FillingsPerHour':'填充率 / 时',
	'A107PLCResolvedMailContent':'<p>The following alert was resolved  at machine MACHINE_NAME:.<br><br> ALERT_TYPE <br><br> MACHINE_LINK <br><br> This e-mails was generated automaticly because of a subscription at <a href=\'TYP_WEBSITE\' style=\'text-decoration: none; font-color: blue;\'>TYP_WEBSITE</a>. To unsubscribe login on the page and select <b>Settings</b> below the menu <b>Alerting</b>.<br><p>Please do not reply to this mail. For questions please contact COMPANY_EMAIL.</p><br><br>Best regards,<br><br>COMPANY_ADDRESS<br><br>COMPANY_PHONE_NUMBER<br>E-Mail: COMPANY_EMAIL<br>Internet: COMPANY_WEBSITE ',
	'A108Availability':'生产能力',
	'A108AvailabilityMailSubject':'能力 警报：设备 名称',
	'A109FillingsRemain':'填充余量',
	'A110StartOfOrder':'批次处理开始时间',
	'A111ProductID':'产品批号',
	'A112TargetWeight':'目标重量',
	'A113Kg':'公斤',
	'A114CurrentOrder':'目前订单',
	'A115FillingCount':'当前批次',
	'A116CycleTime':'设备循环时间',
	'A117Total':'今天',
	'A118CentreStation':'中心处理站',
	'A119N2Station':'氮气站',
	'A120FillingStation':'填充嘴',
	'A121SealingStation':'封口装置',
	'A122Seconds':'秒',
	'A123FaultLength':'设备故障',
	'A124Minutes':'分钟',
	'A124Minute':'分钟',
	'A125ExtFaultLength':'外部故障',
	'A126FillingCount':'合计填充量',
	'A127WithinTolerance':'误差范围内的填充',
	'A128OutsideTolerance':'误差范围外的填充',
	'A129BagManufacture':'合格生产',
	'A130BagsPerHour':'包 / 小时',
	'A131BagQuality':'袋子质量',
	'A132CorrPlacedbags':'上袋合格率',
	'A133TwoAttempts':'二次开袋',
	'A134ThreeAttempts':'三次开袋',
	'A135DiscardeBags':'废弃的袋子',
	'A136Faults':'故障',
	'A137CompAir':'压缩空气',
	'A138ProductFeed':'物料供给 （称重）',
	'A139EmptyBagMag':'空袋袋仓',
	'A140Separation':'分离',
	'A141Vacuum':'真空 ',
	'A142FillingStation':'填充站',
	'A143FullBagTransport':'满袋输送',
	'A144BagSealing':'封口',
	'A145Disrupt':'干扰',
	'A145AccessDoor':'检修门',
	'A145DSafetyLightBarrier':'安全光柵',
	'A145UpstreamSupply':'上游供应',
	'A145EmergencyStop':'急停开关',
	'A146BunChangeAbs':'总共更换小车次数',
	'A147BunChangeToday':'今日更换小车次数',
	'A147BunChangeTodayBatch':'今日更换小车次数',
	'A148BagsPerBundle':'每车可容纳的包装袋数量',
	'A149TypeChanges':'今日产品更换',
	'A149TypeChangesBatch':'今日产品更换',
	'A150FillingTime':'填充时间',
	'A151DosingUnit1':'物料供给',
	'A152DosingUnit2':'物料供给2',
	'A153AerInletBox1':'充气箱入口',
	'A154AerInletBox2':'充气箱入口2',
	'A154DosingUnit1':'物料供给',
	'A155DosingUnit2':'物料供给2',
	'A155RotaryDrive':'旋转驱动器',
	'A156AerInletBox1':'充气箱入口',
	'A156DischargeBelt':'卸包皮带机',
	'A157AerInletBox2':'充气箱入口2',
	'A157AlignmentBelt':'袋对中输送机',
	'A158BagCleaning':'清包机',
	'A158RotaryDrive':'旋转驱动器',
	'A159AccelerationBelt':'加速皮带',
	'A159DischargeBelt':'卸包皮带',
	'A160AlignmentBelt':'对中皮带',
	'A160CheckBelt':'校正秤皮带',
	'A161BagCleaning':'清包机',
	'A161FlatBelt':'平皮带输送机',
	'A162AccelerationBelt':'加速皮带',
	'A162RejectionBelt':'剔除机皮带',
	'A163CheckBelt':'校正秤皮带',
	'A163RejectionDrive':'剔除机提升驱动器',
	'A164FlatBelt':'平板输送皮带',
	'A164RotaryCutter':'破包机',
	'A165RejectionBelt':'破包机皮带',
	'A165ScreenDrum':'振动筛毂',
	'A166RejectionDrive':'剔除机提升驱动器',
	'A166Welding':'焊封装置',
	'A167Cutter':'切刀',
	'A167RotaryCutter':'破包机',
	'A168ScreenDrum':'分离机',
	'A168SuctionCup':'吸盘',
	'A169Brakes':'刹车',
	'A170VacuumPump':'真空泵',
	'A171Filter':'过滤空气',
	'A172BagDisConveyor':'袋分配输送机',
	'A173BagTurnConveyor':'转位输送机',
	'A174BagTurnCross':'转包十字架',
	'A175BagDisConveyor':'袋分配输送机',
	'A175BagTurnClamp':'转位夹具',
	'A176BagTurnConveyor':'转位输送机',
	'A176GroupingBelt':'编组输送机皮带',
	'A177BagTurnCross':'转包十字架',
	'A177RowPusher':'推袋器',
	'A178DoubleStripPlate':'双推袋板',
	'A179ConformingPlate':'对中板',
	'A179GroupingBelt':'编组输送机皮带',
	'A180GantrySafety':'起重机架安全开关',
	'A180RowPusher':'推袋器',
	'A181DoubleStripPlate':'双推袋板',
	'A181Gantry':'起重机架',
	'A182ConformingPlate':'对中板',
	'A182PalletAlignment':'空托盘对齐装置',
	'A183GantrySafety':'起重机架安全开关',
	'A183PalletRoller1':'托盘滚筒输送机',
	'A184Gantry':'起重机架',
	'A184PalletDispenser':'空托盘分配器',
	'A185PalletAlignment':'空托盘对齐装置',
	'A185PalletRoller2':'托盘滚筒输送机',
	'A186PalletRoller1':'托盘滚筒输送机',
	'A186PalletRoller3':'托盘滚筒输送机',
	'A187CyclesChain':'链条',
	'A187PalletDispenser':'空托盘分配器',
	'A188BundlePusher':'推袋气缸',
	'A188PalletRoller2':'托盘滚筒输送机',
	'A189BundlePartition':'推袋气缸',
	'A189PalletRoller3':'托盘滚筒输送机',
	'A190BagLifter':'提袋气缸',
	'A190CyclesChain':'链条',
	'A191BundlePusher':'推袋气缸',
	'A191PressRolls':'整形气缸',
	'A192BundlePartition':'分离气缸',
	'A192OpeningStation':'开袋站气缸',
	'A193BagApplicator':'上袋机气缸',
	'A193BagLifter':'提袋气缸',
	'A194BagSupport':'袋支撑气缸',
	'A194PressRolls':'压辊气缸',
	'A195OpeningStation':'开袋站气缸',
	'A195Pusher':'推袋气缸',
	'A196BagApplicator':'上袋机气缸',
	'A196StretchPliers':'拉伸钳气缸',
	'A197BagSupport':'袋支撑气缸',
	'A197FillingStation':'填充站气缸',
	'A198FilledBagGripper':'满袋抓手气缸',
	'A198Pusher':'推袋气缸',
	'A199StretchPliers':'拉伸钳气缸',
	'A199SucBagSeperation':'袋分离站吸盘',
	'A200FillingStation':'填充站气缸',
	'A200SucOpenStation':'开袋站吸盘',
	'A201FilledBagGripper':'满袋抓手气缸',
	'A201Remaininghours':'剩余时间',
	'A202PriceTotal':'合计',
	'A202SucBagSeperation':'取袋吸盘',
	'A203RemainingNumberFillings':'剩余填充量',
	'A203SucOpenStation':'开袋站吸盘',
	'A204SafetyDoor':'安全门开关',
	'A205SafetyLightBarrier':'光栅',
	'A206BagTransport':'袋输送装置',
	'A207EmptyPalletTrans':'空托盘仓',
	'A208Palletizer':'码垛机',
	'A209FullPalletTrans':'满袋托盘输送',
	'A210NumberTodayPiece':'今天包装量 ',
	'A211Programme':'程序',
	'A212Efficiency':'效率',
	'A213Loginsuccessful':'登录成功',
	'A214CurrentPerformance':'当前运行状态',
	'A215CyclesToday':'今日循环量',
	'A215CyclesTodayBatch':'今日循环量',
	'A216RejectsToday':'今日剔除量',
	'A216RejectsTodayBatch':'今日剔除量',
	'A217MetalDetectorToday':'今日金属检测量',
	'A217MetalDetectorTodayBatch':'今日金属检测量',
	'A218BrokenBagsToday':'今日破袋检测量',
	'A218BrokenBagsTodayBatch':'今日破袋检测量',
	'A219FaultPerShift':'当前批次',
	'A220FaultBagManufacture':'制袋',
	'A221FaultBagFilling':'袋填充',
	'A222FaultBagTransport':'袋运输',
	'A223FaultSealing':'封口',
	'A224FaultWeigher':'填充嘴',
	'A225FaultPeriphery':'其他外界因素',
	'A226Shift':'班次',
	'A227Container':'包装材料',
	'A228Hours':'小时',
	'A229NoPackage':'无包装材料',
	'A230NoProduct':'无物料',
	'A231NoOutlet':'无卸包',
	'A232GoodPerformance':'生产',
	'A233GoodWeights':'合格包',
	'A234NotPlacedBags':'掉袋率',
	'A234NotPlacedBagsAutomatic':'自动落袋',
	'A235CollectiveFaultsToday':'今日故障数',
	'A235CollectiveFaultsTodayBatch':'今日故障数',
	'A236Applicator':'上袋机',
	'A237NetWeigher':'净重称',
	'A238IncorrectWeight':'重量不合格包',
	'A239Hours':'小时',
	'A240QrCode':'QR码',
	'A241OpenBags':'开袋',
	'A242FaultCheckWeigher':'复查称',
	'A243DashboardAllMachines':'指示板 / 所有设备',
	'A244DashboardState':'状态',
	'A245DashboardMachineStateRunning':'开启',
	'A246DashboardMachineStateOffline':'关闭',
	'A247DashboardMachineStateMaintenance':'必要维护',
	'A248DashboardMachineStateError':'错误',
	'A249SortChangeToday':'今日产品更换',
	'A249SortChangeTodayBatch':'今日产品更换',
	'A250EmptyBagMagazineToday':'今日上空袋数',
	'A250EmptyBagMagazineTodayBatch':'今日上空袋数',
	'A251FaultCheckWeigher':'今日故障',
	'A252IVTSTOP':'INTEGRA®',
	'A253N/A':'无',
	'A254CyclePumpFS1':'泵 FS 1',
	'A255CyclePumpFS2':'泵 FS 2',
	'A256CyclePumpFS3':'泵 FS 3',
	'A257ConHoseFS1':'连接软管 FS 1',
	'A258ConHoseFS2':'连接软管 FS 2',
	'A259ConHoseFS3':'连接软管 FS 3',
	'A260InfSleeveFS1':'膨胀套筒 FS 1',
	'A261InfSleeveFS2':'膨胀套筒 FS 2',
	'A262InfSleeveFS3':'膨胀套筒 FS 3',
	'A263BundlePusher':'推袋器',
	'A264VacuumUnit':'真空装置',
	'A265TravellingDrive':'移动装置',
	'A266CenteringUnit':'中心装置',
	'A267EmptyBagMagazine':'空袋仓',
	'A268BeltPlant':'皮带输送机',
	'A269IvtTurbine':'叶轮箱',
	'A270IvtTripSaddle':'袋鞍',
	'A271IvtCoarseFeed':'粗喂料',
	'A272IvtBagHolder':'压袋器',
	'A273IvtFillBoxAir':'充气箱',
	'A274IvtFillChanAir':'充气环',
	'A275IvtInflSleeve':'膨胀套筒',
	'A276IvtInletBoxAir':'充气箱入后',
	'A277IvtFillTubeBlow':'带吹气装置的填充嘴',
	'A278IvtOutlChanAir':'排气出口',
	'A279IvtPusher':'推袋',
	'A280IvtSaddleHeight':'袋鞍高度调节',
	'A281IvtCloseAnvil':'夹具关闭',
	'A282IvtBagSeal':'封口',
	'A283IvtFineFeedAdj':'精喂料调整',
	'A284Interrupts':'今日被中断数',
	'A284InterruptsGeneralPage':'今日被中断数',
	'A284InterruptsBatch':'今日被中断数',
	'A285BagCounterShift':'每班次累积包装数',
	'A286WrongBags':'上袋错误',
	'A287ReelChanges':'今日更换滚筒',
	'A287ReelChangesBatch':'今日更换滚筒',
	'A288VibrationDuration':'延长振动时间',
	'A289Total':'总共',
	'A290HopperMin':'最小料仓容积',
	'A291ManualActivation':'手动激活',
	'A292FillingRelease':'开始填充',
	'A293EGF':'EGF®',
	'A294PalletTrasport':'托盘输送',
	'A295SafetyCircuit':'安全线圈',
	'A296FltCompAir':'今日压缩空气故障',
	'A296FltCompAirBatch':'今日压缩空气故障',
	'A297StrokesPump':'今日泵停顿时间',
	'A297StrokesPumpBatch':'今日泵停顿时间',
	'A298MaxStrokeDur':'今日最大暂停时间',
	'A298MaxStrokeDurBatch':'今日最大暂停时间',
	'A299AvgStrokes':'今日每包平均停顿时间',
	'A299AvgStrokesBatch':'今日每包平均停顿时间',
	'A300InternalPressure':'内部气压',
	'A301Max':'最大',
	'A301MessageAdministration':'信息中心',
	'A302ErrorNumber':'错误次数',
	'A302Millibar':'小电控柜',
	'A303ActivateAlertMail':'警告邮件激活',
	'A303SetValueMax':'最大价值设置',
	'A304AlertText':'警示文本',
	'A304SetValueMin':'最小价值设置',
	'A305ActivateResolvedMail':'已解决问题邮件激活',
	'A305SetValueChanged':'您的设置已保存',
	'A305SetValueChangedRefreshWindow':'你的修改已被保存。刷新浏览器窗口后，更改将可见',
	'A306KW':'kW',
	'A306Resolved Text':'故障解除文本',
	'A306ResolvedText':'故障解除文本',
	'A307AddMessage':'信息',
	'A307KVA':'kVA',
	'A308EditMessage':'信息编辑',
	'A308Nm3PerH':'Nm³/h',
	'A309KWH':'kWh',
	'A310NM3':'Nm³',
	'A311CompAirVol':'压缩空气耗量',
	'A312ActiveEnergy':'能耗',
	'A313ReactivePower':'功率',
	'A314ChartConfiguration':'配置',
	'A315SetDateValueMax':'最长日期设置',
	'A316SetDateValueMin':'最短日期设置',
	'A317InvalidData':'无效数据',
	'A318DeleteUser':'确定删除该用户？',
	'A319EnterAllFields':'请填写数据',
	'A320EnterValidEmail':'请输入有效邮箱地址',
	'A321DeleteCompany':'确认删除该公司名?',
	'A322CouldNotDeleteUser':'管理员无法删除，请先变更至普通用户',
	'A323LoginSuccess':'登录成功',
	'A324Selected':'选择',
	'A325Total':'总共',
	'A326Reset':'重置',
	'A327Reporting':'报告',
	'A328ReportingActive':'激活',
	'A329ReportingActiveHelpPrefix':'激活时将被生成常规报告',
	'A330ReportingActiveHelpSuffix':'发送',
	'A331ReportingFrequency':'频率',
	'A332ReportingFrequencyDaily':'每天',
	'A333ReportingFrequencyWeekly':'每周',
	'A334ReportingFrequencyMonthly':'每月',
	'A335ReportingDynContent':'动态内容',
	'A336ReportingDynContIncludeElapsedMI':'包括过去维护时间',
	'A337ReportingLanguage':'语言',
	'A338ReportingProdDayEnd':'停止生产',
	'A339ReportingFirstReport':'第一封报告时间',
	'A340CardCtxMenuReport':'包含在报告里',
	'A341CardCtxMenuHistoReport':'报告里包含柱状图',
	'A342CardCtxMenuReset':'精度数据重置',
	'A343CardCtxMenuSettings':'设置',
	'A344CardLabelReport':'报告',
	'A345CardLabelHistoReport':'柱状图报告',
	'A346CardLabelMaintenReport':'维护报告',
	'A347ReportSettings':'设置',
	'A348Alerting':'警示 ',
	'A349AlertingSettings':'设置',
	'A350AlertingActive':'激活',
	'A351AlertingActiveHelpPrefix':'激活时将会发送警示报告',
	'A352AlertingActiveHelpSuffix':'以下可选择的警示信息',
	'A353AlertingTypes':'警示',
	'A354AlertTypeMainten':'以往维护人',
	'A355AlertTypeAvail':'生产能力',
	'A356AlertTypePLC':'PLC',
	'A357AlertTypeConError':'连接错误',
	'A358DeleteAdminUser':'管理员不能被删除',
	'A359MaintAids':'维护助手',
	'A360MaintInt':'维护中断',
	'A361ChooseMaschine':'选择设备',
	'A362Video':'视频',
	'A363InstructionManual':'操作说明书',
	'A364ExplodedviewDrawing':'防爆图纸',
	'A364SparePartCatalog':'备件图册',
	'A365OpenVideoHelp':'打开视频帮助',
	'A366OpenInstructionManual':'打开操作手册',
	'A367OpenExploadedView':'打开防爆视图',
	'A368ResetMaschineData':'设备数据重置',
	'A369BotWeld':'底部焊封',
	'A370BotWeldTef':'特氟隆底部焊封',
	'A371TopWeld':'顶部焊封',
	'A372TopWeldTef':'特氟隆顶部焊封',
	'A373LeftWeld':'左边角封',
	'A374LefWeldTef':'特氟隆左边角封',
	'A375RigWeld':'右边角封',
	'A376RigWeldTef':'特氟隆右边角封',
	'A377CutKnife':'切刀',
	'A378ReallyClearReport':'此操作会将报告里的所有项删除，而且不能恢复，请确定是否需要删除？',
	'A379ProdCurrShift':'当前生产批次',
	'A380ReallyDeleteItemReport':'此操作会将报告里的所有项删除，而且不能恢复，请确定是否需要删除？',
	'A380TypeCurrent':'当前设置',
	'A381SampleBags':'样袋不合格剔除',
	'A382OpenBags':'空袋不合格剔除',
	'A383IncorrectBags':'重量不合格包剔除',
	'A384NotPlacedBagsMan':'手动落袋',
	'A385Spout1':'维护 填充嘴1',
	'A386LastUpdate':'上次更新',
	'A386Spout2':'维护 填充嘴2',
	'A387Spout3':'维护 填充嘴3',
	'A388Spout4':'维护 填充嘴4',
	'A389LastUpdate':'上次更新',
	'A390FilledBagTrans':'满袋输送',
	'A391ConveyerSystem':'输送系统',
	'A392BeltBundleTrans':'输送皮带',
	'A393BagHolderBuffer':'压袋头',
	'A394SlidePlates':'闸板',
	'A395ImpellerShaft':'叶轮支撑轴',
	'A396ShaftAssembly':'轴',
	'A397ThreePosCylinder':'三位气缸',
	'A398DateFormatNoSeconds':'YYYY.MM.DD h:mm',
	'A399DateFormat':'YYYY.MM.DD h:mm:ss ',
	'A399DateFormatAngularPipe':'yyyy.MM.dd h:mm:ss ',
	'A400ConveyorSystem':'输送系统',
	'A400History':'历史',
	'A401ReportHistory':'历史报告',
	'A402ReportId':'ID报告',
	'A403ReportGeneratedTime':'报告生成时间',
	'A404DosingFeeder':'喂料阀',
	'A404ReportGeneratedBy':'报告生成者',
	'A405DownloadReport':'下载报告',
	'A406TriggeredHistoryReport':'报告生成开始',
	'A407ViewReport':'查看报告',
	'A408BagHolder':'压袋装置',
	'A408BagHolder ':'压袋装置',
	'A414BundleTransport':'推袋器',
	'A415HoistingDevice':'提升驱动器',
	'A416PlacerArm':'抓袋臂',
	'A417CrossProcess':'摇臂',
	'A418OpeningStation':'开袋站',
	'A421Claw':'抓爪',
	'A422WeightData':'称重数据 Ø',
	'A423StdDevData':'标准偏差数据',
	'A430kg':'公斤',
	'A431lb':'磅',
	'A432t':'吨',
	'A433klb':'千磅',
	'A434g':'克',
	'A435BagManufacture':'制袋',
	'A436Aux_Pump':'真空泵',
	'A437TopWelding':'顶部焊封',
	'A438PunchingDeviceWelding':'焊封撞击装置',
	'A439Aux_Vibrator1':'振动装置 1 ',
	'A440Aux_Vibrator2':'振动装置 2',
	'A441Aux_Vibrator3':'振动装置 3',
	'A442Truck_Loading':'装车机',
	'A443Truck_Available':'今日无卡车',
	'A444FS1':'填充嘴 1',
	'A445FS2':'填充嘴 2',
	'A446FS3':'填充嘴 3',
	'A447FS4':'填充嘴 4',
	'A448FS5':'填充嘴 5',
	'A449FS6':'填充嘴 6',
	'A450FS7':'填充嘴 7',
	'A451FS8':'填充嘴 8',
	'A452FS9':'填充嘴 9',
	'A453FS10':'填充嘴 10',
	'A454FS11':'填充嘴 11',
	'A455FS12':'填充嘴 12',
	'A456FS13':'填充嘴 13',
	'A457FS14':'填充嘴 14',
	'A458FS15':'填充嘴 15',
	'A459FS16':'填充嘴 16',
	'A460OeeValue':'设备综合效率',
	'A460Oee':'OEE',
	'A460PushAlert':'<h1>Alert: ALERT_TYPE</h1><p>Machine name: <b>MACHINE_NAME</b> <br>Alert message: <b>ALERT_TYPE</b>',
	'A461OeeAvailable':'有效性因素',
	'A461PushAlertResolved':'<h1>Resolved: ALERT_TYPE</h1><p>Machine name: <b>MACHINE_NAME</b> <br>Alert message: <b>ALERT_TYPE</b>',
	'A462OeePerformance':'运行因素 ',
	'A463DowntimePlaned':'计划检修时间',
	'A464DowntimeMachine':'设备检修时间',
	'A465DowntimeOther':'其他检修时间',
	'A466OperatingFactor':'操作因素',
	'A467Throughput':'生产量因素',
	'A468OeeRejects':'今天剔除量',
	'A469OeeQuality':'质量因素',
	'A470OeeDowntime':'检修时间',
	'A471RejectsTotal':'总共剔除量',
	'A472RejectsIncorrect':'剔除重量错误包',
	'A473RejectsOpen':'剔除未封口包',
	'A474RejectsSample':'剔除样品包',
	'A475RejectsMetal':'金检不合格剔除',
	'A476ConfigTable':'校准',
	'A477OEERowActive':'激活',
	'A478OEEProduction':'生产',
	'A479OEEBreak':'暂停',
	'A480OEEMaint':'维护',
	'A481OEETypeChg':'产品切换',
	'A482OEEOthers':'其他检修时间',
	'A483OEEFrequ':'频率',
	'A484OEEUnique':'唯一',
	'A485OEEDaily':'每天',
	'A486OEEWeekly':'每周',
	'A487Days':'天数',
	'A488Monday':'星期一',
	'A489Tuesday':'星期二',
	'A490Wednesday':'星期三',
	'A491Thursday':'星期四',
	'A492Friday':'星期五',
	'A494Saturday':'星期六',
	'A498Comment':'评论',
	'A495Sunday':'星期日',
	'A496From':'从',
	'A497To':'到',
	'A499TabMachineMessages':'设备信息',
	'A500MMLive':'在线信息',
	'A500VbeltDrive':'V型皮带驱动器',
	'A501MMStatistic':'信息统计',
	'A501VbeltTurbine':'叶轮箱V型皮带',
	'A502Impeller':'叶轮片',
	'A502MMLive':'设备在线信息',
	'A503FillingTube':'填充嘴',
	'A503MMStatistic':'设备信息统计',
	'A504BagGuide':'导向板',
	'A504Type':'型号',
	'A505Control':'控制',
	'A505Saddle':'袋鞍',
	'A506BoltBagChair':'推袋椅螺栓',
	'A506Number':'数量',
	'A507BushBagChair':'推袋椅衬套',
	'A507Message':'信息',
	'A508Amount':'数量',
	'A508BearingPusher':'轴承推进器',
	'A509Duration':'持续时间',
	'A509RearSlidePlate':'后置闸板',
	'A510SlideValve':'闸阀',
	'A510TonsPerHour':'吨/小时',
	'A511FrontSlidePlate':'前置闸板',
	'A511Tons':'吨',
	'A512RepairKitSlidePlates':'闸板维修包',
	'A512RPM':'轮/分',
	'A513BagChairChange':'更换退包椅',
	'A513BagTransBeltServo':'伺服橡胶皮带',
	'A514InfeedBelt':'喂料皮带',
	'A515FlattBelt':'平皮带',
	'A516BagTakingBelt':'接包皮带',
	'A517RollerConveyor':'辊式输送机',
	'A518LiftingGantry':'提升机架',
	'A519LinearDrive':'直线型驱动器',
	'A520SwivelJoint':'回转接头',
	'A521LiftingGantry':'辊式输送机提升机架',
	'A522FullPallets':'满托盘辊式输送机',
	'A523EvacConveyor':'出垛输送机',
	'A524SafetyBarrier':'安全光栅',
	'A525BasicCleaning':'基本清洁',
	'A526SafetyDevices':'安全装置',
	'A530CylSucBar':'吸盘气缸关闭',
	'A531FilledBagGripper1':'满袋抓手气缸1',
	'A532FilledBagGripper2':'满袋抓手气缸2',
	'A533CylCutKnife':'切刀气缸',
	'A534CylBotWelding':'袋底焊封气缸',
	'A535SpoutLift':'出料嘴提升',
	'A536CylClampTopWeld':'袋顶焊缝气缸夹具',
	'A537CylTopWeld':'袋顶焊缝气缸',
	'A538CylCornerWeld':'角封气缸',
	'A539AdjHeightDischargebelt':'高度自动调节皮带',
	'A540EmptyBagTransport':'空袋输送',
	'A541Virbator':'振动操作时间',
	'A542CylCoolPlatesCornerWeld':'角封冷却板气缸',
	'A543CylBagGuide':'袋导向板气缸',
	'A544CylClampBotWeld':'袋底焊封气缸夹具',
	'A545CylCoolBotWeld':'袋底冷却气缸',
	'A546CylFilledBagGripper3':'满袋抓手气缸3',
	'A547CylStatBagGripper':'抓手固定气缸',
	'A548CylEmptyBagGripper1':'空袋抓手气缸1',
	'A549CylStretching':'拉伸气缸',
	'A550CylOpenSpout':'开袋气缸',
	'A551CylBagAplicatorLift':'取袋气缸',
	'A552CylStretcherOpen':'拉伸钳气缸打开',
	'A553CylStretcherInwards':'拉伸钳内部气缸',
	'A554FoilTrackingDevice':'膜跟踪装置',
	'A555DischargeBelt2':'输送皮带BP2操作时间',
	'A556CylStretching':'拉伸装置气缸',
	'A557CylEmptyBagGripper2':'空袋抓手气缸2',
	'A558UnwindingDevice':'滚筒操作时间',
	'A559BagLenghtAdj':'袋长调节',
	'A560CylVibratorLift':'振动提升',
	'A561CylFilmGuide':'膜导向气缸',
	'A562CylPushFlaps':'推板气缸',
	'A563CylWeldReelChanger':'焊缝滚轮',
	'A564CylWeldReelChangerTef':'特氟隆焊缝滚轮',
	'A565DriveReelChanger':'滚轮驱动器',
	'A566DriveAdjCornerWeld':'角封宽度调节驱动器',
	'A567CylThreadingInwards':'螺纹气缸成功向内',
	'A568CylThreadingOutwards':'螺纹气缸成功向外',
	'A569CylThreadingDownwards':'螺纹气缸分离板朝下',
	'A570CylSucRailClose':'吸盘气缸关闭',
	'A571DischargeBelt2':'输送皮带BP2',
	'A572CylSpoutOpen':'填充嘴气缸打开',
	'A573Drives':'驱动器',
	'A574Cylinders':'气缸',
	'A575LowAttrition':'低易损件',
	'A576HighAttrition':'高易损件',
	'A577SetUpTimeAvBatch':'设置时间 Ø',
	'A578SetUpTimeAv':'今天Ø设置时间',
	'A579GrossTimeBatch':'总时间',
	'A580GrossTime':'今天总时间',
	'A581SetUpTime':'设置时间',
	'A582Downtime':'检修时间 ',
	'A583MonthBestPerf':'每月最佳操作',
	'A584PressureChamperSeal':'密封压力仓',
	'A585Aeration':'充气板',
	'A586SpillageRejector':'遗撒料收集装置',
	'A587VibrationBottle':'振动瓶',
	'A588VibrationTable':'振动台',
	'A589BagPlacing':'放袋',
	'A590RotaryFeeder':'旋转给料机',
	'A591TopSeamCleaning':'顶缝清洁',
	'A592Beltplant1':'皮带输送机 1',
	'A593Beltplant2':'皮带输送机 2',
	'A594HydraulikPump':'液压泵',
	'A595DriveEBTS':'undefined',
	'A596HostingEBTS':'undefined',
	'A597Ventilator':'undefined',
	'A598RD':'undefined',
	'A599SpoutCoarse':'undefined',
	'A600RotaryGateCoarse':'undefined',
	'A601HostingVIC':'undefined',
	'A602SpoutFine':'undefined',
	'A603RotaryGateFine':'undefined',
	'A604HostingVSC1':'undefined',
	'A605HostingVSC2':'undefined',
	'A606FBTransfer':'undefined',
	'A607FBTHost':'undefined',
	'A608FBTswivel':'undefined',
	'A609FBTClamp':'undefined',
	'A610FBTransport':'undefined',
	'A611FBTransportClamp':'undefined',
	'A612HostBFS':'undefined',
	'A613SwivelBFS':'undefined',
	'A614SideGussetblade':'undefined',
	'A615HostTopwelding':'undefined',
	'A616CloseTopWelding':'undefined',
	'A617Folding1':'undefined',
	'A618Folding2':'undefined',
	'A619Folding3 ':'undefined',
	'A620PUD':'undefined',
	'A621PUDWeld':'undefined',
	'A622BeltReject':'undefined',
	'A623RejectingDevice':'undefined',
	'A624BagLenght':'undefined',
	'A624PerfToday':'今日表现',
	'A625PerfTodayBatch':'表现',
	'A626Output':'今天的产出',
	'A627OutputBatch':'输出',
	'A628UnitperH':'袋/小时',
	'A629Percent':'%',
	'A630BagJunction':'袋连接板',
	'A631ToothedBelt':'张紧齿形带',
	'A632LevellingFoot':'检查调平脚 RAD',
	'A633PolymerGuide':'聚合物导轨+滚轮',
	'A634BallBushing':'球衬套耦合器',
	'A635ScissorHoseReplace':'剪刀阀软管',
	'A636ConnectingHoseReplace':'连接软管',
	'A637Bellow':'鞍座高度调节电缆',
	'A638TipperFrame':'检查自卸车框架',
	'A639HosePressureChamber':'检查软管压力室',
	'A640FlapFillingBox':'检查翻盖填充盒',
	'A641RotoLock':'ROTO-LOCK®',
	'A642Hours':'小时',
	'A643Temp':'°C',
	'A644Millisecond':'ms',
	'A645KgPerSecond':'kg/s',
	'A646RoundsperMinute':'rpm',
	'A647Meter':'m',
	'A648Millimeter':'mm',
	'A649Cubicmeter':'m³',
	'A650Liter':'l',
	'A651Counts':'pcs',
	'A652WeldingTemperature':'焊接温度',
	'A653WeldingDuration':'焊接时间',
	'A654FillingSystem':'灌装系统',
	'A655EmailSignature':'电子邮件签名',
	'A656NumberWasteBags':'垃圾袋数量',
	'A657FreqConverter':'频率转换器',
	'A658ServoDrives':'伺服驱动器',
	'A656Voltage':'电压',
	'A657Volt':'V',
	'A658U1':'U1',
	'A659U2':'U2',
	'A660U3':'U3',
	'A661U12':'U12',
	'A662U23':'U23',
	'A663U31':'U31',
	'A664Current':'权力',
	'A665Amps':'A',
	'A666I1':'I1',
	'A667I2':'I2',
	'A668I3':'I3',
	'A669Frequency':'頻率',
	'A670Hertz':'Hz',
	'A671Pressure':'压力',
	'A672Bar':'bar',
	'A673Moisture':'dew point ',
	'A674AirFlowUnit':'l/s',
	'A675AnnualConsum':'年度消费',
	'A676MonthlyConsum':'月度消费',
	'A677FreeConsum':'消费量',
	'A678PowerEfficiency':'功率效率',
	'A679BagsPerKWH':'袋子 / kW/h',
	'A681SinceReset':'由于复位',
	'A682LastTimeSortUse':'自上次使用以来',
	'A683SortNumber':'品种编号',
	'A684Overall':'总的来说',
	'A685AirEfficiency':'空气效率',
	'A686BagsPerAirFlowUnit':'每袋 l/s',
	'A687Jan':'1月',
	'A688Feb':'2月',
	'A689Mar':'3月',
	'A690Apr':'4月',
	'A691Mai':'5月',
	'A692Jun':'6月',
	'A693Jul':'7月',
	'A694Aug':'8月',
	'A695Sep':'9月',
	'A696Oct':'10月',
	'A697Nov':'11月',
	'A698Dec':'12月',
	'A699Year':'年',
	'A700EnterDateRange':'输入日期范围',
	'A701PowerConsumption':'消耗功率',
	'A702AirConsumption':'空气消耗量',
	'A703FlowControlGate':'流量控制门',
	'A704BagDirectionConv':'袋子方向输送机',
	'A705BottomBearing':'底部轴承',
	'A706PressureSwitch':'压力开关',
	'A707ElecModules':'电子模块',
	'A708SpoutWeight':'组件规模',
	'A709Search':'搜索',
	'A710Overweight':'超重袋',
	'A711Underweight':'超轻包',
	'A712RotaryDriveFreqConLoad':'变频旋转驱动',
	'A713MeanValueLoadInPercent':'平均值/负载百分比',
	'A714EmptyBagDischarge':'空袋卸料',
	'A715Bellow1':'波纹管密封',
	'A716TotalCounter':'总计数器',
	'A717Spout0Counter':'加油站 0',
	'A718LSdirtyCounter':'光栅 CW 脏',
	'A719Peripherie':'undefined',
	'A720Robot':'undefined',
	'A721DepositBelt':'undefined',
	'A722RollerConveyor1':'undefined',
	'A723PalletCentering1':'undefined',
	'A724RollerConveyor2':'undefined',
	'A725PalletCentering2':'undefined',
	'A726GripperClamp':'undefined',
	'A727GripperMotor':'undefined',
	'A728AdjustmentCamera':'undefined',
	'A729BagSize':'undefined',
	'A730PalletSupply':'undefined',
	'A731Length':'undefined',
	'A732Width':'undefined',
	'A733DepalPallets':'undefined',
	'A734DepalBundles':'undefined',
	'A735RejectedBundles':'undefined',
	'A736BagsPerBundle':'undefined',
	'A737CountsOfDataPoints':'undefined',
	'A738EmptyKey':' ',
	'A739TrendMeanWeight':'称重数据 Ø 趋势',
	'A740TrendSD':'标准偏差数据 趋势',
	'A741PistonBlower1':'活塞式鼓风机 1',
	'A742PistonBlower2':'活塞式鼓风机 2',
	'A743RotaryFlap':'undefined',
	'A744CurrentLayer':'undefined',
	'A745CurrentBag':'undefined',
	'A746Days':'undefined',
	'A747LayerNo':'undefined',
	'A748BagNo':'undefined',
	'A749AnnualMaint':'undefined',
	'A901MachineCustomerOverview':'设备/ 用户浏览',
	'A902Machines':'设备',
	'A903Permissions':'允许',
	'A904CompanyName':'公司名称',
	'A905MachineName':'设备名称',
	'A906MachineType':'设备型号',
	'A907Order':'订单',
	'A908Action':'行动',
	'A909Remove':'删除',
	'A910ChooseCustomer':'选择客户',
	'A911ConnectMachines':'连接设备',
	'A912User':'用户',
	'A913FirstName':'名',
	'A914Surname':'姓',
	'A915Login':'登录',
	'A916Roles':'职位',
	'A917Company':'公司',
	'A918CreateUser':'创建用户',
	'A919Username':'用户名',
	'A920Password':'密码',
	'A921Name':'名称',
	'A922ParentCompany':'母公司',
	'A923Create':'创建',
	'A924Edit':'编辑',
	'A925SubCompany':'子公司',
	'A926Companies':'公司',
	'A927EditMachine':'编辑设备',
	'A928CompaniesFunctionality':'公司/功能性',
	'A930SelectAll':'全选',
	'A931DetailView':'细节浏览',
	'A932EditUser':'用户编辑',
	'A933Delete':'删除',
	'A934Cancel':'取消',
	'A935Save':'保存',
	'A936LoginFailed':'登录失败',
	'A936Malayalam':'Malayalam',
	'A937DisplayName1':'显示 名称1',
	'A938DisplayName2':'显示 名称2',
	'A939DisplayName3':'显示 名称3',
	'A940ForgotPassword':'忘记密码',
	'A941ForgotText':'没问题，输入您的用户名，您将会收到一封重置密码的链接邮件',
	'A942Submit':'提交',
	'A943EmailAddress':'邮箱地址',
	'A944ChangePassword':'修改密码',
	'A945CurrentPassword':'现用密码',
	'A946NewPassword':'新密码',
	'A947ConfirmPassword':'确认新密码',
	'A948Update':'更新',
	'A949IncorrectPassword':'密码不正确',
	'A950Metrics':'Metricspecs',
	'A950PasswordsNotMatch':'新密码不匹配',
	'A951EmailNotFound':'找不到邮箱地址',
	'A952InvalidEmail':'输入有效的邮箱地址',
	'A953EmailSent':'如果您的电子邮件存在于我们的数据库中，则会通过重置链接将一封电子邮件发送到您的邮件ID',
	'A954ForgetPasswordMailSubject':'重置密码',
	'A955ForgetPasswordMailTitle':'你好，用户名，',
	'A956ForgetPasswordMailBody':'<p>我们收到您重置密码的请求.<br>通过以下链接来重置密码. 如果您没有此请求, 请忽略此邮件该链接会在24小时内失效.<p><a href=\'RESET_LINK\' style=\'text-decoration: none;字体颜色: 白色: inline; border-radius: 2px; border: 0; margin: 0 10px; background: #09174a; color:white; line-height: 15px; width: auto; height: auto; box-sizing: content-box;\'>设置新密码</span></a><br><p>Best regards from the TrackYourPlant-Team</p>',
	'A957DateFormat':'dd.MM.yyyy',
	'A958D3DateFormat':'%d/%m/%Y',
	'A959DashboardMachineStateStandBy':'待定',
	'A959MessageUpdateSuccess':'信息已被更新',
	'A960MessageSaveSuccess':'信息已被保存',
	'A961Yes':'是',
	'A962No':'否',
	'A963ReportOverview':'报告浏览',
	'A964Machine':'设备',
	'A965Card':'卡',
	'A966ReportType':'报告方式',
	'A967HistogramReport':'柱状形报告',
	'A968Report':'报告',
	'A969Day':'一天',
	'A969French':'Français',
	'A970Week':'一周',
	'A971Month':'一个月',
	'A972HistoryChart':'历史图表',
	'A972HistoryChartBacth':'历史图表电流。批',
	'A973Unit':'[单位]',
	'A974Time':'计时',
	'A975ShowBagCounterStackChart':'查看包装图形表',
	'A976TestReportStarted':'测试报告生成开始',
	'A977ActiveSettingsRequired':'激活报告设置需先生成测试报告',
	'A978FirstDayRequired':'第一份报告需求时间',
	'A979CompanyDeleted':'公司名被删除',
	'A980CompanyDeleteError':'公司名不能被删除',
	'A981CompanyParentShouldDifferent':'公司名和总公司名称需为不同名称',
	'A982CompanyCreated':'公司名被保存',
	'A983CompanyCreateError':'公司名不能被保存',
	'A984CompanyAllFields':'进入所有板块',
	'A985UserCreated':'用户名被创建',
	'A986UserExists':'邮件账号已存在',
	'A987UserDeleted':'用户被删除',
	'A988IncludedInReport':'包含在报告里',
	'A989IncludedInHistogram':'报告里包含柱状图',
	'A990DateFormat':'DD.MM.YYYY',
	'A991MachineTime':'设备时间',
	'A992LocalTime':'电脑时间',
	'A993NoMachines':'无设备显示',
	'A994Imprint':'标记',
	'A995PrivacyPolicy':'隐私政策',
	'A996Chinese':'中文',
	'A997Portuguese':'Português',
	'A998Vietnamese':'Tiếng Việt ',
	'A999CompanyNameExists':'公司名稱已存在。',
	'B100ActivatingReports':'每次激活都会生成常规报告到下列邮箱地址',
	'B101EnterValidEmailIds':'输入有效的邮箱地址',
	'B101ValidFrom':'生效日期',
	'B102AtleastOneEmailIdRequired':'至少需要一个邮箱账号',
	'B102ValidTo':'有效期至',
	'B103MaximumLimitReached':'达到最大限',
	'C001FetchingWait':'提取数据，请稍等',
	'C002PreparingWait':'准备数据，请稍等',
	'C003RenderingWait':'图表生成中，请稍等',
	'C004PixelScale':'1 像素 ≈ {{时间间隔}}秒 ',
	'C005ChartError':'错误',
	'C006ChartOk':'OK',
	'D001PortalType':'入口',
	'D002CompanyDomain':'观感',
	'E001AlertSettingSavedSuccessfully':'警示设置成功',
	'E002SelectAtLeastOneAlertTypeToActivateAlerting':'选择至少一种警示型号来激活报警系统',
	'E003ErrorNumberMustBePositive':'错误代码必须有效',
	'E004AlertEmailNeedsToBeActive':'警示邮件需被激活',
	'E005AnErrorHasOccured':'错误',
	'E005ErrorMessageAlreadyExists':'信息已经存在',
	'E005OperationSuccesful':'操作成功',
	'E006ErrorMessageSave':'错误，信息未保存',
	'E006ErrorOccurredSettingsWasNotSaved':'错误，设置未保存',
	'E007ReportSettingsSuccessfullySaved':'报告设置成功保存',
	'E008ErrorSettingsDoNotExists':'错误。 设置不存在',
	'E009ReportSettingsSuccessfullyUpdated':'报告设置成功更新',
	'E010InvalidPassword':'无效的密码',
	'E011UserUpdated':'用户更新',
	'E012InvalidPassword':'无效密码',
	'E013UserUpdated':'用户更新成功',
	'E015CompanyMaxUsersLowerThanActiveUsers':'该公司的活跃用户已经超过设置的最大用户数',
	'E016PasswordChangedSuccessfully':'密码修改成功',
	'F001_CurrentOrder':'当前订单',
	'F002_ProductID':'产品编号',
	'F003_BatchStart':'批量开始',
	'F004_Targetweight':'目标重量（体重',
	'F005_RemainingFill':'剩余馅料',
	'F006_CurrentProduction':'当前生产',
	'F007_FillStatistic':'填写统计',
	'F010_Sum':'和',
	'F011_Centering':'居中',
	'F012_Debunging':'排除故障',
	'F013_Inert':'无效的',
	'F014_Filling_1':'填充 1',
	'F015_Filling_2':'填充 2',
	'F016_Filling_3':'填充 3',
	'F017_Filling_4':'填充 4',
	'F018_Bunging':'密封盖',
	'F019_Sealing':'密封圈',
	'F020_Position':'位置',
	'F021_TransportCycle':'运输周期',
	'F022_CheckWeigher':'校正秤',
	'F023_Closing':'关闭',
	'F024_Palletizing':'码垛',
	'F025_EmptyPaletMagazin':'空托盘库',
	'F026_DePalletizing':'码垛机',
	'F027_Transport':'传送',
	'F028_PailSeperator':'提桶分离器',
	'F029_HangUpCap':'开盖',
	'F030_PushInCap':'拧盖',
	'F031_TestConsoles':'测试控制台',
	'F050_TU2':'TU 2',
	'F051_TU1':'TU 1',
	'F052_LowerTol':'低于误差范围',
	'F053_InTol':'在误差范围内',
	'F054_UpperTol':'超过误差范围',
	'F055_TO1':'TO 1',
	'F056_TO2':'TO 2',
	'F060_ProductInfeed':'物料供给',
	'F061_EmptyTruss':'空桁架',
	'F062DetailedDateFormat':'天/月/年',
	'F090_Minuten':'分钟',
	'F091_Stunden':'时间',
	'F100_Eichung':'下一次校准',
	'F101_TransKette':'输送机链条',
	'F102_Hubtor':'提升门',
	'F103_ZentRollen':'对中滚筒',
	'F104_SpundSuch':'桶孔定位器',
	'F105_Aushub':'容器提升',
	'F106_WerkSchlitten':'刀架',
	'F107_Aufschrauber':'开盖时间',
	'F108_VakuSys':'开盖真空系统',
	'F109_HubAufSchraub':'开盖气缸',
	'F110_OelAufSchraub':'开盖润滑',
	'F111_Inertgas':'惰化',
	'F112_Aushub_F1':'容器提升',
	'F113_HE_F1':'提升装置',
	'F114_TFE_F1':'滴漏液收集槽',
	'F115_Aushub_F2':'容器提升',
	'F116_HE_F2':'提升装置',
	'F117_TFE_F2':'滴漏液收集槽',
	'F118_ZentDorn':'中心轴',
	'F119_Schiebetisch':'滑动台',
	'F120_Kappenband':'输送机',
	'F121_VakuKap':'真空分离器',
	'F122_KapSort':'分类',
	'F123_VerKap':'盖分离器',
	'F124_KapZu':'盖塞子',
	'F125_Schrauber':'拧盖',
	'F126_VakuSchrauber':'真空拧盖系统',
	'F127_HubSchrauber':'拧盖气缸',
	'F128_OelZuSchraub':'拧盖润滑',
	'F129_Dichtung':'拧盖头密封',
	'F130_HubClinch':'气缸密封件',
	'F131_Clinchen':'密封',
	'F132_OelClincher':'密封润滑',
	'F133_VakuClincher':'真空密封系统',
	'G001BatchReport':'批量报告',
	'G002ActivateBatchReport':'激活批量报告',
	'Hülsen MIN-Melder':'低料位计',
	'IVT-Simulation':'INTEGRA® ',
	'Kabel Sattelhöhenv':'袋鞍高度调节电缆',
	'MACHINE_AVAILABILITY_RED_ZONE':'红色区域内设备就绪',
	'MACHINE_AVAILABILITY_YELLOW_ZONE':'黄色区域内设备就绪',
	'Maint Interval 0':'维修周期 0',
	'Maint Interval 1':'维修周期 1',
	'Maint Interval 10':'维修周期 10',
	'Maint Interval 11':'维修周期 11',
	'Maint Interval 12':'维修周期 12',
	'Maint Interval 13':'维修周期 13',
	'Maint Interval 14':'维修周期 14',
	'Maint Interval 2':'维修周期 2',
	'Maint Interval 3':'维修周期 3',
	'Maint Interval 4':'维修周期 4',
	'Maint Interval 5':'维修周期 5',
	'Maint Interval 6':'维修周期6',
	'Maint Interval 7':'维修周期 7',
	'Maint Interval 8':'维修周期 8',
	'Maint Interval 9':'维修周期 9',
	'miniADAMS-Messe':'ROTO-PACKER®',
	'N042BrazAlertGlobalAccL0':'全球加速度超过L0',
	'N042BrazAlertGlobalAccL1':'全球加速度超过L1',
	'N042BrazAlertGlobalAccL2':'全球加速度超过L2',
	'N042BrazAlertGlobalAccL3':'全球加速度超过L3',
	'N042BrazAlertGlobalAccL4':'全球加速度超过L4',
	'N042BrazAlertGlobalAmplL0':'整体振幅越过L0',
	'N042BrazAlertGlobalAmplL1':'整体振幅越过L1',
	'N042BrazAlertGlobalAmplL2':'整体振幅越过L2',
	'N042BrazAlertGlobalAmplL3':'整体振幅越过L3',
	'N042BrazAlertGlobalAmplL4':'整体振幅越过L4',
	'N042BrazAlertMaxRmsAccL0':'LB，CB和RB传感器的最大rms加速度越过L0',
	'N042BrazAlertMaxRmsAccL1':'LB，CB和RB传感器的最大rms加速度越过L1',
	'N042BrazAlertMaxRmsAccL2':'LB，CB和RB传感器的最大rms加速度越过L2',
	'N042BrazAlertMaxTempBearingSensL0':'轴承传感器的最高温度超过L0',
	'N042BrazAlertMaxTempBearingSensL1':'轴承传感器的最高温度超过L1',
	'N042BrazAlertMaxTempBearingSensL2':'轴承传感器的最高温度超过L2',
	'N042BrazAlertMaxZAxisSensorsL0':'lfs，rfs lds和红色传感器的z轴的最大加速度越过L0',
	'N042BrazAlertMaxZAxisSensorsL1':'lfs，rfs lds和红色传感器的z轴的最大加速度越过L1',
	'N042BrazAlertMaxZAxisSensorsL2':'lfs，rfs lds和红色传感器的z轴的最大加速度越过L2',
	'N042BrazAlertRotFreqL0':'旋转频率越过L0',
	'N042BrazAlertRotFreqL1':'旋转频率越过L1',
	'N042BrazAlertRotFreqL2':'旋转频率越过L2',
	'N042BrazAlertRotFreqL3':'旋转频率越过L3',
	'N042BrazAlertRotFreqL4':'旋转频率越过L4',
	'N042BrazAlertType':'巴西警报',
	'N042GlobalAcceleration':'全球加速度超过',
	'N042GlobalAmplitude':'整体振幅',
	'N042GlobalFrequency':'全球频率',
	'N043CardCtxMenuShowConfigScreen':'配置卡',
	'N043ConfigSaveFailure':'配置更新失败',
	'N043ConfigSaveSuccess':'配置更新成功',
	'N044BrazilAlertMailSubject':'Pulse CM Alert : MACHINE_NAME',
	'N045AlertWhenThresholdCrossed':'超过此限制时发出警报',
	'N046RawDataTab':'原始数据',
	'N047SensorL1':'传感器 L 1',
	'N047SensorL4':'传感器 L 4',
	'N047SensorR1':'传感器 R 1',
	'N047SensorR4':'传感器 R 4',
	'N048Acceleration':'加速',
	'N048AccelerationLocal':'本地加速',
	'N048AccelerationX':'加速 X',
	'N048AccelerationY':'加速 Y',
	'N048AccelerationZ':'加速 Z',
	'N048Amplitude':'振幅',
	'N048AmplitudeLocal':'振幅 Local',
	'N048AmplitudeX':'振幅 X',
	'N048AmplitudeY':'振幅 Y',
	'N048AmplitudeZ':'振幅 Z',
	'N048ChooseEndDate':'選擇結束日期',
	'N048ChooseStartDate':'选择开始日期',
	'N048Constant':'不变',
	'N048CourseAngle':'航向角',
	'N048DeltaTemperature':'三角洲温度',
	'N048Frequency':'频率',
	'N048LastSeen':'最后一次露面',
	'N048MovementAngle':'动作角度',
	'N048OffsetX':'X 抵消',
	'N048OffsetY':'Y 抵消',
	'N048OffsetZ':'Z 抵消',
	'N048PhaseAngle':'相位角',
	'N048MotionAngle':'运动角度',
	'N048RMS':'有效值',
	'N048Rpm':'转速',
	'N048SensorDescription':'传感器说明',
	'N048SensorId':'感应器编号',
	'N048SensorTemperature':'传感器温度',
	'N048Side':'侧',
	'N048SiteName':'网站名称',
	'N048UpdatedTime':'更新时间',
	'N049accelFDCompLeft':'Accel.FDComp.Left',
	'N049accelFDCompRight':'Accel.FDComp.Right',
	'N049accelLRCompDisch':'Accel.LRComp.Disch',
	'N049accelLRCompFeed':'Accel.LRComp.Feed',
	'N049Date':'日期',
	'N049ErrorFetchingData':'提取数据时出错',
	'N049FrequencyDaily':'天',
	'N049FrequencyHourly':'小时',
	'N049FrequencyWeekly':'周',
	'N049Length':'长度',
	'N049ModelName':'型号名称',
	'N049pitch':'沥青',
	'N049roll':'滚',
	'N049Rpm':'Rpm',
	'N049strokeDeparture':'中风离开',
	'N049TuningWizard':'调优向导',
	'N049twistG':'Twist.G',
	'N049twistXG':'Twist.XG',
	'N049twistYG':'Twist.YG',
	'N049twistZG':'Twist.ZG',
	'N049Width':'宽度',
	'N049xgFDCompLeft':'X.G.FDComp.Left',
	'N049xgFDCompRight':'X.G.FDComp.Right',
	'N049xgLrCompDisch':'X.G.LRComp.Disch',
	'N049xgLrCompFeed':'X.G.LRComp.Feed',
	'N049ygFDCompLeft':'Y.G.FDComp.Left',
	'N049ygFDCompRight':'Y.G.FDComp.Right',
	'N049ygLrCompDisch':'Y.G.LRComp.Disch',
	'N049ygLrCompFeed':'Y.G.LRComp.Feed',
	'N049zgFDCompLeft':'Z.G.FDComp.Left',
	'N049zgFDCompRight':'Z.G.FDComp.Right',
	'N049zgLrCompDisch':'Z.G.LRComp.Disch',
	'N049zgLrCompFeed':'Z.G.LRComp.Feed',
	'N049zgMax':'ZG.Max',
	'N050AnalystCommentary':'分析师评论',
	'N050mAvgLong':'长',
	'N050mAvgShort':'mavg短',
	'N050TrendAnalysis':'趋势分析',
	'N051SensorL1':'傳感器L1',
	'N051SensorL2':'傳感器L2',
	'N051SensorL3':'傳感器L3',
	'N051SensorL4':'傳感器L4',
	'N051SensorMovement':'傳感器運動',
	'N051SensorR1':'傳感器R1',
	'N051SensorR2':'傳感器R2',
	'N051SensorR3':'傳感器R3',
	'N051SensorR4':'傳感器R4',
	'N052Anomaly':'异常现象',
	'N052AnomalyDetectionTab':'异常检测',
	'N052BrazAlertTypeSL1LocalAmplitudeL0':'传感器L1局部振幅L0',
	'N052BrazAlertTypeSL1LocalAmplitudeL1':'传感器L1局部振幅L1',
	'N052BrazAlertTypeSL1LocalAmplitudeL2':'传感器L1局部振幅L2',
	'N052BrazAlertTypeSL1LocalAmplitudeL3':'传感器L1局部振幅L3',
	'N052BrazAlertTypeSL1LocalAmplitudeL4':'传感器L1局部振幅L4',
	'N052BrazAlertTypeSL1LocalAmplitudeL5':'传感器L1局部振幅L5',
	'N052BrazAlertTypeSL1SensorTempL0':'传感器L1传感器温度超过L0',
	'N052BrazAlertTypeSL1SensorTempL1':'传感器L1传感器温度超过L1',
	'N052BrazAlertTypeSL1SensorTempL2':'传感器L1传感器温度超过L2',
	'N052BrazAlertTypeSL1SensorTempL3':'传感器L1传感器温度超过L3',
	'N052ObservedValue':'观测到的',
	'N053Angle':'角度（0)',
	'N053BrazAlertTypeSL1LocalAccelerationL0':'传感器L1局部加速度越过L0',
	'N053BrazAlertTypeSL1LocalAccelerationL1':'传感器L1局部加速度越过L1',
	'N053BrazAlertTypeSL1LocalAccelerationL2':'传感器L1局部加速度越过L2',
	'N053BrazAlertTypeSL1LocalAccelerationL3':'传感器L1局部加速度越过L3',
	'N053BrazAlertTypeSL1LocalAccelerationL4':'传感器L1局部加速度越过L4',
	'N053BrazAlertTypeSL1LocalAccelerationL5':'传感器L1局部加速度越过L5',
	'N053BrazAlertTypeSl1RotationFreqL0':'传感器L1本地旋转频率L0',
	'N053BrazAlertTypeSl1RotationFreqL1':'传感器L1本地旋转频率L1',
	'N053BrazAlertTypeSl1RotationFreqL2':'传感器L1本地旋转频率L2',
	'N053BrazAlertTypeSl1RotationFreqL3':'传感器L1本地旋转频率L3',
	'N053BrazAlertTypeSl1RotationFreqL4':'传感器L1本地旋转频率L4',
	'N053BrazAlertTypeSl1RotationFreqL5':'传感器L1本地旋转频率L5',
	'N053BrazAlertTypeSL1ZAxisL0':'传感器L1与z轴交叉的加速度L0',
	'N053BrazAlertTypeSL1ZAxisL1':'传感器L1与z轴交叉的加速度L1',
	'N053BrazAlertTypeSL1ZAxisL2':'传感器L1与z轴交叉的加速度L2',
	'N053BrazAlertTypeSL1ZAxisL3':'传感器L1与z轴交叉的加速度L3',
	'N053Displacement':'移位',
	'N053G':'g',
	'N053LocalAmplitude':'局部振幅',
	'N053LocalRotationFrequency':'局部旋转（频率）',
	'N053MM':'mm',
	'N053XAxis':'X轴',
	'N053YAxis':'Y轴',
	'N053ZAxis':'Z轴',
	'N053ZAxisAcceleration':'Z轴加速度',
	'N054Higher':'上',
	'N054Lower':'降低',
	'N054StructuralAnalysis':'结构分析',
	'N054TWvarsForecasts':'TW vars预测',
	'N054TWvarsFull':'TW vars full',
	'N054Value':'價值',
	'N0551On0Off':'1 =开：0 =关',
	'N055BrazTabGeneral':'一般的',
	'N055ChooseStartEndDate':'选择开始日期和结束日期',
	'N055Close':'关闭',
	'N055Custom':'风俗',
	'N055MaterialFlow':'物料流',
	'N055MAxBearingTemperature':'最高轴承温度',
	'N055MaxRmsAccelBearingSensors':'轴承传感器的最大Rms加速度',
	'N055MazAccelZAxis':'结构传感器 的Z轴的最大加速度',
	'N055Off':'离开',
	'N055On':'在',
	'N055RDCenter':'機器視圖',
	'N055ShowHistory':'显示历史',
	'N055StartDateCannotBeAfterEndDate':'开始日期不能晚于结束日期',
	'N056Commentary':'评论',
	'N056EvaluateEquipment':'设备评估条件',
	'N056High80':'高80',
	'N056High95':'高95',
	'N056Importance':'重要性',
	'N056Low80':'低80',
	'N056Low95':'低95',
	'N056MaxLatAccelZgMax':'最大横向加速度ZG.Max',
	'N056Pred':'Pred',
	'N056Recommendations':'推荐建议',
	'N056Type':'类型',
	'N056Report':'报告',
	'N0571Year':'1年',
	'N057AccelGms':'累计',
	'N057AccelRms':'加速度RMS',
	'N057AccelSpectrum':'加速度谱',
	'N057AccelTimeWForm':'加速时间波形',
	'N057Axial':'轴向的',
	'N057CrestFactor':'波峰因数',
	'N057DegreeCelcius':'°C',
	'N057FreqHz':'频率（赫兹',
	'N057FullSpectrum':'全谱',
	'N057FundBearingDefectFreq':'资金，轴承，缺陷频率',
	'N057HighHarBearFreq':'更高的谐波轴承频率',
	'N057HighRes':'High Res',
	'N057HistoricTemperature':'历史温度',
	'N057Horizontal':'水平的',
	'N057Latest':'最新的',
	'N057LowHarBearFreq':'较低的谐波轴承频率',
	'N057mms':'mm/s',
	'N057PeakToPeak':'峰到峰',
	'N057PeakToPeakMg':'Peak to peak(mg)',
	'N057PowerBands':'强力乐队',
	'N057RedAlert':'红色警报',
	'N057Rmsmg':'Rms(mg)',
	'N057Rmsmms':'Rms(mm/s)',
	'N057SensorLB1':'传感器LB1',
	'N057SensorLB2':'传感器LB2',
	'N057SensorRB1':'传感器RB1',
	'N057SensorRB2':'传感器RB2',
	'N057SensorCB1':'传感器CB1',
	'N057SensorCB2':'传感器CB2',
	'N057Spectrum':'光谱',
	'N057Temperature':'温度',
	'N057TimeMs':'时间（毫秒）',
	'N057Trending':'趋向',
	'N057VelocityRms':'速度RMS',
	'N057Vertical':'垂直的',
	'N057X1Rpm':'1X Rpm',
	'N057YellowAlert':'黄色警报',
	'N058ImageUploadedFailure':'图片上传失败',
	'N058ImageUploadedSuccesfully':'图片上传成功',
	'N058UploadImage':'上传图片',
	'N059Print':'打印',
	'N060MachineHistoricalData':'设备历史数据',
	'N061HistoricalBearingData':'历史关系数据',
	'N062HistoricalSpectrumData':'历史范围数据',
	'N063GlobalAcceleration':'全球积累',
	'N064RotationFrequency':'旋转（频率）',
	'N065GlobalBreadth':'全球幅度',
	'N066LocalAccelerationsEachStructureSensor':'各感应器速度',
	'N067MotionAngleEachStructureSensor':'每个结构传感器的运动角度',
	'N067PhaseAngleEachStructureSensor':'每个结构传感器的相位角',
	'N067LocalAmplitudesEachStructureSensor':'各感应器振幅',
	'N068LateralAccelerationsStructureSensor':'各感应器侧向加速',
	'N069TemperatureEachBearingSensor':'各轴承感应器温度',
	'N070PeakToPeakEachBearingSensor':'各轴承感应器峰值',
	'N071AccelerationRMS':'加速RMS',
	'N072VelocityRMS':'速度RMS',
	'N073CrestFactor':'波峰因素',
	'N074PowerBandsSensorLB1':'电源带-感应器LB1',
	'N075PowerBandsSensorRB1':'电源带-感应器RB1',
	'N076PowerBandsSensorLB2':'电源带-感应器LB1',
	'N077PowerBandsSensorRB2':'电源带-感应器RB2',
	'N077PowerBandsSensorCB1':'电源带-感应器CB1',
	'N077PowerBandsSensorCB2':'电源带-感应器CB2',
	'N078TimeWaveformDataAcceleration':'时间波形数据-加速',
	'N079FrequencyDomainAcceleration':'频域',
	'N080Select':'选择',
	'N081SelectedPeriod':'选择时段',
	'N100Capacity':'能力',
	'N101Product':'产品',
	'N102Undersized':'小于正常 ',
	'N103Oversized':'大于正常',
	'N104Speed':'速度RMS',
	'N105Inclination':'倾向',
	'N106BoardHeigh':'高度',
	'N107Moisture':'潮湿',
	'N108WaterAdd':'水量',
	'N109OpMode':'操作模式',
	'N110Auto':'自动模式',
	'N110Hand':'手动模式',
	'N111OpHours':'操作时间',
	'N112Temp':'温度',
	'N113BearingTemp1':'轴承温度1',
	'N114BearingTemp2':'轴承温度2',
	'N115DriveTemp':'驱动器温度',
	'N116ConvTemp':'转换器温度',
	'N117PowerInput':'电源输入',
	'New001BagTransArea':'袋输送区域',
	'New002EmptyPalArea':'空托盘区',
	'New003FullPalArea':'满托盘区',
	'New004SheetDispArea':'空托盘覆膜机',
	'New005PneuFalut':'气动故障',
	'New006ElectricFault':'电动故障',
	'New007Introduction':'介绍',
	'New008Orientation':'定向',
	'New009PreGrouping':'预分组',
	'New010Grouping':'分组',
	'New011RowPusher':'推板',
	'New012SlideTable':'滑台 & 整形板',
	'New013Elevator':'提升器',
	'New014Gantry':'机架',
	'New015Gripper':'抓手',
	'New016FilmDispenser':'空托盘覆膜机',
	'New017FullPalTrans':'满托盘输送',
	'New018EmpPalDisp':'空托盘分离器',
	'New019FilmDisp':'空托盘覆膜机',
	'New020EmpCardDisp':'纸板分离机',
	'New021EmpPalTrans':'空托盘输送机',
	'New022EjectingDev':'剔除装置',
	'New023MetDetector':'金属检测',
	'New024MarkingDev':'喷码机',
	'New025LabellingDev':'标签机',
	'New026PalletStracker':'托盘码垛',
	'New027PalletPress':'压垛',
	'New028Wrapper':'缠膜/套膜机',
	'New029Fault':'故障',
	'New030StandBy':'暂停',
	'New031BagProduction':'填充包数',
	'New032WeightDetection':'重量检测',
	'New033LeakingDetection':'遗撒检测',
	'New034PrintingFault':'打印错误',
	'New035BagProduced':'包装包数',
	'New036SamplingEjection':'样品退回',
	'New037PalletProduction':'托盘数',
	'New038PalletInfeed':'托盘净给',
	'New039PalletOutfeed':'托盘输出',
	'New040PalletIncomplete':'未完成托盘',
	'New041Checkweigher':'复检称',
	'New041PalledProduced':'托盘数',
	'New042ProgramChngsToday':'今天程序修改',
	'New042ProgramChngs':'程序修改',
	'New043EndofBatch':'批次结尾',
	'New044ProductonTime':'生产时间',
	'RVT8-Messe':'ROTO-PACKER® ',
	'RVT8-Simulation':'ROTO-PACKER® ',
	'TERAM2-Messe':'TERRAM®',
	'TERAM-Messe':'TERRAM®',
	'Typ84-Messe':'INTEGRA® ',
	'Waagenabgleich':'精度调节',
	'Zyklen':'循环',
	'N082AlertMe':'当此卡有任何警报时提醒我',
	'N083TimeFormat':'HH:mm',
	'N090Synced':'同步',
	'N091NotSynced':'不同步',
	'N084New':'最新的',
	'N085ConfigSettingSavedSuccessfully':'参数设置保存成功',
	'N86ErrorOccurredSettingsWasNotSaved':'参数设置保存错误',
	'A496OEEWeek':'每两周',
	'A497OEEMonthly':'每月最佳操作',
	'N87ServiceRequestUploadFiles':'长传文件',
	'N88ServiceRequestFileSizeError':'文件过大',
	'N89ServiceRequestFileTypeError':'不支持此文件类型',
	'N90FilesUploadedSuccesfully':'成功上传文件',
	'N91FilesUploadedFailure':'上传文件错误',
	'N932IncludeExcelReport':'包括excel报告',
	'N94ServiceRequestFileLimitError':'文件过大',
	'N055MaxHAxisRmsAccelBearingSensors':'undefined',
	'N055MaxAxialRmsAccelBearingSensors':'undefined',
	'N053MG':'undefined',
	'N056DummyMachine':'Dummy machines',
	'N057NiagaraAlertYellow':'黄色警报',
	'N058NiagaraAlertOrange':'橙色警报',
	'N059NiagaraAlertRed':'红色警报',
	'N060AddDummyMachine':'undefined',
	'N96ConfigureValue':'配置值',
	'N97NoDataAvailable':'没有可用数据',
	'N98TimeStamp':'Timestamps',
	'N99Documentation':'Documentation',
	'N100ErrorFileHeader':'undefined',
	'N101DocumentationHeader':'undefined',
	'N102DownloadMachineTypeErrorMessageFile':'undefined',
	'N102DownloadMachineErrorMessageFile':'undefined',
	'N103ErrorInRetrievingErrorMessages':'undefined',
	'E011MaintIntervalSubmitSuccess':'undefined',
	'A368OpenPartsShop':'undefined',
	'N104ExtSparePartCatalog':'undefined',
	'N105OpenExtSparePartCatalog':'undefined',
	'N106LperH':'l/h',
	'N107Degree':'°',
	'N107DegreeText':'° (Degree)',
	'N108UseRelativeValues':'undefined',
	'N109PartsShopUsername':'undefined',
	'N110Tons':'t',
	'AX_VibratingBodyCondition':'undefined',
	'AX_BearingCondition':'undefined',
	'AX_AlarmsCount':'undefined',
	'AX_Last24Hours':'undefined',
	'D001TransBearingCondition':'undefined',
	'D002TransBearingCondition':'undefined',
	'D003TransBearingCondition':'undefined',
	'D004TransBearingCondition':'undefined',
	'D005TransBearingCondition':'undefined',
	'D006TransBearingCondition':'undefined',
	'D007TransBearingCondition':'undefined',
	'D008TransBearingCondition':'undefined',
	'D009TransBearingCondition':'undefined',
	'D010TransBearingCondition':'undefined',
	'D011TransBearingCondition':'undefined',
	'D012TransBearingCondition':'undefined',
	'D013TransBearingCondition':'undefined',
	'D014TransBearingCondition':'undefined',
	'D015TransBearingCondition':'undefined',
	'D016TransBearingCondition':'undefined',
	'D001TransVBCondition':'undefined',
	'D002TransVBCondition':'undefined',
	'D003TransVBCondition':'undefined',
	'D004TransVBCondition':'undefined',
	'D005TransVBCondition':'undefined',
	'D006TransVBCondition':'undefined',
	'D007TransVBCondition':'undefined',
	'D008TransVBCondition':'undefined',
	'D009TransVBCondition':'undefined',
	'D010TransVBCondition':'undefined',
	'D011TransVBCondition':'undefined',
	'D012TransVBCondition':'undefined',
	'D013TransVBCondition':'undefined',
	'D014TransVBCondition':'undefined',
	'D015TransVBCondition':'undefined',
	'D016TransVBCondition':'undefined',
	'F01CardExpProduction':'***Coming Soon***',
	'F01CardExpAvailability':'***Coming Soon***',
	'F01CardExpMaintenance':'***Coming Soon***',
	'F01CardExpProductionTime':'***Coming Soon***',
	'F01CardExpProductionTimeBaumit':'***Coming Soon***',
	'F01CardExpProductionToday':'***Coming Soon***',
	'F01CardExpRemProduction':'***Coming Soon***',
	'F01CardExpLastReset':'***Coming Soon***',
	'F01CardExpFaults_C':'***Coming Soon***',
	'F01CardExpFaults_M':'***Coming Soon***',
	'F01CardExpEnergy':'***Coming Soon***',
	'F01CardExpFillingTime':'***Coming Soon***',
	'F01CardExpBagManufacture':'***Coming Soon***',
	'F01CardExpWeightData':'***Coming Soon***',
	'F01CardExpStdDevData':'***Coming Soon***',
	'F01CardExpIncorrectWeight':'***Coming Soon***',
	'F01CardExpInterrupts':'***Coming Soon***',
	'F01CardExpTypeChange':'***Coming Soon***',
	'F01CardExpBagCounterPerFS':'***Coming Soon***',
	'F01CardExpNotPlacedBags':'***Coming Soon***',
	'F01CardExpEmptyBagMagazineToday':'***Coming Soon***',
	'F01CardExpRejectsToday':'***Coming Soon***',
	'F01CardExpCollectiveFaultsToday':'***Coming Soon***',
	'F01CardExpGoodWeights':'***Coming Soon***',
	'F01CardExpFltCompAir':'***Coming Soon***',
	'F01CardExpStrokesPump':'***Coming Soon***',
	'F01CardExpMaxStrokeDur':'***Coming Soon***',
	'F01CardExpAvgStrokes':'***Coming Soon***',
	'F01CardExpInternalPressure':'***Coming Soon***',
	'F01CardExpFillings':'***Coming Soon***',
	'F01CardExpStartOfOrder':'***Coming Soon***',
	'F01CardExpProductID':'***Coming Soon***',
	'F01CardExpTargetWeight':'***Coming Soon***',
	'F01CardExpFillingCount':'***Coming Soon***',
	'F01CardExpRemainingNumberFillings':'***Coming Soon***',
	'F01CardExpCycleTime':'***Coming Soon***',
	'F01CardExpFaultLength':'***Coming Soon***',
	'F01CardExpExtFaultLength':'***Coming Soon***',
	'F01CardExpWithinTolerance':'***Coming Soon***',
	'F01CardExpOutsideTolerance':'***Coming Soon***',
	'F01CardExpCurrentProduction':'***Coming Soon***',
	'F01CardExpFillStatistic':'***Coming Soon***',
	'F01CardExpRDCenter':'***Coming Soon***',
	'F01CardExpGlobalAcceleration':'***Coming Soon***',
	'F01CardExpGlobalFrequency':'***Coming Soon***',
	'F01CardExpGlobalAmplitude':'***Coming Soon***',
	'F01CardExpMazAccelZAxis':'***Coming Soon***',
	'F01CardExpMaxHAxisRmsAccelBearingSensors':'***Coming Soon***',
	'F01CardExpMaxRmsAccelBearingSensors':'***Coming Soon***',
	'F01CardExpMaxAxialRmsAccelBearingSensors':'***Coming Soon***',
	'F01CardExpMAxBearingTemperature':'***Coming Soon***',
	'F01CardExpSensorMovement':'***Coming Soon***',
	'F01CardExpAccelerationLocal':'***Coming Soon***',
	'F01CardExpAcceleration':'***Coming Soon***',
	'F01CardExpAxisAcceleration':'***Coming Soon***',
	'F01CardExpLocalRotationFrequency':'***Coming Soon***',
	'F01CardExpLocalAmplitude':'***Coming Soon***',
	'F01CardExpDisplacement':'***Coming Soon***',
	'F01CardExpPhaseAngle':'***Coming Soon***',
	'F01CardExpMotionAngle':'***Coming Soon***',
	'F01CardExpSensorTemperature':'***Coming Soon***',
	'F01CardExpBagQuality':'***Coming Soon***',
	'F01CardExpFaults':'***Coming Soon***',
	'F01CardExpBunChangeAbs':'***Coming Soon***',
	'F01CardExpBunChangeToday':'***Coming Soon***',
	'F01CardExpBagsPerBundle':'***Coming Soon***',
	'F01CardExpTypeChanges':'***Coming Soon***',
	'F01CardExpSortChangeToday':'***Coming Soon***',
	'F01CardExpCyclesToday':'***Coming Soon***',
	'F01CardExpMetalDetectorToday':'***Coming Soon***',
	'F01CardExpIncorrectBags':'***Coming Soon***',
	'F01CardExpOpenBags':'***Coming Soon***',
	'F01CardExpSampleBags':'***Coming Soon***',
	'F01CardExpRejectionsToday':'***Coming Soon***',
	'F01CardExpContainer':'***Coming Soon***',
	'F01CardExpEmptyRejects':'***Coming Soon***',
	'F01CardExpFullRejects':'***Coming Soon***',
	'F01CardExpProductionRate':'***Coming Soon***',
	'F01CardExpDailyProduction':'***Coming Soon***',
	'F01CardExpFillingStation':'***Coming Soon***',
	'F01CardExpPalletizer':'***Coming Soon***',
	'F01CardExpEmptyPalArea':'***Coming Soon***',
	'F01CardExpBagTransArea':'***Coming Soon***',
	'F01CardExpFullPalArea':'***Coming Soon***',
	'F01CardExpSheetDispArea':'***Coming Soon***',
	'F01CardExpProductonTime':'***Coming Soon***',
	'F01CardExpProgramChngsToday':'***Coming Soon***',
	'F01CardExpEndofBatch':'***Coming Soon***',
	'F01CardExpEmptyPallets':'***Coming Soon***',
	'F01CardExpBagProduction':'***Coming Soon***',
	'F01CardExpPalletProduction':'***Coming Soon***',
	'F01CardExpFSDifference':'***Coming Soon***',
	'F01CardExpProduct':'***Coming Soon***',
	'F01CardExpCapacity':'***Coming Soon***',
	'F01CardExpUndersized':'***Coming Soon***',
	'F01CardExpOversized':'***Coming Soon***',
	'F01CardExpSpeed':'***Coming Soon***',
	'F01CardExpInclination':'***Coming Soon***',
	'F01CardExpBoardHeigh':'***Coming Soon***',
	'F01CardExpMoisture':'***Coming Soon***',
	'F01CardExpWaterAdd':'***Coming Soon***',
	'F01CardExpOpMode':'***Coming Soon***',
	'F01CardExpOpHours':'***Coming Soon***',
	'F01CardExpTemp':'***Coming Soon***',
	'F01CardExpPowerInput':'***Coming Soon***',
	'F01CardExpFullPalletCount':'***Coming Soon***',
	'F01CardExpLoadEmptyPallets':'***Coming Soon***',
	'F01CardExpOeeValue':'***Coming Soon***',
	'F01CardExpOeeAvailable':'***Coming Soon***',
	'F01CardExpOeePerformance':'***Coming Soon***',
	'F01CardExpOeeQuality':'***Coming Soon***',
	'F01CardExpOeeDowntime':'***Coming Soon***',
	'F01CardExpOperatingFactor':'***Coming Soon***',
	'F01CardExpThroughput':'***Coming Soon***',
	'F01CardExpOeeRejects':'***Coming Soon***',
	'F01CardExpMachineDetailPage':'***Coming Soon***',
	'F01CardExpReportSettingsPage':'***Coming Soon***',
	'F01CardExpReportOverviewPage':'***Coming Soon***',
	'F01CardExpCompanyOverviewPage':'***Coming Soon***',
	'F01CardExpAlertSettingsPage':'***Coming Soon***',
	'F01CardExpMsgAdminPage':'***Coming Soon***',
	'F01CardExpUserRolesPage':'***Coming Soon***',
	'F01CardExpCompaniesPage':'***Coming Soon***',
	'F01CardExpDummyMachinePage':'***Coming Soon***',
	'F01CardExpPermissionsPage':'***Coming Soon***',
	'F01CardExpMaintenanceAidPage':'***Coming Soon***',
	'F01CardExpMachinePage':'***Coming Soon***',
	'F01CardExpLogBookTab':'***Coming Soon***',
	'F01CardExpServiceRequestTab':'***Coming Soon***',
	'F01CardExpMessagesTab':'***Coming Soon***',
	'F01CardExpDocumentSTab':'***Coming Soon***',
	'F01CardExpOeeTab':'***Coming Soon***',
	'F01CardExpMaintenanceTab':'***Coming Soon***',
	'F01CardExpMachineSpecificTab':'***Coming Soon***',
	'F01CardExpGeneralTab':'***Coming Soon***',
	'F01CardExpGoodPerformance':'***Coming Soon***',
	'F01CardExpWrongApplBags':'***Coming Soon***',
	'F01CardExpReelChanges':'***Coming Soon***',
	'F01CardExpTypeCurrent':'***Coming Soon***',
	'F01CardExpInterruptsFeigeGeneral':'***Coming Soon***',
	'F01CardExpInterruptsAdams':'***Coming Soon***',
	'F01CardExpInterruptsISF':'***Coming Soon***',
	'F01CardExpInterruptsIVT':'***Coming Soon***',
	'F01CardExpInterruptsMiniAdams':'***Coming Soon***',
	'F01CardExpInterruptsRVT':'***Coming Soon***',
	'F01CardExpInterruptsNewtecTerram':'***Coming Soon***',
	'A-AlertTip-main_acc-ORANGE':'主加速橙色警报',
	'A-AlertTip-main_acc-YELLOW':'主加速黄色警报',
	'A-AlertTip-main_acc-RED':'主加速红色警戒',
	'A-AlertTip-u8MachAvail-AVAIL':'可用性警报',
};